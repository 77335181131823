import React, { useCallback, useState } from 'react';
import {
  Root,
  PhoneButton,
  Header,
  Main,
  FormTitle,
  FieldsContainer,
  ModalFooter,
  ButtonWrapper,
  POCWrapper,
  ModalHeader,
  InputSection,
  InputLabel,
} from '../styled';
import { PocType } from 'utils/entities';
import { ContactAgentSubmitterSource } from 'store/state/app/payloads';
import { POCLayout } from '../poc-layout';
import { createPhoneURI, useDisplayPhone } from 'hooks/useDisplayPhone';
import PhoneIcon from 'assets/svg/phone-new.svg';
import DoneIcon from 'assets/svg/done.svg';
import { useLocale } from 'locale';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { createMortgageValidationFn } from 'components/madad/contact-modal/utils';
import { getSpacing } from 'ds/theme/spacing';
import { ContactAgentModalSource } from 'store/state/app';
import { isBulletinPoiType, isProjectPoiType } from 'utils/marketplaceRoutes';
import replaceEmoji from 'utils/emojis';
import MailIcon from 'assets/svg/mail-forward.svg';
import CheckIcon from 'assets/svg/ok-check.svg';
import { Route, useRoute } from 'config/routes';
import { SpacingBlock } from 'components/spacing-block';
import { LeadContactTermsAndConditions } from 'components/term-and-conditions/LeadContactTermsAndConditions';
import {
  AuthenticationSocialSuggestion,
  AuthenticationSocialSuggestionWrapper,
} from 'components/authentication/social-buttons/styled';
import DescriptionApartment from 'components/inner-contact-form/description-apartment';
import {
  SuccessSentMessage,
  SuccessHeader,
  SuccessHeaderName,
  SentContainer,
  ProjectsCarouselWrapper,
  ProjectsHeader,
  ModalRoot,
} from './styledModeB';
import { first } from 'lodash';
import ProjectsCarousel from 'components/project-carousel/modeB';
import ListingsCarousel from './PostLeadListingsCarousel';
import { getPOCCompanyName, getPOCName } from 'helpers/contact';
import { MortgageFormProps } from './';
import { useABTests } from 'hooks/useABTests';
import SendIcon from 'assets/svg/send-alt.svg';
import CalendarIcon from 'assets/svg/tourAttended.svg';
import { PrioritizeFormProjectsAbTest } from 'config/abTests';


export const Mortgage: React.FC<MortgageFormProps> = props => {
  const {
    poi,
    isModal,
    isContacted,
    description,
    contactFormRef,
    onCallClickEvent,
    handleSubmitEvent,
    onContactPageSubmit,
    onSetContactModalPoiId,
    contactFormInitialValues,
    hidePhoneNumber,
    onContactAgentInputClick,
    source,
    isLeadingAgent,
    hasListingsCarousel,
  } = props;

  const {
    phone: initialPhone,
    name: initialName,
  } = contactFormInitialValues;

  const { t } = useLocale();
  const { route } = useRoute();
  const isProject = isProjectPoiType(poi);
  const { prioritizeFormProjects } = useABTests();
  const [ phoneNumberIsVisible, setPhoneNumberIsVisible ] = useState(false);
  const [ userMessage, setUserMessage ] = useState('');
  const [ userPhone, setUserPhone ] = useState(initialPhone || '');
  const [ userName, setUserName ] = useState(initialName || '');
  const [ formErrors, setFormErrors ] = useState<{ phone?: string; message?: string; }>({});
  const breakpoint = useScreenBreakpoint();
  const phone = useDisplayPhone({ id: poi.id, type: poi.type });
  const isMobile = breakpoint === 1;
  // Changes should be implemented only for range 4
  const isDesktop = breakpoint === 4;
  const poc = isBulletinPoiType(poi) && poi.poc;
  const showOnlyOffice = poc && 'showOnlyOffice' in poc && poc.showOnlyOffice;

  const validate = useCallback(createMortgageValidationFn({
    phone: {
      invalid: t('contactAgentModal.form.phone.invalid'),
      required: t('contactAgentModal.form.phone.required'),
    },
    message: {
      required: t('contactAgentModal.form.message.required'),
    },
  }), [ t ]);

  const onSubmit = () => {
    const errors = validate({
      phone: userPhone,
    });
    const formValues = {
      phone: userPhone,
      name: userName,
      message: userMessage,
      isMortgageConsult: false,
      type: poi.type,
      id: poi.id,
      ...(source ? { eventSource: source } : null),
    };
    setFormErrors(errors);

    let hasErrors = false;

    if (errors.phone) {
      hasErrors = true;
    }

    handleSubmitEvent(hasErrors, formValues);

    if (hasErrors) return;

    onContactPageSubmit({
      key: formValues.id,
      variables: formValues,
      source: ContactAgentSubmitterSource.Form,
    });
  };

  const onPhoneChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserPhone(e.currentTarget.value), [ setUserPhone ]);
  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.currentTarget.value), [ setUserName ]);
  const onMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserMessage(replaceEmoji(e.currentTarget.value)), [ setUserMessage ]);
  const developer = poi && isProjectPoiType(poi) && first(poi.developers);
  const displayName = isProject
    ? developer && developer.name
    : poc.type === PocType.Private
      ? poc.contactInfo && poc.contactInfo.name
      : poc && showOnlyOffice ? getPOCCompanyName(poc) : getPOCName(poc);
  const handleCallClickEvent = () => {
    const isCallEvent = isMobile || phoneNumberIsVisible;
    onCallClickEvent(isCallEvent, { phone: userPhone, name: userName, message: userMessage, isMortgageConsult: false, id: poi.id, type: poi.type });
  };

  const handlePhoneSubmit = (e: React.MouseEvent<HTMLElement>) => {
    handleCallClickEvent();

    if (!phoneNumberIsVisible && !isMobile) {
      e.preventDefault();
      setPhoneNumberIsVisible(true);
    }
  };

  const handleModalOpen = (isSetUpMeetingButton?: boolean) => {
    onSetContactModalPoiId(poi.id, {
      source: isSetUpMeetingButton ? ContactAgentModalSource.SetUpMeetingContactSection : ContactAgentModalSource.ContactSection,
    });
  };

  const contactAgentPhoneLabel = isMobile
    ? t('contactAgentPhone.button.modeB.mobile')
    : t('contactAgentPhone.button.modeB');

  const isPrioritizeFormAbTestActive =
    isProject && [ PrioritizeFormProjectsAbTest.modeB, PrioritizeFormProjectsAbTest.modeC ].includes(prioritizeFormProjects);

  const renderContactFormButtons = () => {
    if (isPrioritizeFormAbTestActive && !isContacted) {
      switch (prioritizeFormProjects) {
        case PrioritizeFormProjectsAbTest.modeB:
          return (
            <Button
              style={{ minWidth: '162px' }}
              iconColorField="stroke"
              onClick={() => handleModalOpen()}
              data-auto="phone-number-button-modal"
              size="extraLarge"
            >
              <SendIcon width={24} height={24} />
              {t('contactAgentStickyBar.contactAgentForm.open.alternative')}
            </Button>
          );
        case PrioritizeFormProjectsAbTest.modeC:
          return (
            <>
              <Button
                onClick={() => handleModalOpen(true)}
                data-auto="phone-number-button-modal"
                size="extraLarge"
              >
                <CalendarIcon width={24} height={24} />
                {t('contactAgentStickyBar.contactAgentForm.open')}
              </Button>
              <Button
                mode="ghost"
                iconColorField="stroke"
                onClick={() => handleModalOpen()}
                data-auto="phone-number-button-modal"
                size="extraLarge"
              >
                <SendIcon width={24} height={24} />
                {t('contactAgentStickyBar.contactAgentForm.open.alternative')}
              </Button>
            </>
          );
      }
    }

    return isContacted ? (
      <Button
        disabled
        data-auto="phone-number-button-modal-disabled"
        size="extraLarge"
        mode="ghost"
      >
        <CheckIcon width={24} height={24} />
        {t('contactAgentModal.button.disabled.modeB.static')}
      </Button>
    ) : (
      <Button
        onClick={() => handleModalOpen()}
        data-auto="phone-number-button-modal"
        size="extraLarge"
        mode="ghost"
      >
        <MailIcon width={24} height={24} />
        {t('contactAgentModal.button.modeB')}
      </Button>
    );
  };

  if (isModal) {
    return (
      <ModalRoot data-auto="popup-leads-form" isWide={!!isContacted && !isMobile}>
        {isContacted ? (
          <SuccessSentMessage>
            {isMobile ? null : <DoneIcon width={51} height={51} />}
            <SuccessHeader>
              {t('contactForm.contact.form.static.message.postLead.success1')}
              <SuccessHeaderName>
                {t('contactForm.contact.form.static.message.postLead.developerName', { name: displayName })}
              </SuccessHeaderName>
              {t('contactForm.contact.form.static.message.postLead.success2')}
            </SuccessHeader>
          </SuccessSentMessage>
          ) : (
          <>
            {isMobile && !isProject ? null : (
              <FormTitle
                style={{ marginBottom: getSpacing(3), textAlign: 'center' }}
                weight="bold"
              >
                {t('contactForm.contact.form.withMortgage.mobile.title', { isContacted: false })}
              </FormTitle>
            )}
            <ModalHeader isMobile>
              <POCLayout
                poi={poi}
                isLeadingAgent={isLeadingAgent}
                oneRowLayout={!isMobile}
                showOnlyOffice={showOnlyOffice}
              />
            </ModalHeader>
          </>
        )}
        <Main ref={contactFormRef}>
          {isContacted ? (
            <SentContainer>
              <ProjectsHeader weight="bold">
                {isProject
                  ? t('contactForm.contact.form.static.message.postLead.header', { isMobile })
                  : hasListingsCarousel ? t('contactForm.contact.form.static.message.postLeadListing.header', { isMobile }) : ''}
              </ProjectsHeader>
              <ProjectsCarouselWrapper>
                {isProject
                  ? <ProjectsCarousel source={ContactAgentModalSource.PostLead} />
                  : <ListingsCarousel />}
              </ProjectsCarouselWrapper>
            </SentContainer>
          ) : (
            <>
              <FieldsContainer largeInput isMobile noTopMargin>
                {isProject ? (
                  <InputSection>
                    <InputLabel weight="medium">{t('contactModal.label.name.modeB')}</InputLabel>
                    <TextField
                      fullWidth
                      data-auto="name-input"
                      value={userName}
                      onChange={onNameChange}
                      placeholder={t('contactModal.placeholder.name.modeB')}
                    />
                  </InputSection>
                ) : null}
                <InputSection>
                  <InputLabel weight="medium">{t('contactModal.label.phone.modeB')}</InputLabel>
                  <TextField
                    fullWidth
                    value={userPhone}
                    hasError={Boolean(formErrors.phone)}
                    data-auto="phone-input"
                    errorMessage={formErrors.phone}
                    onChange={onPhoneChange}
                    placeholder={t('contactModal.placeholder.phone.modeB')}
                    onClick={() => onContactAgentInputClick('property_lead_form_phone_text_box_click', poi)}
                  />
                </InputSection>
                <InputSection>
                  <InputLabel weight="medium">{t('contactModal.label.message.modeB')}</InputLabel>
                  <TextField
                    rows={3}
                    multiline
                    fullWidth
                    data-auto="message-input"
                    hasError={false}
                    errorMessage={null}
                    value={userMessage}
                    onChange={onMessageChange}
                    placeholder={t('contactModal.placeholder.message.modeB')}
                    onClick={() => onContactAgentInputClick('property_lead_form_messege_box_click', poi)}
                  />
                </InputSection>
              </FieldsContainer>
              {route.name === Route.Shortlist && isMobile ? (
                <>
                  <Button onClick={onSubmit} data-auto="phone-number-button-modal" size="extraLarge" mode="ghost" fullWidth>
                    <MailIcon width={24} height={24} />
                    {t('contactAgentModal.button.modeB')}
                  </Button>
                  {hidePhoneNumber ? null : (
                    <>
                      <AuthenticationSocialSuggestionWrapper>
                        <AuthenticationSocialSuggestion caps>{t('authentication.submitSuggestion')}</AuthenticationSocialSuggestion>
                      </AuthenticationSocialSuggestionWrapper>
                      <PhoneButton
                        data-auto="phone-number-button"
                        size="extraLarge"
                        onClick={handlePhoneSubmit}
                        href={createPhoneURI(phone)}
                      >
                        <PhoneIcon />
                        {phoneNumberIsVisible ? phone : contactAgentPhoneLabel}
                      </PhoneButton>
                    </>
                  )}
                </>
              ) : (
                <Button
                  style={{ marginTop: getSpacing(1) }}
                  onClick={onSubmit}
                  fullWidth
                  size="extraLarge"
                >
                  {t('contactForm.contact.form.withMortgage.submitPhone.modal.button.modeB', { isPrioritizeFormAbTest: Boolean(isPrioritizeFormAbTestActive) })}
                </Button>
              )}
              <SpacingBlock mTop={1}>
                <LeadContactTermsAndConditions />
              </SpacingBlock>
            </>
          )}
        </Main>
        {hidePhoneNumber || (!isContacted && (route.name !== Route.Shortlist)) || isContacted
          ? null
          : route.name === Route.Shortlist && isMobile || !phone ? null : (
            <ModalFooter isProject={isProject} isDesktop={isDesktop}>
              {isMobile ? null : (
                <FormTitle weight="medium" style={{ margin: 0, color: '#606060', fontSize: 15 }}>
                  {t('contactForm.contact.form.phoneButton.title.modeB')}
                </FormTitle>
              )}
              {hidePhoneNumber ? null : (
                <PhoneButton
                  data-auto="phone-number-button"
                  size="extraLarge"
                  mode={route.name === Route.Shortlist ? 'ghost' : undefined}
                  onClick={handlePhoneSubmit}
                  href={createPhoneURI(phone)}
                  fullWidth={isMobile}
                >
                  <PhoneIcon />
                  {phoneNumberIsVisible ? phone : contactAgentPhoneLabel}
                </PhoneButton>
              )}
            </ModalFooter>
          )}
      </ModalRoot>
    );
  }

  return (
    <>
      {description ? <DescriptionApartment description={description} withRedesignedForm /> : null}
      <Root data-auto="leads-form" ref={contactFormRef}>
        <Header isMobile={isMobile} isProject={isProject}>
          <FormTitle weight="bold">
            {t('contactForm.contact.form.withMortgage.mobile.title', { isContacted })}
          </FormTitle>
        </Header>
        <POCWrapper>
          <POCLayout poi={poi} isLeadingAgent={isLeadingAgent} oneRowLayout={!isMobile} showOnlyOffice={showOnlyOffice} />
        </POCWrapper>
        <ButtonWrapper>
          {hidePhoneNumber || isPrioritizeFormAbTestActive ? null : (
            <PhoneButton
              data-auto="phone-number-button"
              size="extraLarge"
              onClick={handlePhoneSubmit}
              href={createPhoneURI(phone)}
            >
              <PhoneIcon />
              {phoneNumberIsVisible ? phone : contactAgentPhoneLabel}
            </PhoneButton>
          )}
          {renderContactFormButtons()}
        </ButtonWrapper>
      </Root>
    </>
  );
};
