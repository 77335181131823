import React from 'react';
import styled from '@emotion/styled';
import {
  AuthenticationInfoHeader,
  AlreadyInUseDescription,
  BlockIconWrapper,
  AlreadyInUseCallInfo,
  AfterSocialMobileRoot,
  AfterSocialDesktopRoot,
  AlreadyInUseOrSign,
  EmailDetails,
  WhatsAppButton,
} from './styled';
import Modal from 'ds/components/modal';
import BlockIcon from 'assets/svg/block.svg';
import AdvisorIcon from 'assets/svg/advisor-support.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import WhatsappIcon from 'assets/svg/whatsapp-logo.svg';
import { useLocale } from 'locale';


const Root = styled.div(({ theme }) => ({
  marginTop: theme.spacing(10),
}));

interface EmailAlreadyInUseProps {
  onClose: () => void;
}

export const EmailAlreadyInUse: React.FC<EmailAlreadyInUseProps> = props => {
  const {
    onClose,
  } = props;

  const currentBreakpoint = useScreenBreakpoint();
  const isMobile = currentBreakpoint < 3;
  const { t } = useLocale();

  if (isMobile) {
    return (
      <Modal isOpen onClose={onClose} title={t('authentication.authenticationHeader')}>
        <AfterSocialMobileRoot>
          <div>
            <BlockIconWrapper>
              <BlockIcon height={67} width={67} />
            </BlockIconWrapper>
            <AuthenticationInfoHeader weight="bold">
              {t('authentication.consents.email.error.header')}
            </AuthenticationInfoHeader>
            <AlreadyInUseDescription>
              {t('authentication.consents.email.error.description')}
            </AlreadyInUseDescription>
            <AlreadyInUseCallInfo>
              <AdvisorIcon height={24} width={24} />
              {t('authentication.consents.email.error.callInfo')}
            </AlreadyInUseCallInfo>
          </div>
          <Root>
            <EmailDetails weight="medium">
              {t('authentication.consents.email.error.emailReport')}
              <a href="mailto:agents@madlan.co.il">{t('authentication.consents.email.error.emailToSend')}</a>
            </EmailDetails>
            <AlreadyInUseOrSign>
              {t('contactForm.contact.form.formButton.description')}
            </AlreadyInUseOrSign>

            <WhatsAppButton
              href={'whatsAppHref'}
              mode="ghost"
              size="extraLarge"
            >
              {t('authentication.consents.email.error.whatsapp')}
              <WhatsappIcon width={24} height={24}/>
            </WhatsAppButton>
          </Root>
        </AfterSocialMobileRoot>
      </Modal>
    );
  }

  return (
    <Modal isOpen onClose={onClose} title={t('authentication.authenticationHeader')}>
      <AfterSocialDesktopRoot>
        <BlockIconWrapper>
          <BlockIcon height={67} width={67} />
        </BlockIconWrapper>
        <AuthenticationInfoHeader weight="bold">
          {t('authentication.consents.email.error.header')}
        </AuthenticationInfoHeader>
        <AlreadyInUseDescription>
          {t('authentication.consents.email.error.description')}
        </AlreadyInUseDescription>
        <AlreadyInUseCallInfo>
          <AdvisorIcon height={24} width={24} />
          {t('authentication.consents.email.error.callInfo')}
        </AlreadyInUseCallInfo>
        <Root>
          <EmailDetails weight="medium">
            {t('authentication.consents.email.error.emailReport')}
            <a href="mailto:agents@madlan.co.il">{t('authentication.consents.email.error.emailToSend')}</a>
          </EmailDetails>
          <AlreadyInUseOrSign>
            {t('contactForm.contact.form.formButton.description')}
          </AlreadyInUseOrSign>

          <WhatsAppButton
            href={'whatsAppHref'}
            mode="ghost"
            size="extraLarge"
          >
            {t('authentication.consents.email.error.whatsapp')}
            <WhatsappIcon width={24} height={24}/>
          </WhatsAppButton>
        </Root>
      </AfterSocialDesktopRoot>
    </Modal>
  );
};
