import { select, fork, put, getContext, call } from 'redux-saga/effects';
import { State as RouteState, Route } from 'config/routes';
import { LoadType, LoadOptions } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import {
  IDocId2InsightsVariables,
  IPoiUserData,
  MarketplaceType,
  IProject,
  ICommercialProject,
  ISearchPoiVariables,
  IDeveloper,
} from 'utils/entities';
import { createSelector, OutputSelector } from 'reselect';
import { loadInsights } from '../loadInsights';
import { navigateTo } from 'store/state/router/actions';
import { flow } from 'lodash';
import {
  projectPageProjectSelector,
  projectPageCommercialProjectSelector,
  userCommuteWithPrioritiesSelector,
  resolvedStrictUnitDocSelector,
} from 'store/state/domainData/selectors';
import { waitForUserResolve } from '../utils';
import config from 'config';
import { State } from 'store/state';
import { makeSearchVariablesSelector } from 'store/state/app/selectors';
import { hasDemoProjectFlagSelector } from 'store/state/selectors/router';
import { resetDomainData } from 'store/state/domainData/actions';


const campaignProjectId = config.campaignProjectId;

const insightsVariablesSelector = createSelector([
  projectPageProjectSelector,
], (project): IDocId2InsightsVariables => {

  if (!project) return null;

  const { docId } = project.addressDetails;

  return {
    docId,
    user: null,
  };
});

const commercialInsightsVariablesSelector = createSelector([
  projectPageCommercialProjectSelector,
], (project): IDocId2InsightsVariables => {

  if (!project) return null;

  const { docId } = project.addressDetails;

  return {
    docId,
    user: null,
  };
});


const localDocIdSelector = (project: IProject): string => {
  if (!project) return null;
  return project.addressDetails.neighbourhoodDocId ? project.addressDetails.neighbourhoodDocId : project.addressDetails.cityDocId;
};

const residentialProjectLocalDocIdSelector = flow(projectPageProjectSelector, localDocIdSelector);

function* fetchNearbyProjects() {
  const localDocId = yield select(residentialProjectLocalDocIdSelector);
  if (!localDocId) return;

  yield call(queryData, {
    loadType: LoadType.StrictUnitDoc,
    meta: { variables: { docId: localDocId } },
  });

  const searchProjectListVariables: ISearchPoiVariables = yield select(makeSearchVariablesSelector(resolvedStrictUnitDocSelector));
  const projectLoadOptions: LoadOptions<LoadType.SearchProjectList> = {
    loadType: LoadType.SearchProjectList,
    meta: { variables: searchProjectListVariables },
  };
  yield call(queryData, projectLoadOptions);
}

interface SSRProjectFactoryOptions<P> {
  marketplace: MarketplaceType;
  getProjectLoadOptions: (id: string, userData: IPoiUserData) => LoadOptions<LoadType.ProjectPageCommercialProject | LoadType.ProjectPageProject>;
  projectSelector: (_: State) => IProject | ICommercialProject;
  projectInsightsVariablesSelector: OutputSelector<State, IDocId2InsightsVariables, (res: P) => IDocId2InsightsVariables>;
  nearbyProjectsFetcher?: () => void;
}

function* fetchProjectDevelopersProjects(developers: IDeveloper[]) {
  yield put(resetDomainData({ loadType: LoadType.DevelopersAndPromotedProjects }));

  const loadOptions: LoadOptions<LoadType.DevelopersAndPromotedProjects> = {
    loadType: LoadType.DevelopersAndPromotedProjects,
    meta: {
      variables: { ids: developers.map(developer => developer.id) },
    },
  };
  yield call(queryData, loadOptions);
}

function SSRProjectPageHandlerFactory<P>(options: SSRProjectFactoryOptions<P>) {
  const {
    nearbyProjectsFetcher,
    getProjectLoadOptions,
    projectSelector,
    projectInsightsVariablesSelector,
  } = options;

  return function* (params: RouteState) {
    yield call(waitForUserResolve);

    const userData: IPoiUserData = yield select(userCommuteWithPrioritiesSelector);
    const canFetchDemo: boolean = yield select(hasDemoProjectFlagSelector);

    const logger = yield getContext('logger');
    const projectLoadOptions = getProjectLoadOptions(params.params.id, { canFetchDemo, ...userData });
    yield call(queryData, projectLoadOptions);

    const project: IProject | ICommercialProject = yield select(projectSelector);

    if (!Boolean(project)) {
      logger.error('Project page project did not exist, redirecting Home.');
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
      return;
    }

    if (project.developers && project.developers.length) {
      yield fork(fetchProjectDevelopersProjects, project.developers);
    }

    const insightsVariables: IDocId2InsightsVariables = yield select(projectInsightsVariablesSelector);
    if (insightsVariables) {
      yield fork(loadInsights, insightsVariables);
    }

    if (nearbyProjectsFetcher) {
      yield fork(nearbyProjectsFetcher);
    }
  };
}

export const commercialSSRProjectHandler = SSRProjectPageHandlerFactory<ICommercialProject>({
  marketplace: MarketplaceType.Commercial,
  getProjectLoadOptions: (id, userData) => ({
    loadType: LoadType.ProjectPageCommercialProject,
    meta: {
      variables: {
        campaignProjectId,
        ids: [
          { type: 'project', id },
        ],
        userData,
      },
    },
  }),
  projectSelector: projectPageCommercialProjectSelector,
  projectInsightsVariablesSelector: commercialInsightsVariablesSelector,
});

export const residentialSSRProjectHandler = SSRProjectPageHandlerFactory<IProject>({
  marketplace: MarketplaceType.Residential,
  getProjectLoadOptions: (id, userData) => ({
    loadType: LoadType.ProjectPageProject,
    meta: {
      variables: {
        campaignProjectId,
        ids: [
          { type: 'project', id },
        ],
        userData,
      },
    },
  }),
  projectSelector: projectPageProjectSelector,
  projectInsightsVariablesSelector: insightsVariablesSelector,
  nearbyProjectsFetcher: fetchNearbyProjects,
});

export function* projectPageHandler(params: RouteState) {
  yield fork(residentialSSRProjectHandler, params);
}
