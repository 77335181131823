import React from 'react';

import {
  CheckboxWrapper,
  Checkbox,
  Checkmark,
} from 'ds/components/input/Checkbox';
import CheckmarkIcon from 'assets/svg/checkmark.svg';
import IndeterminateIcon from 'assets/svg/indeterminate.svg';
import { Text, SmallText } from 'ds/components/typography';

import { InputProps } from './Input';

export interface CheckboxProps extends InputProps {
  label?: string | React.ReactNode;
  indeterminate?: boolean;
  icon?: JSX.Element;
  small?: boolean;
  alignStart?: boolean;
  htmlLabel?: string;
  size?: number;
}

export class CheckboxField extends React.Component<CheckboxProps, never> {
  public static defaultProps: Partial<CheckboxProps> = {
    disabled: false,
    label: '',
    checked: false,
    indeterminate: false,
    icon: null,
    small: false,
    alignStart: false,
    htmlLabel: '',
    size: null,
  };

  public render () {
    const { label, disabled, checked, indeterminate, icon, small, htmlLabel, alignStart, size, ...rest } = this.props;
    const TextComponent = small ? SmallText : Text;
    return (
      <CheckboxWrapper disabled={disabled} alignStart={alignStart}>
        <Checkbox disabled={disabled} {...rest} />
        <Checkmark
          checked={checked}
          disabled={disabled}
          data-auto="checkmark"
          size={size}
        >
          {checked && (indeterminate ? <IndeterminateIcon /> : <CheckmarkIcon /> )}
        </Checkmark>
        {icon}
        {!!label && <TextComponent>{label}</TextComponent>}
        {!!htmlLabel && <TextComponent dangerouslySetInnerHTML={{ __html: htmlLabel }} />}
      </CheckboxWrapper>
    );
  }
}
