import styled from '@emotion/styled';
import { Button } from 'ds/components/button';
import {  H2, Text, SmallText } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';


export const FadeWrapper = styled.div<{ isVisible: boolean, transitionTime?: number, deferTime?: number }>`
  ${({ isVisible, transitionTime = 200, deferTime = 0 }) => isVisible ? `
    opacity: 1;
    visibility: visible;
    transition: opacity ${transitionTime}ms ease ${deferTime}ms, visibility 0s ${deferTime}ms;
  ` : `
    opacity: 0;
    visibility: hidden;
    transition: opacity ${transitionTime}ms ease 0ms, visibility 0s ${transitionTime}ms, height 0s ${transitionTime}ms;
    height: 0;
    overflow: hidden;
  `}
`;

export const AuthenticationTypeSwitchWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.neutrals.grey2};
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin-top: auto;
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const AuthenticationTypeSwitch = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.brand.main};
    cursor: pointer;
    margin-${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(0.5)};
  `}
`;

export const FormControlsWrapper = styled.div<{ smallWidth?: boolean }>`
  ${({ theme, smallWidth }) => `
    margin-top: ${theme.spacing(3)};
    ${smallWidth && 'width: 150px;'};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const AuthenticationBase = styled.div`
  max-width: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    max-width: 338px;
  `}
`;

export const AuthenticationRoot = styled(AuthenticationBase)``;

export const SetPasswordRoot = styled(AuthenticationBase)``;

export const EnterPasswordFormRoot = styled(AuthenticationBase)``;

export const SetPasswordFormRoot = styled(AuthenticationBase)``;

const AuthenticationInfoDefault = styled(Text)(({ theme }) => ({
  margin: theme.spacing(1, 0, 3),
  color: theme.colors.neutrals.grey1,
  [theme.media.createObjectMediaQuery({ from: 3 })]: {
    marginBottom: theme.spacing(4),
  },
}));

export const AuthenticationConsentsEmail = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1, 1.5),
  textAlign: 'center',
  color: theme.colors.neutrals.grey3,
  borderRadius: theme.borderRadius.small,
  border: `1px solid ${theme.colors.neutrals.grey8}`,
  backgroundColor: theme.colors.neutrals.grey11,
}));

export const AuthenticationInfo = styled(AuthenticationInfoDefault)(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  [theme.media.createObjectMediaQuery({ to: 2 })]: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 1.25),
  },
}));

export const TextRow = styled(AuthenticationInfo)(() => ({
  '&&': {
    marginBottom: 0,
  },
}));

export const EmailTextRow = styled(AuthenticationInfo)<{ centered?: boolean }>(({ theme, centered }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  justifyContent: centered ? 'center' : undefined,
}));

export const ForgotLinkWrapper = styled.div(({ theme }) => ({
  color: theme.colors.brand.main,
  cursor: 'pointer',
  marginTop: theme.spacing(1),
  textAlign: 'left',
  fontWeight: 500,
  justifySelf: 'flex-end',
}));

export const SetPasswordInfo = styled(AuthenticationInfo)`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: 0;
  `}
`;

export const EnterPasswordInfo = styled(SetPasswordInfo)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-bottom: ${theme.spacing(4)};
  `}
`;

export const AuthenticationForgotPasswordButton = styled.div`
  ${({ theme }) => `
    display: inline-block;
    margin-top: ${theme.spacing(4)};
    color: ${theme.colors.neutrals.grey2};
    cursor: pointer;
  `}
`;

export const AuthenticationMoreLink = styled.div(({ theme }) => ({
  color: theme.colors.brand.main,
  textAlign: 'center',
  marginTop: theme.spacing(20),
  cursor: 'pointer',
}));

export const AuthTrapWrapper = styled.div<{ vCentered: boolean; }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${({ theme, vCentered }) => `
    background: ${theme.colors.neutrals.white};
    padding: ${theme.spacing(7)} ${theme.spacing(3.5)} 0;
    ${vCentered ? 'justify-content: center;' : ''}
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShortlistPreStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => `
    margin-top: -136px;
    width: 100%;
    svg {
      margin-bottom: ${theme.spacing(4)};
    }
    h3 {
      margin-bottom: ${theme.spacing(1)};
    }
    button {
      margin-top: ${theme.spacing(4)};
    }
  `}
`;

export const AuthenticationInfoHeader = styled(H2.withComponent('div'))(({ theme }) => ({
  color: theme.colors.neutrals.black,
  textAlign: 'center',
  [theme.media.createObjectMediaQuery({ from: 3 })]: {
    fontSize: `${typographyFontSizeCfg.h2.mobile}px`,
    lineHeight: `${typographyLineHeightCfg.h2.mobile}px`,
  },
}));

export const AuthenticationInfoDescription = styled(SmallText)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  color: theme.colors.neutrals.grey1,
  marginTop: theme.spacing(1),
}));

export const AlreadyInUseOrSign = styled(SmallText)(({ theme }) => ({
  color: theme.colors.neutrals.grey2,
  textAlign: 'center',
  margin: theme.spacing(1, 0),
}));

export const AlreadyInUseDescription = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.neutrals.grey1,
  marginTop: theme.spacing(1),
}));

export const EmailDetails = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.neutrals.grey1,
  'a,a:active,a:hover': {
    display: 'block',
    color: theme.colors.brand.main,
    textDecoration: 'none',
  },
}));

export const AlreadyInUseCallInfo = styled(Text)(({ theme }) => ({
  color: theme.colors.neutrals.grey2,
  maxWidth: '325px',
  backgroundColor: theme.colors.neutrals.grey10,
  display: 'flex',
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
  marginRight: 'auto',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  'svg': {
    display: 'block',
    flexBasis: '24px',
    flexShrink: 0,
  },
}));

export const MadlanLogoWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  svg: {
    display: 'block',
  },
}));

export const BlockIconWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  svg: {
    display: 'block',
  },
  [theme.media.createObjectMediaQuery({ from: 3 })]: {
    marginBottom: theme.spacing(4),
  },
}));

export const WhatsAppButton = styled(Button.withComponent('a'))(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  background: theme.colors.brand.madlanGreen,
  borderColor: theme.colors.brand.madlanGreen,
  color: theme.colors.neutrals.white,
  textDecoration: 'none',
  'svg path': {
    fill: theme.colors.neutrals.transparent,
    stroke: theme.colors.neutrals.white,
  },
}));

export const AfterSocialDesktopRoot = styled.div(({ theme }) => ({
  maxWidth: '344px',
  margin: theme.spacing(6, 14),
}));

export const AfterSocialMobileRoot = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: `calc(100vh - ${theme.spacing(12)})`,
}));

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};
  `}
  & a {
    color: ${({ theme }) => theme.colors.brand.main};
    font-weight: 700;
    text-decoration: none;
  }
`;

export const Rule = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ConsentsInfoHeader = styled(H2)`
  &&& {
    text-align: center;
    white-space: break-spaces;
    font-size: ${typographyFontSizeCfg.h2.mobile}px;
    line-height: ${typographyLineHeightCfg.h2.mobile}px;
  }
`;

export const ConsentsLogoWrapper = styled(LogoWrapper)`
  margin-top: 0;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: 0 ${theme.spacing(3)} ${theme.spacing(4)};
  `}
`;
