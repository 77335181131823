import React from 'react';
import * as Styled from './styled';
import { Link } from 'components/link';
import { Route } from 'config/routes';
import { useLocale } from 'locale';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { noop } from 'lodash';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import useAnchorLink from 'hooks/useAnchorLink';

export interface BlogCardProps {
  title: string;
  articleId: string;
  imageUrl: string;
  postUrl: string;
  withLeftMargin?: boolean;
  developerName?: string;
  developerId?: string;
  onItemClick?: (isPaid: boolean, articleId: string, developer?: { id: string; name: string; } ) => void;
  onCardExposeEvent?: (isPaid: boolean, articleId: string, developer?: { id: string; name: string; }) => void;
  rootNode?: React.MutableRefObject<HTMLDivElement>;
  sectionInView: boolean;
}

export const BlogCard: React.FC<BlogCardProps> = props => {
  const {
    imageUrl,
    title,
    postUrl,
    articleId,
    developerId,
    developerName,
    withLeftMargin,
    rootNode = null,
    sectionInView,
    onItemClick = noop,
    onCardExposeEvent = noop,
  } = props;

  const { t } = useLocale();
  const isPaid = Boolean(developerId);
  const constructImageURI = useImageUrlBuilder();
  const fullImageURL = constructImageURI(imageUrl, { height: 516, width: 516 });
  const [ isVisible, setIsVisible ] = React.useState(false);
  const cardItem = React.useRef<HTMLAnchorElement>(null);
  const postUrlLink = useAnchorLink(postUrl);

  useIntersectionObserver(([ entry ]) => {
    setIsVisible(entry.isIntersecting);
  }, [ cardItem ], { root: rootNode });


  React.useEffect(() => {
    if (isVisible && sectionInView) {
      onCardExposeEvent(isPaid, articleId, isPaid ? { id: developerId, name: developerName } : null);
    }
  }, [ onCardExposeEvent, isVisible, isPaid, sectionInView ]);

  return (
    <Styled.Root
      ref={cardItem}
      key={title}
      data-auto="article"
      onClick={() => onItemClick(isPaid, articleId, isPaid ? { id: developerId, name: developerName } : null)}
      className="blog-card__root"
      withLeftMargin={Boolean(withLeftMargin)}
      target="_blank"
      href={postUrlLink}
    >
      <Styled.Image src={fullImageURL} alt={title} />
      <Styled.Body>
        <Styled.Title weight="bold">{title}</Styled.Title>
        {isPaid ? (
          <Styled.LinkWrap>
            {t('blog.card.developer')}
            <Link target="_blank" routeName={Route.DeveloperPage} routeParams={{ id: developerId, source: 'article' }}>{developerName}</Link>
          </Styled.LinkWrap>
        ) : null}
      </Styled.Body>
    </Styled.Root>
  );
};
