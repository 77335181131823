import React, { useRef } from 'react';
import { useLocale } from 'locale';
import {
  AuthenticationSocialSubmitWrapper,
  AuthenticationSocialSuggestionWrapper,
  AuthenticationSocialSuggestion,
  AuthenticationSocialWrapper,
  OtherSocialsInfo,
  OtherSocialsButtons,
  AppleButton,
} from './styled';
import { SocialVendor } from '../types';
import { useLoginWithGoogleButton } from 'hooks/useLoginWithGoogleButton';
import { SpacingBlock } from 'components/spacing-block';
import AppleIcon from 'assets/svg/new-apple.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { insideReactNative } from 'react-native/lib/platform';
import SocialsButtons from 'react-native/components/social-buttons';

export interface WebAuthSocialsButtonsProps {
  withSuggestionBefore?: boolean;
  onSocialLogin: (vendor: SocialVendor) => void;
}

export const WebAuthSocialsButtons: React.FunctionComponent<WebAuthSocialsButtonsProps> = (props) => {
  const { withSuggestionBefore, onSocialLogin } = props;
  const currentBreakpoint = useScreenBreakpoint();
  const insideRN = insideReactNative();
  const { t } = useLocale();
  const googleButtonNode = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  useLoginWithGoogleButton({ parent: googleButtonNode, containerRef: rootRef });

  const isMobile = currentBreakpoint < 3;

  const suggestion = (
    <AuthenticationSocialSuggestionWrapper>
      <AuthenticationSocialSuggestion caps weight="medium">{t('authentication.submitSuggestion')}</AuthenticationSocialSuggestion>
    </AuthenticationSocialSuggestionWrapper>
  );

  if (insideRN) {
    return (
      <SpacingBlock ref={rootRef} mTop={3}>
        <AuthenticationSocialSubmitWrapper>
          <SocialsButtons suggestion={suggestion} />
        </AuthenticationSocialSubmitWrapper>
      </SpacingBlock>
    );
  }

  return (
    <SpacingBlock ref={rootRef} mTop={3}>
      <AuthenticationSocialSubmitWrapper>
        {withSuggestionBefore && suggestion}
        <AuthenticationSocialWrapper>
          <div ref={googleButtonNode} />
        </AuthenticationSocialWrapper>
        {!withSuggestionBefore && suggestion}
      </AuthenticationSocialSubmitWrapper>

      {isMobile ? (
        <>
          <OtherSocialsInfo weight="medium">{t('authentication.authenticationInfo.social')}</OtherSocialsInfo>
          <OtherSocialsButtons>
            <AppleIcon onClick={() => onSocialLogin('apple')} data-auto="apple-connect-button" width={44} height={44} />
          </OtherSocialsButtons>
        </>
      ) : (
        <AppleButton onClick={() => onSocialLogin('apple')}>
          <AppleIcon data-auto="apple-connect-button" width={24} height={24} />
          {t('authentication.apple.text')}
        </AppleButton>
      )}
    </SpacingBlock>
  );
};
