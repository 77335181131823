import React, { useMemo } from 'react';
import {
  ProfileWrapper,
  ProfileTabHeader,
  ProfileTabsHeaderWrapper,
  ProfileTabContent,
  ProfileTabsContentWrapper,
} from './styled';
import { Tabs } from 'ds/components/tabs';
import { useLocale } from 'locale';
import { TextLabel } from 'ds/components/typography';
import { CommutePopupForm } from './CommutePopupForm';
import { ProfileSettings } from './ProfileSettings';
import { User, CommutePreference } from 'utils/entities';
import { IGenerateEmailVerificationCodeVariables, IUpdateUserProfileVariables, MutationType } from 'store/sagas/apiService/types';
import { useRoute } from 'config/routes';
import { ShowAt } from 'consts/breakpoints';
import { IMutationResponse } from 'store/state/mutationsResponse/selectors';
import { AuthenticationModalType } from 'components/authentication/types';
import { IReasonState } from './types';


export enum ProfileTab {
  Settings = 'settings',
  Personal = 'personal',
}

interface ProfileProps {
  onUserDelete: (data: IReasonState) => void;
  onUpdateUser: (data: IUpdateUserProfileVariables, key: string) => void;
  user: User;
  isAgent: boolean;
  deregisterUserResponse: IMutationResponse<MutationType.DeregisterUser>;
  onSetActiveModal: (nextType: AuthenticationModalType) => void;
  generateEmailVerificationCode: (data: IGenerateEmailVerificationCodeVariables) => void;
  setEmail: (email: string) => void;
}

export const Profile: React.FC<ProfileProps> = ({
  onUpdateUser,
  onSetActiveModal,
  generateEmailVerificationCode,
  setEmail,
  deregisterUserResponse,
  user,
  onUserDelete,
  isAgent,
}) => {
  const { route, router } = useRoute();
  const { t } = useLocale();

  const safeUser: User = useMemo(() => ({
    commutePreference: null,
    ...(user || {}),
    firstName: (user || {}).firstName || '',
    phone: (user || {}).phone || '',
    email: (user || {}).email || '',
  }), [ user ]);

  const onChangePassword = () => {
    if (user.email) {
      setEmail(user.email);
      generateEmailVerificationCode({ email: user.email });
      onSetActiveModal(AuthenticationModalType.UserEmailVerification);
    }
  };

  const handleSaveCommute = (commutePreference: CommutePreference) => {
    onUpdateUser({
      commutePreference,
    }, ProfileTab.Personal);
  };

  const handleSaveChanges = (updatedUserFields: User) => {
    onUpdateUser({
      ...updatedUserFields,
    }, ProfileTab.Settings);
  };

  const renderTabContent = (tab: ProfileTab) => {
    switch (tab) {
      case ProfileTab.Personal:
        return (
          <CommutePopupForm
            onSaveCommute={handleSaveCommute}
            user={safeUser}
          />
        );
      case ProfileTab.Settings:
        return (
          <ProfileSettings
            isAgent={isAgent}
            user={safeUser}
            deregisterUserIsLoading={deregisterUserResponse && deregisterUserResponse.loading}
            onUserDelete={onUserDelete}
            onSaveChanges={handleSaveChanges}
            onChangePassword={onChangePassword}
          />
        );
    }
  };
  const tabs = [ ProfileTab.Settings, ProfileTab.Personal ];

  const activeTab = route.params.initialTab || ProfileTab.Settings;
  const setActiveTab = (tab: ProfileTab) => {
    router.navigate(route.name, {
      ...route.params,
      initialTab: tab,
    }, { replace: true });
  };

  const bodyWrapper: React.FC = ({ children }) => (
    <ProfileTabsContentWrapper>
      {children}
    </ProfileTabsContentWrapper>
  );

  return (
    <ProfileWrapper>
      <Tabs
        activeTab={tabs.indexOf(activeTab)}
        setActiveTab={(nextTabIdx: number) => setActiveTab(tabs[nextTabIdx])}
        headerWrapper={ProfileTabsHeaderWrapper}
        bodyWrapper={bodyWrapper}
        headers={tabs.map((tab: ProfileTab) => (
          <ShowAt from={3}>
            <ProfileTabHeader isActive={activeTab === tab}>
              <TextLabel data-auto={`tab-${tab}`} caps>{t('profile.tabLabel', { tab })}</TextLabel>
            </ProfileTabHeader>
          </ShowAt>
        ))}
        bodies={tabs.map((tab: ProfileTab) => (
          <ProfileTabContent key={activeTab}> {/* discard all changes when switching to another tab */}
            {renderTabContent(tab)}
          </ProfileTabContent>
        ))}
      />
    </ProfileWrapper>
  );
};

