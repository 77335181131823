import { CheckboxField } from 'ds/components/input/CheckboxField';
import { useLocale } from 'locale';
import React from 'react';
import { Wrapper } from './styled';
import { IConsent } from 'store/sagas/apiService/types';


interface IConsents {
  setConsents: (data: IConsent[]) => void;
  consents: IConsent[];
}

export const Сonsents: React.FC<IConsents> = ({ setConsents, consents }) => {
  const { t } = useLocale();
  const [ conditions, newsletters ] = consents;

  return (
    <Wrapper>
      <CheckboxField size={24} small checked={consents[0].approval} onChange={() => setConsents([ { ...conditions, approval: !conditions.approval }, newsletters ])} htmlLabel={t('authentication.consents.conditions')} />
      <CheckboxField size={24} alignStart small checked={consents[1].approval} onChange={() => setConsents([ conditions, { ...newsletters, approval: !newsletters.approval } ])} label={t('authentication.consents.newsletters')} />
    </Wrapper>
  );
};
