import { Actions, StateChangeOptions, ControllerStateAndHelpers } from 'downshift';

import { IAutocompleteEntry, CompletionType } from 'utils/entities';
import { Route } from 'config/routes';
import { InitialSuggestion } from 'utils/initialSuggestionsConfig';

export interface SharedAutocompleteProps {
  searchStr: string;
  onSearchStrChange: (value: string) => void;
  placeholder: string;
  overlayId?: string;
  optionsCount?: number;
  showType?: boolean;
  completionTypes: CompletionType[];
  onBlur?: () => void;
  onSuggest?: (suggestions: IAutocompleteEntry[]) => void;
  inputIcon?: React.ReactNode;
  autoFocus?: boolean;
  source?: AddressAutocompleteSource;
  initialSuggestions?: InitialSuggestion[];
  disableHebrewLayoutConvert?: boolean;
  disabled?: boolean;
  suggestionItemIcon?: React.ReactNode;
  makeMobileDiscoveryDisabled?: () => void;
  makeMobileDiscoveryEnabled?: () => void;
}

export type SingleInputValue = IAutocompleteEntry;

export interface SingleInputOwnProps extends SharedAutocompleteProps {
  initialValue?: SingleInputValue;
  onChange: (value: SingleInputValue, idx?: number, totalResults?: number) => void;
  sendOnClickEvent?: (source: AddressAutocompleteSource, routeName?: Route) => void;
  inlineView?: boolean;
  showClearButton?: boolean;
  downshiftRef?: React.Ref<Actions<IAutocompleteEntry>>;
  hasError?: boolean;
  errorMessage?: string;
  onDownshiftStateChange?: (
  options: StateChangeOptions<unknown>,
  stateAndHelpers: ControllerStateAndHelpers<unknown>
  ) => void;
  suggestionMessage?: string;
  useApi3AutocompleteWithApi2?: boolean;
  useApi3?: boolean;
}

export type MultiInputValue = SingleInputValue[];

export interface MultiInputOwnProps extends SharedAutocompleteProps {
  onChange: (values: MultiInputValue) => void;
  values: MultiInputValue;
  onMobileClose?: () => void;
  onCancel: () => void;
  valuesLimit?: number;
}

export interface AutocompleteMergeProps {
  suggestedAddresses: IAutocompleteEntry[];
  loading?: boolean;
}

export type SingleInputProps = SingleInputOwnProps & AutocompleteMergeProps;
export type MultiInputProps = MultiInputOwnProps & AutocompleteMergeProps;

export enum AddressAutocompleteSource {
  CheckAddress = 'check_address',
  CommuteSurveyPreferences = 'commute_survey_preferences',
  HomePageTop = 'homepage_top',
  HomePageBottom = 'homepage_bottom',
  LocationSurveyPreferences = 'location_survey_preferences',
  SearchResults = 'search_results',
  NewSearch = 'new_search',
  NewBulletin = 'new_bulletin',
  MadadIndex = 'madad_index',
  MadadSearch = 'madad_search',
  PersonalAssistantForm = 'personal_assistant_signup_form',
  CommercialLanding = 'homepage_top',
  DeveloperSearch = 'developer_index',
  DevelopersCitySearch = 'developer_search_results',
  MortgageOfficesSearch = 'mortgage_index',
  AgentConsoleDealsLocation = 'console_agent_deals_location',
}
