// Declare here only the BI schema types!

export enum BiEventCategory {
  ADDRESS = 'address',
  AGENT = 'agent',
  ARTICLE = 'article',
  BANNER = 'banner',
  BULLETIN_MANAGE = 'bulletin_manage',
  DEVELOPER = 'developer',
  FAVORITES = 'favorites',
  HOMEPAGE = 'homepage',
  INSIGHT = 'insight',
  MADAD = 'madad',
  MAP = 'map',
  MARKETING = 'marketing',
  OFFICE = 'office',
  PAGE = 'page',
  POPUP = 'popup',
  PROPERTY = 'property',
  SEARCH = 'search',
  TABU = 'tabu',
  USER = 'user',
}

export const biEventName = {
  [BiEventCategory.BULLETIN_MANAGE]: {
    PROMOTE_MANUAL: 'bulletin_manage_promote_manual',
  },
  [BiEventCategory.USER]: {
    UPGRADE_PLAN_CLICK: 'user_promotion_plan_upgrade_click',
  },
};

export enum BiDealType {
  BUY = 'for_sale',
  RENT = 'for_rent',
}

export enum BiPromoteBulletinSource {
  MANAGE_BULLETINS_PAGE = 'manage_bulletins_page',
  BULLETIN_PAGE = 'property_page',
}
