import { getContext, takeEvery, call, select, take } from 'redux-saga/effects';
import { NEW_LISTING } from 'store/state/uploadBulletin/types';
import { RootAction } from 'store/state';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { getRouteParams } from 'store/state/selectors/router';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from '../apiService/types';
import { newListingAction } from 'store/state/uploadBulletin/actions';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { DealType } from 'utils/entities';

function* createListingWatcher(action: ReturnType<typeof newListingAction>) {
  const logger = yield getContext('logger');
  try {
    const { sendEvent } = yield getContext('analytics');
    const { source } = yield select(getRouteParams);

    const eventSource = source || 'other';
    yield call(sendEvent, 'bulletin_upload_wizard_submit', 'bulletin_upload_wizard', {  event: { source: eventSource } });

    const dealType = action.payload.listing.dealType;
    const responseAction: SetMutationResponseAction<MutationType.NewListing | MutationType.NewCommercialListing> = yield take((a: RootAction) => (
      a.type === SET_MUTATION_RESPONSE && (a.mutationType === MutationType.NewCommercialListing || a.mutationType === MutationType.NewListing)
    ));
    if (responseAction.meta.response.data) {
      const listing = 'newCommercialListing' in responseAction.meta.response.data
        ? responseAction.meta.response.data.newCommercialListing.commercialListing
        : responseAction.meta.response.data.newListing.listing;

      if (listing.id) {
        yield call(sendEvent, 'bulletin_upload_wizard_submit_success', 'bulletin_upload_wizard', {
          event: { source: eventSource },
          property: {
            deal_type: dealType === DealType.Buy ? 'for_sale' : 'for_rent',
            property_id: listing.id,
          },
        });
      }
    }
  }
  catch (error) {
    logger.error('Error in analytics watcher about new listing upload.', error);
  }
}

const openUploadBulletinPagePattern = (action: RootAction) => (
  (action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.UploadBulletin
    && (!action.payload.previousRoute || action.payload.previousRoute.name !== Route.UploadBulletin))
);

function* openUploadBulletinPageWatcher() {
  const { sendEvent } = yield getContext('analytics');
  const { source } = yield select(getRouteParams);

  yield call(sendEvent, 'bulletin_upload_wizard_open', 'bulletin_upload_wizard', {
    event: {
      source: source || 'other',
    },
  });
}

export function* uploadBulletinEventsWorker() {
  yield takeEvery(NEW_LISTING, createListingWatcher);
  yield takeEvery(openUploadBulletinPagePattern, openUploadBulletinPageWatcher);
}
