import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import {
  AddListingButton,
  AuthButton,
  GroupHeader,
  GroupLink,
  GrowSpan,
  HeaderLinkItem,
  LinkGroupWrapper,
  LinksWrapper,
  ModesWrapper,
  MoreActionsButton,
  SectionLinkWrapper,
  SeoColumnsWrapper,
  SimpleHeader,
  WidthLimiter,
  Wrapper,
  InnerLink,
  Avatar,
  HeaderAlignment,
} from './styled';
import { Route, State as RouteState } from 'config/routes';
import { Link } from 'components/link';
import { useLocale } from 'locale';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { Text } from 'ds/components/typography';
import Logo from '../Logo';
import { UserActions } from './UserActions';
import MoreIcon from 'assets/svg/menu.svg';
import PlusIcon from 'assets/svg/plus-1-5.svg';
import ProfileIcon from 'assets/svg/profile.svg';
import UserIcon from 'assets/svg/navigation/user.svg';
import AddThickIcon from 'assets/svg/navigation/plus.svg';
import HamburgerIcon from 'assets/svg/navigation/hamburger.svg';
import ArrowIcon from 'assets/svg/left.svg';
import { AppMode, prevRouteSelector, showAgentConsoleBulletinFormSelector, prevSearchFilterSelector } from 'store/state/selectors/router';
import CloseIcon from 'assets/svg/dropdown/close.svg';
import config from 'config';
import withEvents from 'analytics/withEvents';
import BetaBadge from './BetaBadge';
import { connect } from 'react-redux';
import BackArrowIcon from 'assets/svg/navigation/chevron-left.svg';
import { isConnectedUserSelector, isUserSelector, isVisitorUserSelector, userLoadingSelector } from 'store/state/domainData/selectors';
import { RootAction, State } from 'store/state';
import { navEventMap } from 'components/navigation/header/utils';
import UploadBulletinLink from 'components/upload-bulletin-link';
import { SeoTooltip } from 'components/seo-tooltip/SeoTooltip';
import {
  ADD_EDIT_BULLETIN_PAGES,
  getHeaderLinks,
  linkToIcon,
  PROFILE_MENU_INNER_PAGES,
} from './config';
import { HeaderLinkType, ResidentialBuyRentHistoryFilters, SeoLinksSection } from './types';
import { LocalStorage } from 'utils/localStorage';
import { debounce, noop } from 'lodash';
import BackLink from '../BackLink';
import { AuthenticationModalPayload, AuthenticationModalType } from 'components/authentication/types';
import {
  setAuthModal,
  setIsHeaderTabSubmenuVisible,
  setUserMobileProfileOpen,
} from 'store/state/app/actions';
import { AuthModalSource } from 'store/state/app';
import { IUserProfileFields, userProfileFieldsSelector } from 'components/navigation/profileFields';
import { isLikedToastOpenSelector, isUserMobileProfileOpenSelector } from 'store/state/app/selectors';
import LikedToast from 'components/favorites/liked-toast/LikedToast';
import HeartIconWithCounter from 'components/navigation/header/HeartIconWithCounter';
import ShortlistHoverPreview from 'components/navigation/shortlist-hover-preview';
import { userFavoritesPreviewPoisCountSelector } from 'store/state/domainData/selectors/favorites';
import { SpacingBlock } from 'components/spacing-block';
import { ShortlistMobileHeader } from './ShortlistMobileHeader';
import { isUnitPageRoute } from 'utils/marketplaceRoutes';
import AnchorMiddleware from 'components/anchor';

interface HeaderProps {
  route: RouteState;
  isUser: boolean;
  isConnectedUser: boolean;
  isUserLoading: boolean;
  isVisitor: boolean;
  isUserMobileProfileOpen: boolean;
  mode: AppMode;
  userProfile: IUserProfileFields;
  onMore: () => void;
  onOpenAuthModal: () => void;
  onLogout: () => void;
  onBackToMenu: () => void;
  onModeClick?: (m: AppMode) => void;
  onStaticLinksClick?: (a: Route) => void;
  onSetAuthModal: typeof setAuthModal;
  onSetUserMobileProfileOpen: (status: boolean) => void;
  isLogoTransparent?: boolean;
  authLabel?: string;
  onMadadLinkClick?: () => void;
  onShortlistLinkHover?: (previewListingsCount: number) => void;
  setIsVisible: (isVisible: boolean) => void;
  prevRoute?: RouteState;
  isLikedToastOpen: boolean;
  shortlistHoverPreviewListingsCount: number;
  showAgentConsoleBulletinForm: boolean;
  prevSearchFilter: ResidentialBuyRentHistoryFilters;
  tooltipStyle?: React.CSSProperties;
}

interface BaseEffectProps {
  routeName: Route;
  allowedRoutes: Set<Route>;
  localKey: string;
  stateHandler: (v: boolean) => void;
}

const renderSectionItem = (section: SeoLinksSection, idx: number, onMadadLinkClick: () => void) => {
  if (!section.title) return null;

  switch (section.type) {
    case HeaderLinkType.Inner:
      return (
        <InnerLink key={`link_${idx}`}>
          <Link
            data-auto={`link-${idx}`}
            routeName={section.routeName}
            routeParams={section.params}
            hideLinkParams
          >
            {section.icon}
            {section.title}
          </Link>
        </InnerLink>
      );
    case HeaderLinkType.Outer:
      return (
        <AnchorMiddleware href={section.href}>
          {(href) => (
            <GroupLink
              data-auto={`link-${idx}`}
              key={`link_${idx}`}
              onClick={section.href === '/madad' ? onMadadLinkClick : undefined}
              target="_blank"
              href={href}
            >
              {section.icon}
              {section.title}
            </GroupLink>
          )}
        </AnchorMiddleware>
      );
    case 'old':
      return (
        <GroupLink
          data-auto={`link-${idx}`}
          href={section.href}
          key={`link_${idx}`}
        >
          {section.title}
        </GroupLink>
      );
    default:
      return null;
  }
};

const renderSeoLinks = (seoLinks: SeoLinksSection[], onMadadLinkClick: () => void) => seoLinks.map((list, idx) => (
  <LinkGroupWrapper key={idx}>
    <GroupHeader>{list.title}</GroupHeader>
    <SeoColumnsWrapper>
      {list.children.map((l, lIdx) => (
        <div key={`wrapper_${lIdx}`}>
          {l.map((item, i) => renderSectionItem(item, i, onMadadLinkClick))}
        </div>
      ))}
    </SeoColumnsWrapper>
  </LinkGroupWrapper>
));


const ONE_DAY_MS = 3600 * 1000 * 24;
const DEBOUNCE_DELAY_MS = 2000;
const LIMIT_SHOW_COUNT = 3;

const baseOnceDayEffect = (props: BaseEffectProps) => () => {
  const { routeName, allowedRoutes, localKey, stateHandler } = props;
  if (allowedRoutes.has(routeName)) {
    const { expireTimeMS, count } = LocalStorage.get(localKey) || { expireTimeMS: null, count: 0 };
    const nowMS = new Date().getTime();
    if (!expireTimeMS) {
      stateHandler(true);
      LocalStorage.set(localKey, { expireTimeMS: nowMS + ONE_DAY_MS, count: count + 1 });
    }
    else if ((expireTimeMS < nowMS) && (count < LIMIT_SHOW_COUNT)) {
      stateHandler(true);
      LocalStorage.set(localKey, { expireTimeMS: nowMS + ONE_DAY_MS, count: count + 1 });
    }
  }
};

const UPLOAD_ANIMATION_KEY = 'UPLOAD_ANIMATION_BUTTON';
const ALLOWED_ROUTES_FOR_ANIMATION = new Set([ Route.Home, Route.CommercialMarketLanding ]);

const uploadAnimationEffect = (props: BaseEffectProps & { prevRoute: RouteState }) => {
  if (props.prevRoute) return noop;
  return baseOnceDayEffect(props);
};

function useUploadAnimationState(routeName: Route, prevRoute: RouteState) {
  const [ showAnimation, setShowAnimation ] = useState(false);
  const setDebounceAnimation = useCallback(debounce((v: boolean) => setShowAnimation(v), DEBOUNCE_DELAY_MS), []);
  useEffect(uploadAnimationEffect({
    routeName,
    allowedRoutes: ALLOWED_ROUTES_FOR_ANIMATION,
    localKey: UPLOAD_ANIMATION_KEY,
    stateHandler: setDebounceAnimation,
    prevRoute,
  }), [ routeName, prevRoute ]);

  return showAnimation;
}

const HeaderComponent: React.FC<HeaderProps> = ({
  route,
  isConnectedUser,
  isUserLoading,
  userProfile,
  onOpenAuthModal,
  onMore,
  onLogout,
  mode,
  onModeClick,
  onStaticLinksClick,
  onSetAuthModal,
  onSetUserMobileProfileOpen,
  isLogoTransparent,
  authLabel,
  onMadadLinkClick,
  onShortlistLinkHover,
  setIsVisible,
  prevRoute,
  isLikedToastOpen,
  shortlistHoverPreviewListingsCount,
  prevSearchFilter,
  showAgentConsoleBulletinForm,
  tooltipStyle,
}) => {
  const { t } = useLocale();
  const [ visibleIdx, setVisibleIdx ] = useState<number>();
  const showAnimation = useUploadAnimationState(route.name, prevRoute);
  const screenBreakpoint = useScreenBreakpoint();
  const isUnitPage = isUnitPageRoute(route.name);
  const isDesktop = screenBreakpoint >= 3;
  const isMiddleScreen = screenBreakpoint === 3;

  const [ isShortlistPreviewVisible, setIsShortlistPreviewVisible ] = useState(false);

  const setVisibleTooltipIdx = (visible: boolean, idx: number): void => {
    if (visible) {
      setVisibleIdx(idx);
      setIsVisible(true);
    }
    else {
      setVisibleIdx((current: number) => current === idx ? null : current);
      setIsVisible(false);
    }
  };

  const realMode = route.name === Route.LocalPage ? AppMode.Check : mode;
  const isSearchPage = route.name === Route.Search || route.name === Route.SearchCommercial;
  const isProfileMenuInnerPage = PROFILE_MENU_INNER_PAGES.has(route.name);
  const isAddEditPage = ADD_EDIT_BULLETIN_PAGES.has(route.name);

  const linkClickHandler = (r: Route) => {
    switch (r) {
      case Route.CommercialMarketLanding:
        onStaticLinksClick(Route.CommercialMarketLanding);
        break;
      case Route.Search:
      case Route.CheckAddress:
        onModeClick(realMode);
        break;
      case Route.MadadPage:
        onStaticLinksClick(Route.MadadPage);
        onMadadLinkClick();
        break;
      default:
        break;
    }
  };

  const modes = (
    <ModesWrapper data-auto="search-mode-selection" isMiddleScreen={isMiddleScreen}>
      {getHeaderLinks(route, isDesktop, t, prevSearchFilter).map((linkConf, idx) => {
        let isActive = false;
        const link = ((): JSX.Element => {
          let data = null;
          switch (linkConf.type) {
            case HeaderLinkType.Inner: {
              const {
                label,
                routeName,
                params,
                activePredicate,
              } = linkConf;
              isActive = activePredicate(route.name, realMode);
              data = (
                <Link
                  routeName={routeName}
                  preserveParams
                  data-auto={`${routeName}-link`}
                  data-auto-active={isActive}
                  onClick={() => linkClickHandler(routeName)}
                  routeParams={params}
                  className="tab-link"
                  hideLinkParams
                >
                  {label}
                </Link>
              );
              break;
            }
            case HeaderLinkType.Outer: {
              const {
                label,
                name,
                href,
              } = linkConf;
              data = (
                <AnchorMiddleware href={href}>
                  {(hrefLink) => (
                    <a data-auto={`${name}-link`} target="_blank" className="tab-link" href={hrefLink}>
                      {label}
                    </a>
                  )}
                </AnchorMiddleware>
              );
              break;
            }
            default:
              return null;
          }
          return linkConf.seoLinks && isDesktop ? (
            <SeoTooltip
              visible={idx === visibleIdx}
              setVisible={(visible) => setVisibleTooltipIdx(visible, idx)}
              tooltipStyle={tooltipStyle}
              refElement={data}
              placement="bottom-end"
              positionFixed
            >
              <SectionLinkWrapper>{renderSeoLinks(linkConf.seoLinks, onMadadLinkClick)}</SectionLinkWrapper>
            </SeoTooltip>
          ) : data;
        })();

        return (
          <HeaderLinkItem
            isDesktop={isDesktop}
            weight="medium"
            key={linkConf.label}
            isActive={isActive}
          >
            {link}
          </HeaderLinkItem>
        );
      })}
    </ModesWrapper>
  );

  const moreButton = (
    <MoreActionsButton
      data-auto="more-button"
      onClick={onMore}
    >
      <MoreIcon width={24} height={24} />
    </MoreActionsButton>
  );

  const moreMenuIcon = <HamburgerIcon data-auto="more-button" onClick={() => onMore()} />;

  const handleAvatarClick = () => {
    onSetUserMobileProfileOpen(true);
  };

  const mobileShortlistIcon = (
    <Link routeName={Route.Shortlist} preserveParams data-auto={`${Route.Shortlist}-link`} style={{ height: '100%' }}>
      <HeaderLinkItem isActive={Route.Shortlist === route.name} noSvgMargin>
        <HeartIconWithCounter />
      </HeaderLinkItem>
    </Link>
  );

  let mobileHeader;
  if (route.name === Route.Shortlist) {
    mobileHeader = <ShortlistMobileHeader />;
  }
  else if (route.name === Route.UploadBulletinForm || route.name === Route.EditBulletinForm || route.name === Route.EditResidentialBulletinForm) {
    mobileHeader = (
      <SimpleHeader position="left">
        <div data-auto="back-button">
          <BackLink>{<CloseIcon width={24} height={24} />}</BackLink>
        </div>
        <Text weight="medium">
          {t('bulletinForm.form.title', { isEdit: route.name === Route.EditBulletinForm || route.name === Route.EditResidentialBulletinForm })}
        </Text>
      </SimpleHeader>
    );
  }
  else if (isProfileMenuInnerPage) {
    mobileHeader = (
      <SimpleHeader position={isAddEditPage ? 'left' : 'right'}>
        <div data-auto="back-button">
        <BackLink>{isAddEditPage ? <CloseIcon width={24} height={24} /> : <ArrowIcon width={24} height={24} />}</BackLink>
        </div>
        <Text weight="medium">
          {t('navigation.header.staticLinkLabel', {
            link: route.name,
            initialTab: route.params.initialTab,
          })}
        </Text>
      </SimpleHeader>
    );
  }
  else if (isSearchPage) {
    mobileHeader = (
      <>
        {moreMenuIcon}
        {modes}
        <SpacingBlock mLeft={3} style={{ height: '100%' }}>
          {mobileShortlistIcon}
        </SpacingBlock>
        <Logo
          small
        />
      </>
    );
  }
  else if (isUnitPage) {
    mobileHeader = (
      <HeaderAlignment>
        <BackLink>
          <BackArrowIcon style={{ width: 8, height: 16 }} />
        </BackLink>
        <Logo small={false} />
        <div />
      </HeaderAlignment>
    );
  }
  else {
    mobileHeader = (
      <>
        {moreMenuIcon}
        <Link
          routeName={showAgentConsoleBulletinForm ? Route.UploadBulletinForm : Route.UploadBulletin}
          routeParams={{ source: 'topbar' }}
          preserveParams
          data-auto={`${Route.UploadBulletin}-link`}
        >
          <AddThickIcon />
        </Link>
        <Logo small={false} />
        {mobileShortlistIcon}
        {isConnectedUser && userProfile && userProfile.initials ? (
          <Avatar data-auto="user-menu" userInitials={userProfile.initials} imageURL={userProfile.avatar} onClick={handleAvatarClick} />
        ) : (
          <UserIcon
            onClick={() => onSetAuthModal({ type: AuthenticationModalType.SignIn }, { isUserInitiated: true })}
          />
        )}
      </>
    );
  }

  const hideUploadButton =
    route.name === Route.Listings ||
    route.name === Route.ManageBulletins ||
    (route.name === Route.UploadBulletin && isConnectedUser) ||
    (route.name === Route.UploadBulletinForm && isConnectedUser);

  const linkLabel = (link: Route) => isMiddleScreen ? null : t('navigation.header.staticLinkLabel', { link });

  const handleShortlistLinkHover = () => {
    setIsShortlistPreviewVisible(true);
    onShortlistLinkHover(shortlistHoverPreviewListingsCount);
  };

  const shortlistLink = (
    <Link
      routeName={Route.Shortlist}
      preserveParams
      data-auto={`${Route.Shortlist}-link`}
      data-auto-active={Route.Shortlist === route.name}
      onClick={() => onStaticLinksClick(Route.Shortlist)}
      onMouseEnter={handleShortlistLinkHover}
      onMouseLeave={() => setIsShortlistPreviewVisible(false)}
    >
      <HeaderLinkItem
        isDesktop={isDesktop}
        weight="medium"
        isActive={Route.Shortlist === route.name}
        noSvgMargin={isMiddleScreen}
      >
        {linkToIcon[Route.Shortlist]}
        {linkLabel(Route.Shortlist)}
      </HeaderLinkItem>
    </Link>
  );

  return (
    <>
      {config.header.showBetaBadge && <BetaBadge />}
      <ShowAt to={isUnitPage ? 2 : 1}>
        <WidthLimiter noBG={isLogoTransparent}>
          <Wrapper className="mobile-header-wrapper">
            {mobileHeader}
          </Wrapper>
        </WidthLimiter>
        {isLikedToastOpen && <LikedToast />}
      </ShowAt>
      <ShowAt from={isUnitPage ? 3 : 2}>
        <WidthLimiter noBG={isLogoTransparent}>
          <Wrapper data-auto="desktop-header-wrapper">
            <Logo />
            {modes}
            {isUserLoading ? <GrowSpan /> : (
              <>
                {hideUploadButton ? null : (
                  <AddListingButton isDesktop={isDesktop} withUploadButtonAnimation={showAnimation}>
                    <UploadBulletinLink
                      source="topbar"
                      icon={<PlusIcon width={16} height={16} />}
                      text={t('navigation.header.addListingButton', { isMiddleScreen })}
                    />
                  </AddListingButton>
                )}
                {isConnectedUser ? (
                  <>
                    <ShortlistHoverPreview
                      isVisible={isShortlistPreviewVisible}
                      onMouseEnter={() => setIsShortlistPreviewVisible(true)}
                      onMouseLeave={() => setIsShortlistPreviewVisible(false)}
                    />
                    <LinksWrapper marginAuto={hideUploadButton} isMiddleScreen={isMiddleScreen}>
                      {isLikedToastOpen ? <LikedToast>{shortlistLink}</LikedToast> : <>{shortlistLink}</>}
                    </LinksWrapper>
                    <UserActions onLogout={onLogout} />
                  </>
                ) : (
                  <AuthButton
                    isDesktop={isDesktop}
                    data-auto="signup-trigger"
                    weight="medium"
                    onClick={onOpenAuthModal}
                    isMiddleScreen={screenBreakpoint === 3 || screenBreakpoint === 4}
                  >
                    {authLabel ? authLabel : (
                      <>
                        <ProfileIcon width={24} height={24} />
                        {screenBreakpoint === 3 || screenBreakpoint === 4 ? null : t('authentication.authenticationLabel', {
                          isSignUp: true,
                          pendingActionType: null,
                        })}
                      </>
                    )}
                  </AuthButton>
                )}
              </>
            )}
            {moreButton}
          </Wrapper>
        </WidthLimiter>
      </ShowAt>
    </>
  );
};

export const HeaderWithEvents = withEvents<HeaderProps>((sendEvent, props) => ({
  onMadadLinkClick() {
    sendEvent('madad_click', 'madad');
  },
  onModeClick(m: AppMode) {
    const searchType = m === AppMode.Buy ? 'buy_home' : (m === AppMode.Rent ? 'rent_home' : 'check_address');
    if (m !== props.mode) {
      sendEvent('search_type_change', 'search', {
        event: {
          search_type: searchType,
        },
        search: {
          search_type: searchType,
        },
      });
    }
  },
  onMore() {
    sendEvent('homepage_sidebar_menu_open', 'homepage');
  },
  onStaticLinksClick(action: Route) {
    if (navEventMap[action]) {
      sendEvent('page_navigation_bar_icon_click', 'page', { event: { icon: navEventMap[action] } });
    }
  },
  onShortlistLinkHover(previewListingsCount: number) {
    sendEvent('favorites_icon_hover', 'favorites', { event: { saved_listings_num: previewListingsCount } });
  },
}))(HeaderComponent);

const mapStateToProps = (state: State) => ({
  isUser: isUserSelector(state),
  isConnectedUser: isConnectedUserSelector(state),
  isUserLoading: userLoadingSelector(state),
  isVisitor: isVisitorUserSelector(state),
  isUserMobileProfileOpen: isUserMobileProfileOpenSelector(state),
  userProfile: userProfileFieldsSelector(state),
  prevRoute: prevRouteSelector(state),
  isLikedToastOpen: isLikedToastOpenSelector(state),
  shortlistHoverPreviewListingsCount: userFavoritesPreviewPoisCountSelector(state),
  showAgentConsoleBulletinForm: showAgentConsoleBulletinFormSelector(state),
  prevSearchFilter: prevSearchFilterSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetAuthModal: (payload: AuthenticationModalPayload, meta: { isUserInitiated?: boolean, source?: AuthModalSource }) => dispatch(setAuthModal(payload, meta)),
  onSetUserMobileProfileOpen: (payload: boolean) => dispatch(setUserMobileProfileOpen(payload)),
  setIsVisible: (isVisible: boolean) => dispatch(setIsHeaderTabSubmenuVisible(isVisible)),
});

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderWithEvents);
