import styled from '@emotion/styled';
import { AuthenticationInfo, FormControlsWrapper } from 'components/authentication/styled';
import { SmallText } from 'ds/components/typography';

export const TimerText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey4};
`;

export const ResendLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const AuthCodeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CodeInputWrapper = styled.div<{ isSuccess: boolean | null }>`
  direction: ltr;
  & > div {
    margin-top: 0;
    margin-bottom: 0;
    & input {
      color: ${({ theme }) => theme.colors.neutrals.grey1};
      font-size: 15px;
    }
  }
  & > div > div {
    margin: 0 ${({ theme }) => theme.spacing(0.75)};
    height: 40px;
    width: 34px;
    border: 1px solid ${({ theme, isSuccess }) => isSuccess === null ? theme.colors.neutrals.grey6 : (isSuccess ? theme.colors.brand.madlanGreen : theme.colors.alert.red)};
  }
`;

export const AppleAuthenticationInfo = styled(AuthenticationInfo)`
  font-size: 13px;
`;

export const ControlsWrapper = styled(FormControlsWrapper)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-left: 0;
    margin-right: 0;
  `}
`;

export const SkipLink = styled.div`
  margin-top: auto;
  align-self: center;
  font-weight: 500;
  cursor: pointer;
`;
