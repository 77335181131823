import { TrackJS } from 'trackjs';

const SCRIPT_ID = 'msq_pixel_id';


export class RTBAPI {
  private static hasLoaded: boolean = false;
  private config: any;

  constructor(config: any) {
    this.config = config;
  }

  public sendEvent(maskyooNumber: string) {
    function msqRequest(f: () => void) {
      /in/.test(document.readyState) ? setTimeout('msq_r(' + f + ')', 9) : f();
    }

    try {
      msqRequest(() => {
        const msqPixel = new MSQ(this.config);
        msqPixel.push('__maskyoo', maskyooNumber); // send maskyoo number

        const uid = localStorage && localStorage.getItem('_rtbh.uid');
        if (uid) {
          msqPixel.pushUrlParam('uid', uid);
        }

        const time = (new Date()).getMilliseconds();
        msqPixel.pushUrlParam('time', time);
        msqPixel.pushUrlParam('tag', `pr_aZUfSgSVQykHO6GFMRSH_conversion_action_call_110_${time}_all`);

        msqPixel.process();
      });
    }
    catch (e) {
      TrackJS.track([
        'RTBAPI: failed to send event. Error: ',
        JSON.stringify(e),
      ]);
    }
  }

  public loadClient() {
    return new Promise<void>((resolve) => {
      if (RTBAPI.hasLoaded) {
        resolve();
      }
      else {
        const loadCB = () => {
          resolve();
        };

        const maybeScriptElt = document.getElementById(SCRIPT_ID) as HTMLScriptElement;

        if (maybeScriptElt) {
          maybeScriptElt.onload = loadCB;
        }

        else {
          const scriptElt = document.createElement('script');
          scriptElt.src = 'https://s3.eu-central-1.amazonaws.com/maskyoo-cdn/msq_pixel_2023.js?setting_disable_autorun=true';
          scriptElt.id = SCRIPT_ID;
          scriptElt.onload = loadCB;

          const firstJS = document.getElementsByTagName('script')[0];
          firstJS.parentNode.insertBefore(scriptElt, firstJS);
        }
      }
    });
  }
}
