import React  from 'react';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { IAdditionalMenuOption, MenuActions, AdditionalMenu } from '../AdditionalMenu';
import { EditListingStatusParams } from 'store/state/uploadBulletin/actions';
import DeleteIcon from 'assets/svg/interface-16-trash.svg';
import FreezeIcon from 'assets/svg/hide-tooltip.svg';
import MenuIcon from 'assets/svg/menu-dots.svg';
import EditIcon from 'assets/svg/edit-pencil.svg';
import { ControlButton } from 'ds/components/button';
import { BulletinStatusType, IBulletin, ICommercialBulletin } from 'utils/entities';
import { NavigateToFn, Route } from 'config/routes';


const ToggleWrapper = styled(ControlButton)<{ isRound?: boolean }>`
  ${({ theme, isRound }) => `
    padding: 0 ${isRound ? theme.spacing(1) : theme.spacing(1.5)};

    svg {
      margin: 0;
    }
  `}
`;

const IconOnlyToggleWrapper = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    &&& svg {
      height: 16px;
      width: 16px;
      margin-top: ${theme.spacing(0.5)};
    }
  `}
`;
export interface ManageMenuProps {
  poi: IBulletin | ICommercialBulletin;
  iconOnly?: boolean;
  isRound?: boolean;
  dropdownPosition?: 'bottom-right' | 'botton-left';
  editBulletinRouteName: Route;
  editListingStatus: (data: EditListingStatusParams) => void;
  toggleDeletePopup: (v: boolean) => void;
  navigateTo: NavigateToFn;
}

export const ManageMenu: React.FC<ManageMenuProps> = (props) => {
  const {
    poi,
    iconOnly,
    isRound,
    editBulletinRouteName,
    editListingStatus,
    toggleDeletePopup,
    navigateTo,
    dropdownPosition = 'bottom-left',
  } = props;
  const { t } = useLocale();

  if (!poi) return null;

  const {
    id,
    type,
  } = poi;

  const additionalMenuOptions: IAdditionalMenuOption[] = [
    {
      value: MenuActions.Freeze,
      icon: <FreezeIcon />,
      label: t('manageBulletinPage.bulletin.freeze'),
      onChange: () => editListingStatus({ status: BulletinStatusType.Frozen, id, type }),
    },
    {
      value: MenuActions.Delete,
      icon: <DeleteIcon />,
      label: t('manageBulletinPage.bulletin.delete'),
      onChange: () => toggleDeletePopup(true),
    },
    {
      value: MenuActions.Edit,
      icon: <EditIcon />,
      label: t('manageBulletinPage.bulletin.editBulletin'),
      onChange: () => {
        if (editBulletinRouteName && id) {
          navigateTo(editBulletinRouteName, { id });
        }
      },
    },
  ];

  const styles = {
    top: '47px',
    right: dropdownPosition === 'bottom-right' ? '-165px' : '0',
    width: '204px',
  };

  const icon = <MenuIcon width={16} height={16}/>;

  let button;
  if (isRound) {
    button = (
      <ToggleWrapper size="large" isRound>
        <MenuIcon width={24} height={24} />
      </ToggleWrapper>
    );
  }
  else if (iconOnly) {
    button = <IconOnlyToggleWrapper>{icon}</IconOnlyToggleWrapper>;
  }
  else {
    button = <ToggleWrapper size="large">{icon}</ToggleWrapper>;
  }

  return (
    <AdditionalMenu selectStyles={styles} options={additionalMenuOptions}>
      {button}
    </AdditionalMenu>
  );
};
