import React from 'react';
import Modal from 'ds/components/modal';
import CrossIcon from 'assets/svg/close-modal.svg';
import WarningIcon from 'assets/svg/warning-icon.svg';
import { useLocale } from 'locale';
import * as Styled from './styled';
import TrashIcon from 'assets/svg/trash2.svg';
import { Button } from 'ds/components/button';
import { useScreenBreakpoint } from 'consts/breakpoints';


interface DeleteUserPopupProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
  userEmail: string;
}


export const DeleteUserPopup: React.FC<DeleteUserPopupProps> = (props) => {
  const {
    isOpen,
    onDelete,
    onClose,
    userEmail,
  } = props;

  const { t } = useLocale();
  const screen = useScreenBreakpoint();
  const isMobile = screen < 3;

  if (!isOpen) return null;

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      isOpen
      hideCloseButton={true}
      onClose={onClose}
      contentWrapperStyle={{ marginTop: isMobile ? 0 : undefined }}
      footerStyle={{ borderTop: '0 none', textAlign: 'center', padding: 32 }}
      buttons={isMobile ? (
        <>
          <Styled.TextInfo>{t('profile.removeUserReason.textInfo')}</Styled.TextInfo>
          <Styled.DeleteButton onClick={handleDelete} fullWidth size="extraLarge">
            <Styled.TrashWrapper>
              <TrashIcon width={20} height={20} />
              {t('profile.removeUserReason.confirmDeleteBtn')}
            </Styled.TrashWrapper>
          </Styled.DeleteButton>
          <Styled.CancelButtonWrapper>
            <Button mode="ghost" fullWidth size="extraLarge" onClick={onClose}>
              {t('profile.removeUserReason.confirmCancelBtn')}
            </Button>
          </Styled.CancelButtonWrapper>
        </>
      ) : null}
    >
      <Styled.Root desktopHeight={390}>
        <Styled.CloseWrapper onClick={onClose}>
          <CrossIcon width={24} height={24} />
        </Styled.CloseWrapper>
        <Styled.WarningWrapper>
          <WarningIcon width={60} />
        </Styled.WarningWrapper>
        <Styled.Title>{t('profile.removeUser.title')}</Styled.Title>
        <Styled.Subtitle>{t('profile.removeUser.subtitle')}<br />{userEmail}</Styled.Subtitle>
        {isMobile ? null : (
          <>
            <Styled.TextInfo>{t('profile.removeUserReason.textInfo')}</Styled.TextInfo>
            <Styled.DeleteButton onClick={handleDelete} fullWidth size="extraLarge">
              <Styled.TrashWrapper>
                <TrashIcon width={20} height={20} />
                {t('profile.removeUserReason.confirmDeleteBtn')}
              </Styled.TrashWrapper>
            </Styled.DeleteButton>
            <Styled.CancelButtonWrapper>
              <Button mode="ghost" fullWidth size="extraLarge" onClick={onClose}>
                {t('profile.removeUserReason.confirmCancelBtn')}
              </Button>
            </Styled.CancelButtonWrapper>
          </>
        )}
      </Styled.Root>
    </Modal>
  );
};
