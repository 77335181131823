import { call, getContext, put, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { generateShortId } from 'utils/ids';
import { RootAction } from 'store/state';
import { mutate } from 'store/state/mutationsResponse/actions';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import {
  setToastMessage,
  promotePrivateBulletin,
  setPrivateBulletinSuccesfullyPromotedModal,
  setPrivateBulletinSuccesfullyPromotedPopover,
  setPrivateBulletinHasAlreadyBeenPromotedModal,
  PromotePrivateBulletinViewSource,
  openAgentSupportModal,
} from 'store/state/app/actions';
import { promotePrivateUserBulletinResponseSelector } from 'store/state/mutationsResponse/selectors';
import { LOGOUT } from 'store/state/app/types';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { resetDomainData } from 'store/state/domainData/actions';
import { isAgentManagerSelector, isAgentSelector, isConnectedUserSelector } from 'store/state/domainData/selectors';
import { MANUAL_PROMOTE_PRIVATE_BULLETIN } from 'store/state/app/private-user-monetization/types';
import { isPromotePrivateBulletinMutationLoadingSelector, latestManualPromotionsSelector } from 'store/state/app/private-user-monetization/selectors';
import { queryData } from './apiService';
import { LoadOptions, LoadType, MutationType } from './apiService/types';
import { BiDealType, BiEventCategory, biEventName, BiPromoteBulletinSource } from 'analytics/biTypes';
import { DealType } from 'utils/entities';
import { loginSuccessMutationPattern } from './userWatcher';
import { waitForUserResolve } from './routing/handlers/utils';
import { AgentSupportModalVariant } from 'store/state/app';

const waitForMutationResponse = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.PromotePrivateUserBulletin
);

const safeStringIncludes = (str: string, substr: string) => {
  try {
    return str.includes(substr);
  }
  catch (error) {
    return false;
  }
};

const viewSourceToAnalyticsSource: Record<PromotePrivateBulletinViewSource, BiPromoteBulletinSource> = {
  [PromotePrivateBulletinViewSource.ManageBulletinsPage]: BiPromoteBulletinSource.MANAGE_BULLETINS_PAGE,
  [PromotePrivateBulletinViewSource.BulletinPage]: BiPromoteBulletinSource.BULLETIN_PAGE,
};

function* handleManualPromotePrivateBulletin(action: ReturnType<typeof promotePrivateBulletin>) {
  const logger = yield getContext('logger');
  const { sendEvent } = yield getContext('analytics');
  const { bulletinId, bulletinType, viewSource, isTimerStillRunning, dealType } = action.payload || {};
  try {
    if (isTimerStillRunning) {
      yield put(setPrivateBulletinHasAlreadyBeenPromotedModal({ isOpen: true }));
      return;
    }

    const isLoading: ReturnType<typeof isPromotePrivateBulletinMutationLoadingSelector> = yield select(isPromotePrivateBulletinMutationLoadingSelector);

    if (isLoading) return;

    yield put(
      mutate({
        mutationType: MutationType.PromotePrivateUserBulletin,
        meta: {
          variables: {
            input: {
              bulletinId,
              bulletinType: bulletinType === 'commercialBulletin' ? 'COMMERCIAL' : 'RESIDENTIAL',
              source: viewSource,
            },
          },
        },
      })
    );
    yield call(sendEvent, biEventName[BiEventCategory.BULLETIN_MANAGE].PROMOTE_MANUAL, BiEventCategory.BULLETIN_MANAGE, {
      event: {
        source: viewSourceToAnalyticsSource[viewSource] || 'unknown',
      },
      property: {
        deal_type: dealType === DealType.Buy ? BiDealType.BUY : BiDealType.RENT,
        property_id: bulletinId,
      },
    });

    yield take(waitForMutationResponse);
    const response: ReturnType<typeof promotePrivateUserBulletinResponseSelector> = yield select(promotePrivateUserBulletinResponseSelector);

    if (!response || !response.data) {
      // probably network error
      return;
    }

    if (response.data.promotePrivateUserBulletin.success) {
      const latestManualPromotions: ReturnType<typeof latestManualPromotionsSelector> = yield select(latestManualPromotionsSelector);
      const hasPromotedThisBulletinBefore = (latestManualPromotions || []).some(p => p.bulletinId === bulletinId);
      if (hasPromotedThisBulletinBefore) {
        yield put(setPrivateBulletinSuccesfullyPromotedPopover({ isOpen: true }));
      }
      else {
        yield put(setPrivateBulletinSuccesfullyPromotedModal({ isOpen: true }));
      }
      yield call(loadCurrentPromotionPlan);
    }
    else if (safeStringIncludes(response.data.promotePrivateUserBulletin.errorMessage, 'not allowed at this time')) {
      yield put(setPrivateBulletinHasAlreadyBeenPromotedModal({ isOpen: true }));
    }
    else if (safeStringIncludes(response.data.promotePrivateUserBulletin.errorMessage, 'does not have the required role')) {
      yield put(openAgentSupportModal({ variant: AgentSupportModalVariant.MONETIZATION }));
    }
    else {
      yield put(setToastMessage({ type: 'error', term: 'error.generic', autoHideTimeout: 5000 }));
    }
  }
  catch (error) {
    const correlationId = generateShortId();
    logger.error(`Failed to promote private bulletin: bulletinId=${bulletinId}, bulletinType=${bulletinType}, correlationId=${correlationId}.`, error);
    yield put(setToastMessage({
      type: 'error',
      preLineText: true,
      term: 'error.genericWithId',
      params: { correlationId },
      autoHideTimeout: 7000,
    }));
  }
}

const userSuccesfullyAuthenticatedPattern = (action: RootAction) => {
  return loginSuccessMutationPattern(action)
    || (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.CurrentUser);
};

function* loadCurrentPromotionPlan() {
  const logger = yield getContext('logger');
  try {
    const isAgent: ReturnType<typeof isAgentSelector> = yield select(isAgentSelector);
    const isAgentManager: ReturnType<typeof isAgentManagerSelector> = yield select(isAgentManagerSelector);
    const isConnectedUser: ReturnType<typeof isConnectedUserSelector> = yield select(isConnectedUserSelector);

    if (!isConnectedUser || isAgent || isAgentManager) return;

    const loadOptions: LoadOptions<LoadType.CurrentPromotionPlan> = {
      loadType: LoadType.CurrentPromotionPlan,
      meta: { variables: { uiNonCache: Date.now().toString() } },
    };
    yield call(queryData, loadOptions);
  }
  catch (error) {
    logger.error('Error in loadCurrentPromotionPlan', error);
  }
}

function* logoutWorker() {
  yield put(resetDomainData({ loadType: LoadType.CurrentPromotionPlan }));
}

export function* privateUserMonetizationWatcher() {
  yield takeLeading(MANUAL_PROMOTE_PRIVATE_BULLETIN, handleManualPromotePrivateBulletin);
  yield takeLatest(userSuccesfullyAuthenticatedPattern, loadCurrentPromotionPlan);
  yield takeEvery(LOGOUT, logoutWorker);
}

export function* ssrMaybeLoadCurrentPromotionPlan() {
  yield call(waitForUserResolve);
  yield call(loadCurrentPromotionPlan);
}
