import { connect } from 'react-redux';
import { Profile } from './Profile';
import { State, RootAction } from 'store/state';
import { Dispatch } from 'redux';
import { setAuthEmail, setAuthModal } from 'store/state/app/actions';
import { MutationType, IUpdateUserProfileVariables, IGenerateEmailVerificationCodeVariables } from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import { AuthenticationModalType } from 'components/authentication/types';
import { isAgentSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { deregisterUserResponseSelector } from 'store/state/mutationsResponse/selectors';
import { IReasonState } from './types';


const mapStateToProps = (state: State) => ({
  user: userProfileSelector(state),
  isAgent: isAgentSelector(state),
  deregisterUserResponse: deregisterUserResponseSelector(state, null),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onUpdateUser: (data: IUpdateUserProfileVariables, key: string) => dispatch(inlineMutate(MutationType.UpdateUserProfile, data, key)),
  onUserDelete: (data: IReasonState) => dispatch(inlineMutate(MutationType.DeregisterUser, data)),
  onSetActiveModal: (type: AuthenticationModalType) => dispatch(setAuthModal({ type }, { isUserInitiated: true })),
  generateEmailVerificationCode: (data: IGenerateEmailVerificationCodeVariables) => dispatch(inlineMutate(MutationType.GenerateEmailVerificationCode, data)),
  setEmail: (email: string) => dispatch(setAuthEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
