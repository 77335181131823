import { flow } from 'lodash';
import { routeSelector } from 'store/state/selectors/router';
import { createSelector } from 'reselect';
import { poiSelector } from 'store/state/selectors/searchListView';
import { searchLastInGeometryIdSelector, searchDocEntriesSelector, searchListTotalSelector, searchListItemsPerPageLimit, searchListTotalNearbySelector, projectsCarouselSearchPoiListSelector } from 'store/state/domainData/selectors';
import { CardPoi } from './residential/ListView';
import { developerSellerSelector } from 'components/document-head';
import { abTestsStateSelector } from 'store/state/ab-tests/selectors';
import { SearchResultProjectsCarousel } from 'config/abTests';
import { dealTypeSelector } from 'store/state/selectors/search';
import { DealType } from 'utils/entities';

export const pageSelector = flow(routeSelector, ({ params }) => params.page);

const EMPTY: CardPoi = [];

export const showProjectsCarouselSelector = createSelector([
  projectsCarouselSearchPoiListSelector,
  dealTypeSelector,
  developerSellerSelector,
  abTestsStateSelector,
], (pois, dealType, onlyDeveloperFilter, { searchResultProjectsCarousel }) => {
  const isSearchResultProjectsCarouselModeB = searchResultProjectsCarousel === SearchResultProjectsCarousel.modeB;

  if (isSearchResultProjectsCarouselModeB) {
    if (dealType === DealType.Rent) return false;
    if (pois && (pois.length < 2)) return false;
    if (onlyDeveloperFilter) return false;
    return true;
  }
  return false;
});

export const multiSearchAwarePoiSelector = createSelector([
  poiSelector,
  searchLastInGeometryIdSelector,
  searchDocEntriesSelector,
], (poi, lastInGeometryId, addresses) => {
  const isSingleAddress = addresses && addresses.length === 1;
  const lastPoiIndex = poi.findIndex((entry) => entry.id === lastInGeometryId);
  // if multi-search - show only the main cards and stop once lastIndex is reached
  const poiToRender: CardPoi = poi && poi.length > 0
    ? poi.slice(0, isSingleAddress ? Infinity : (lastPoiIndex + 1 || Infinity))
    : EMPTY;

  return poiToRender;
});

export const searchListTotalPagesSelector = createSelector([
  searchListTotalSelector,
  searchListTotalNearbySelector,
  searchListItemsPerPageLimit,
  searchDocEntriesSelector,
], (allTotal, totalNearby, limit, addresses) => {
  const isSingleAddress = addresses && addresses.length === 1;
  const total = isSingleAddress ? allTotal : allTotal - totalNearby;
  return Number.isInteger(total) && limit ? Math.ceil(total / limit) : null;
});
