import { AuthenticationInfoHeader, LogoWrapper } from 'components/authentication/styled';
import CodeInput from 'components/code-input';
import React, { useEffect, useState } from 'react';
import EmailIcon from 'assets/svg/auth/auth-mail.svg';
import { useLocale } from 'locale';
import { Link } from 'ds/components/link';
import { AppleAuthenticationInfo, AuthCodeWrapper, CodeInputWrapper, ControlsWrapper, ResendLinkWrapper, SkipLink, TimerText } from './styled';
import { Button } from 'ds/components/button';
import { SpacingBlock } from 'components/spacing-block';
import { AuthenticationModalType } from '../types';
import { EmailVerificationCodeStatus, IGenerateEmailVerificationCodeVariables, IVerifyEmailVerificationCodeVariables, MutationResponseByType, MutationType } from 'store/sagas/apiService/types';
import { ApolloQueryResult } from 'apollo-client';
import { noop } from 'lodash';
import Spinner from 'components/widget/prices/prices-table/tables/history/affiliation/Spinner';

const CODE_LENGTH = 4;
const START_TIME = 60;
const MIN_LOADING_DELAY = 500;

export const AuthenticationCode: React.FC<{
  email: string;
  setActiveModal: (type: AuthenticationModalType) => void;
  generateEmailVerificationCode: (data: IGenerateEmailVerificationCodeVariables) => void;
  verifyEmailVerificationCode: (data: IVerifyEmailVerificationCodeVariables) => void;
  verifyEmailVerificationCodeData: ApolloQueryResult<MutationResponseByType[MutationType.VerifyEmailVerificationCode]>;
  isUser: boolean;
}> = ({ email, setActiveModal, generateEmailVerificationCode, verifyEmailVerificationCode, verifyEmailVerificationCodeData, isUser }) => {
  const { t } = useLocale();
  const [ code, setCode ] = useState('');
  const [ sendNewCode, setSendNewCode ] = useState(false);
  const [ time, setTime ] = useState(START_TIME);

  const isLoading = verifyEmailVerificationCodeData && verifyEmailVerificationCodeData.loading;
  const [ showFakeLoading, setShowFakeLoading ] = useState(false);
  const hasData = !isLoading && verifyEmailVerificationCodeData && verifyEmailVerificationCodeData.data;
  const isSuccess = hasData && verifyEmailVerificationCodeData.data.verifyEmailVerificationCode.statusCode === EmailVerificationCodeStatus.SUCCESS;
  const dissableSubmit = !code || code.length < CODE_LENGTH;
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!sendNewCode && time > 0) {
      interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);
    }
    else {
      clearInterval(interval);
      setSendNewCode(true);
    }
    return () => clearInterval(interval);
  }, [ sendNewCode, setTime, setSendNewCode, time ]);

  const handleBackToLogin = () => {
    setActiveModal(AuthenticationModalType.SignIn);
  };

  const handleResendCode = () => {
    setTime(START_TIME);
    setSendNewCode(false);
    generateEmailVerificationCode({ email });
  };

  const handleSendCode = () => {
    setShowFakeLoading(true);
    setTimeout(() => {
      verifyEmailVerificationCode({ email, code });
    }, MIN_LOADING_DELAY);
  };

  useEffect(() => {
    return () => {
      setShowFakeLoading(false);
    };
  });

  const showLoading = isLoading || showFakeLoading;

  return (
    <AuthCodeWrapper>
      <LogoWrapper>
        <EmailIcon width={44} height={44} />
      </LogoWrapper>
      <AuthenticationInfoHeader weight="bold">
        {t('authentication.confirmEmail.title')}
      </AuthenticationInfoHeader>
      <AppleAuthenticationInfo>{t('authentication.authCode.info', { email })}</AppleAuthenticationInfo>
      <CodeInputWrapper isSuccess={showLoading || !hasData || dissableSubmit ? null : isSuccess}>
        <CodeInput
          codeLength={CODE_LENGTH}
          value={code}
          setValue={setCode}
          error={
            showLoading || !hasData
              ? false
              : (
                !isSuccess ? t('authentication.authError.verificationCode') : null
              )
          }
        />
      </CodeInputWrapper>
      <ControlsWrapper>
        <Button
          data-auto="authentication-submit-button"
          fullWidth
          size="extraLarge"
          disabled={dissableSubmit || showLoading || isSuccess}
          onClick={dissableSubmit || isLoading || showFakeLoading || isSuccess ? noop : handleSendCode}
          style={isLoading || showFakeLoading ? { lineHeight: 0 } : undefined}
        >
          {isLoading || showFakeLoading || isSuccess ? <Spinner /> : t('authentication.appleEmailConfirm.button')}
        </Button>
      </ControlsWrapper>
      <ResendLinkWrapper>{sendNewCode
        ? <Link simple small style={{ cursor: 'pointer' }} onClick={handleResendCode}>{t('authentication.authCode.afterTimerLink')}</Link>
        : <TimerText>{t('authentication.authCode.timer', { time })}</TimerText>
      }</ResendLinkWrapper>
      {isUser ? null : (
        <>
          <SpacingBlock mTop={2} />
          <SkipLink>
            <Link simple onClick={handleBackToLogin}>{t('authentication.authCode.skipCode')}</Link>
          </SkipLink>
        </>
      )}
    </AuthCodeWrapper>
  );
};
