import { call, getContext, select } from 'redux-saga/effects';
import { DecodedRouteParams } from 'config/routes';
import { waitForPoiView } from '../viewedPoiPersistance';
import { getRouteParams } from 'store/state/selectors/router';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { getCurrentDesignRange } from 'consts/breakpoints';
import { isBulletinPoiType } from 'utils/marketplaceRoutes';
import { getPOCValidAgentPhoneWithPrefix } from 'helpers/contact';
import { IDeal } from 'utils/entities';
import { TrackJS } from 'trackjs';

const PROPERTY_VIEW_SOURCES = new Set([
  'map',
  'list_regular_card',
  'list_banner_card',
  'list_sticky_banner',
  'unit_page_top_banner',
  'unit_page_bottom_banner',
  'unit_page_additional_banner',
  'new_projects_nearby_carousel',
  'new_projects_nearby_carousel_non_promoted',
  'developer_page',
  'property_carousel',
  'favorites',
  'favorites_hover',
  'office_page_gallery',
  'office_page_active_listings',
  'agent_page_gallery',
  'agent_page_active_listings',
  'leading_agent_carousel',
  'recommended_listings_carousel',
  'recommended_listings_carousel_homepage',
  'recommended_listings_carousel_marketplace',
  'recommended_listings_carousel_neighborhood',
  'recommended_listings_carousel_city',
  'header_projects_carousel',
]);

const PUSH_NOTIFICATION_TO_PROPERY_VIEW_SOURCES: Record<string, string> = {
  notifications_manager_new: 'push_notification',
  notifications_manager_existing: 'push_notification',
  notifications_manager_price_change: 'push_notification',
  notifications_manager_favorite_price_change: 'push_notification',
  notifications_manager_project_promotions: 'push_notification',
};
const PUSH_NOTIFICATION_OPEN_SOURCES: Record<string, string> = {
  notifications_manager_new: 'saved_search',
  notifications_manager_existing: 'saved_search',
  notifications_manager_price_change: 'price_change',
  notifications_manager_favorite_price_change: 'price_change',
  notifications_manager_project_promotions: 'project_promotion',
};

export function* propertyEventsWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    yield call(waitForPoiView);
    const routeParams: DecodedRouteParams = yield select(getRouteParams);
    const poi: Exclude<ReturnType<typeof currentPoiSelector>, IDeal> = yield select(currentPoiSelector);
    const source = routeParams.tracking_event_source
      ? (
        PROPERTY_VIEW_SOURCES.has(routeParams.tracking_event_source)
          ? routeParams.tracking_event_source
          : PUSH_NOTIFICATION_TO_PROPERY_VIEW_SOURCES[routeParams.tracking_event_source] || 'other'
      )
      : 'direct';

    if (PUSH_NOTIFICATION_OPEN_SOURCES[routeParams.tracking_event_source]) {
      yield call(sendEvent, 'user_push_notification_click', 'user', {
        event: {
          push_type: PUSH_NOTIFICATION_OPEN_SOURCES[routeParams.tracking_event_source],
          notification_id: routeParams.tracking_push_uuid,
        },
      });
    }
    const screenBreakpoint = getCurrentDesignRange();
    const isMobile = screenBreakpoint === 1;

    if (!poi) {
      TrackJS.track([
        '[B2C-12159] Investigate missing matchScore from poi',
        `routeParams=${JSON.stringify(routeParams)}`,
        `poiList=${JSON.stringify(poi)}`,
      ]);
    }
    else {
      const validAgentNumber = isBulletinPoiType(poi) && getPOCValidAgentPhoneWithPrefix(poi.poc);
      yield call(sendEvent, 'property_view', 'property', {
        event: {
          match_percentage: 'matchScore' in poi && poi.matchScore || undefined,
          source,
          has_whatsapp_button: validAgentNumber && isMobile,
        },
      });
    }
  }
}
