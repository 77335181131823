import React from 'react';
import styled from '@emotion/styled';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InfoSignIcon from 'assets/svg/private-bulletin-promotion/modal-info-sign.svg';
import { useLocale } from 'locale';
import { RootAction, State } from 'store/state';
import { setPrivateBulletinHasAlreadyBeenPromotedModal } from 'store/state/app/actions';
import {
  currentPromotionPlanIntervalHoursSelector,
  isPrivateBulletinHasAlreadyBeenPromotedModalSelector,
} from 'store/state/app/private-user-monetization/selectors';
import Modal from 'ds/components/modal';
// import { Button } from 'ds/components/button';
import { H3 } from 'ds/components/typography';
import { AlertModalContent } from './sharedStyled';

const Title = styled(H3)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

interface PrivateBulletinHasAlreadyBeenPromotedModalProps {
  isOpen: boolean;
  promoteHoursInterval: number;
  onClose: () => void;
}

const PrivateBulletinHasAlreadyBeenPromotedModal: React.FC<PrivateBulletinHasAlreadyBeenPromotedModalProps> = props => {
  const { isOpen, promoteHoursInterval, onClose } = props;
  const { t } = useLocale();

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileModalPositionBottom
    >
      <AlertModalContent>
        <InfoSignIcon width={88} height={88} />
        <Title>
          {t('privateBulletinPromotion.hasAlreadyBeenPromoted', { hoursInterval: promoteHoursInterval })}
        </Title>
        {/* Phase 2 */}
        {/* <Button mode="primary" size="large" fullWidth>
          {t('privateBulletinPromotion.seeMorePromotionsButton')}
        </Button> */}
      </AlertModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: State) => ({
  promoteHoursInterval: currentPromotionPlanIntervalHoursSelector(state),
  isOpen: isPrivateBulletinHasAlreadyBeenPromotedModalSelector(state),
});


const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(setPrivateBulletinHasAlreadyBeenPromotedModal({ isOpen: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateBulletinHasAlreadyBeenPromotedModal);
