import { BiEvent } from 'analytics';
import { select } from 'redux-saga/effects';
import { routeSelector } from 'store/state/selectors/router';
import { DecodedRouteParams, Route } from 'config/routes';
import {
  BuildingStage,
  DealType,
  IArchitect,
  IBulletinAgent,
  IBulletinPrivate,
  IDeveloper,
  ProjectIntegration,
  PromotionValues,
  UrbanRenewal,
  IAutocompleteAddress,
  Tabu,
  PocType,
  IAutocompleteEntry,
  IAutocompleteStreet,
  LeadingAgentFeature,
  User,
} from 'utils/entities';
import { getPOCExclusive, checkEnabledLeadingAgentFeature } from 'helpers/contact';
import config from 'config';
import { allPoisByIdsHeapSelector, PoiHeapDict } from 'store/state/selectors/poisHeap';
import {
  addressDocEntrySelector,
  resolvedStrictLocalDocSelector,
  searchDocEntriesSelector,
  userProfileSelector,
} from 'store/state/domainData/selectors';
import { priceEstimationDataSelector } from 'store/state/selectors/insights/prices';
import { RequestType } from 'components/contact-modal/HebrewContactModal';
import { validTabuByDocIdSelector } from 'store/state/domainData/selectors/tabuByDocId';
import { ANALYTICS_MAPPING, Section } from 'components/navigation/subheader/SectionsNavigation';
import { TrackJS } from 'trackjs';
import { isCommercialProjectPredicate } from 'components/project-page/utils';
import { isBulletinPoiType, isBulletin, isProjectPoiType, isUnitPageRoute } from 'utils/marketplaceRoutes';
import { areaInsightsHasDataSelector, collapsedSectionsWithDataSelector, impactfulInsightsSelector } from 'store/state/selectors/insights/insightSections';
import { mapLocationTypeForEvents } from 'components/search-widget/helpers';
import { unitPageLeadingAgentSelector } from 'store/state/domainData/selectors/unit-page-leading-data';
import { recommendedListingsListSelector } from 'store/state/domainData/selectors/recommendedListings';
import { RecommendedListing } from '../apiService/types';


const dealTypeToEventValue = (dealType: DealType) => dealType === DealType.Buy ? 'for_sale' : 'for_rent';
const numeralSystem = 'sqm';

export const developerIntegrationLevelToText: Record<ProjectIntegration, string> = {
  [ProjectIntegration.Level0]: 'free',
  [ProjectIntegration.Level100]: 'basic',
  [ProjectIntegration.Level200]: 'demo',
  [ProjectIntegration.Level300]: 'full',
};

const projectBuildingStageToText: Record<BuildingStage, string> = {
  [BuildingStage.Building]: 'building',
  [BuildingStage.Finished]: 'done_building',
  [BuildingStage.Initial]: 'initial_planning',
  [BuildingStage.Permit]: 'with_permit',
  [BuildingStage.PermitRequest]: 'permit_request',
};

const projectUrbanRenewalToText: Record<UrbanRenewal, string> = {
  [UrbanRenewal.None]: 'none',
  [UrbanRenewal.Destruction]: 'destruction',
  [UrbanRenewal.DestructionAfterBuilding]: 'destruction_after_building',
  [UrbanRenewal.MilitaryBaseEvacuation]: 'military_base_evacuation',
  [UrbanRenewal.Rehabilitation]: 'rehabilitation',
  [UrbanRenewal.Renovation]: 'renovation',
  [UrbanRenewal.Tama38a]: 'tama38a',
  [UrbanRenewal.Tama38b]: 'tama38b',
  [UrbanRenewal.Thickening]: 'thickening',
};

let submenuAutoScrollingSection: string = null;

const RELATED_CATEGORIES = new Set([
  'property',
  'insight',
  'favorites',
  'address',
  'banner',
  'tabu',
  'local_page',
]);

const ALLOWED_PROPERTY_NAMES = new Set([
  'property_description_read_more',
  'property_lead_form_open',
  'property_lead_form_submit_attempt',
  'property_lead_form_submit',
  'property_lead_form_submit_success',
  'property_lead_call_submit',
  'property_lead_phone_expose',
  'property_lead_whatsapp_click',
]);

const SCROLL_TO_SECTION_EVENTS = new Set([
  'property_scroll_to_section',
  'address_scroll_to_section',
  'local_page_scroll_to_section',
]);

const TOP_MENU_CLICK_EVENTS = new Set([
  'property_view_top_menu_click',
  'address_view_top_menu_click',
  'local_page_top_menu_click',
]);

let requestType: RequestType = null;

const splitScrollSections = (section: Section): string[] =>
  ANALYTICS_MAPPING[section]
    ? ANALYTICS_MAPPING[section].split('/')
    : [];


export function poiEventPayload(input: {
  poi: any;
  estimatedPrice: number;
  isLeadingAgent: boolean;
  currentUser: User | null;
}) {
  const { poi, estimatedPrice, isLeadingAgent, currentUser } = input;

  let property: any;
  let location: any;

  if (poi) {
    location = {
      borough: poi.addressDetails && poi.addressDetails.borough,
      street_name: poi.addressDetails && poi.addressDetails.streetName,
      street_number: poi.addressDetails && poi.addressDetails.streetNumber,
      unit_number: poi.addressDetails && poi.addressDetails.unitNumber,
      city: poi.addressDetails && poi.addressDetails.city,
      country: config.country,
      location_id: undefined,
      location_type: 'address',
      neighborhood: poi.addressDetails && poi.addressDetails.neighbourhood,
    };

    if (isBulletinPoiType(poi)) {
      location.latitude = poi.locationPoint.lat;
      location.longitude = poi.locationPoint.lng;

      const isOwnedByUser = (currentUser && currentUser.uid) === (poi.poc && poi.poc.userId);

      let isPromoted = false;
      if (poi.poc.type === 'agent') {
        isPromoted = Boolean(poi.status && poi.status.promoted);
      }
      else if (poi.poc.type === 'private' && isBulletin(poi) && poi.promotedUntil) {
        const promotedUntilDate = new Date(poi.promotedUntil);
        const now = new Date();
        isPromoted = promotedUntilDate > now;
      }

      property = {
        deal_type: dealTypeToEventValue(poi.dealType),
        numeral_system: numeralSystem,
        property_id: poi.id,
        estimated_property_value: estimatedPrice,
        property_listing_type: isBulletin(poi) ? 'bulletin' : 'commercial_bulletin',
        seller_type: poi.poc.type === 'agent' ? 'agent' : 'individual',
        is_owned_by_user: isOwnedByUser,
        is_promoted: isPromoted,
        bulletin_info: {
          listing_provider_name: poi.source,
          listing_provider_property_id: poi.originalId,
          lease_term: poi.leaseTerm,
          publish_date: poi.firstTimeSeen && poi.firstTimeSeen.replace('Z', '').replace('T', ' ').replace(/\..*/, ''),
          area: poi.area,
          bathrooms: isBulletin(poi) ? poi.baths : null,
          currency: poi.currency,
          floor: poi.floor ? +poi.floor : undefined,
          is_agent_exclusive: getPOCExclusive(poi.poc),
          price: poi.price,
          rooms: isBulletin(poi) ? poi.beds : poi.rooms,
          madad_tag: (poi.poc && poi.poc.type === PocType.Agent && poi.poc.madadSearchResult) || 'no_tag',
        },
      };
      if (poi.amenities) {
        Object.assign(property.bulletin_info, {
          has_air_conditioning: !!poi.amenities.airConditioner,
          has_balconies: !!poi.amenities.balcony,
          has_elevator: !!poi.amenities.elevator,
          has_mamad: !!poi.amenities.secureRoom,
          has_parking: !!poi.amenities.parking,
          has_storeroom: !!poi.amenities.storage,
          is_accessible: !!poi.amenities.accessible,
        });
      }

      if (poi.poc.type === 'agent') {
        const agentDetails = poi.poc as IBulletinAgent;

        property.bulletin_info.agent_details = {
          office_name: agentDetails.officeContact && agentDetails.officeContact.name,
          office_id: agentDetails.officeId,
          email: agentDetails.agentContact && agentDetails.agentContact.email,
          id: agentDetails.agentId,
          name: agentDetails.agentContact && agentDetails.agentContact.name,
          phone_number: agentDetails.displayNumber,
          title: agentDetails.title,
          is_leading_agent: isLeadingAgent,
        };
      }
      else {
        const privateDetails = poi.poc as IBulletinPrivate;
        property.bulletin_info.private_seller_details = {
          id: privateDetails.userId,
          name: privateDetails.contactInfo && privateDetails.contactInfo.name,
          phone: privateDetails.displayNumber,
        };
      }
    }
    else if (isProjectPoiType(poi)) {
      location.latitude = poi.locationPoint.lat;
      location.longitude = poi.locationPoint.lng;

      property = {
        deal_type: dealTypeToEventValue(poi.dealType),
        numeral_system: numeralSystem,
        property_id: poi.id,
        property_listing_type: isCommercialProjectPredicate(poi) ? 'commercial_project' : 'project',
        seller_type: 'developer',
        is_promoted: poi.promotionStatus && poi.promotionStatus.status === PromotionValues.Promoted,
        project_info: {
          architects: poi.architects && poi.architects.map((a: IArchitect) => ({
            id: a.identifier,
            name: a.name,
          })),
          building_stage: poi.buildingStage && projectBuildingStageToText[poi.buildingStage],
          constructors: poi.constructors,
          buildings: poi.blockDetails && poi.blockDetails.buildingsNum,
          units: poi.blockDetails && poi.blockDetails.units,
          urban_renewal_type: poi.blockDetails && projectUrbanRenewalToText[poi.blockDetails.urbanRenewal],
          floors_max: poi.blockDetails && poi.blockDetails.floorRange && poi.blockDetails.floorRange.max,
          floors_min: poi.blockDetails && poi.blockDetails.floorRange && poi.blockDetails.floorRange.min,
          developers: poi.developers && poi.developers.map((p: IDeveloper) => ({
            id: p.id,
            integration_level: p.integrationLevel && developerIntegrationLevelToText[p.integrationLevel],
            name: p.name,
          })),
          name: poi.projectName,
        },
      };
    }
  }

  return {
    location,
    property,
  };
}

export function* propertyPreProcessor(event: BiEvent) {
  const { name, category, payload: payloadWithId = {} } = event;
  const { bulletinId, ...payload } = payloadWithId;

  const routerState: ReturnType<typeof routeSelector> = yield select(routeSelector);
  const routeParams: DecodedRouteParams = routerState.params || {};

  let payloadInsights;
  let payloadCollapsedSections;
  if ([ 'property_view_top_menu_click', 'address_view_top_menu_click', 'property_scroll_to_section', 'address_scroll_to_section' ].includes(name)) {
    if (payload.event.section === Section.AREA_INSIGHTS) {
      payloadInsights = yield select(areaInsightsHasDataSelector);
    }
    else if (payload.event.section === Section.OVERVIEW_PROS_AND_CONS) {
      payloadInsights = yield select(impactfulInsightsSelector);
    }
    else if (payload.event.section === Section.EXPLORE_THE_AREA) {
      payloadCollapsedSections = yield select(collapsedSectionsWithDataSelector);
    }
  }

  if (routerState.name === Route.AddressPage) {
    if (category === 'property') return null;
    if (category === 'insight') return { name, category, payload };
  }

  if (name === 'property_view_photo_scroll' && category === 'property') {
    if (![ Route.UnitPage, Route.ProjectPageCommercial, Route.UnitPageCommercial, Route.ProjectPage ].includes(routerState.name)) {
      payload.event.image_source = routerState.name === Route.Shortlist ? 'favorites_page' : 'search_result';
    }
    else {
      payload.event.image_source = 'property_page';
    }
  }

  if (RELATED_CATEGORIES.has(category)) {

    // this prevents property_scroll_to_section | address_scroll_to_section events from being emitted when
    // auto scroll is in progress
    if (SCROLL_TO_SECTION_EVENTS.has(name)) {
      const { section } = payload.event;
      if ((section === Section.OVERVIEW || section === Section.OVERVIEW_HEADER) && isUnitPageRoute(routerState.name)) {
        return null;
      }
      if (submenuAutoScrollingSection !== null) {
        if (section === submenuAutoScrollingSection) {
          submenuAutoScrollingSection = null;
        }
        return null;
      }
      const analyticsSection = splitScrollSections(section);
      payload.event = {
        'page_section': analyticsSection[0],
        'page_sub_section': analyticsSection[1],
        'insights': payloadInsights && payloadInsights.length ? payloadInsights : undefined,
        'collapsed_sections': payloadCollapsedSections,
      };
    }

    if (name === 'property_lead_form_submit') {
      requestType = payload.event.request_type;
    }

    if (name === 'property_lead_form_submit_success') {
      payload.event.request_type = requestType;
    }

    if (name === 'property_estimation_click') {
      submenuAutoScrollingSection = 'prices_estimation';
    }

    if (TOP_MENU_CLICK_EVENTS.has(name)) {
      const section = splitScrollSections(payload.event.section);

      submenuAutoScrollingSection = payload.event.section;

      payload.event = {
        'top_menu_button': section[0],
        'page_sub_section': section[1],
        'insights': payloadInsights && payloadInsights.length ? payloadInsights : undefined,
        'collapsed_sections': payloadCollapsedSections,
      };
    }

    const allowedRoutes = [ Route.UnitPage, Route.ProjectPageCommercial, Route.UnitPageCommercial, Route.ProjectPage, Route.Shortlist, Route.AddressPage, Route.LocalPage ];

    if (
      !allowedRoutes.includes(routerState.name)
      && category !== 'favorites'
      && category !== 'banner'
      && category !== 'property'
      && name !== 'property_view_photo_scroll'
    ) {
      return null;
    }

    let poiId = bulletinId;
    let location: any;

    if (!poiId && (routerState.name === Route.UnitPage || routerState.name === Route.ProjectPage || routerState.name === Route.ProjectPageCommercial || routerState.name === Route.UnitPageCommercial)) {
      poiId = routeParams.id;
    }

    const allPois: PoiHeapDict = yield select(allPoisByIdsHeapSelector);
    const poi: any = allPois[poiId];

    if (routerState.name === Route.AddressPage) {
      const addressDoc: IAutocompleteAddress = yield select(addressDocEntrySelector);
      location = {
        borough: addressDoc.borough,
        street_name: addressDoc.street,
        street_number: addressDoc.houseNumber,
        city: addressDoc.city,
        country: config.country,
        location_id: addressDoc.id,
        location_type: addressDoc.type,
        neighborhood: addressDoc.neighbourhood,
      };
    }

    if (routerState.name === Route.EmploymentAreaPage && category === 'banner') {
      const [ addressDoc ]: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
      location = {
        city: addressDoc.city,
        country: config.country,
        location_id: addressDoc.id,
        location_type: 'employment_area',
      };
    }

    if (routerState.name === Route.StreetPage) {
      const streetDoc: IAutocompleteStreet = yield select(resolvedStrictLocalDocSelector);
      if (streetDoc) {
        location = {
          street_name: streetDoc.street,
          city: streetDoc.city,
          location_id: streetDoc.id,
          location_type: streetDoc.type,
        };
      }
    }

    if ((routerState.name === Route.Search || routerState.name === Route.SearchCommercial) && category === 'banner') {
      const [ addressDoc ]: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
      location = {
        city: addressDoc.city,
        country: config.country,
        location_id: addressDoc.id,
        location_type: mapLocationTypeForEvents(addressDoc.type),
        street_number: 'houseNumber' in addressDoc ? addressDoc.houseNumber : undefined,
        street_name: 'street' in addressDoc ? addressDoc.street : undefined,
        borough: 'borough' in addressDoc ? addressDoc.borough : undefined,
        neighborhood: 'neighbourhood' in addressDoc ? addressDoc.neighbourhood : undefined,
        zip_code: 'zipcode' in addressDoc ? addressDoc.zipcode : undefined,
      };
    }

    if (routerState.name === Route.LocalPage) {
      const addressDoc: IAutocompleteAddress = yield select(resolvedStrictLocalDocSelector);

      if (addressDoc) {
        location = {
          borough: addressDoc.borough,
          city: addressDoc.city,
          country: config.country,
          location_id: addressDoc.id,
          location_type: addressDoc.type,
          neighborhood: addressDoc.neighbourhood,
        };
      }
      else {
        TrackJS.track([
          '[B2C-9643] addressDoc investigating null cases',
          `event=${JSON.stringify(event)}`,
          `addressDoc=${JSON.stringify(addressDoc)}`,
        ]);
      }
    }

    let propertyInfo: any;

    if (poi) {

      // TODO ask for commercial
      const priceEstimation: ReturnType<typeof priceEstimationDataSelector> = yield select(priceEstimationDataSelector);
      const leadingData: ReturnType<typeof unitPageLeadingAgentSelector> = yield select(unitPageLeadingAgentSelector);
      const featureName = (name === 'property_view') || (name === 'agent_carousel_scroll') ? LeadingAgentFeature.AboutTheAgentInListingPage : LeadingAgentFeature.ContactForm;
      const isLeadingAgent = checkEnabledLeadingAgentFeature(leadingData, featureName);
      const currentUser: ReturnType<typeof userProfileSelector> = yield select(userProfileSelector);

      const poiPayload = poiEventPayload({
        poi,
        estimatedPrice: priceEstimation.estimatedPrice,
        isLeadingAgent,
        currentUser,
      });
      location = poiPayload.location;
      propertyInfo = poiPayload.property;
    }

    if (category === 'property' && ALLOWED_PROPERTY_NAMES.has(name) && payload.event.source !== 'favorites_hover') {
      const { tracking_event_source } = routeParams;
      payload.event.property_view_source = tracking_event_source;
    }

    if (category === 'tabu' && poi) {
      const tabu: Tabu = yield select(validTabuByDocIdSelector);
      if (tabu) {
        location.block = String(tabu.block || '');
        location.parcel = String(tabu.parcel || '');
      }
    }

    propertyInfo = payload.forceProperty ? payload.property : propertyInfo;

    if (poiId && propertyInfo && routeParams.tracking_event_source && routeParams.tracking_event_source.startsWith('recommended_listings_carousel_')) {
      // If navigated to a POI from the recommended listings carousel
      const recommendedListings: RecommendedListing[] | null = yield select(recommendedListingsListSelector);
      if (recommendedListings && recommendedListings.length) {
        const recommendedListing = recommendedListings.find(listing => 'project' in listing ? listing.project.id === poiId : listing.bulletin.id === poiId);
        if (recommendedListing) {
          propertyInfo.recommendation_model = recommendedListing.recommendationVersion;
        }
      }
    }

    const listIndex = routeParams.tracking_list_index as any;
    return {
      name,
      category,
      payload: {
        ...payload,
        location: payload.forceProperty ? payload.location : location,
        search_property_index: listIndex && parseInt(listIndex, 10),
        property: propertyInfo ? {
          ...propertyInfo,
        } : undefined,
      },
    };
  }

  return { name, category, payload };
}
