import { SmallText } from 'ds/components/typography';
import React, { MutableRefObject } from 'react';
import { useLocale } from 'locale';
import MadlanIcon from 'assets/svg/feed-madlan-filled.svg';
import WhatsappIcon from 'assets/svg/whatsapp-icon.svg';
import EmailIcon from 'assets/svg/email-icon.svg';
import { Body, Header, ItemHeader, ItemRightSide, ItemSubheader, ItemWrapper, SupportPopupWrapper } from './styled';
import { SUPPORT_EMAIL, SUPPORT_WHATSAPP_DISPLAY_NUMBER } from './const';
import { useContactSupportHandlers } from './useContactSupportHandlers';

interface SupportPopupProps {
  popupRef: MutableRefObject<HTMLDivElement>;
}

export const SupportPopup: React.FC<SupportPopupProps> = ({ popupRef }) => {
  const { t } = useLocale();
  const { handleWhatsAppClick, handleEmailClick } = useContactSupportHandlers();

  const options = [
    {
      header: t('navigation.subheader.supportPopup.option.whatsapp.header'),
      subheader: SUPPORT_WHATSAPP_DISPLAY_NUMBER,
      icon: <WhatsappIcon />,
      onClick: handleWhatsAppClick,
    },
    {
      header: t('navigation.subheader.supportPopup.option.email.header'),
      subheader: SUPPORT_EMAIL,
      icon: <EmailIcon />,
      onClick: handleEmailClick,
    },
  ];

  const handleSubheaderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <SupportPopupWrapper ref={popupRef}>
      <Header>
        <span>
          {t('navigation.subheader.supportPopup.headerPart1')}{' '}
          <SmallText weight="bold">{t('navigation.subheader.supportPopup.headerPart2')}</SmallText>{' '}
          {t('navigation.subheader.supportPopup.headerPart3')}{' '}
          <SmallText weight="bold">{t('navigation.subheader.supportPopup.headerPart4')}</SmallText>
        </span>
        <MadlanIcon width={24} height={24} />
      </Header>
      <Body>
        {options.map((item) => (
          <ItemWrapper
            key={item.header}
            onClick={item.onClick}
          >
            <ItemRightSide>
              <ItemHeader weight="medium">{item.header}</ItemHeader>
              <ItemSubheader onClick={handleSubheaderClick}>{item.subheader}</ItemSubheader>
            </ItemRightSide>
            {item.icon}
          </ItemWrapper>
        ))}
      </Body>
    </SupportPopupWrapper>
  );
};
