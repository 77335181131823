import React from 'react';
import { useForm } from 'react-final-form-hooks';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { Text } from 'ds/components/typography';
import { AuthenticationField, AuthenticationModalType } from './types';
import {
  EnterPasswordFormRoot,
  FormControlsWrapper,
  LogoWrapper,
  AuthenticationInfoHeader,
  TextRow,
  EmailTextRow,
  ForgotLinkWrapper,
  AuthenticationMoreLink,
} from './styled';
import { AuthError } from 'store/sagas/apiService/types';
import MadlanLogoIcon from 'assets/svg/il-logo-small.svg';
import Spinner from 'components/widget/prices/prices-table/tables/history/affiliation/Spinner';
import { noop } from 'lodash';

interface EnterPasswordFormProps {
  email: string;
  password: string;
  onPasswordChange(e: React.ChangeEvent<HTMLInputElement>): void;
  dirtyFields: Set<AuthenticationField>;
  onSetDirty: (field: AuthenticationField) => void;
  onSubmit: () => void;
  onToggleResetPassword: () => void;
  isLoginFailed: boolean;
  setActiveModal: (type: AuthenticationModalType) => void;
  isLoading: boolean;
}

export const EnterPasswordForm: React.FC<EnterPasswordFormProps> = ({
  email,
  onPasswordChange,
  password,
  dirtyFields,
  onSetDirty,
  onSubmit,
  onToggleResetPassword,
  isLoginFailed,
  setActiveModal,
  isLoading,
}) => {
  const { t } = useLocale();
  const { handleSubmit } = useForm({ onSubmit });
  const handleBackToLogin = () => {
    setActiveModal(AuthenticationModalType.SignIn);
  };

  return (
    <EnterPasswordFormRoot>
      <LogoWrapper>
        <MadlanLogoIcon height={40} width={44} />
      </LogoWrapper>
      <AuthenticationInfoHeader weight="bold">
        {t('authentication.enterEmailPassword.header')}
      </AuthenticationInfoHeader>
      <TextRow>{t('authentication.enterEmailPassword.info')}</TextRow>
      <EmailTextRow centered>
        <Text weight="bold">{email || 'auto_agent_no_approved@yopmail.com'}</Text>
      </EmailTextRow>
      <form noValidate onSubmit={isLoading ? noop : handleSubmit}>
        <TextField
          data-auto="set-password-field"
          type="password"
          fullWidth
          onChange={onPasswordChange}
          value={password}
          placeholder={t('authentication.enterPassword.inputPlaceholder')}
          hasError={(isLoginFailed) && dirtyFields.has('password')}
          errorMessage={isLoginFailed
            ? t('authentication.authError', { error: AuthError.LoginFailed })
            : null
          }
          onBlur={() => onSetDirty('password')}
        />
        <ForgotLinkWrapper onClick={onToggleResetPassword}>{t('authentication.enterEmailPassword.forgotPasswordLink')}</ForgotLinkWrapper>
        <FormControlsWrapper>
          <Button
            data-auto="password-submit"
            disabled={!password || isLoading}
            fullWidth
            size="large"
            style={isLoading ? { lineHeight: 0 } : undefined}
          >
            {isLoading ? <Spinner /> : t('authentication.enterPassword.submitText')}
          </Button>
        </FormControlsWrapper>
      </form>
      <AuthenticationMoreLink data-auto="forgot-password-button" onClick={handleBackToLogin}>
        <Text weight="medium">{t('authentication.more')}</Text>
      </AuthenticationMoreLink>
    </EnterPasswordFormRoot>
  );
};
