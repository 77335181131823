import React from 'react';
import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import config from 'config';
import { useLocale } from 'locale';
import useAnchorLink from 'hooks/useAnchorLink';

const TermsAndConditionsText = styled(SmallText)`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.neutrals.grey3};
  `}
`;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;
  :active, :visited, :focus {
    color: inherit;
  }
`;

export const LeadContactTermsAndConditions: React.FC = () => {
  const { t } = useLocale();
  const href = useAnchorLink(config.termsOfServiceUrl || '/etc/terms');

  return (
    <TermsAndConditionsText>
      {t('termsAndConditions.leadContact.textStart')}
      <Link href={href} rel="noopener noreferrer" target="_blank">
        {t('termsAndConditions.leadContact.link')}
      </Link>
      {t('termsAndConditions.leadContact.textEnd')}
    </TermsAndConditionsText>
  );
};
