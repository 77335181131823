import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-final-form-hooks';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { SmallText } from 'ds/components/typography';
import { SocialAuthButton } from 'ds/components/button/styled';
import { AuthenticationField, AuthenticationModalType } from './types';
import { LoginType } from 'utils/entities';
import { AuthError } from 'store/sagas/apiService/types';
import { FormControlsWrapper } from './styled';
import { useLoginWithGoogleButton } from 'hooks/useLoginWithGoogleButton';

const SetPasswordFormWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    position: relative;
    width: 338px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  `}
`;

const AuthenticationForgotPasswordButton = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.brand.main};
  cursor: pointer;
  margin-top: 8px;
`;

const UserExistHintText = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  margin-bottom: 24px;
  text-align: center;
`;

const EmailBox = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.neutrals.grey10};
  padding: 13px;
  margin: 20px 0;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

interface UserExistsLoginFormProps {
  email: string;
  password: string;
  handleSetEmail(email: string): void;
  onSubmit: (type: LoginType) => void;
  onPasswordChange(e: React.ChangeEvent<HTMLInputElement>): void;
  dirtyFields: Set<AuthenticationField>;
  onSetDirty: (field: AuthenticationField) => void;
  onToggleResetPassword(): void;
  type: LoginType;
  modalType: AuthenticationModalType;
  isLoginFailed: boolean;
}

export const UserExistsLoginForm: React.FC<UserExistsLoginFormProps> = ({
  type,
  modalType,
  email,
  password,
  onPasswordChange,
  dirtyFields,
  onSetDirty,
  handleSetEmail,
  onToggleResetPassword,
  isLoginFailed,
  onSubmit,
}) => {
  const { t } = useLocale();
  const googleButtonNode = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (email) {
      handleSetEmail(email);
    }
  }, []);

  useLoginWithGoogleButton({ parent: googleButtonNode, theme: 'filled_blue', containerRef: rootRef });

  const { handleSubmit } = useForm({ onSubmit: () => onSubmit(type) });

  return (
    <SetPasswordFormWrapper ref={rootRef}>
      <UserExistHintText>
        <SmallText dangerouslySetInnerHTML={{ __html: t('authentication.userExists', { type }) }}/>
      </UserExistHintText>
      <EmailBox>{email}</EmailBox>
      <form noValidate onSubmit={handleSubmit}>
        {type === LoginType.Email ? (
          <>
            <TextField
              data-auto="signin-password-field"
              type="password"
              fullWidth
              onChange={onPasswordChange}
              value={password}
              label={t('authentication.passwordLabel', { isSignUp: false })}
              hasError={isLoginFailed && dirtyFields.has('password')}
              errorMessage={isLoginFailed
                ? t('authentication.authError', { error: AuthError.LoginFailed })
                : null
              }
              onBlur={() => onSetDirty('password')}
            />
            <AuthenticationForgotPasswordButton data-auto="forgot-password-button" onClick={onToggleResetPassword}>
                <SmallText>{t('authentication.forgotPasswordLabel')}</SmallText>
            </AuthenticationForgotPasswordButton>
          </>
        ) : null}
        <FormControlsWrapper>
          {type === LoginType.Google ? (
            <div ref={googleButtonNode} />
          ) : (
            <SocialAuthButton
              data-auto={`${modalType}-connect-button`}
              loginType={type}
              type="submit"
              size="large"
              fullWidth
            >
              {t('authentication.logInLabel', { type })}
            </SocialAuthButton>
          )}
        </FormControlsWrapper>
      </form>
    </SetPasswordFormWrapper>
  );
};
