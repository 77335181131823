import { flow } from 'lodash';
import { createSelector } from 'reselect';
import { State } from 'store/state';
import { makeNullSafeDomainGetter, privateUserCurrentPromotionPlanDomainSelector } from 'store/state/domainData/selectors/common';
import { promotePrivateUserBulletinResponseSelector } from 'store/state/mutationsResponse/selectors';

const appStateSelector = (state: State) => state.app;
const privateUserMonetizationSelector = flow(appStateSelector, state => state.privateUserMonetization);

export const isPrivateBulletinSuccesfullyPromotedModalOpenSelector = flow(privateUserMonetizationSelector, value => value.privateBulletinSuccesfullyPromotedModal.isOpen);
export const isPrivateBulletinSuccesfullyPromotedPopoverOpenSelector = flow(privateUserMonetizationSelector, value => value.privateBulletinSuccesfullyPromotedPopover.isOpen);
export const isPrivateBulletinHasAlreadyBeenPromotedModalSelector = flow(privateUserMonetizationSelector, value => value.privateBulletinHasAlreadyBeenPromotedModal.isOpen);

export const isPromotePrivateBulletinMutationLoadingSelector = flow(promotePrivateUserBulletinResponseSelector, response => Boolean(response && response.loading));

export const currentPromotionPlanDomainDataSelector = makeNullSafeDomainGetter(privateUserCurrentPromotionPlanDomainSelector, 'currentPromotionPlan');
export const currentPromotionPlanNameSelector = flow(currentPromotionPlanDomainDataSelector, data => data && data.promotionPlan.name);
export const currentPromotionPlanIntervalMinutesSelector = flow(currentPromotionPlanDomainDataSelector, data => data && data.promotionPlan.intervalMinutes);
export const currentPromotionPlanIntervalHoursSelector = createSelector(currentPromotionPlanIntervalMinutesSelector, minutes => {
  return Number.isFinite(minutes) ? parseFloat((minutes / 60).toFixed(2)) : null;
});
export const latestManualPromotionsSelector = createSelector(currentPromotionPlanDomainDataSelector, data => data && data.latestManualPromotions);

export const createNextAvailablePromotionDateSelector = (bulletinId: string) => createSelector(currentPromotionPlanDomainDataSelector, data => {
  if (!data) {
    return null;
  }

  const bulletinPromotionInfo = data.latestManualPromotions && data.latestManualPromotions.find(promotion => promotion.bulletinId === bulletinId);

  if (!bulletinPromotionInfo) {
    // Was not promoted yet
    return null;
  }

  return new Date(bulletinPromotionInfo.promotedUntil);
});
