import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from 'components/authentication/ClickAwayListener';
import { Manager, Reference, Popper } from 'react-popper';
import { Modifiers } from 'popper.js';
import { createPanResponder } from 'helpers/pan-responder';
import { ShowAt } from 'consts/breakpoints';
import CloseIcon from 'assets/svg/interface-16-close-1-5.svg';
import { noop } from 'lodash';
import { TooltipWrapper, TooltipArrow } from '../tooltip-container/styled';
import { TooltipProps } from '../types';


const LONG_TAP_THRESHOLD = 150;

const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  placement,
  size,
  hideArrow,
  raw,
  wrapperStyle,
  tapThreshold = LONG_TAP_THRESHOLD,
  clickable,
  disableClickAway,
}) => {
  const [ tooltipVisible, setTooltipVisible ] = useState(false);
  const [ hasBeenClicked, setHasBeenClicked ] = useState(false);
  const handleMouseEnter = useCallback(() => {
    if (!tooltipVisible) setTooltipVisible(true);
  }, [ tooltipVisible ]);
  const handleMouseLeave = useCallback(() => !hasBeenClicked && setTooltipVisible(false), [ hasBeenClicked ]);
  const handleAwayClick = useCallback(() => {
    setTooltipVisible(false);
    setHasBeenClicked(false);
  }, []);

  useEffect(() => {
    if (clickable) {
      setTooltipVisible(true);
    }
  }, [ clickable ]);

  const touchStartTime = useRef(null);
  const touchHandlers = clickable ? null : useRef(createPanResponder({
    onStart: () => {
      touchStartTime.current = +Date.now();
      setTooltipVisible(false);
      setHasBeenClicked(false);
    },
    onMove: (state, e) => {
      if (!state.down) {
        const isLongTap = (+Date.now() - touchStartTime.current) > tapThreshold;
        if (isLongTap) {
          setTooltipVisible(true);
          e.preventDefault();
        }
      }
    },
    onEnd: () => {
      touchStartTime.current = null;
    },
  }));

  const mouseHandlers = clickable ? null : {
    onMouseOver: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  const modifiers: Modifiers = {
    preventOverflow: {
      boundariesElement: 'viewport',
    },
  };

  return (
    <Manager>
      <ShowAt at={1}>
        {isMobile => (
          <Reference>
            {({ ref }) => (
              <div
                {...(isMobile ? touchHandlers.current : mouseHandlers)}
                className="tooltip-wrapper"
                ref={ref}
              >
                {children}
              </div>
            )}
          </Reference>
        )}
      </ShowAt>
      {tooltipVisible ? (
        <ClickAwayListener onClickAway={disableClickAway ? noop : handleAwayClick}>
          <Popper
            placement={placement || 'top'}
            positionFixed
            modifiers={modifiers}
          >
            {({ ref, style, placement: tooltipPlacement, arrowProps }) => (
              raw ? (
                ReactDOM.createPortal(
                  <div ref={ref} style={{ ...style, zIndex: 102 }} data-placement={placement}>
                    {tooltip}
                  </div>, document.getElementById('sticky-root'))
              ) : (
                ReactDOM.createPortal(
                  <TooltipWrapper
                    ref={ref}
                    style={{ ...style, ...wrapperStyle }}
                    data-placement={tooltipPlacement}
                    onClick={clickable ? undefined : handleAwayClick}
                    size={size}
                    hideArrow={hideArrow}
                    clickable={clickable}
                  >
                    {tooltip}
                    {!hideArrow && (
                      <>
                        <TooltipArrow
                          placement={tooltipPlacement}
                          ref={arrowProps.ref}
                          style={arrowProps.style}
                        />
                        {clickable ? (
                          <div style={{ cursor: 'pointer' }}>
                            <CloseIcon data-auto="tooltip-close-button" onClick={handleAwayClick} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </TooltipWrapper>, document.getElementById('sticky-root'))
              ))}
          </Popper>
        </ClickAwayListener>
      ) : null}
    </Manager>
  );
};

export default Tooltip;
