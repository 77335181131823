import { DeepReadonly } from 'utility-types';
import { AppAction } from '..';
import {
  SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_MODAL,
  SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_POPOVER,
  SET_PRIVATE_BULLETIN_HAS_ALREADY_BEEN_PROMOTED_MODAL,
} from './types';

export type PrivateUserMonetizationState = DeepReadonly<{
  privateBulletinSuccesfullyPromotedModal: {
    isOpen: boolean;
  };
  privateBulletinSuccesfullyPromotedPopover: {
    isOpen: boolean;
  },
  privateBulletinHasAlreadyBeenPromotedModal: {
    isOpen: boolean;
  };
}>;

const INITIAL_STATE: PrivateUserMonetizationState = {
  privateBulletinSuccesfullyPromotedModal: {
    isOpen: false,
  },
  privateBulletinSuccesfullyPromotedPopover: {
    isOpen: false,
  },
  privateBulletinHasAlreadyBeenPromotedModal: {
    isOpen: false,
  },
};

export const privateUserMonetizationReducer = (state = INITIAL_STATE, action: AppAction): PrivateUserMonetizationState => {
  switch (action.type) {
    case SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_MODAL: {
      return {
        ...state,
        privateBulletinSuccesfullyPromotedModal: {
          isOpen: action.payload.isOpen,
        },
      };
    }
    case SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_POPOVER: {
      return {
        ...state,
        privateBulletinSuccesfullyPromotedPopover: {
          isOpen: action.payload.isOpen,
        },
      };
    }
    case SET_PRIVATE_BULLETIN_HAS_ALREADY_BEEN_PROMOTED_MODAL: {
      return {
        ...state,
        privateBulletinHasAlreadyBeenPromotedModal: {
          isOpen: action.payload.isOpen,
        },
      };
    }
    default: {
      return state;
    }
  }
};
