import { combineReducers } from 'redux';
import { DeepReadonly } from 'utility-types';
import { ActionType } from 'typesafe-actions';
import { AuthenticationModalPayload } from 'components/authentication/types';
import { FeedbackToastMessage } from 'components/feedback-toast/ToastContainer';
import { CommutePopupStatus } from 'components/commute-popup/types';
import { PopupImageGalleryStatus, PopupImageGalleryStatusInitial } from 'components/unit-page/gallery/Gallery';
import * as Types from './types';
import * as actions from './actions';
import {
  DealType,
  DeveloperProjectMarketplace,
  ISendFeedbackCallbackProps,
  PoiId,
  ProjectBuildingStage,
} from 'utils/entities';
import { Section } from 'components/navigation/subheader/SectionsNavigation';
import { RouterAction } from '../router/actionType';
import { TRANSITION_SUCCESS } from '../router/types';
import { ShareModalType } from 'components/share-modal';
import { MadadContactModalInfo } from 'components/madad/contact-modal/ContactModal';
import { ListingResponseType, UserCannotAddListingErrorField } from 'store/sagas/apiService/types';
import { SearchViewMode } from 'components/list-view-switcher/constants';
import { IDealAffiliationPopupState } from 'components/deal-affiliation-popup/types';
import { privateUserMonetizationReducer, PrivateUserMonetizationState } from './private-user-monetization/reducer';

export type AppAction = ActionType<typeof actions>;
export enum SaveSearchUserAction {
  Accept = 'ACCEPT',
  ChangeSearchArea = 'CHANGE_SEARCH_AREA',
  Reject = 'REJECT',
}

export enum AuthModalSource {
  AgentsLanding = 'agents_landing',
  SaveListing = 'save_listing',
  SaveSearch = 'save_search',
  SignUpButton = 'sign_up_button',
  SignUpFromGetUpdates = 'sign_up_get_updates',
  TrackAddress = 'track_address',
  ImpactCard = 'impact_card',
  InsightExpand = 'insight_expand',
  UnlockDailyLimitCard = 'unlock_card',
  UGC = 'user_generated_content',
}

export enum ContactAgentModalSource {
  ContactSection = 'contact_section',
  ContactSticky = 'contact_sticky',
  SetUpMeetingContactSticky = 'contact_sticky_set_up_meeting',
  SetUpMeetingContactSection = 'contact_section_set_up_meeting',
  ShortlistHoverPreview = 'favorites_hover',
  Carousel = 'carousel',
  PostLead = 'post_lead',
  ExitModal = 'exit_campaign_landing_page_popup',
  ExitLandingModal = 'exit_landing_page_popup',
  WelcomeModal = 'welcome_campaign_landing_page_popup',
  AllGalleryList = 'property_view_photo_gallery',
  FullScreenGallery = 'property_view_photo_gallery_image',
}

export interface ContactModalOpenMeta {
  source?: ContactAgentModalSource;
  userInitiated?: boolean;
}

export interface FinishFormState {
  dirty: boolean;
  published: boolean;
  isFinishPopupOpen: boolean;
}

export interface TabuState {
  tabuSubmitted?: boolean;
  isSent?: boolean;
}

export type TabuDictionary = Record<string, TabuState>;

export type MadadContactModalState = MadadContactModalInfo;

export interface ManageListingsErrorModal {
  type: ListingResponseType;
  field?: UserCannotAddListingErrorField;
}

export interface DeveloperFiltersState {
  dealType: DealType[];
  marketplaceType: DeveloperProjectMarketplace[];
  buildingStage: ProjectBuildingStage[];
}

export enum PushNotificationsModalType {
  SaveSearch = 'SAVE_SEARCH',
  EnablePushNotifications = 'ENABLE_PUSH_NOTIFICATIONS',
}

export interface DevicePushStatus {
  hasPermission: boolean;
  deviceToken: string;
}

export enum AgentSupportModalVariant {
  DEFAULT = 'DEFAULT',
  MONETIZATION = 'MONETIZATION',
}

interface AgentSupportModalState {
  isOpen: boolean;
  variant: AgentSupportModalVariant;
}

export type AppState = DeepReadonly<{
  agentSupportModal: AgentSupportModalState;
  toastMessage: FeedbackToastMessage;
  authModal: AuthenticationModalPayload;
  commutePopup: CommutePopupStatus;
  contactModalPoiId: PoiId;
  intentModalPoiId: PoiId;
  welcomeModalPoiId: PoiId;
  isFeedbackPopupModalOpen: ISendFeedbackCallbackProps;
  isReportListingPopupModalOpen: boolean;
  finishFormState: FinishFormState;
  isAddressFeedbackPopupModalOpen: boolean;
  isSaveSearchModalOpen: boolean;
  galleryPopup: PopupImageGalleryStatus;
  isNeedsPopupOpen: boolean;
  contactAgentSuccessPoiId: PoiId;
  isMobileSearchMapViewActive: boolean;
  sectionInView: Section;
  fixedMultiPreview: Record<number, PoiId[]>;
  shareModalOpenType: ShareModalType;
  isUserMobileProfileOpen: boolean;
  isUserMobileProfileReturnable: boolean;
  isSidebarOpen: boolean;
  showGetUpdatesSection: boolean;
  madadContactModal: MadadContactModalState;
  tabuDictionary: TabuDictionary;
  isUploadBulletinReportModalOpen: boolean;
  isAfterUploadBulletinModalOpen: 'UPLOAD' | 'UPDATE' | null;
  authEmail: string;
  manageListingsErrorModal: ManageListingsErrorModal;
  deleteListingModalOpenStatus: boolean;
  mobileDiscoveryPopupOpenStatus: boolean;
  manageListingsFilterAgentUserId: string;
  developerFilters: DeveloperFiltersState;
  isListingAdditionalBannerClosed: boolean;
  isSearchResultAdditionalBannerClosed: boolean;
  isMobileMenuHidden: boolean;
  isAgentOfficeContactPopupOpen: boolean;
  isMizrahiBankModalOpen: boolean;
  isMortgageOfficeContactPopupOpen: boolean;
  searchViewMode: SearchViewMode;
  isLikedToastOpen: boolean;
  isHeaderTabSubmenuVisible: boolean;
  isNewUnitPageMapExpanded: boolean;
  pushNotificationsModalType: PushNotificationsModalType;
  devicePushStatus: DevicePushStatus;
  showLoginSuccessNotify: boolean;
  // This property was added to update the search list after editing a bulletin. [ILDEV-6319]
  listingEdited: number;
  dealAffiliationPopup: IDealAffiliationPopupState;
  isListingsTableLoading: boolean;
  privateUserMonetization: PrivateUserMonetizationState;
  contactFormEventsSource: ContactAgentModalSource;
}>;

export default combineReducers<AppState, AppAction | RouterAction>({
  agentSupportModal: (
    state = {
      isOpen: false,
      variant: AgentSupportModalVariant.DEFAULT,
    },
    action
  ) => {
    switch (action.type) {
      case Types.OPEN_AGENT_SUPPORT_MODAL: {
        return {
          ...state,
          isOpen: true,
          variant: action.payload.variant || AgentSupportModalVariant.DEFAULT,
        };
      }
      case Types.CLOSE_AGENT_SUPPORT_MODAL: {
        return {
          ...state,
          isOpen: false,
          variant: AgentSupportModalVariant.DEFAULT,
        };
      }
      default: {
        return state;
      }
    }
  },
  contactFormEventsSource: (state = ContactAgentModalSource.ContactSection, action) => {
    switch (action.type) {
      case Types.SET_CONTACT_FORM_EVENTS_SOURCE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isListingsTableLoading: (state = true, action) => {
    switch (action.type) {
      case Types.SET_IS_LISTINGS_TABLE_LOADING:
        return action.payload;
      default: {
        return state;
      }
    }
  },
  deleteListingModalOpenStatus: (state = false, action) => {
    switch (action.type) {
      case Types.SET_OPEN_STATUS_DELETE_LISTING_MODAL:
        return action.payload;
      default: {
        return state;
      }
    }
  },
  isNewUnitPageMapExpanded: (state = false, action) => {
    switch (action.type) {
      case Types.SET_NEW_UNIT_PAGE_MAP_EXPANDED: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  showLoginSuccessNotify: (state = false, action) => {
    switch (action.type) {
      case Types.TOGGLE_LOGIN_SUCCESS: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isUserMobileProfileOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_USER_MOBILE_PROFILE_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isUserMobileProfileReturnable: (state = false, action) => {
    switch (action.type) {
      case Types.SET_USER_MOBILE_PROFILE_RETURNABLE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isSidebarOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_SIDEBAR_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  fixedMultiPreview: (state = {}, action) => {
    switch (action.type) {
      case Types.SET_FIXED_MULTI_PREVIEW: {
        const nextState = { ...state };

        if (action.payload.ids) {
          nextState[action.payload.routeId] = action.payload.ids;
        }
        else {
          delete nextState[action.payload.routeId];
        }

        return nextState;
      }
      case TRANSITION_SUCCESS: {
        const options = action.payload.route.meta.options || {};
        const source = options.source;
        if (source !== 'multiPreviewPopup' && action.payload.previousRoute) {
          const prevId = action.payload.previousRoute.meta.id;
          const currentId = action.payload.route.meta.id;
          const nextState = { ...state };
          const isReplace = options.replace;
          if (isReplace && nextState[prevId] && prevId < currentId) {
            nextState[currentId] = nextState[prevId];
          }
          delete nextState[prevId];
          return nextState;
        }
        return state;
      }
      default: {
        return state;
      }
    }
  },
  toastMessage: (state = null, action) => {
    switch (action.type) {
      case Types.SET_TOAST_MESSAGE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  mobileDiscoveryPopupOpenStatus: (state = false, action) => {
    switch (action.type) {
      case Types.SET_MOBILE_DISCOVERY_POPUP_STATUS: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  tabuDictionary: (state = {}, action) => {
    switch (action.type) {
      case Types.SET_TABU_STATE: {
        const [ docId ] = Object.keys(action.payload);
        return {
          ...state,
          [docId]: { ...state[docId], ...action.payload[docId] },
        };
      }
      default: {
        return state;
      }
    }
  },
  authModal: (state = { type: null }, action) => {
    switch (action.type) {
      case Types.SET_AUTH_MODAL: {
        let { type, pendingAction } = state;
        if (action.payload.type === null) {
          type = null;
        }
        else if (!action.payload.pendingAction && type === null) {
          type = action.payload.type;
          pendingAction = null;
        }
        else {
          if (action.payload.type) type = action.payload.type;
          if (action.payload.pendingAction) pendingAction = action.payload.pendingAction;
        }
        return {
          type,
          pendingAction,
          authSource: action.payload.authSource,
          authSourceVariables: action.payload.authSourceVariables,
        };
      }
      case Types.UPDATE_PENDING_ADDRESS_CARD_SETTINGS: {
        return {
          ...state,
          pendingAction: {
            ...state.pendingAction,
            settings: action.payload,
          },
        };
      }
      default: {
        return state;
      }
    }
  },
  commutePopup: (state = CommutePopupStatus.Initial, action) => {
    switch (action.type) {
      case Types.SET_COMMUTE_POPUP_STATUS: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  contactModalPoiId: (state = null, action) => {
    switch (action.type) {
      case Types.SET_CONTACT_AGENT_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  intentModalPoiId: (state = null, action) => {
    switch (action.type) {
      case Types.SET_INTENT_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  welcomeModalPoiId: (state = null, action) => {
    switch (action.type) {
      case Types.SET_WELCOME_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  contactAgentSuccessPoiId: (state = null, action) => {
    switch (action.type) {
      case Types.SET_CONTACT_AGENT_SUCCESS_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isFeedbackPopupModalOpen: (state = { isOpen: false, insightId: null }, action) => {
    switch (action.type) {
      case Types.SET_FEEDBACK_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isUploadBulletinReportModalOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_UPLOAD_BULLETIN_REPORT_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isAfterUploadBulletinModalOpen: (state = null, action) => {
    switch (action.type) {
      case Types.SET_AFTER_UPLOAD_BULLETIN_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  finishFormState: (state = { dirty: false, published: false, isFinishPopupOpen: false }, action) => {
    switch (action.type) {
      case Types.SET_FINISH_FORM_STATE: {
        return {
          ...state,
          ...action.payload,
        };
      }
      default: {
        return state;
      }
    }
  },
  isAddressFeedbackPopupModalOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_ADDRESS_FEEDBACK_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isReportListingPopupModalOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_REPORT_LISTING_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isSaveSearchModalOpen: (state = false, action) => {
    switch (action.type) {
      case Types.RESOLVE_SAVE_SEARCH_MODAL:
        return false;
      case Types.SET_SAVE_SEARCH_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isNeedsPopupOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_NEEDS_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  galleryPopup: (state = PopupImageGalleryStatusInitial, action) => {
    switch (action.type) {
      case Types.SET_GALLERY_STATUS: {
        return { ...state, ...action.payload };
      }
      default: {
        return state;
      }
    }
  },
  sectionInView: (state = null, action) => {
    switch (action.type) {
      case Types.SET_VISIBLE_SECTION: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isMobileSearchMapViewActive: (state = false, action) => {
    switch (action.type) {
      case Types.SET_MOBILE_SEARCH_MAP_VIEW_ACTIVE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  shareModalOpenType: (state = null, action) => {
    switch (action.type) {
      case Types.SET_SHARE_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  showGetUpdatesSection: (state = null, action) => {
    switch (action.type) {
      case Types.LOGOUT: {
        return false;
      }
      default: {
        return state;
      }
    }
  },
  madadContactModal: (state = null, action) => {
    switch (action.type) {
      case Types.SET_MADAD_CONTACT_MODAL_STATE: {
        return action.payload ? { ...action.payload } : null;
      }
      default:
        return state;
    }
  },
  authEmail: (state = '', action) => {
    switch (action.type) {
      case Types.SET_AUTH_EMAIL: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  manageListingsErrorModal: (state = null, action) => {
    switch (action.type) {
      case Types.SET_MANAGE_LISTINGS_ERROR_MODAL: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  manageListingsFilterAgentUserId: (state = null, action) => {
    switch (action.type) {
      case Types.SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  developerFilters: (state = null, action) => {
    switch (action.type) {
      case Types.SET_DEVELOPER_FILTERS: {
        return action.payload;
      }
      default:
        return state;
    }
  },
  isListingAdditionalBannerClosed: (state = false, action) => {
    switch (action.type) {
      case Types.SET_IS_LISTING_ADDITIONAL_BANNER_CLOSED: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isSearchResultAdditionalBannerClosed: (state = false, action) => {
    switch (action.type) {
      case Types.SET_IS_SEARCH_RESULT_ADDITIONAL_BANNER_CLOSED: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isMobileMenuHidden: (state = false, action) => {
    switch (action.type) {
      case Types.SET_IS_MOBILE_MENU_HIDDEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isAgentOfficeContactPopupOpen: (state = null, action) => {
    switch (action.type) {
      case Types.SET_AGENT_OFFICE_CONTACT_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isMizrahiBankModalOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_IS_MIZRAHI_BANK_MODAL_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isMortgageOfficeContactPopupOpen: (state = false, action) => {
    switch (action.type) {
      case Types.SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  searchViewMode: (state = null, action) => {
    switch (action.type) {
      case Types.SET_SEARCH_VIEW_MODE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isLikedToastOpen: (state = null, action) => {
    switch (action.type) {
      case Types.SET_IS_LIKED_TOAST_OPEN: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  isHeaderTabSubmenuVisible: (state = null, action) => {
    switch (action.type) {
      case Types.SET_IS_HEADER_TAB_SUBMENU_VISIBLE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  pushNotificationsModalType: (state = null, action) => {
    switch (action.type) {
      case Types.SET_PUSH_NOTIFICATIONS_MODAL_TYPE: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  devicePushStatus: (state = null, action) => {
    switch (action.type) {
      case Types.SET_DEVICE_PUSH_STATUS: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  listingEdited: (state = null, action) => {
    switch (action.type) {
      case Types.SET_LISTING_EDITED: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  dealAffiliationPopup: (state = null, action) => {
    switch (action.type) {
      case Types.SET_DEAL_AFFILIATION_POPUP: {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  },
  privateUserMonetization: privateUserMonetizationReducer,
});
