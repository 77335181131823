import styled from '@emotion/styled';
import { H2 } from 'ds/components/typography';

export const TimeoutWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TimeoutInfo = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  text-align: center;
`;

export const SkipLink = styled.div`
  margin-top: auto;
  align-self: center;
  font-weight: 500;
  cursor: pointer;
`;

export const TimeoutInfoHeader = styled(H2)`
  text-align: center;
`;
