import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';
import { typographyLineHeightCfg } from 'ds/components/typography/types';

export const AffiliationSectionRoot = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `}
`;

export const AffiliationTileRoot = styled(SmallText)<{ halfSize?: boolean; affiliated?: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ affiliated }) => affiliated ? 'flex-start' : 'center'};
  align-items: center;
  height: 159px;
  flex-basis: unset;
  width: ${({ halfSize }) => halfSize ? '180px' : '376px'};
  background-color: ${({ theme, affiliated }) => affiliated ? theme.colors.neutrals.white : theme.colors.neutrals.grey10};
  border: ${({ theme, affiliated }) => affiliated ? `1px solid ${theme.colors.neutrals.grey8}` : 'none'};
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 100%;
    min-width: calc(100% + 22px);
  `}
`;

export const AgentName = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  line-height: ${typographyLineHeightCfg.text}px;
`;

export const AgencyName = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const Photo = styled.div<{ imageURL: string, isSquare?: boolean, height: number, width: number }>`
  height: ${({ height }) => height}px;
  min-width: ${({ width }) => width}px;
  width: ${({ width }) => width}px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey6};
  border-radius: ${({ isSquare, theme }) => isSquare ? `${theme.borderRadius.tiny}` : theme.borderRadius.round};
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.neutrals.grey8};
  ${({ imageURL }) => imageURL ? `background-image: url(${imageURL});` : ''}
`;

export const PhotosWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    margin-top: ${theme.spacing(2.5)};
  `}
`;

export const AgencyLogoPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  background: ${({ theme }) => theme.colors.neutrals.white};

  svg {
    width: 18px;
  }

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    width: 40px;
    height: 40px;
    svg {
      width: 24px;
    }
  `}
`;

export const AffiliationSideText = styled.span`
  position: absolute;
  bottom: 16px;
`;

export const AssignAgentButton = styled(Text)<{ disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing(0.75)} ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey6};
  background: ${({ theme }) => theme.colors.neutrals.white};
  gap: 4px;
  text-decoration: none;
  color: ${({ theme, disabled }) => disabled ? theme.colors.neutrals.grey4 : theme.colors.neutrals.grey1};

  &:hover {
    ${({ theme, disabled }) => disabled ? '' : `color: ${theme.colors.brand.main}`};
  }

  &:active {
    ${({ theme, disabled }) => disabled ? '' : `color: ${theme.colors.brand.darker}`};
  }
`;

export const PendingRequestNote = styled(SmallText)`
  ${({ theme }) => `
    text-align: center;
    color: ${theme.colors.neutrals.grey1};
    white-space: pre-line;
    position: absolute;
    top: 16px;
  `}
`;

export const SpinnerIconWrapper = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    animation: rotating 2s linear infinite;
  }
`;
