import React, { useCallback } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import SuccessSignIcon from 'assets/svg/private-bulletin-promotion/modal-success-sign.svg';
import { useLocale } from 'locale';
import { RootAction, State } from 'store/state';
import { setPrivateBulletinSuccesfullyPromotedModal } from 'store/state/app/actions';
import {
  currentPromotionPlanIntervalHoursSelector,
  isPrivateBulletinSuccesfullyPromotedModalOpenSelector,
} from 'store/state/app/private-user-monetization/selectors';
import { Text } from 'ds/components/typography';
import Modal from 'ds/components/modal';
// import { Button } from 'ds/components/button';
import { AlertModalContent, AlertTitle } from './sharedStyled';


export const AlertHint = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  color: '#828282',
  '> span': {
    color: theme.colors.brand.lighter,
    fontWeight: 700,
  },
}));

interface PrivateBulletinSuccesfullyPromotedModalProps {
  isOpen: boolean;
  promoteHoursInterval: number;
  onClose: () => void;
}

const PrivateBulletinSuccesfullyPromotedModal: React.FC<PrivateBulletinSuccesfullyPromotedModalProps> = props => {
  const { isOpen, promoteHoursInterval, onClose } = props;
  const { t } = useLocale();

  const getHint = useCallback(() => {
    const hours = Math.max(promoteHoursInterval - 1, 0);
    const minutes = 59;
    const text = t('privateBulletinPromotion.timeUntilNextPromotion');
    const parts = text.split(/(<hours>|<minutes>)/g);
    return parts.map((part, i) => {
      if (part === '<hours>') return <span key={i}>{hours}</span>;
      if (part === '<minutes>') return <span key={i}>{minutes}</span>;
      return part;
    });

  }, [ promoteHoursInterval ]);

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileModalPositionBottom
    >
      <AlertModalContent>
        <SuccessSignIcon />
        <AlertTitle style={{ marginTop: 32, marginBottom: 16 }}>
          {t('privateBulletinPromotion.successfullyPromoted', { hoursInterval: promoteHoursInterval })}
        </AlertTitle>
        <AlertHint style={{ marginBottom: 32 }}>
          {getHint()}
        </AlertHint>
        {/* Phase 2 */}
        {/* <Button mode="primary" size="large" fullWidth>
          {t('privateBulletinPromotion.navigateToAutomaticPromotionsButton')}
        </Button> */}
      </AlertModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: State) => ({
  promoteHoursInterval: currentPromotionPlanIntervalHoursSelector(state),
  isOpen: isPrivateBulletinSuccesfullyPromotedModalOpenSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(setPrivateBulletinSuccesfullyPromotedModal({ isOpen: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateBulletinSuccesfullyPromotedModal);
