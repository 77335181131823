import { get } from 'lodash';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { CheckboxWrapper } from './styled';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { SpacingBlock } from 'components/spacing-block';
import { Button } from 'ds/components/button';
import { ICompleteAppleLoginVariables, MutationType, ConsentTypeNG, IConsent } from 'store/sagas/apiService/types';
import { connect } from 'react-redux';
import { AuthenticationModalType } from './types';
import { RootAction, State } from 'store/state';
import { Dispatch } from 'redux';
import { setAuthModal } from 'store/state/app/actions';
import {
  AuthenticationInfoHeader,
  AuthenticationInfoDescription,
  AuthenticationConsentsEmail,
  MadlanLogoWrapper,
  AfterSocialMobileRoot,
  AfterSocialDesktopRoot,
} from './styled';
import Modal from 'ds/components/modal';
import MadlanLogoIcon from 'assets/svg/il-logo-small.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { createSelector } from 'reselect';
import { appleLoginResponseSelector } from 'store/state/mutationsResponse/selectors';
import { EmailAlreadyInUse } from './EmailAlreadyInUse';
import { authModalAuthSourceVariablesSelector } from 'store/state/app/selectors';
import { inlineMutate } from 'store/state/mutationsResponse/actions';


const Root = styled.div(({ theme }) => ({
  marginTop: theme.spacing(7),
}));

interface ConsentsAfterSocialFormProps {
  handleCloseModal: () => void;
  onAppleLogin: (data: ICompleteAppleLoginVariables) => void;
  onSetActiveModal: (type: AuthenticationModalType) => void;
  email: string;
  code: string;
  agentEmailAlreadyExists: boolean;
}

const ConsentsAfterSocialForm: React.FC<ConsentsAfterSocialFormProps> = props => {
  const {
    agentEmailAlreadyExists,
    email,
    code,
    onSetActiveModal,
    handleCloseModal,
    onAppleLogin,
  } = props;
  const { t } = useLocale();
  const currentBreakpoint = useScreenBreakpoint();
  const isMobile = currentBreakpoint < 3;
  const [ rulesAccepted, setRulesAccepted ] = useState(false);
  const [ marketingAccepted, setMarketingAccepted ] = useState(false);

  const handleSubmitForm = () => {
    const consentsApi3: IConsent[] = [
      { type: ConsentTypeNG.TERMS_AND_CONDITIONS, approval: rulesAccepted },
      { type: ConsentTypeNG.MARKETING, approval: marketingAccepted },
    ].filter(el => Boolean(el.approval));


    onAppleLogin({
      token: code,
      consents: consentsApi3,
    });
  };

  if (agentEmailAlreadyExists) {
    return <EmailAlreadyInUse onClose={() => onSetActiveModal(null)}/>;
  }

  if (isMobile) {
    return (
      <Modal isOpen onClose={handleCloseModal} title={t('authentication.authenticationHeader')}>
        <AfterSocialMobileRoot>
          <div>
            <MadlanLogoWrapper>
              <MadlanLogoIcon height={40} width={44} />
            </MadlanLogoWrapper>
            <AuthenticationInfoHeader weight="bold">
              {t('authentication.consents.header')}
            </AuthenticationInfoHeader>
            <AuthenticationInfoDescription>
              {t('authentication.consents.description')}
            </AuthenticationInfoDescription>
            {email ? <AuthenticationConsentsEmail>{email}</AuthenticationConsentsEmail> : null}
          </div>
          <Root>
            <CheckboxWrapper>
              <CheckboxField
                checked={rulesAccepted}
                small
                onClick={() => setRulesAccepted(!rulesAccepted)}
                htmlLabel={t('authentication.consents.rulesLabel')}
                size={20}
              />
              <CheckboxField
                checked={marketingAccepted}
                small
                onChange={() => setMarketingAccepted(!marketingAccepted)}
                label={t('authentication.consents.newsLabel')}
                size={20}
                alignStart
              />
            </CheckboxWrapper>
            <SpacingBlock mTop={3} />
            <Button size="extraLarge" weight="medium" onClick={handleSubmitForm} fullWidth disabled={!rulesAccepted}>{t('authentication.consents.social.submit')}</Button>
            <Button size="extraLarge" weight="medium" mode="primaryNegative" onClick={() => onSetActiveModal(AuthenticationModalType.SignIn)} fullWidth>{t('authentication.consents.social.cancel')}</Button>
          </Root>
        </AfterSocialMobileRoot>
      </Modal>
    );
  }

  return (
    <Modal isOpen onClose={handleCloseModal} title={t('authentication.authenticationHeader')}>
      <AfterSocialDesktopRoot>
        <MadlanLogoWrapper>
          <MadlanLogoIcon height={40} width={44} />
        </MadlanLogoWrapper>
        <AuthenticationInfoHeader weight="bold">
          {t('authentication.consents.header')}
        </AuthenticationInfoHeader>
        <AuthenticationInfoDescription>
          {t('authentication.consents.description')}
        </AuthenticationInfoDescription>
        {email ? <AuthenticationConsentsEmail>{email}</AuthenticationConsentsEmail> : null}
        <Root>
          <CheckboxWrapper>
            <CheckboxField
              checked={rulesAccepted}
              small
              onClick={() => setRulesAccepted(!rulesAccepted)}
              htmlLabel={t('authentication.consents.rulesLabel')}
              size={20}
            />
            <CheckboxField
              checked={marketingAccepted}
              small
              onChange={() => setMarketingAccepted(!marketingAccepted)}
              label={t('authentication.consents.newsLabel')}
              size={20}
              alignStart
            />
          </CheckboxWrapper>
          <SpacingBlock mTop={3} />
          <Button size="extraLarge" weight="medium" onClick={handleSubmitForm} fullWidth disabled={!rulesAccepted}>{t('authentication.consents.social.submit')}</Button>
          <Button size="extraLarge" weight="medium" mode="primaryNegative" onClick={() => onSetActiveModal(AuthenticationModalType.SignIn)} fullWidth>{t('authentication.consents.social.cancel')}</Button>
        </Root>
      </AfterSocialDesktopRoot>
    </Modal>
  );
};

const agentEmailAlreadyExistsSelector = createSelector(
  appleLoginResponseSelector,
  (res) => get(res, 'data.appleLogin.errorMessage') === 'Agent email already exists'
);

const emailSelector = createSelector(
  authModalAuthSourceVariablesSelector,
  (variables) => variables ? variables.email : null
);

const codeSelector = createSelector(
  authModalAuthSourceVariablesSelector,
  (variables) => variables ? variables.appleCode : null
);

const mapStateToProps = (state: State) => ({
  email: emailSelector(state),
  code: codeSelector(state),
  agentEmailAlreadyExists: agentEmailAlreadyExistsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetActiveModal: (type: AuthenticationModalType) => dispatch(setAuthModal({ type }, { isUserInitiated: true })),
  onAppleLogin: (data: ICompleteAppleLoginVariables) => dispatch(inlineMutate(MutationType.CompleteAppleLogin, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsAfterSocialForm);
