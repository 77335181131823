import { State } from 'store/state';
import { MutationType, MutationResponseByType, MutationVariablesByType } from 'store/sagas/apiService/types';
import { ApolloQueryResult } from 'apollo-client';

export type IMutationResponse<T extends MutationType> = ApolloQueryResult<MutationResponseByType[T]> & {
  meta: {
    variables: MutationVariablesByType[T],
  };
};

export const makeResponseGetter = <T extends MutationType>(mutationType: T) => (state: State, key: string = null): IMutationResponse<T> =>
  state.mutationsResponse[mutationType][key] as IMutationResponse<T>;

export const articlesResponseSelector = makeResponseGetter(MutationType.GetArticles);
export const uploadBulletinBadAddressReportSelector = makeResponseGetter(MutationType.UploadBulletinBadAddressReport);
export const resetPasswordUpdateResponseSelector = makeResponseGetter(MutationType.ResetPasswordUpdate);
export const verifyEmailVerificationCodeDataSelector = makeResponseGetter(MutationType.VerifyEmailVerificationCode);
export const signInSignUpLoadingSelector = makeResponseGetter(MutationType.SignInSignUpWithEmail);
export const loginWithEmailAndPasswordDataSelector = makeResponseGetter(MutationType.LoginWithEmailAndPassword);
export const contactV4ResponseSelector = makeResponseGetter(MutationType.ContactV4);
export const contactPageResponseSelector = makeResponseGetter(MutationType.ContactPageSubmit);
export const contactAgentResponseSelector = makeResponseGetter(MutationType.AddContactAgent);
export const contactAgentPageResponseSelector = makeResponseGetter(MutationType.AgentOfficeContactPageSubmit);
export const sendFeedbackResponseSelector = makeResponseGetter(MutationType.SendFeedback);
export const newListingResponseSelector = makeResponseGetter(MutationType.NewListing);
export const newCommercialListingResponseSelector = makeResponseGetter(MutationType.NewCommercialListing);
export const editListingResponseSelector = makeResponseGetter(MutationType.EditListing);
export const editCommercialListingResponseSelector = makeResponseGetter(MutationType.EditCommercialListing);
export const editListingStatusResponseSelector = makeResponseGetter(MutationType.EditListingStatus);
export const editCommercialListingStatusResponseSelector = makeResponseGetter(MutationType.EditCommercialListingStatus);
export const toggleListingPromotionSelector = makeResponseGetter(MutationType.EditCommercialListingPromotionStatus);
export const validationCodeResponseSelector = makeResponseGetter(MutationType.CheckValidationCode);
export const generateValidationCodeResponseSelector = makeResponseGetter(MutationType.GenerateValidationCode);
export const deregisterUserResponseSelector = makeResponseGetter(MutationType.DeregisterUser);
export const addNotificationDeviceResponseSelector = makeResponseGetter(MutationType.AddNotificationDevice);
export const removeNotificationDeviceResponseSelector = makeResponseGetter(MutationType.RemoveNotificationDevice);
export const editDealAffiliationRequestResponseSelector = makeResponseGetter(MutationType.EditDealAffiliationRequest);
export const requestDealAffiliationResponseSelector = makeResponseGetter(MutationType.RequestDealAffiliation);
export const appleLoginResponseSelector = makeResponseGetter(MutationType.AppleLogin);
export const acceptConsentsResponseSelector = makeResponseGetter(MutationType.AcceptConsents);
export const removeMarketingConsentResponseSelector = makeResponseGetter(MutationType.RemoveMarketingConsent);
export const unsubscribeSavedSearchResponseSelector = makeResponseGetter(MutationType.SavedSearchUnsubscribe);
export const promotePrivateUserBulletinResponseSelector = makeResponseGetter(MutationType.PromotePrivateUserBulletin);
