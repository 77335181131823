import React from 'react';
import useAnchorLink from 'hooks/useAnchorLink';

interface AnchorMiddlewareAnchorProps {
  href: string;
  children: (href: string) => JSX.Element;
}


export const AnchorMiddleware: React.FC<AnchorMiddlewareAnchorProps> = (props) => {
  const { href, children } = props;
  const link = useAnchorLink(href);

  return (
    <>
      {children(link)}
    </>
  );
};
