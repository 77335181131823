import { takeEvery, put, call, select, take, fork, cancel, race } from 'redux-saga/effects';
import { RootAction } from 'store/state';
import {
  IUpdateUserProfileVariables,
  MutationType,
  MutationVariablesByType,
  LeadType,
  LoadType,
} from 'store/sagas/apiService/types';
import {
  setToastMessage,
  submitContactAgent,
  setContactAgentOpen,
  setContactAgentSuccessPoiId,
  setIntentModalOpen,
  setWelcomeModalOpen,
} from 'store/state/app/actions';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction, mutate } from 'store/state/mutationsResponse/actions';
import { userProfileSelector } from 'store/state/domainData/selectors';
import { setEmptyLastContactAgentValues, setLastContactAgentValues } from 'helpers/contactFormStore';
import { IBulletin, ICommercialBulletin, ICommercialProject, IProject, PocType, PoiId, PoiType, User } from 'utils/entities';
import { SET_CONTACT_AGENT_OPEN, SET_INTENT_MODAL_OPEN, SET_WELCOME_MODAL_OPEN, SUBMIT_CONTACT_AGENT } from 'store/state/app/types';
import { ContactAgentSubmitterSource } from 'store/state/app/payloads';
import { routeSelector } from 'store/state/selectors/router';
import { Route } from 'config/routes';
import { currentPoiSelector } from 'store/state/selectors/poi';
import { ContactAgentModalSource } from 'store/state/app';
import { contactModalPoiIdSelector, welcomeModalPoiIdSelector } from 'store/state/app/selectors';
import { queryData } from './apiService';
import { contactPoisByIdsHeapSelector } from 'store/state/selectors/poisHeap';
import { getCurrentDesignRange } from 'consts/breakpoints';
import { TRANSITION_START, TRANSITION_SUCCESS } from 'store/state/router/types';
import config from 'config';
import { addEventListenerFn } from 'helpers/pan-responder';
import { waitForPoiResolve } from './routing/handlers/search/utils';
import Cookie from 'js-cookie';
import { getQAAutomationParams } from 'utils/qa';
import { intentModalSourceSelector } from 'store/state/selectors/intentModal';
import { trafficSegmentationShowSelector, TrafficSegmentationRules, TrafficSegmentationPlace } from 'store/state/selectors/traffic';


const contactSubmitMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.ContactPageSubmit
);

function* addContactAgent(id: PoiId, type: PoiType) {
  yield put(mutate({
    mutationType: MutationType.AddContactAgent,
    meta: {
      variables: {
        contactId: { id, type },
      },
    },
  }));
}

function* commonContactAgentHandler(variables: MutationVariablesByType[MutationType.ContactPageSubmit]) {
  const { phone, email, name, id, type } = variables;

  yield put(setToastMessage({ term: 'contactAgentModal.submitSuccessMessage' }));
  yield call(setEmptyLastContactAgentValues, { phone, email: null, name });

  const currentUser: User = yield select(userProfileSelector);
  const metaVariables: IUpdateUserProfileVariables = { phone };

  if (name) {
    const [ firstName, lastName ] = name.split(' ');

    if (firstName) {
      metaVariables.firstName = firstName;
    }
    if (lastName) {
      metaVariables.lastName = lastName;
    }
  }

  if (currentUser) {

    if (!currentUser.phone) {
      yield put(mutate({
        mutationType: MutationType.UpdateUserProfile,
        meta: {
          variables: metaVariables,
        },
      }));
    }

    if (type === LeadType.Bulletin || type === LeadType.Project) {
      yield call(addContactAgent, id, type);
    }
  }
  else {
    yield call(setLastContactAgentValues, { phone, name, email });
  }
}

function* handleContactSubmit(action: SetMutationResponseAction<MutationType.ContactPageSubmit>) {
  const { variables, response: { data } } = action.meta;
  if (data && data.contactV4) {
    yield call(commonContactAgentHandler, variables);

    /* post lead project carousel open success message on mobile */
    const relevantRoutes = new Set([ Route.ProjectPage, Route.UnitPage, Route.UnitPageCommercial ]);
    const routerState = yield select(routeSelector);

    if (!relevantRoutes.has(routerState.name)) return;

    const poi = yield select(currentPoiSelector);
    const openPoiId = yield select(contactModalPoiIdSelector);

    if (poi.id === variables.id && !openPoiId) {
      yield put(setContactAgentOpen(poi.id, { source: ContactAgentModalSource.PostLead }));
      yield put(setIntentModalOpen(null, {}));
      yield put(setWelcomeModalOpen(null, {}));
    }
  }
}

function* dispatchMutation({ payload }: ReturnType<typeof submitContactAgent>) {
  const { variables, key } = payload;

  const mutationParams = {
    mutationType: MutationType.ContactPageSubmit,
    meta: {
      key,
      variables: { ...variables },
    },
  };
  yield put(mutate(mutationParams));
}

const submitContactFromModalPattern = (action: RootAction) => (
  action.type === SUBMIT_CONTACT_AGENT && action.payload.source === ContactAgentSubmitterSource.Modal
);

const welcomeOrContactModalClosedPattern = (action: RootAction) => (
  action.type === SET_WELCOME_MODAL_OPEN || action.type === SET_CONTACT_AGENT_OPEN
);

function* handleContactSubmitFromModal({ payload }: ReturnType<typeof submitContactAgent>) {
  yield put(setContactAgentOpen(null, {}));
  yield put(setIntentModalOpen(null, {}));
  yield put(setWelcomeModalOpen(null, {}));

  const { variables: { id } } = payload;

  yield take(contactSubmitMutationPattern);
  yield put(setContactAgentSuccessPoiId(id));
}

function* contactFormOpenWatcher({ payload }: ReturnType<typeof setContactAgentOpen>) {
  const bulletins = yield select(contactPoisByIdsHeapSelector);
  const bulletin: IBulletin | ICommercialBulletin = bulletins[payload];

  if (bulletin && bulletin.poc && bulletin.poc.type === PocType.Agent) {
    yield call(queryData, { loadType: LoadType.AgentContactInfoById, meta: { variables: { id: bulletin.poc.agentId } } });
  }
}

export const INTENT_MODAL_SESSION_KEY = 'INTENT_MODAL_SESSION_KEY';
function* setIntentModalSession() {
  Cookie.set(INTENT_MODAL_SESSION_KEY, '1', { expires: new Date(new Date().getTime() + 10 * 60 * 1000) });
}

const TEN_SECONDS = 10 * 1000;
const WELCOME_OR_CONTACT_MODAL_SESSION_KEY = 'WELCOME_OR_CONTACT_MODAL_SESSION_KEY';
function* setWelcomeOrContactModalSession() {
  Cookie.set(WELCOME_OR_CONTACT_MODAL_SESSION_KEY, '1', { expires: new Date(new Date().getTime() + TEN_SECONDS) });
}

const campaignProjectId = config.campaignProjectId;

const welcomeModalPattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS
  && (action.payload.route.name === Route.ProjectPage || action.payload.route.name === Route.ProjectPageCommercial)
  && (
    (
      campaignProjectId
      && action.payload.route.params.id === campaignProjectId
      && action.payload.route.params.utm_campaign
      && action.payload.route.params.utm_campaign.toLowerCase().startsWith('remarketing_')
    )
    || (!action.payload.previousRoute && action.payload.route.params.mp_remarketing && action.payload.route.params.mp_remarketing === 'true')
  )
);

const DANGER_CURSOR_SCROLL_AREA_SIZE = 121;
let unsubscribeEvent: ReturnType<typeof addEventListenerFn>;
let promiseAbortListener: any;
function waitCursorLeaves(signal: AbortSignal) {
  return new Promise<boolean>(resolve => {
    promiseAbortListener = addEventListenerFn(signal, 'abort', () => {
      resolve(false);
    });
    let activateOnMove = false;
    unsubscribeEvent = addEventListenerFn(document.documentElement, 'mousemove', (e) => {
      if (!activateOnMove) {
        activateOnMove = e.y > DANGER_CURSOR_SCROLL_AREA_SIZE;
      }
      else {
        const intentModalSession = Cookie.get(INTENT_MODAL_SESSION_KEY);
        const welcomeOrContactModalSession = Cookie.get(WELCOME_OR_CONTACT_MODAL_SESSION_KEY);
        if (e.y < DANGER_CURSOR_SCROLL_AREA_SIZE && !intentModalSession && !welcomeOrContactModalSession) {
          resolve(true);
          unsubscribeEvent.remove();
        }
      }
    }, true);
  });
}

function mobileScrollToExit(signal: AbortSignal) {
  return new Promise<boolean>(resolve => {
    promiseAbortListener = addEventListenerFn(signal, 'abort', () => {
      resolve(false);
    });
    let activateOnScroll = false;
    unsubscribeEvent = addEventListenerFn(window, 'scroll', (e) => {
      const scrollPosition = window.scrollY;
      if (!activateOnScroll) {
        activateOnScroll = scrollPosition > DANGER_CURSOR_SCROLL_AREA_SIZE;
      }
      else {
        const intentModalSession = Cookie.get(INTENT_MODAL_SESSION_KEY);
        const welcomeOrContactModalSession = Cookie.get(WELCOME_OR_CONTACT_MODAL_SESSION_KEY);
        if (scrollPosition < DANGER_CURSOR_SCROLL_AREA_SIZE && !intentModalSession && !welcomeOrContactModalSession) {
          resolve(true);
          unsubscribeEvent.remove();
        }
      }
    }, true);
  });
}

function* waitForActionTask(poiId: string) {
  const screenBreakpoint = getCurrentDesignRange();
  const isMobile = screenBreakpoint < 3;
  const controller = new AbortController();

  while (true) {
    const [ userExitAction, changeRoute ] = yield race([
      call(isMobile ? mobileScrollToExit : waitCursorLeaves, controller.signal),
      take(TRANSITION_START),
    ]);

    if (changeRoute) {
      if (unsubscribeEvent) unsubscribeEvent.remove();
      if (promiseAbortListener) promiseAbortListener.remove();
      controller.abort();
      if (actionTask) {
        yield cancel(actionTask);
      }
      break;
    }

    const isWelcomeModalOpened = yield select(welcomeModalPoiIdSelector);
    const isContactModalOpened = yield select(contactModalPoiIdSelector);
    const intentModalSource = yield select(intentModalSourceSelector);
    if (userExitAction && !isContactModalOpened && !isWelcomeModalOpened) {
      yield put(setIntentModalOpen(poiId, { source: intentModalSource }));
    }
    else {
      continue;
    }
  }
}

let actionTask: any;
function* intentModalWatcher() {
  while (true) {
    yield take(TRANSITION_SUCCESS);
    const { [TrafficSegmentationPlace.ExitPopUp]: allowShowPopup }: TrafficSegmentationRules = yield select(trafficSegmentationShowSelector);
    const route = yield select(routeSelector);

    if (allowShowPopup) {
      actionTask = yield call(waitForActionTask, route.params.id);
    }
  }
}

function* welcomeModalWatcher() {
  yield take(welcomeModalPattern);
  const { popups } = getQAAutomationParams();

  // do not show welcome modal if a project page tab was opened from the same domain [ILDEV-9828]
  if (window && document && document.referrer) {
    const referrerUrl = new URL(document.referrer);
    const referrerHost = referrerUrl.host;

    if (window.location.host === referrerHost) return;
  }

  if (popups !== 'disable') {
    yield call(waitForPoiResolve);
    const { [TrafficSegmentationPlace.WelcomePopUp]: allowShowPopup }: TrafficSegmentationRules = yield select(trafficSegmentationShowSelector);
    const currentPoi: IProject | ICommercialProject = yield select(currentPoiSelector);
    const sameProject = campaignProjectId === currentPoi.id;

    if (allowShowPopup && sameProject) {
      yield put(setWelcomeModalOpen(currentPoi.id, { source: ContactAgentModalSource.WelcomeModal }));
    }
  }
}

export function* contactPageWatcher() {
  yield takeEvery(SUBMIT_CONTACT_AGENT, dispatchMutation);
  yield takeEvery(submitContactFromModalPattern, handleContactSubmitFromModal);
  yield takeEvery(contactSubmitMutationPattern, handleContactSubmit);
  yield takeEvery(SET_CONTACT_AGENT_OPEN, contactFormOpenWatcher);
  yield takeEvery(SET_INTENT_MODAL_OPEN, setIntentModalSession);
  yield takeEvery(welcomeOrContactModalClosedPattern, setWelcomeOrContactModalSession);
  yield fork(welcomeModalWatcher);
  yield fork(intentModalWatcher);
}
