import { ResponseByType, LoadType, MutationType, ISaveSearch, AuthError, IError, IAgentStatusType, PricingTierApi3, PricingTier } from 'store/sagas/apiService/types';

import { MutationsResponseAction } from 'store/state/mutationsResponse';
import { SET_MUTATION_RESPONSE, MUTATE } from '../mutationsResponse/types';
import { AutocompleteDocument, AutocompleteLocation, CommuteType, CommuteTypeNG, DatedPoi, GeneralCondition, IBaseAutocompleteDoc, ISearch, PoiIdObject, PoiType, PoiTypeNg, RegisterType, RegistrationTypeNG, SubscriptionUpdateFrequency, UpdateFrequency, User, UserNG, UserRole, UserRoleNG } from 'utils/entities';
import { domainInitialState } from './utils';
import { Domain } from './index';
import { isSearchEqual } from 'utils/saveSearch';
import { Condition, PropertyType } from 'components/filters/types';

type UserData = ResponseByType[LoadType.CurrentUser];

function mergeProfile(state: UserData, updated: Partial<User>): UserData {
  return {
    ...state,
    me: {
      ...state.me,
      ...updated,
    },
  };
}

function removeSaveSearchFromList (list: ISaveSearch[], searchIds: Array<Partial<ISaveSearch>>) {
  return searchIds.reduce((acc, search) => {
    const { searchId } = search;
    let index;
    if (searchId) {
      index = acc.findIndex((s) => s.searchId === searchId);
    }
    else {
      index = acc.findIndex((s) => isSearchEqual(s, search));
    }

    if (index === -1) return acc;

    return [
      ...acc.slice(0, index),
      ...acc.slice(index + 1),
    ];
  }, list);
}

function addSaveSearchLists(list: ISaveSearch[], newSearches: ISaveSearch[]) {
  return newSearches.reduce((acc, newSearch) => {
    const index = acc.findIndex((s) => s.searchId === newSearch.searchId || isSearchEqual(s, newSearch));

    if (index === -1) {
      return [ newSearch, ...acc ];
    }

    return [
      ...acc.slice(0, index),
      { ...acc[index], ...newSearch },
      ...acc.slice(index + 1),
    ];
  }, list);
}

function userDataReducer(state: UserData, action: MutationsResponseAction): UserData {
  // can't de-dupe cause of type guards
  switch (action.type) {
    case MUTATE: {
      switch (action.mutationType) {
        case MutationType.UpdateUserProfile: {
          const { commutePreference, firstName, lastName, phone } = action.meta.variables;
          return mergeProfile(state, { commutePreference, firstName, lastName, phone });
        }

        case MutationType.DeleteSaveSearch:
          return mergeProfile(state, {
            savedSearches: removeSaveSearchFromList(
              state.me.savedSearches,
              [ { searchId: action.meta.variables.searchId } ]
            ),
          });

        case MutationType.SaveSearchQuery:
          return mergeProfile(state, {
            savedSearches: addSaveSearchLists(
              state.me.savedSearches,
              [ action.meta.variables.search ] as ISaveSearch[]
            ),
            searchHistory: removeSaveSearchFromList(
              state.me.searchHistory,
              [ { searchId: action.meta.variables.search.searchId } ]
            ),
          });

        default: return state;
      }
    }
    case SET_MUTATION_RESPONSE: {
      switch (action.mutationType) {
        case MutationType.AddRecentlyViewed:
          return mergeProfile(state, { lastViewed: action.meta.response.data.addRecentlyViewed });

        case MutationType.SaveAddress:
          return mergeProfile(state, { savedAddresses: action.meta.response.data.saveAddress });

        case MutationType.DeleteAddress:
          return mergeProfile(state, { savedAddresses: action.meta.response.data.deleteAddress });

        case MutationType.AddContactAgent:
          return mergeProfile(state, { lastContacted: action.meta.response.data.addContactAgent });

        case MutationType.AddFavorites:
          return mergeProfile(state, { favorites: action.meta.response.data.addFavorites });

        case MutationType.RemoveFavorites:
          return mergeProfile(state, { favorites: action.meta.response.data.removeFavorites });

        case MutationType.UpdateUserProfile: {
          const { commutePreference, firstName, lastName, phone } = action.meta.response.data.updateUserProfile;
          return mergeProfile(state, { commutePreference, firstName, lastName, phone });
        }

        case MutationType.FacebookLogin:
          return {
            ...state,
            me: action.meta.response.data.facebookLogin.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.facebookLogin.user)
              : null,
          };
        case MutationType.GoogleLogin:
          return {
            ...state,
            me: action.meta.response.data.googleLogin.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.googleLogin.user)
              : null,
          };
        case MutationType.AppleLogin:
          return {
            ...state,
            me: action.meta.response.data.appleLogin.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.appleLogin.user)
              : null,
          };
        case MutationType.CompleteAppleLogin:
          return {
            ...state,
            me: action.meta.response.data.completeAppleLogin.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.completeAppleLogin.user)
              : null,
          };
        case MutationType.LoginWithEmailAndPassword:
          return {
            ...state,
            me: action.meta.response.data.passwordLoginV3.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.passwordLoginV3.user)
              : null,
          };
        case MutationType.TempTokenAuth:
          return {
            ...state,
            me: action.meta.response.data.completeAutoLogin.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.completeAutoLogin.user)
              : null,
          };
        case MutationType.SignInSignUpWithEmail:
          return {
            ...state,
            me: action.meta.response.data.loginV3.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.loginV3.user)
              : null,
          };
        case MutationType.RegisterVisitor:
          return {
            ...state,
            me: action.meta.response.data.registerVisitor.__typename === 'LoginOutcome'
              ? adaptUserNG(action.meta.response.data.registerVisitor.user)
              : null,
          };
        case MutationType.SaveSearchQuery:
          return mergeProfile(state, {
            savedSearches: addSaveSearchLists(
              state.me.savedSearches,
              [ action.meta.response.data.saveSearchQuery ]
            ),
          });
        case MutationType.DeleteSaveSearch:
          return mergeProfile(state, {
            savedSearches: removeSaveSearchFromList(
              state.me.savedSearches,
              [ { searchId: action.meta.variables.searchId } ]
            ),
          });
        case MutationType.SaveRecentSearch:
          return mergeProfile(state, {
            searchHistory: addSaveSearchLists(
              state.me.searchHistory,
              [ action.meta.response.data.saveRecentSearch ]
            ),
          });

        default:
          return state;
      }
    }
    default: return state;
  }
}

const ERRORS_NOT_RESETTING_DATA = new Set([ AuthError.NoNeedToRegister, AuthError.NeedPassword, AuthError.LoginFailed ]);

export function userDomainReducer(state: Domain<LoadType.CurrentUser> = domainInitialState, action: MutationsResponseAction): Domain<LoadType.CurrentUser> {
  if (action.type === SET_MUTATION_RESPONSE && action.meta.response.errors && action.meta.response.errors.length) {
    const { errors } = action.meta.response;
    const shouldKeepData = (errors as IError[]).some(error => ERRORS_NOT_RESETTING_DATA.has(error.reason));

    return {
      ...state,
      data: shouldKeepData ? state.data : null,
      errors: action.meta.response.errors,
    };
  }

  const reduced = userDataReducer(state.data, action);
  if (state.data === reduced) return state;

  return {
    ...state,
    data: reduced,
    loading: false,
  };
}

const registrationTypeNgToRegisterTypeMap: Record<RegistrationTypeNG, RegisterType> = {
  [RegistrationTypeNG.FACEBOOK]: RegisterType.Facebook,
  [RegistrationTypeNG.GOOGLE]: RegisterType.Google,
  [RegistrationTypeNG.NATIVE]: RegisterType.Local,
  [RegistrationTypeNG.VISITOR]: RegisterType.Visitor,
  [RegistrationTypeNG.APPLE]: RegisterType.Apple,
};

const userRoleNgToUserRoleMap: Partial<Record<UserRoleNG, UserRole>> = {
  [UserRoleNG.USER]: UserRole.User,
  [UserRoleNG.AGENT]: UserRole.Agent,
  [UserRoleNG.BULLETINS_ADMIN]: UserRole.BulletinsAdmin,
};

const commuteTypeNgToCommuteTypeMap: Record<CommuteTypeNG, CommuteType> = {
  [CommuteTypeNG.BIKE]: 'bike',
  [CommuteTypeNG.BUS]: 'bus',
  [CommuteTypeNG.CAR]: 'car',
  [CommuteTypeNG.COMMUTE]: 'commute',
  [CommuteTypeNG.TRAIN]: 'train',
  [CommuteTypeNG.WALK]: 'walk',
};

const poiTypeNgToPoiTypeMap: Record<PoiTypeNg, PoiType> = {
  [PoiTypeNg.AD]: 'ad',
  [PoiTypeNg.BULLETIN]: 'bulletin',
  [PoiTypeNg.COMMERCIAL_BULLETIN]: 'commercialBulletin',
  [PoiTypeNg.COMMERCIAL_PROJECT]: 'commercialProject',
  [PoiTypeNg.DEAL]: 'deal',
  [PoiTypeNg.PROJECT]: 'project',
};

const subscriptionUpdateFrequencyToUpdateFrequencyMap: Record<SubscriptionUpdateFrequency, UpdateFrequency> = {
  [SubscriptionUpdateFrequency.DAILY]: UpdateFrequency.Daily,
  [SubscriptionUpdateFrequency.NOUPDATES]: UpdateFrequency.Never,
  [SubscriptionUpdateFrequency.REALTIME]: UpdateFrequency.Realtime,
  [SubscriptionUpdateFrequency.WEEKLY]: UpdateFrequency.Weekly,
};

const generalConditionToConditionMap: Record<GeneralCondition, Condition> = {
  [GeneralCondition.asNew]: Condition.AsNew,
  [GeneralCondition.fine]: Condition.Preserved,
  [GeneralCondition.new]: Condition.New,
  [GeneralCondition.renovated]: Condition.Renovated,
  [GeneralCondition.toRenovate]: Condition.ToRenovated,
  [GeneralCondition.preserved]: Condition.Preserved,
};

const pricingTierToApiPricingTier: Record<PricingTierApi3, PricingTier> = {
  [PricingTierApi3.Standard]: PricingTier.Standard,
  [PricingTierApi3.Discounted]: PricingTier.Discounted,
};

function mapSearchToSaveSearch(search: ISearch): ISaveSearch {
  return {
    locationDocIds: search.documents.map(doc => doc.docId),
    query: {
      amenities: search.query.amenities,
      areaRange: search.query.areaRange,
      bathsRange: search.query.bathsRange,
      buildingClass: search.query.buildingClass as PropertyType[],
      dealType: search.query.dealType,
      floorRange: search.query.floorRange,
      generalCondition: (search.query.generalCondition || []).map(condition => generalConditionToConditionMap[condition]),
      location: undefined, // Not sure what to put here
      monthlyTaxRange: search.query.monthlyTaxRange,
      noFee: search.query.noFee,
      ppmRange: search.query.ppmRange,
      priceRange: search.query.priceRange,
      roomsRange: search.query.roomsRange,
      sellerType: search.query.sellerType,
    },
    searchId: search.searchId,
    updateFrequency: subscriptionUpdateFrequencyToUpdateFrequencyMap[search.updateFrequency],
    title: search.title,
  };
}

function mapPoiIdObjectToDatedPoi(poiIdObject: PoiIdObject): DatedPoi {
  return {
    poiId: {
      id: poiIdObject.id,
      type: poiTypeNgToPoiTypeMap[poiIdObject.type],
    },
    date: poiIdObject.createdAt,
  };
}

function adaptUserNG(userNg: UserNG): User {
  const user: User = {
    uid: userNg.userId,
    email: userNg.email,
    firstName: userNg.fullName, // same as on clojure api-gateway server
    phone: userNg.phone,
    registrationType: registrationTypeNgToRegisterTypeMap[userNg.registrationType],
    roles: userNg.roles.map(role => userRoleNgToUserRoleMap[role]),
    avatar: userNg.profileImage,
    commutePreference: userNg.preferences && userNg.preferences.commute ? {
      docId: userNg.preferences.commute.place && userNg.preferences.commute.place.docId,
      location: userNg.preferences.commute.place && userNg.preferences.commute.place.ref && userNg.preferences.commute.place.ref.location ? {
        lat: userNg.preferences.commute.place.ref.location[0],
        lng: userNg.preferences.commute.place.ref.location[1],
      } : null,
      commuteType: commuteTypeNgToCommuteTypeMap[userNg.preferences.commute.commuteType],
      rushHour: userNg.preferences.commute.rushHour,
      text: userNg.preferences.commute.place && userNg.preferences.commute.place.ref && userNg.preferences.commute.place.ref.name,
      maxCommute: null,
    } : null,
    favorites: (userNg.favorites || []).map(mapPoiIdObjectToDatedPoi),
    lastContacted: (userNg.contactPois || []).map(mapPoiIdObjectToDatedPoi),
    lastViewed: (userNg.viewedPois || []).map(mapPoiIdObjectToDatedPoi),
    savedAddresses: (userNg.savedAddresses || []).map(({ settings, document }) => ({
      settings,
      document: {
        docId: document.docId,
        location: (document.ref as AutocompleteLocation).location,
        name: (document.ref as AutocompleteDocument).name,
      } as IBaseAutocompleteDoc,
    })),
    savedSearches: (userNg.savedSearches || []).map(mapSearchToSaveSearch),
    searchHistory: (userNg.searchHistory || []).map(mapSearchToSaveSearch),
    agentStatus: userNg.agentStatus ? {
      agentId: userNg.agentStatus.agentId,
      displayLevel: userNg.agentStatus.displayLevel,
      officeId: userNg.agentStatus.officeId,
      pricingTier: pricingTierToApiPricingTier[userNg.agentStatus.pricingTier],
      status: userNg.agentStatus.isApproved ? IAgentStatusType.Approved : IAgentStatusType.Unapproved,
    } : null,
    commercialEnablePrivateUser: userNg.commercialEnablePrivateUser,
    commercialIsOfficeManager: Boolean(userNg.officeManager && userNg.officeManager.officeId),
    commercialOfficeManagerAgentId: userNg.officeManager && userNg.officeManager.agentId,
    commercialOfficeManagerOfficeId: userNg.officeManager && userNg.officeManager.officeId,
    commercialOfficeManagerOfficeName: userNg.officeManager && userNg.officeManager.officeName,
    commercialAgentsInOffice: userNg.officeManager && userNg.officeManager.agentsInOffice.map(info => ({
      agentId: info.agentId,
      agentName: info.agentName,
      userId: info.userId,
      officeName: info.officeName,
      officeId: info.officeId,
    })),
  };
  return user;
}
