import { createSelector } from 'reselect';
import {
  makeNullSafeDomainGetter,
  agentWithBulletinsByIdSelector,
  createIsLoadingSelector,
  officeDataByIdSelector,
} from 'store/state/domainData/selectors/common';
import { flow } from 'lodash';
import { MarketplaceType, DealType, PocType } from 'utils/entities';
import { routeSelector } from 'store/state/selectors/router';
import { unitPageBulletinSelector, unitPageCommercialBulletinSelector } from './unitPageBulletin';
import { Route } from 'config/routes';


export const agentPageDataSelector = makeNullSafeDomainGetter(agentWithBulletinsByIdSelector, 'getRealEstateAgentById');

export const isOfficeDataLoadingSelector = createIsLoadingSelector(officeDataByIdSelector);
export const officeDataSelector = makeNullSafeDomainGetter(officeDataByIdSelector, 'getRealEstateOfficeById');

export const officeDescriptionSelector = flow(officeDataSelector, d => d ? d.descriptionHtml : null);
export const agentDescriptionSelector = flow(agentPageDataSelector, d => d ? d.descriptionHtml : null);


const commonBulletinSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
], (route, bulletin, commercialBulletin) => {
  let poi = null;

  switch (route.name) {
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
  }
  return poi;
});

export const showAboutTheOfficeSectionSelector = createSelector([
  officeDataSelector,
  commonBulletinSelector,
], (data, poi) => {
  if (data && poi && poi.poc && poi.poc.type === PocType.Agent && poi.poc.officeId) {
    return data.isMadadWinner && data.payingAboutTheOfficeInListingPage;
  }
  return false;
});


export const displayLevelAndPricingTierSelector = createSelector([
  agentPageDataSelector,
], (agentData) => {
  if (!agentData) return null;
  return {
    displayLevel: agentData.displayLevel,
    pricingTier: agentData.pricingTier,
    licenseId: agentData.licenseId || null,
  };
});

export const residentialAgentListingsSelector = createSelector(agentPageDataSelector, data => data && data[MarketplaceType.Residential]);
export const commercialAgentListingsSelector = createSelector(agentPageDataSelector, data => data && data[MarketplaceType.Commercial]);

export const allAgentAndOfficeListingsSelector = createSelector(
  [ residentialAgentListingsSelector, commercialAgentListingsSelector ],
  (res, com) => [ ...(res ? [ ...res[DealType.Rent], ...res[DealType.Buy] ] : []), ...(com ? [ ...com[DealType.Rent], ...com[DealType.Buy] ] : []) ]
);
