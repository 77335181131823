import { createSelector } from 'reselect';
import {
  normalizeRoomsRange,
  normalizePriceRange,
  mergeWithInitialState,
  normalizeBathsRange,
  normalizeFloorsRange,
  normalizeMonthlyTaxRange,
  normalizePPMRange,
  makeNormalizeRangeForBE,
} from 'components/filters/utils';
import {
  AmenitiesMap,
  ISearchParameters,
  Priority,
  DealType,
  IFooterByDocIdVariables,
  MarketplaceType,
} from 'utils/entities';
import { getRouteParams, marketplaceSelector } from 'store/state/selectors/router';
import { flow, head } from 'lodash';
import { DecodedRouteParams } from 'config/routes';
import { userPrioritiesSelector, userHasCommutePreferenceSelector } from 'store/state/domainData/selectors';
import { SORT_FIELD_BY_PRIORITY } from './types';
import { SortValue, SortDirection, SortField } from 'components/listing-sort/types';
import { basicSortValueSelector, smartSortValueSelector, dealTypeSelector } from 'store/state/selectors/search';
import { IFiltersState } from 'components/filters/types';
import { IABTestContext } from 'config/abTests';

export const pageSelector = flow(getRouteParams, (params) => params.page || null);

export function makeCommercialSearchParams(filters: IFiltersState, amenities: AmenitiesMap, normalizedSort: Array<[SortField, SortDirection]>, dealType: DealType, marketplace: MarketplaceType, withDaysRangeFilter?: boolean): ISearchParameters {
  const {
    seller,
    ppmRange,
    areaRange,
    conditions,
    floorRange,
    priceRange,
    roomsRange,
    qualityClass,
    propertyTypes,
    numberOfEmployeesRange,
  } = filters;

  return {
    noFee: false,
    dealType,
    numberOfEmployeesRange,
    commercialAmenities: amenities,
    qualityClass,
    roomsRange,
    bathsRange: [ null, null ],
    floorRange,
    areaRange,
    buildingClass: propertyTypes,
    sellerType: seller,
    generalCondition: conditions,
    ppmRange: ppmRange ? normalizePPMRange(ppmRange, marketplace, dealType) : [ null, null ],
    priceRange: normalizePriceRange(priceRange, marketplace, dealType),
    monthlyTaxRange: [ null, null ],
    amenities: {},
    sort: normalizedSort.length ? normalizedSort.map(([ field, order ]) => ({ field, order })) : null,
    priceDrop: null,
    underPriceEstimation: null,
  };
}

export function makeResidentialSearchParams(filters: IFiltersState, amenities: AmenitiesMap, normalizedSort: Array<[SortField, SortDirection]>, dealType: DealType, marketplace: MarketplaceType, withDaysRangeFilter?: boolean): ISearchParameters {
  const {
    fee,
    seller,
    ppmRange,
    areaRange,
    conditions,
    bathsRange,
    floorRange,
    priceRange,
    roomsRange,
    propertyTypes,
    monthlyTaxRange,
    projectDiscount,
    priceChanges: { priceDrop, underPriceEstimation },
  } = filters;

  return {
    noFee: fee,
    dealType,
    numberOfEmployeesRange: [ null, null ],
    commercialAmenities: {},
    qualityClass: [],
    discountedProjects: projectDiscount,
    roomsRange: makeNormalizeRangeForBE(normalizeRoomsRange)(roomsRange, marketplace),
    bathsRange: makeNormalizeRangeForBE(normalizeBathsRange)(bathsRange, marketplace),
    floorRange: makeNormalizeRangeForBE(normalizeFloorsRange)(floorRange, marketplace),
    areaRange,
    buildingClass: propertyTypes,
    sellerType: seller,
    generalCondition: conditions,
    ppmRange: ppmRange ? normalizePPMRange(ppmRange, marketplace, dealType) : [ null, null ],
    priceRange: normalizePriceRange(priceRange, marketplace, dealType),
    monthlyTaxRange: normalizeMonthlyTaxRange(monthlyTaxRange, marketplace),
    amenities,
    sort: normalizedSort.length ? normalizedSort.map(([ field, order ]) => ({ field, order })) : null,
    priceDrop,
    underPriceEstimation,
  };
}

export interface IContextualSearchParameters {
  searchParams: ISearchParameters;
  marketplaceType: MarketplaceType;
}


export function projectsCarouselSearchClearFilters(dealType: DealType, marketplace: MarketplaceType): IContextualSearchParameters {
  const filters = mergeWithInitialState({}, dealType, marketplace);
  const amenitiesMap = filters.amenities.reduce<AmenitiesMap>((acc, amenity) => ({
    ...acc,
    [amenity]: true,
  }), {});

  const isCommercial = marketplace === MarketplaceType.Commercial;

  if (isCommercial) {
    return {
      searchParams: makeCommercialSearchParams(filters, amenitiesMap, [], dealType, marketplace),
      marketplaceType: MarketplaceType.Commercial,
    };
  }

  return {
    searchParams: makeResidentialSearchParams(filters, amenitiesMap, [], dealType, marketplace),
    marketplaceType: MarketplaceType.Residential,
  };
}


export function searchParamsSelectorResult(
  routeParams: DecodedRouteParams,
  basicSort: SortValue[],
  smartSort: SortValue[],
  dealType: DealType,
  marketplace: MarketplaceType,
  abTestsState?: IABTestContext
): IContextualSearchParameters {
  const { filters: partialFilters } = routeParams;

  const filters = mergeWithInitialState(partialFilters, dealType, marketplace);

  const amenitiesMap = filters.amenities.reduce<AmenitiesMap>((acc, amenity) => ({
    ...acc,
    [amenity]: true,
  }), {});

  const isCommercial = marketplace === MarketplaceType.Commercial;
  const normalizedSort = basicSort.length ? basicSort : smartSort;

  if (isCommercial) {
    return {
      searchParams: makeCommercialSearchParams(filters, amenitiesMap, normalizedSort, dealType, marketplace),
      marketplaceType: MarketplaceType.Commercial,
    };
  }


  return {
    searchParams: makeResidentialSearchParams(filters, amenitiesMap, normalizedSort, dealType, marketplace),
    marketplaceType: MarketplaceType.Residential,
  };
}

export const searchParamsSelector = createSelector([
  getRouteParams,
  basicSortValueSelector,
  smartSortValueSelector,
  dealTypeSelector,
  marketplaceSelector,
], searchParamsSelectorResult);

const mapPrioritiesToSortValues = (p: Priority): [SortField, SortDirection] => [ SORT_FIELD_BY_PRIORITY[p], SortDirection.Asc ];


export const userSmartSortValueSelector = createSelector([  userPrioritiesSelector,
  userHasCommutePreferenceSelector,
], (priorities, hasCommute) => {
  if (!priorities) return null;
  // user should not have 'commuteTime' in priorities without having commutePreference set
  const commuteConstrainedPriorities = hasCommute ? priorities : priorities.filter((v) => v !== 'commuteTime');

  return commuteConstrainedPriorities.map(mapPrioritiesToSortValues);
});

export const cityFooterVariablesSelector = createSelector([
  dealTypeSelector,
  getRouteParams,
], (dealType, { term }): IFooterByDocIdVariables => {
  const onlyOneTerm = term && term.length === 1;

  if (onlyOneTerm && dealType) {
    const footerContext = dealType === DealType.Buy ? 'sale' : 'rent';

    return {
      cityDocId: head(term),
      footerContext,
    };
  }

  return null;
});
