import { AppleInitConf } from 'typings/window';

const SCRIPT_ID = '--apple-api';

export class AppleAPI {
  private static hasLoaded: boolean = false;
  private config: AppleInitConf;

  constructor(config: AppleInitConf) {
    this.config = config;
    this.loadClient();
  }

  public signIn() {
    if (window.AppleID) {
      return window.AppleID.auth.signIn();
    }
    return Promise.reject('AppleID is not available');
  }

  private initClient() {
    if (window.AppleID) {
      try {
        window.AppleID.auth.init(this.config);
        AppleAPI.hasLoaded = true;
      }
      // tslint:disable-next-line: no-empty
      catch (error) {}
    }
  }

  private loadClient() {
    return new Promise<void>((resolve) => {
      if (AppleAPI.hasLoaded) {
        resolve();
      }
      else {
        const loadCB = () => {
          this.initClient();
          resolve();
        };

        const maybeScriptElt = document.getElementById(SCRIPT_ID) as HTMLScriptElement;

        if (maybeScriptElt) {
          maybeScriptElt.onload = loadCB;
        }

        else {
          const scriptElt = document.createElement('script');
          scriptElt.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/he_IL/appleid.auth.js';
          scriptElt.id = SCRIPT_ID;
          scriptElt.onload = loadCB;

          const firstJS = document.getElementsByTagName('script')[0];
          firstJS.parentNode.insertBefore(scriptElt, firstJS);
        }
      }
    });
  }
}
