import React from 'react';
import { Route } from 'config/routes';
import UploadBulletinLink from 'components/upload-bulletin-link';
import { MarketplaceType } from 'utils/entities';

interface FooterAnchorLink {
  href: string;
  text: string;
  routeName?: Route;
}

interface FooterComponentLink {
  Component: JSX.Element;
}

export type FooterLink = FooterAnchorLink | FooterComponentLink;

interface FooterSection {
  header: string;
  links: FooterLink[];
}

const hebrewBaseUrl = '/';

const baseFooterSections = [
  {
    header: 'כלים',
    links: [
      {
        href: `https://www.madlan.co.il/blog?source=footer_link`,
        text: 'בלוג מדל״ן',
      },
      {
        href: `${hebrewBaseUrl}education/bestSchools?source=footer_link`,
        text: 'חינוך ומפת המיצ"ב',
      },
      {
        href: `${hebrewBaseUrl}developers?source=footer_link`,
        text: 'אינדקס יזמים',
      },
      {
        href: `${hebrewBaseUrl}search-agent?source=footer_link`,
        text: 'מאגר המתווכים',
      },
      {
        href: `${hebrewBaseUrl}madad?source=footer_link`,
        text: 'מדד המתווכים 2023/24',
      },
      {
        href: `${hebrewBaseUrl}madad/2023?source=footer_link`,
        text: 'מדד המתווכים 2022/23',
      },
      {
        href: `${hebrewBaseUrl}mechirLamishtaken/introduction?source=footer_link`,
        text: 'מחיר למשתכן',
      },
      {
        href: `${hebrewBaseUrl}mechirLamishtaken/eligible?source=footer_link`,
        text: 'מחשבון זכאות',
      },
      {
        href: `${hebrewBaseUrl}article/main?source=footer_link`,
        text: 'כתבות נדל"ן',
      },
      {
        href: `${hebrewBaseUrl}appartmentTax/calculator?source=footer_link`,
        text: 'מחשבון מס רכישה',
      },
      {
        href: `${hebrewBaseUrl}lp/homeOwner?source=footer_link`,
        text: 'הבית שלי',
      },
    ],
  },
  {
    header: 'מידע',
    links: [
      {
        href: `${hebrewBaseUrl}lp/promotion?source=footer_link`,
        text: 'פרסום ליזמים',
      },
      {
        href: `${hebrewBaseUrl}lp/agents?source=footer_link`,
        text: 'פרסום למתווכים',
      },
      {
        href: `${hebrewBaseUrl}professional?source=footer_link`,
        text: 'מערכת מקצועית ליזמים',
      },
      {
        href: `${hebrewBaseUrl}agent/main?source=footer_link`,
        text: 'מערכת מקצועית למתווכים',
      },
      {
        href: 'https://www.madlan.co.il/blog/careers?source=footer_link',
        text: 'דרושים',
      },
      {
        href: `${hebrewBaseUrl}etc/faq?source=footer_link`,
        text: 'שאלות ותשובות',
      },
      {
        href: `${hebrewBaseUrl}etc/aboutUs?source=footer_link`,
        text: 'אודות',
      },
      {
        href: `${hebrewBaseUrl}etc/terms?source=footer_link`,
        text: 'תנאי שימוש',
      },
      {
        href: `${hebrewBaseUrl}${Route.Accessibility}?source=footer_link`,
        text: 'הצהרת נגישות',
      },
      {
        href: `${hebrewBaseUrl}lp/connectUs?source=footer_link`,
        text: 'צור קשר',
      },
    ],
  },
];

const RENT_RESIDENTIAL_SECTION_HEADER = 'דירות להשכרה';

const footerSectionsResidential: FooterSection[] = [
  {
    header: 'לוח דירות',
    links: [
      {
        href: `${hebrewBaseUrl}for-sale/ישראל?source=footer_link`,
        text: 'לוח דירות למכירה',
      },
      {
        href: `${hebrewBaseUrl}for-rent/ישראל?source=footer_link`,
        text: 'לוח דירות להשכרה',
      },
      {
        href: `${hebrewBaseUrl}for-sale/ישראל?filters=_0-10000000___developer______&source=footer_link`,
        text: 'דירות חדשות ופרויקטים חדשים',
      },
      {
        Component: <UploadBulletinLink source="home_page_footer_bar" text="פרסום דירה בחינם!" />,
      },
    ],
  },
  {
    header: 'דירות למכירה',
    links: [
      {
        href: `${hebrewBaseUrl}for-sale/ישראל`,
        text: 'דירות למכירה בכל הארץ',
      },
      {
        href: `${hebrewBaseUrl}for-sale/תל-אביב-יפו-ישראל`,
        text: 'דירות למכירה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}for-sale/ירושלים-ישראל`,
        text: 'דירות למכירה בירושלים',
      },
      {
        href: `${hebrewBaseUrl}for-sale/הרצליה-ישראל`,
        text: 'דירות למכירה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}for-sale/רמת-גן-ישראל`,
        text: 'דירות למכירה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}for-sale/גבעתיים-ישראל`,
        text: 'דירות למכירה בגבעתיים',
      },
      {
        href: `${hebrewBaseUrl}for-sale/חיפה-ישראל`,
        text: 'דירות למכירה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}for-sale/אשדוד-ישראל`,
        text: 'דירות למכירה באשדוד',
      },
      {
        href: `${hebrewBaseUrl}for-sale/פתח-תקווה-ישראל`,
        text: 'דירות למכירה בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}for-sale/חולון-ישראל`,
        text: 'דירות למכירה בחולון',
      },
      {
        href: `${hebrewBaseUrl}for-sale/רחובות-ישראל`,
        text: 'דירות למכירה ברחובות',
      },
    ]
      .map(({ href, text }) => ({
        href: `${href}?tracking_search_source=homepage_footer`,
        text,
      })),
  },
  {
    header: RENT_RESIDENTIAL_SECTION_HEADER,
    links: [
      {
        href: `${hebrewBaseUrl}for-rent/ישראל`,
        text: 'דירות להשכרה בכל הארץ',
      },
      {
        href: `${hebrewBaseUrl}for-rent/תל-אביב-יפו-ישראל`,
        text: 'דירות להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}for-rent/ירושלים-ישראל`,
        text: 'דירות להשכרה בירושלים',
      },
      {
        href: `${hebrewBaseUrl}for-rent/הרצליה-ישראל`,
        text: 'דירות להשכרה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}for-rent/רמת-גן-ישראל`,
        text: 'דירות להשכרה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}for-rent/גבעתיים-ישראל`,
        text: 'דירות להשכרה בגבעתיים',
      },
      {
        href: `${hebrewBaseUrl}for-rent/חיפה-ישראל`,
        text: 'דירות להשכרה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}for-rent/אשדוד-ישראל`,
        text: 'דירות להשכרה באשדוד',
      },
      {
        href: `${hebrewBaseUrl}for-rent/פתח-תקווה-ישראל`,
        text: 'דירות להשכרה בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}for-rent/חולון-ישראל`,
        text: 'דירות להשכרה בחולון',
      },
      {
        href: `${hebrewBaseUrl}for-rent/רחובות-ישראל`,
        text: 'דירות להשכרה ברחובות',
      },
    ]
      .map(({ href, text }) => ({
        href: `${href}?tracking_search_source=homepage_footer`,
        text,
      })),
  },
  {
    header: 'שכונות',
    links: [
      {
        href: `${hebrewBaseUrl}local/pricesHeatmap`,
        text: 'מפת הנדל"ן של ישראל',
      },
      {
        href: `${hebrewBaseUrl}area-info/תל-אביב-יפו-ישראל`,
        text: 'שכונות בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}area-info/ירושלים-ישראל`,
        text: 'שכונות בירושלים',
      },
      {
        href: `${hebrewBaseUrl}area-info/הרצליה-ישראל`,
        text: 'שכונות בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}area-info/רמת-גן-ישראל`,
        text: 'שכונות ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}area-info/גבעתיים-ישראל`,
        text: 'שכונות בגבעתיים',
      },
      {
        href: `${hebrewBaseUrl}area-info/חיפה-ישראל`,
        text: 'שכונות בחיפה',
      },
      {
        href: `${hebrewBaseUrl}area-info/אשדוד-ישראל`,
        text: 'שכונות באשדוד',
      },
      {
        href: `${hebrewBaseUrl}area-info/פתח-תקווה-ישראל`,
        text: 'שכונות בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}area-info/חולון-ישראל`,
        text: 'שכונות בחולון',
      },
      {
        href: `${hebrewBaseUrl}area-info/רחובות-ישראל`,
        text: 'שכונות ברחובות',
      },
    ].map(({ href, text }) => ({
      href: `${href}?source=footer_link`,
      text,
    })),
  },
  ...baseFooterSections,
];

const footerSectionsCommercial: FooterSection[] = [
  {
    header: 'נדל״ן מסחרי',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-sale/ישראל?source=footer_link`,
        text: 'נדל״ן מסחרי למכירה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/ישראל?source=footer_link`,
        text: 'נדל״ן מסחרי להשכרה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/ישראל?filters=_0-10000000___developer_______0-100000____&source=footer_link`,
        text: 'פרויקטים חדשים',
      },
      {
        href: `${hebrewBaseUrl}upload-bulletin?source=commercial_landing_page_footer`,
        text: 'פרסום נכס מסחרי',
      },
    ],
  },
  {
    header: 'נכסים מסחריים למכירה',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-sale/ישראל`,
        text: 'נכסים מסחריים למכירה בכל הארץ',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/תל-אביב-יפו-ישראל`,
        text: 'נכסים מסחריים למכירה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רמת-גן-ישראל`,
        text: 'נכסים מסחריים למכירה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/הרצליה-ישראל`,
        text: 'נכסים מסחריים למכירה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/נתניה-ישראל`,
        text: 'נכסים מסחריים למכירה בנתניה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/פתח-תקווה-ישראל`,
        text: 'נכסים מסחריים למכירה בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/ירושלים-ישראל`,
        text: 'נכסים מסחריים למכירה בירושלים',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/חיפה-ישראל`,
        text: 'נכסים מסחריים למכירה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רעננה-ישראל`,
        text: 'נכסים מסחריים למכירה ברעננה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רחובות-ישראל`,
        text: 'נכסים מסחריים למכירה ברחובות',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/חולון-ישראל`,
        text: 'נכסים מסחריים למכירה בחולון',
      },
    ].map(({ href, text }) => ({
      href: `${href}?source=footer_link`,
      text,
    })),
  },
  {
    header: 'נכסים מסחריים להשכרה',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-rent/ישראל`,
        text: 'נכסים מסחריים להשכרה בכל הארץ',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/תל-אביב-יפו-ישראל`,
        text: 'נכסים מסחריים להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רמת-גן-ישראל`,
        text: 'נכסים מסחריים להשכרה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/הרצליה-ישראל`,
        text: 'נכסים מסחריים להשכרה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/נתניה-ישראל`,
        text: 'נכסים מסחריים להשכרה בנתניה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/פתח-תקווה-ישראל`,
        text: 'נכסים מסחריים להשכרה בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/ירושלים-ישראל`,
        text: 'נכסים מסחריים להשכרה בירושלים',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חיפה-ישראל`,
        text: 'נכסים מסחריים להשכרה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רעננה-ישראל`,
        text: 'נכסים מסחריים להשכרה ברעננה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רחובות-ישראל`,
        text: 'נכסים מסחריים להשכרה ברחובות',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חולון-ישראל`,
        text: 'משרדים להשכרה בחולון',
      },
    ].map(({ href, text }) => ({
      href: `${href}?source=footer_link`,
      text,
    })),
  },
  {
    header: 'חיפושים פופולריים',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-rent/תל-אביב-יפו-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/תל-אביב-יפו-ישראל?filters=_0-500000____shop______0-10000____`,
        text: 'חנויות להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רמת-גן-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/הרצליה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/נתניה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בנתניה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/פתח-תקווה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בפתח תקווה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/ירושלים-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בירושלים',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חיפה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רעננה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברעננה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רחובות-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברחובות',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חולון-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בחולון',
      },
    ].map(({ href, text }) => ({
      href: `${href}?source=footer_link`,
      text,
    })),
  },
  ...baseFooterSections,
];


function seoFooterSectionsCreator(sections: FooterSection[], insertAfterHeader: string, sectionsToInsert: FooterSection[]): FooterSection[] {
  let result: FooterSection[] = [];
  for (const section of sections) {
    if (section.header === insertAfterHeader) {
      result = result.concat([ section, ...sectionsToInsert ]);
    }
    else {
      result.push(section);
    }
  }
  return result;
}

export const seoFooterSections: FooterSection[] = seoFooterSectionsCreator(footerSectionsResidential, RENT_RESIDENTIAL_SECTION_HEADER, [
  {
    header: 'נדל״ן מסחרי לקנייה',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-sale/תל-אביב-יפו-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/תל-אביב-יפו-ישראל?filters=_0-10000000____shop______0-100000____`,
        text: 'חנויות למכירה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רמת-גן-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/הרצליה-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/נתניה-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בנתניה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/חולון-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בחולון',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/חיפה-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רעננה-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה ברעננה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/רחובות-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה ברחובות',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-sale/פתח-תקווה-ישראל?filters=_0-10000000____office______0-100000____`,
        text: 'משרדים למכירה בפתח תקווה',
      },
    ],
  },
  {
    header: 'נדל״ן מסחרי להשכרה',
    links: [
      {
        href: `${hebrewBaseUrl}commercial/for-rent/תל-אביב-יפו-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/תל-אביב-יפו-ישראל?filters=_0-500000____shop______0-10000____`,
        text: 'חנויות להשכרה בתל אביב',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רמת-גן-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברמת גן',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/הרצליה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בהרצליה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/נתניה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בנתניה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חולון-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בחולון',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/חיפה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בחיפה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רעננה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברעננה',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/רחובות-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה ברחובות',
      },
      {
        href: `${hebrewBaseUrl}commercial/for-rent/פתח-תקווה-ישראל?filters=_0-500000____office______0-10000____`,
        text: 'משרדים להשכרה בפתח תקווה',
      },
    ],
  },
]);

export const getFooterSections = (marketplace: MarketplaceType) => marketplace === MarketplaceType.Residential ? footerSectionsResidential : footerSectionsCommercial;
