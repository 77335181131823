import { IProject, POC, PocType, PromotionValues, ICommercialProject, IBulletin, ICommercialBulletin, LeadingAgentFeature, Award } from 'utils/entities';
import { ITranslate } from 'locale';
import { IFormFields, IContactPageSubmitVariables } from 'store/sagas/apiService/types';
import { State } from 'store/state';
import { contactPageResponseSelector as contactResponseSelector } from 'store/state/mutationsResponse/selectors';
import { contactModalCall, setContactAgentOpen, setContactFormEventsSource, submitContactAgent } from 'store/state/app/actions';
import { createSelector } from 'reselect';
import { getLastContactAgentValues, IContactAgentValues } from 'helpers/contactFormStore';
import { userProfileSelector } from 'store/state/domainData/selectors';
import { contactModalPoiIdSelector } from 'store/state/app/selectors';
import { flow } from 'lodash';
import { RequestType } from 'components/contact-modal/HebrewContactModal';
import _ from 'lodash';

export const getAgentPOCName = (poc: POC) => {
  if (poc) {
    if (poc.type === PocType.Agent) {
      return poc.agentContact ? poc.agentContact.name : null;
    }
  }
  return null;
};

export const getPOCName = (poc: POC) => {
  if (poc) {
    if (poc.type === PocType.Agent) {
      return poc.agentContact ? poc.agentContact.name : null;
    }
    else if (poc.contactInfo) {
      return poc.contactInfo.name;
    }
  }
  return null;
};

export const getPOCPhoneNumber = (poc: POC) => {
  if (poc && (poc.type === PocType.Agent || poc.type === PocType.Private)) {
    return poc.displayNumber;
  }
  return null;
};

function addPrefixToPhoneNumber(phone: string) {
  if (/^05/gi.test(phone)) return `+972${phone.substring(1)}`;
  if (/^972/gi.test(phone)) return `+${phone}`;
  return phone;
}

export const getPOCValidAgentPhoneWithPrefix = (poc: POC): string | null => {
  if (poc) {
    if (poc.type === PocType.Agent) {
      const phone = _.get(poc, [ 'agentContact', 'phone' ]);
      if (phone) {
        const phonePattern = /^[+]{0,1}[9][7][2][5][0|1|2|4|5|6|8|9]{1}[-]{0,1}[0-9]{7}$|^[0][5][0|1|2|4|5|6|8|9]{1}[-]{0,1}[0-9]{7}$/gi;

        return phonePattern.test(phone) ? addPrefixToPhoneNumber(phone) : null;
      }
    }
  }
  return null;
};

function insert(str: string, index: number, value: string) {
  return str.slice(0, index) + value + str.slice(index);
}

export const addDashesToPhoneNumber = (phone?: string) => {
  if (phone) {
    if (/^[+]{0,1}[9][7][2][5][0|1|2|4|5|6|8|9]{1}[-]{0,1}[0-9]{7}$/gi.test(phone)) return phone.replace(/(^[+]{0,1}[9][7][2][5][0|1|2|4|5|6|8|9]{1})[-]{0,1}([0-9]{7}$)/, '$1-$2');
    if (/^[0][5][0|1|2|4|5|6|8|9]{1}[-]{0,1}[0-9]{7}$/gi.test(phone)) return phone.replace(/^([0][5][0|1|2|4|5|6|8|9]{1})[-]{0,1}([0-9]{7})$/, '$1-$2');
    if (phone.length === 10) return insert(phone, 3, '-');
  }
  return phone;
};

export const getPOCEmail = (poc: POC) => {
  if (poc) {
    if (poc.type === PocType.Agent) {
      return poc.agentContact ? poc.agentContact.email : null;
    }
    else if (poc.contactInfo) {
      return poc.contactInfo.email;
    }
  }
  return null;
};

export const getPOCAvatar = (poc: POC) => {
  if (poc) {
    if (poc.type === PocType.Agent) {
      return poc.agentContact ? poc.agentContact.imageUrl : null;
    }
    else if (poc.contactInfo) {
      return poc.contactInfo.imageUrl;
    }
  }
  return null;
};

export function checkEnabledLeadingAgentFeature(a: Award, f?: LeadingAgentFeature) {
  if (a) {
    if (f) {
      for (const { name, isEnabled } of a.features) {
        if (name === f) return isEnabled;
      }
    }

    return a.features.some(({ isEnabled }) => isEnabled);
  }
  return false;
}

export const getAgentLink = (id: string, source?: string) => `/agent/${id}${source ? `?source=${source}` : ''}`;

export const getPOCAvatarLink = (poc: POC, source?: string) => {
  if (poc && poc.type === PocType.Agent) {
    return getAgentLink(poc.agentId, source);
  }
  return null;
};

export const getOfficeLink = (id: string, source?: string) => `/agentsOffice/${id}${source ? `?source=${source}` : ''}`;

export const getPOCLogoLink = (poc: POC, source?: string) => {
  if (poc && poc.type === PocType.Agent) {
    return getOfficeLink(poc.officeId, source);
  }
  return null;
};

export const getPOCMadadCategory = (poc: POC) => {
  if (poc && poc.type === PocType.Agent && poc.agentContact) {
    return poc.agentContact.madadCategory;
  }
  return null;
};

export const getPOCMadadSearchResultCategory = (poc: POC) => {
  if (poc && poc.type === PocType.Agent) {
    return poc.madadSearchResult;
  }
  return null;
};

export const getPOCLogo = (poc: POC) => {
  if (poc && poc.type === PocType.Agent && poc.officeContact) {
    return poc.officeContact.imageUrl;
  }
  return null;
};

export const getProjectPhoneNumber = (project: IProject | ICommercialProject) => {
  if (project) {
    return project.phoneNumber[0];
  }
  return null;
};

export const getProjectLogoPath = (project: IProject | ICommercialProject): string => {
  if (project) {
    return project.projectLogo;
  }
  return null;
};

export const getDeveloperLogoPath = (project: IProject | ICommercialProject): string => {
  if (project && project.developers && project.developers[0]) {
    return project.developers[0].logoPath;
  }
  return null;
};

export const getDeveloperLink = (project: IProject | ICommercialProject): string => {
  if (project && project.developers && project.developers[0]) {
    return project.developers[0].developerLink;
  }
  return null;
};

export const getDeveloperName = (project: IProject | ICommercialProject): string => {
  if (project && project.developers && project.developers[0]) {
    return project.developers[0].name;
  }
  return null;
};

const projectIsNotPromoted = (project: IProject | ICommercialProject) => {
  return project && project.promotionStatus && (project.promotionStatus.status === PromotionValues.NotPromoted);
};

export const getProjectName = (project: IProject | ICommercialProject, t: ITranslate): string => {
  if (project) {
    const isNotPromoted = projectIsNotPromoted(project);
    return t('projectPage.project.name', { name: project.projectName, isNotPromoted });
  }
  return null;
};

export const getProjectOrDeveloperLogoPath = (project: IProject | ICommercialProject): string => {
  return getProjectLogoPath(project) || getDeveloperLogoPath(project);
};

export const getPOCCompanyName = (poc: POC) => {
  if (poc && poc.type === PocType.Agent && poc.officeContact) {
    return poc.officeContact.name;
  }
  return null;
};

export const getAgentOfficeId = (poc: POC) => {
  if (poc && poc.type === PocType.Agent) {
    return poc.officeId;
  }
  return null;
};

export const getPOCTitle = (poc: POC) => {
  if (poc && poc.type === PocType.Agent) {
    return poc.title;
  }
  return null;
};

export const getPOCCompanyTitle = (poc: POC) => {
  if (poc && poc.type === PocType.Agent && poc.officeContact) {
    return poc.officeContact.title;
  }
  return null;
};

export const getPOCExclusive = (poc: POC) => {
  return poc && poc.type === PocType.Agent ? (poc.exclusivity && (poc.exclusivity.exclusive || poc.exclusive)) : null;
};

export const createValidationFn = (t: ITranslate) => (values: IFormFields) => {
  const errors: Partial<IFormFields> = {};
  const phonePattern = /[\D]+/;
  const namePattern = /^[a-z\u0590-\u05fe\s]+$/gi;

  if (!values.message) {
    errors.message = t('contactAgentModal.form.message.required');
  }
  if (!values.phone) {
    errors.phone = t('contactAgentModal.form.phone.required');
  }
  else if (phonePattern.test(values.phone)) {
    errors.phone = t('contactAgentModal.form.phone.invalid');
  }

  if (values.name && !namePattern.test(values.name)) {
    errors.name = t('contactAgentModal.form.name.invalid');
  }

  return errors;
};

export const getContactPageEventPayload = (poc: POC, values: Partial<IContactPageSubmitVariables>, isCall?: boolean, requestType?: RequestType) => {
  const payload: any = {
    event: {
      contact_details: {
        email: values.email,
        phone: values.phone,
        full_name: values.name,
      },
      request_type: requestType,
      message: isCall ? undefined : values.message,
    },
  };

  if (poc) {
    payload.agent = {
      name: getPOCName(poc),
      email: getPOCEmail(poc),
      company_name: getPOCCompanyName(poc),
      title: getPOCTitle(poc),
    };
  }

  return payload;
};

export const contactFormInitialValuesSelector = createSelector([
  userProfileSelector,
  getLastContactAgentValues,
], (profile, valuesFromLs): IContactAgentValues => {
  let phone = '';
  let name = '';
  let email = '';

  if (profile && profile.phone) phone = profile.phone;
  else if (valuesFromLs && valuesFromLs.phone) phone = valuesFromLs.phone;

  if (profile && profile.email) email = profile.email;
  else if (valuesFromLs && valuesFromLs.email) email = valuesFromLs.email;

  if (profile && (profile.firstName || profile.lastName)) name = [ profile.firstName, profile.lastName ].filter(Boolean).join(' ');
  else if (valuesFromLs && valuesFromLs.name) name = valuesFromLs.name;

  return {
    phone,
    name,
    email,
  };
});

const isContactModalOpenSelector = flow(contactModalPoiIdSelector, Boolean);

const isContactedSelector = (state: State, props: { poi: IBulletin | IProject | ICommercialBulletin | ICommercialProject }) => {
  const res = contactResponseSelector(state, props.poi.id);

  return Boolean(res && res.data && res.data.contactV4);
};

export const mapStateToProps = (state: State, props: { poi: IBulletin | IProject | ICommercialBulletin | ICommercialProject }) => ({
  isContacted: isContactedSelector(state, props),
  isContactModalOpen: isContactModalOpenSelector(state),
  contactFormInitialValues: contactFormInitialValuesSelector(state),
});

export const mapDispatchToProps = {
  onContactPageSubmit: submitContactAgent,
  onSetContactModalPoiId: setContactAgentOpen,
  onCallClick: contactModalCall,
  onSetContactFormEventsSource: setContactFormEventsSource,
};
