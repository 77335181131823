import { useScreenBreakpoint } from 'consts/breakpoints';
import { useEffect, useState } from 'react';

interface GoogleButtonProps {
  parent: React.MutableRefObject<HTMLDivElement>;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  theme?: GsiButtonConfiguration['theme'];
}

export const useLoginWithGoogleButton = ({ parent, theme = 'outline', containerRef }: GoogleButtonProps): void => {
  const [ apiLoaded, setApiLoaded ] = useState(false);
  const isDesktop = useScreenBreakpoint() >= 3;

  useEffect(() => {
    if (window.google && !apiLoaded) {
      setApiLoaded(true);
    }
  });

  useEffect(() => {
    if (apiLoaded && parent.current && theme && containerRef.current) {
      const wrapper = document.createElement('div');
      const width = containerRef.current.clientWidth;
      wrapper.style.cssText = 'height:40px;overflow:hidden;';

      window.google.accounts.id.renderButton(
        wrapper,
        {
          theme,
          type: 'standard',
          size: 'large',
          shape: 'circle',
          text: 'continue_with',
          locale: 'he_IL',
          width: `${isDesktop ? (width || 385) : (width || 320)}px`,
        }
      );
      parent.current.appendChild(wrapper);
    }
  }, [ parent.current, theme, apiLoaded, containerRef.current ]);

  return null;
};
