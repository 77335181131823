import React from 'react';
import * as Styled from './styled';
import { FooterLink as FootLink, seoFooterSections, getFooterSections } from './config';
import { SmallText } from 'ds/components/typography';
import { socialLinks as socialLinkSettings } from 'components/footer/config';
import { SocialLink, SocialLinks } from 'components/footer/styled';
import { BottomFooter, socialLinkToIcon } from 'components/footer/Footer';
import withEvents from 'analytics/withEvents';
import { noop } from '@babel/types';
import SitemapIcon from 'assets/svg/sitemap.svg';
import { useLocale } from 'locale';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { chunk } from 'lodash';
import { marketplaceSelector, routeSelector } from 'store/state/selectors/router';
import { useSelector } from 'react-redux';
import { Route } from 'config/routes';
import { MarketplaceType } from 'utils/entities';
import AnchorMiddleware from 'components/anchor';

interface FooterProps {
  onMadadLinkClick?: () => void;
  useVerticalLayout?: boolean;
  target?: string;
  noBorderTop?: boolean;
  hideSeoFooter?: boolean;
  breadcrumbs?: JSX.Element;
}

interface FooterLinkProps {
  link: FootLink;
  LinkComponent?: React.ElementType;
  onMadadLinkClick?: () => void;
  target?: string;
}

const FooterLink: React.FC<FooterLinkProps> = (props) => {
  const {
    link,
    LinkComponent,
    onMadadLinkClick,
    target,
  } = props;

  if ('Component' in link) {
    const { Component } = link;
    return <>{Component}</>;
  }

  const isMadadLink = link.href === '/madad?source=footer_link';

  return (
    <>
      <AnchorMiddleware href={link.href}>
        {(href) => (
          <LinkComponent
            href={href}
            children={link.text}
            target={target}
            onClick={isMadadLink ? onMadadLinkClick : undefined}
          />
        )}
      </AnchorMiddleware>
    </>
  );
};

FooterLink.defaultProps = {
  onMadadLinkClick: noop,
  LinkComponent: Styled.DefaultLink,
  target: '_blank',
};

const ExpandSectionLinkCount = 4;

const ExpandedFooter: React.FC<{ target?: string; }> = ({ target }) => {
  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();

  const isDesktop = screenBreakpoint >= 2;
  const iconSize = isDesktop ? 60 : 50;
  const Header = isDesktop ? Styled.DesktopHeader : Styled.MobileHeader;
  const SectionHeader = isDesktop ? Styled.ExpandedDesktopSectionHeader : Styled.ExpandedMobileSectionHeader;
  const ExpandedLinkWrapper = isDesktop ? Styled.ExpandedDesktopLinkWrapper : Styled.ExpandedMobileLinkWrapper;

  return (
    <>
      <Styled.ExpandedHeaderWrapper bigSpacing={isDesktop}>
        <SitemapIcon width={iconSize} height={iconSize} />
        <Header weight="extraBold">{t('footer.expanded.header')}</Header>
      </Styled.ExpandedHeaderWrapper>
      <div>
        {seoFooterSections.map((section, index) => {
          return (
            <Styled.ExpandedSection bigRightPadding={isDesktop} key={index}>
              <SectionHeader weight="bold">{section.header}</SectionHeader>
              {chunk(section.links, ExpandSectionLinkCount).map((links, linkChunkIndex) => {
                return (
                  <Styled.ExpandedLinkSection smallMargins={!isDesktop} key={linkChunkIndex}>
                    {links.map((link, linkIndex) => {
                      return (
                        <ExpandedLinkWrapper data-auto={`expanded-link-${index}-${linkIndex}`} key={linkIndex}>
                          <FooterLink
                            link={link}
                            target={target}
                            onMadadLinkClick={noop}
                          />
                        </ExpandedLinkWrapper>
                      );
                    })}
                  </Styled.ExpandedLinkSection>
                );
              })}
            </Styled.ExpandedSection>
          );
        })}
      </div>
    </>
  );
};

const Footer: React.FC<FooterProps> = (props) => {
  const route = useSelector(routeSelector);
  const marketplace = route.name === Route.CheckAddress ? MarketplaceType.Residential : useSelector(marketplaceSelector);
  const {
    onMadadLinkClick,
    useVerticalLayout,
    target = '_blank',
    noBorderTop = false,
    hideSeoFooter = false,
    breadcrumbs,
  } = props;

  return (
    <>
      <Styled.FooterContainer data-auto="footer-container">
        {breadcrumbs}
        {hideSeoFooter
          ? null
          : (useVerticalLayout ? <ExpandedFooter target={target} /> : (
            <Styled.TopMenu noBorderTop={noBorderTop}>
              {getFooterSections(marketplace).map((section, index) => (
                <Styled.TopMenuSection idCommercialLanding={route.name === Route.CommercialMarketLanding} key={index}>
                  <SmallText weight="bold">{section.header}</SmallText>
                  <Styled.LinkWrapper>
                    {section.links.map((link, i) => {
                      return (
                        <React.Fragment key={i}>
                          <FooterLink
                            link={link}
                            target={target}
                            onMadadLinkClick={onMadadLinkClick}
                          />
                        </React.Fragment>
                      );
                    })}
                  </Styled.LinkWrapper>
                </Styled.TopMenuSection>
              ))}
            </Styled.TopMenu>
          ))}
        <Styled.SocialMenu>
          <SocialLinks>
            {socialLinkSettings.map((link) => (
              <AnchorMiddleware href={link.url} key={link.type}>
                {(href) => (
                  <a href={href} target="_blank">
                    <SocialLink isSearch={false}>
                      {socialLinkToIcon[link.type]}
                    </SocialLink>
                  </a>
                )}
              </AnchorMiddleware>
            ))}
          </SocialLinks>
        </Styled.SocialMenu>
      </Styled.FooterContainer>
      <Styled.BottomMenu>
        <Styled.FooterContainer>
          <BottomFooter withSitemap={!useVerticalLayout} />
        </Styled.FooterContainer>
      </Styled.BottomMenu>
    </>
  );
};

Footer.defaultProps = {
  onMadadLinkClick: noop,
  useVerticalLayout: false,
};

export default withEvents<FooterProps>(sendEvent => ({
  onMadadLinkClick() {
    sendEvent('madad_click', 'madad');
  },
}))(Footer);
