import React, { useEffect, useRef, useState } from 'react';
import { useLocale } from 'locale';
import { isEmailValid as isEmailValidHelper } from 'utils/formValidationHelpers';
import theme from 'ds/theme/default';
import {
  AuthenticationInfo,
  AuthenticationInfoHeader,
  AuthenticationTypeSwitch,
  AuthenticationTypeSwitchWrapper,
  LogoWrapper,
} from './styled';
import {
  AuthenticationField,
  AuthenticationModalType,
  AuthenticationType,
  PendingAction,
  SocialVendor,
} from './types';
import { SmallText } from 'ds/components/typography';
import { UserExistsLoginForm } from './UserExistsLoginForm';
import Modal from 'ds/components/modal';
import {
  IConsent,
  IGenerateEmailVerificationCodeVariables,
  ILoginWithEmailAndPasswordVariables,
  IResetPasswordUpdateVariables,
  ISignInSignUpWithEmailVariables,
  IVerifyEmailVerificationCodeVariables,
  MutationResponseByType,
  MutationType,
} from 'store/sagas/apiService/types';
import { ApolloQueryResult } from 'apollo-client';
import { LoginType } from 'utils/entities';
import { AuthenticationEmailForm } from './AuthenticationEmailForm';
import { EnterPasswordForm } from './EnterPasswordForm';
import AuthSocialsButtons from './social-buttons';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { AuthTrap } from './AuthTrap';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import MadlanLogoIcon from 'assets/svg/il-logo-small.svg';
import { ConsentsForm } from './ConsentsForm';
import { BottomSheet, BottomSheetRef } from 'components/bottom-sheet';
import ConsentsAfterSocialForm from './ConsentsAfterSocialForm';
import { AuthenticationCode } from './authentication-code';
import { PasswordSet } from './password-set';
import { TimeoutStep } from './timeout';

interface AuthenticationProps {
  onSetPassword: (data: IResetPasswordUpdateVariables) => void;
  generateEmailVerificationCode: (data: IGenerateEmailVerificationCodeVariables) => void;
  verifyEmailVerificationCode: (data: IVerifyEmailVerificationCodeVariables) => void;
  onSocialLogin: (vendor: SocialVendor) => void;
  onSignInSignUpWithEmail: (data: ISignInSignUpWithEmailVariables) => void;
  onLoginWithEmailAndPassword: (data: ILoginWithEmailAndPasswordVariables) => void;
  activeModal: AuthenticationModalType;
  onSetActiveModal: (nextType: AuthenticationModalType) => void;
  alreadyExistsEmail?: string;
  resetPasswordUpdateResponse: ApolloQueryResult<MutationResponseByType[MutationType.ResetPasswordUpdate]>;
  verifyEmailVerificationCodeData: ApolloQueryResult<MutationResponseByType[MutationType.VerifyEmailVerificationCode]>;
  pendingAction: PendingAction;
  isLoginFailed: boolean;
  email: string;
  setEmail: (email: string) => void;
  authModalError: boolean;
  loginEmailPasswordResponse?: ApolloQueryResult<MutationResponseByType[MutationType.LoginWithEmailAndPassword]>;
  signInSignUpLoading?: ApolloQueryResult<MutationResponseByType[MutationType.SignInSignUpWithEmail]>;
  isUser?: boolean;
}

export const Authentication: React.FC<AuthenticationProps> = ({
  onSetPassword,
  generateEmailVerificationCode,
  verifyEmailVerificationCode,
  verifyEmailVerificationCodeData,
  resetPasswordUpdateResponse,
  onSocialLogin,
  onSignInSignUpWithEmail,
  onLoginWithEmailAndPassword,
  activeModal = AuthenticationModalType.EnterPassword,
  onSetActiveModal,
  alreadyExistsEmail,
  pendingAction,
  isLoginFailed,
  setEmail,
  email,
  authModalError,
  signInSignUpLoading,
  loginEmailPasswordResponse,
  isUser,
}) => {
  const [ password, setPassword ] = useState<string>('');
  const [ dirty, setDirty ] = useState(new Set<AuthenticationField>([]));
  const currentBreakpoint = useScreenBreakpoint();
  const isMobile = currentBreakpoint < 3;
  const { t } = useLocale();

  const sheetRef = useRef<BottomSheetRef>(null);

  const isUserExistForm = [
    AuthenticationModalType.FacebookAlreadyExists,
    AuthenticationModalType.GoogleAlreadyExists,
    AuthenticationModalType.NativeAlreadyExists,
  ].includes(activeModal);

  useEffect(() => {
    if (isUserExistForm && alreadyExistsEmail) {
      setEmail(alreadyExistsEmail);
    }
  }, [ alreadyExistsEmail, activeModal ]);

  const handleResetForm = () => {
    setEmail('');
    setDirty(new Set([]));
    setPassword('');
  };

  const onLogin = () => {
    onLoginWithEmailAndPassword({ email: email.toLowerCase(), password });
  };

  const handleSignInSignUpSubmit = () => {
    if (email && isEmailValid) {
      onSignInSignUpWithEmail({ email: email.toLowerCase() });
    }
    else {
      handleSetDirty('email');
    }
  };

  const handleSignInSignUpWithConsentsSubmit = (consents?: IConsent[]) => {
    onSignInSignUpWithEmail({ email: email.toLowerCase(), consents });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value);
  const handleSetDirty = (field: AuthenticationField) => setDirty(prev => new Set([ ...prev, field ]));

  const handleCloseModal = () => {
    onSetActiveModal(null);
    handleResetForm();
  };


  const isEmailValid = email && isEmailValidHelper(email);

  const signInAuthType = () => {
    onSetActiveModal(AuthenticationModalType.SignIn);
  };

  const pendingType = pendingAction && pendingAction.type ? pendingAction.type : null;

  switch (activeModal) {
    case AuthenticationModalType.FacebookAlreadyExists:
    case AuthenticationModalType.GoogleAlreadyExists:
    case AuthenticationModalType.NativeAlreadyExists: {
      const loginType: LoginType = AuthenticationType[activeModal];
      const handleAuth = (type: LoginType) => {
        if (type === LoginType.Email) {
          onLogin();
        }
        else {
          onSocialLogin(type);
        }
      };

      return (
        <Modal
          isOpen
          onClose={handleCloseModal}
          buttons={(
            <AuthenticationTypeSwitchWrapper>
              <AuthenticationTypeSwitch onMouseDown={(e) => e.preventDefault()} onClick={signInAuthType}>
                <SmallText data-auto="log-different-account" >{t('authentication.differentAccount')}</SmallText>
              </AuthenticationTypeSwitch>
            </AuthenticationTypeSwitchWrapper>
          )}
        >
          <UserExistsLoginForm
            type={loginType}
            modalType={activeModal}
            email={alreadyExistsEmail}
            handleSetEmail={setEmail}
            onSetDirty={handleSetDirty}
            password={password}
            isLoginFailed={isLoginFailed || authModalError}
            onPasswordChange={handlePasswordChange}
            onSubmit={handleAuth}
            dirtyFields={dirty}
            onToggleResetPassword={() => onSetActiveModal(AuthenticationModalType.ResetPassword)}
          />
        </Modal>
      );
    }
    case AuthenticationModalType.AfterSocialConsents: {
      return (
        <ConsentsAfterSocialForm
          handleCloseModal={handleCloseModal}
        />
      );
    }
    case AuthenticationModalType.AuthTrapPreStep:
    case AuthenticationModalType.AuthTrap:
    case AuthenticationModalType.SignIn:
    case AuthenticationModalType.SignUp:
    case AuthenticationModalType.Consents: {
      const title = t('authentication.authenticationHeader');
      const termsTitle = t('authentication.authenticationEmailSubmitLabel');
      const infoText = t('authentication.authenticationInfo', { type: pendingType });
      const isConsentsModal = activeModal === AuthenticationModalType.Consents;

      if ((
        activeModal === AuthenticationModalType.AuthTrap
        || activeModal === AuthenticationModalType.AuthTrapPreStep
      ) && isMobile) {
        return (
          <AuthTrap
            isPreStep={activeModal === AuthenticationModalType.AuthTrapPreStep}
            onClosePreStep={() => onSetActiveModal(AuthenticationModalType.AuthTrap)}
          >
            <LogoWrapper>
              <MadlanLogoIcon />
            </LogoWrapper>
            <AuthenticationInfoHeader weight="bold">
              {t('authentication.authenticationInfo.header')}
            </AuthenticationInfoHeader>
            <AuthSocialsButtons
              onSocialLogin={onSocialLogin}
            />
            <AuthenticationEmailForm
              onSetDirty={handleSetDirty}
              email={email}
              submitText={t('authentication.authenticationEmailSubmitLabel')}
              isEmailValid={isEmailValid}
              dirtyFields={dirty}
              smallWidthSubmitButton
              onEmailChange={handleEmailChange}
              onSubmit={handleSignInSignUpSubmit}
              isLoading={signInSignUpLoading ? signInSignUpLoading.loading : false}
            />
          </AuthTrap>
        );
      }

      const modalStyleProps = isMobile
        ? {}
        : {
          titleStyle: { margin: `${theme.spacing(1.5)} 0`, fontSize: typographyFontSizeCfg.text, fontWeight: 500 },
          headerStyle: {
            borderBottom: `1px solid ${theme.colors.neutrals.grey8}`,
            marginLeft: `-${theme.spacing(isConsentsModal ? 10 : 14)}`,
            marginRight: `-${theme.spacing(isConsentsModal ? 10 : 14)}`,
          },
          contentWrapperStyle: {
            padding: `0 ${theme.spacing(isConsentsModal ? 10 : 14)} ${theme.spacing(isConsentsModal ? 10 : 7)} ${theme.spacing(isConsentsModal ? 10 : 14)}`,
            maxWidth: isConsentsModal ? 502 : 544,
          },
          closeButtonStyle: { top: 4, left: 24 },
        };

      if (isConsentsModal && !isMobile) {
        return (
          <Modal isOpen onClose={handleCloseModal} title={termsTitle} {...modalStyleProps}>
            <ConsentsForm handleSubmit={handleSignInSignUpWithConsentsSubmit} />
          </Modal>
        );
      }

      return (
        <>
          <Modal isOpen onClose={handleCloseModal} title={title} {...modalStyleProps}>
            <div style={{ maxWidth: '330px', margin: '0 auto' }}>
              <LogoWrapper>
                <MadlanLogoIcon height={40} width={44} />
              </LogoWrapper>
              <AuthenticationInfoHeader weight="bold">
                {t('authentication.authenticationInfo.header')}
              </AuthenticationInfoHeader>
              <AuthenticationInfo>{infoText}</AuthenticationInfo>

              <AuthenticationEmailForm
                onSetDirty={handleSetDirty}
                email={email}
                submitText={t('authentication.authenticationEmailSubmitLabel')}
                isEmailValid={isEmailValid}
                dirtyFields={dirty}
                onEmailChange={handleEmailChange}
                onSubmit={handleSignInSignUpSubmit}
                isLoading={signInSignUpLoading ? signInSignUpLoading.loading : false}
              />
              <AuthSocialsButtons
                onSocialLogin={onSocialLogin}
                withSuggestionBefore
              />
            </div>
          </Modal>

          {isConsentsModal && isMobile ? (
            <BottomSheet ref={sheetRef} onCollapse={signInAuthType} coverModal>
              <ConsentsForm handleSubmit={handleSignInSignUpWithConsentsSubmit} />
            </BottomSheet>
          ) : null}
        </>
      );
    }

    case AuthenticationModalType.ResetPassword:
    case AuthenticationModalType.NeedPasswordReset:
    case AuthenticationModalType.UserEmailVerification: {
      const title = t('authentication.authenticationHeader');
      const modalStyleProps = isMobile
        ? {}
        : {
          titleStyle: { margin: `${theme.spacing(1.5)} 0`, fontSize: typographyFontSizeCfg.text, fontWeight: 500 },
          headerStyle: {
            borderBottom: `1px solid ${theme.colors.neutrals.grey8}`,
            marginLeft: `-${theme.spacing(14)}`,
            marginRight: `-${theme.spacing(14)}`,
          },
          contentWrapperStyle: {
            padding: `0 ${theme.spacing(14)} ${theme.spacing(7)} ${theme.spacing(14)}`,
            maxWidth: 544,
          },
          closeButtonStyle: { top: 4, left: 24 },
        };
      const fullHeightStyles = { contentWrapperStyle: { height: 'calc(100vh - 48px)', padding: theme.spacing(4) } };
      return (
        <Modal isOpen onClose={handleCloseModal} title={title} {...(isMobile ? fullHeightStyles : modalStyleProps)}>
          <AuthenticationCode
            email={email}
            setActiveModal={onSetActiveModal}
            generateEmailVerificationCode={generateEmailVerificationCode}
            verifyEmailVerificationCode={verifyEmailVerificationCode}
            verifyEmailVerificationCodeData={verifyEmailVerificationCodeData}
            isUser={isUser}
          />
        </Modal>
      );
    }

    case AuthenticationModalType.VerificationTimeout: {
      const title = t('authentication.authenticationHeader');
      const modalStyleProps = isMobile
        ? {}
        : {
          titleStyle: { margin: `${theme.spacing(1.5)} 0`, fontSize: typographyFontSizeCfg.text, fontWeight: 500 },
          headerStyle: {
            borderBottom: `1px solid ${theme.colors.neutrals.grey8}`,
            marginLeft: `-${theme.spacing(14)}`,
            marginRight: `-${theme.spacing(14)}`,
          },
          contentWrapperStyle: {
            padding: `0 ${theme.spacing(14)} ${theme.spacing(7)} ${theme.spacing(14)}`,
            maxWidth: 544,
          },
          closeButtonStyle: { top: 4, left: 24 },
        };
      const fullHeightStyles = { contentWrapperStyle: { height: 'calc(100vh - 48px)', padding: theme.spacing(4) } };
      return (
        <Modal isOpen onClose={handleCloseModal} title={title} {...(isMobile ? fullHeightStyles : modalStyleProps)}>
          <TimeoutStep setActiveModal={onSetActiveModal} />
        </Modal>
      );
    }

    case AuthenticationModalType.EnterPassword: {
      const title = t('authentication.authenticationHeader');
      const modalStyleProps = isMobile
        ? {}
        : {
          titleStyle: { margin: `${theme.spacing(1.5)} 0`, fontSize: typographyFontSizeCfg.text, fontWeight: 500 },
          headerStyle: {
            borderBottom: `1px solid ${theme.colors.neutrals.grey8}`,
            marginLeft: `-${theme.spacing(14)}`,
            marginRight: `-${theme.spacing(14)}`,
          },
          contentWrapperStyle: {
            padding: `0 ${theme.spacing(14)} ${theme.spacing(7)} ${theme.spacing(14)}`,
            minWidth: 544,
          },
          closeButtonStyle: { top: 4, left: 24 },
        };
      return (
        <Modal
          isOpen
          onClose={handleCloseModal}
          title={title}
          {...modalStyleProps}
        >
          <EnterPasswordForm
            email={email}
            setActiveModal={onSetActiveModal}
            onToggleResetPassword={() => {
              onSetActiveModal(AuthenticationModalType.UserEmailVerification);
              generateEmailVerificationCode({ email });
            }}
            password={password}
            onPasswordChange={handlePasswordChange}
            isLoginFailed={isLoginFailed || authModalError}
            dirtyFields={dirty}
            onSubmit={onLogin}
            onSetDirty={handleSetDirty}
            isLoading={loginEmailPasswordResponse ? loginEmailPasswordResponse.loading : false}
          />
        </Modal>
      );
    }

    case AuthenticationModalType.AgentSetPassword:
    case AuthenticationModalType.SetPassword: {
      const title = t('authentication.authenticationHeader');
      const modalStyleProps = isMobile
        ? {}
        : {
          titleStyle: { margin: `${theme.spacing(1.5)} 0`, fontSize: typographyFontSizeCfg.text, fontWeight: 500 },
          headerStyle: {
            borderBottom: `1px solid ${theme.colors.neutrals.grey8}`,
            marginLeft: `-${theme.spacing(14)}`,
            marginRight: `-${theme.spacing(14)}`,
          },
          contentWrapperStyle: {
            padding: `0 ${theme.spacing(14)} ${theme.spacing(7)} ${theme.spacing(14)}`,
            minWidth: 544,
          },
          closeButtonStyle: { top: 4, left: 24 },
        };
      const fullHeightStyles = { contentWrapperStyle: { height: 'calc(100vh - 48px)', padding: theme.spacing(4) } };

      return (
        <Modal isOpen onClose={handleCloseModal} title={title} {...(isMobile ? fullHeightStyles : modalStyleProps)}>
          <PasswordSet
            verifyEmailVerificationCodeData={verifyEmailVerificationCodeData}
            isLoading={resetPasswordUpdateResponse ? resetPasswordUpdateResponse.loading : false}
            onSubmit={onSetPassword}
            email={email}
          />
        </Modal>
      );
    }
  }
  return null;
};
