import { createStandardAction } from 'typesafe-actions';
import {
  SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_MODAL,
  SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_POPOVER,
  SET_PRIVATE_BULLETIN_HAS_ALREADY_BEEN_PROMOTED_MODAL,
  MANUAL_PROMOTE_PRIVATE_BULLETIN,
} from './types';
import { BulletinPoiType, DealType } from 'utils/entities';

export const setPrivateBulletinSuccesfullyPromotedModal = createStandardAction(SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_MODAL)<{ isOpen: boolean }>();
export const setPrivateBulletinSuccesfullyPromotedPopover = createStandardAction(SET_PRIVATE_BULLETIN_SUCCESFULLY_PROMOTED_POPOVER)<{ isOpen: boolean }>();
export const setPrivateBulletinHasAlreadyBeenPromotedModal = createStandardAction(SET_PRIVATE_BULLETIN_HAS_ALREADY_BEEN_PROMOTED_MODAL)<{ isOpen: boolean }>();

export enum PromotePrivateBulletinViewSource {
  ManageBulletinsPage = 'manage_bulletins_page',
  BulletinPage = 'bulletin_page',
}
export interface PromotePrivateBulletinPayload {
  bulletinId: string;
  bulletinType: BulletinPoiType;
  dealType: DealType;
  viewSource: PromotePrivateBulletinViewSource;
  isTimerStillRunning: boolean;
}
export const promotePrivateBulletin = createStandardAction(MANUAL_PROMOTE_PRIVATE_BULLETIN)<PromotePrivateBulletinPayload>();
