import { ISaveSearch, SavedAddress, ToggleAddressMeta } from 'store/sagas/apiService/types';
import { IPoiIdentifier, SaveSearchMeta, LoginType } from 'utils/entities';

export type AuthenticationField = 'email' | 'password';

export type SocialVendor = 'facebook' | 'google' | 'apple';

export enum AuthenticationModalType {
  SignIn = 'signIn',
  SignUp = 'signUp',
  Consents = 'consents',
  AfterSocialConsents = 'afterSocialConsents',
  ResetPassword = 'resetPassword',
  SetPassword = 'setPassword',
  EnterPassword = 'enterPassword',
  NeedPasswordReset = 'needPasswordReset',
  GoogleAlreadyExists = 'googleAlreadyExists',
  FacebookAlreadyExists = 'facebookAlreadyExists',
  NativeAlreadyExists = 'nativeAlreadyExists',
  DuplicatedEmail = 'duplicatedEmail',
  AgentSetPassword = 'AgentSetPassword',
  AuthTrap = 'AuthTrap',
  AuthTrapPreStep = 'AuthTrapPreStep',
  AgentEmailVerification = 'AgentEmailVerification',
  AgentCompanyNotFound = 'AgentCompanyNotFound',
  UserEmailVerification = 'UserEmailVerification',
  VerificationTimeout = 'verificationTimeout',
}

export enum PendingActionType {
  SaveAddress = 'saveAddress',
  SaveBulletin = 'saveBulletin',
  SaveSearch = 'saveSearch',
}

interface AuthenticationModalPendingAction {
  type: PendingActionType;
}

export interface IPendingSaveAddress extends SavedAddress, AuthenticationModalPendingAction {
  type: PendingActionType.SaveAddress;
  meta?: ToggleAddressMeta;
}
export interface IPendingSaveBulletin extends AuthenticationModalPendingAction {
  type: PendingActionType.SaveBulletin;
  poi: IPoiIdentifier;
}

export interface IPendingSaveSearch extends AuthenticationModalPendingAction, Partial<ISaveSearch> {
  type: PendingActionType.SaveSearch;
  meta?: SaveSearchMeta;
}

export type PendingAction = IPendingSaveAddress | IPendingSaveBulletin | IPendingSaveSearch;
export enum AuthSource {
  Google = 'google',
  Facebook = 'facebook',
  EmailAndPassword = 'emailAndPassword',
}
export interface AuthenticationModalPayload {
  type: AuthenticationModalType;
  pendingAction?: PendingAction;
  authSource?: AuthSource;
  authSourceVariables?: {
    idToken?: string;
    fbUserId?: string;
    googleClientId?: string;
    appleCode?: string;
    email?: string;
    password?: string;
    accessToken?: string;
    error?: boolean;
  };
}

export const AuthenticationType = {
  [AuthenticationModalType.GoogleAlreadyExists]: LoginType.Google,
  [AuthenticationModalType.FacebookAlreadyExists]: LoginType.Facebook,
  [AuthenticationModalType.NativeAlreadyExists]: LoginType.Email,
};

export const UserAlreadyExists = {
  'google-already-exists': AuthenticationModalType.GoogleAlreadyExists,
  'facebook-already-exists': AuthenticationModalType.FacebookAlreadyExists,
  'native-already-exists': AuthenticationModalType.NativeAlreadyExists,
};
