import {
  FooterLink,
  FooterSection,
  FooterSectionGetter,
  FooterSectionType,
  GeneralLink,
  ISocialLink,
  LinksByDealType,
  LinkType,
} from './types';
import { IFiltersState, PropertyType, Seller } from 'components/filters/types';
import { CompletionType, DealType, IDocItem, MarketplaceType } from 'utils/entities';
import mainConfig from 'config';
import { Route } from 'config/routes';
import { ITranslate } from 'locale';

export const buildNearbyNeighbourhoodsSection = (nearbyNeighbourhoods: IDocItem[]): FooterSection => ({
  type: FooterSectionType.CloseNeighbourhoods,
  links: nearbyNeighbourhoods.map(neighbourhood => ({
    id: neighbourhood.docId,
    type: LinkType.RouterLink,
    text: neighbourhood.text,
    params: {
      term: [ neighbourhood.docId ],
    },
    newTab: false,
  })),
});

export const GENERAL_LINKS: GeneralLink[] = [
  {
    type: 'careers',
    href: 'https://www.madlan.co.il/blog/careers?source=footer_link',
  },
  {
    type: 'terms',
    href: `${mainConfig.termsOfServiceUrl}?source=footer_bar_link`,
  },
];

export const socialLinks: ISocialLink[] = [
  {
    type: 'linkedin',
    url: 'https://www.linkedin.com/company/madlan',
  },
  {
    type: 'facebook',
    url: 'https://www.facebook.com/madlan.co.il/',
  },
  {
    type: 'instagram',
    url: 'https://www.instagram.com/madlan_il/',
  },
  {
    type: 'tiktok',
    url: 'https://www.tiktok.com/@madlan_il0',
  },
  {
    type: 'twitter',
    url: 'https://x.com/Madlan_il',
  },
  {
    type: 'podcast',
    url: 'https://www.madlan.co.il/blog/%d7%94%d7%93%d7%a8%d7%9a-%d7%9c%d7%91%d7%99%d7%aa-%d7%a4%d7%95%d7%93%d7%a7%d7%90%d7%a1%d7%98-%d7%97%d7%93%d7%a9-%d7%9e%d7%91%d7%99%d7%aa-%d7%9e%d7%93%d7%9c%d7%9f/',
  },
];

enum DocId {
  Acre = 'עכו-ישראל',
  Afula = 'עפולה-ישראל',
  Ashdod = 'אשדוד-ישראל',
  Ashkelon = 'אשקלון-ישראל',
  Arad = 'ערד-ישראל',
  AloneiHaBashan = 'אלוני-הבשן-ישראל',
  BatYam = 'בת-ים-ישראל',
  Beersheba = 'באר-שבע-ישראל',
  BneiYehuda = 'בני-יהודה-ישראל',
  CentralRegion = 'אזור-מרכז-ישראל',
  Dimona = 'דימונה-ישראל',
  Eilat = 'אילת-ישראל',
  EmekIzraelRegion = 'אזור-עמק-יזרעאל-ישראל',
  GalilElyonRegion = 'אזור-גליל-עליון-ישראל',
  GalilTahtonRegion = 'אזור-גליל-תחתון-ישראל',
  GalilMaharaviRegion = 'אזור-גליל-מערבי-ישראל',
  Gedera = 'גדרה-ישראל',
  Givataim = 'גבעתיים-ישראל',
  GushDanRegion = 'אזור-גוש-דן-ישראל',
  Haifa = 'חיפה-ישראל',
  HatzorHaGlilit = 'חצור-הגלילית-ישראל',
  Herzliya = 'הרצליה-ישראל',
  HodHaSharon = 'הוד-השרון-ישראל',
  Holon = 'חולון-ישראל',
  Israel = 'ישראל',
  Jerusalem = 'ירושלים-ישראל',
  Kanaf = 'כנף-ישראל',
  Katzrin = 'קצרין-ישראל',
  KfarYona = 'כפר-יונה-ישראל',
  KfarSaba = 'כפר-סבא-ישראל',
  KfarVradim = 'כפר-ורדים-ישראל',
  KfarTabur = 'כפר-תבור-ישראל',
  KubuzimInCentralRegion = 'אזור-קיבוצים-במרכז-ישראל',
  KibbutzimInNorthRegion = 'אזור-קיבוצים-בצפון-ישראל',
  KibbutzimInSouthRegion = 'אזור-קיבוצים-בדרום-ישראל',
  KiryatAta = 'קרית-אתא-ישראל',
  KiryatBialik = 'קרית-ביאליק-ישראל',
  KiryatShmona = 'קרית-שמונה-ישראל',
  KiryatTivon = 'קרית-טבעון-ישראל',
  KritGat = 'קרית-גת-ישראל',
  KiryatMotzkin = 'קרית-מוצקין-ישראל',
  Nahariya = 'נהריה-ישראל',
  NegevRegion = 'אזור-נגב-ישראל',
  MaaleGamla = 'מעלה-גמלא-ישראל',
  MaalotTarshiha = 'מעלות-תרשיחא-ישראל',
  MigdalHaEmek = 'מגדל-העמק-ישראל',
  Safed = 'צפת-ישראל',
  Sderot = 'שדרות-ישראל',
  ShaflaRegion = 'אזור-שפלה-ישראל',
  SharonRegion = 'אזור-שרון-ישראל',
  Shlomi = 'שלומי-ישראל',
  SouthRegion = 'אזור-דרום-ישראל',
  Netanya = 'נתניה-ישראל',
  Netivot = 'נתיבות-ישראל',
  NesZiyona = 'נס-ציונה-ישראל',
  NorthRegion = 'אזור-צפון-ישראל',
  NofHaGalil = 'נוף-הגליל-ישראל',
  Metula = 'מטולה-ישראל',
  Raanana = 'רעננה-ישראל',
  RamatHasharon = 'רמת-השרון-ישראל',
  RamatGan = 'רמת-גן-ישראל',
  RamatHaGolanRegion = 'אזור-רמת-הגולן-ישראל',
  RamatYishai = 'רמת-ישי-ישראל',
  Ramot = 'רמות-ישראל',
  Rehovot = 'רחובות-ישראל',
  RishonLezion = 'ראשון-לציון-ישראל',
  RoshHaayin = 'ראש-העין-ישראל',
  RoshPina = 'ראש-פינה-ישראל',
  TelAviv = 'תל-אביב-יפו-ישראל',
  Tveriya = 'טבריה-ישראל',
  Ilaniya = 'אילניה-ישראל',
  Odem = 'אודם-ישראל',
  Ofakim = 'אופקים-ישראל',
  Yavne = 'יבנה-ישראל',
  Yavneel = 'יבנאל-ישראל',
  Yeruham = 'ירוחם-ישראל',
  YokneamIllit = 'יוקנעם-עילית-ישראל',
  VillagesInCentralRegion = 'אזור-מושבים-במרכז-ישראל',
  VillagesInShaflaRegion = 'אזור-מושבים-בשפלה-ישראל',
  VillagesInSharonRegion = 'אזור-מושבים-בשרון-ישראל',
  VillagesInSouthRegion = 'אזור-מושבים-בדרום-ישראל',
}

const docIdToName: Record<DocId, string> = {
  [DocId.Acre]: 'עכו',
  [DocId.Afula]: 'עפולה',
  [DocId.Ashdod]: 'אשדוד',
  [DocId.Arad]: 'ערד',
  [DocId.Ashkelon]: 'אשקלון',
  [DocId.AloneiHaBashan]: 'אלוני הבשן',
  [DocId.BatYam]: 'בת ים',
  [DocId.Beersheba]: 'באר שבע',
  [DocId.BneiYehuda]: 'בני יהודה',
  [DocId.CentralRegion]: 'מרכז',
  [DocId.Dimona]: 'דימונה',
  [DocId.Eilat]: 'אילת',
  [DocId.EmekIzraelRegion]: 'עמק יזרעאל',
  [DocId.GalilElyonRegion]: 'גליל עליון',
  [DocId.GalilTahtonRegion]: 'גליל תחתון',
  [DocId.GalilMaharaviRegion]: 'גליל מערבי',
  [DocId.Gedera]: 'גדרה',
  [DocId.Givataim]: 'גבעתיים',
  [DocId.GushDanRegion]: 'גוש דן',
  [DocId.Haifa]: 'חיפה',
  [DocId.HatzorHaGlilit]: 'חצור הגלילית',
  [DocId.Herzliya]: 'הרצליה',
  [DocId.HodHaSharon]: 'הוד השרון',
  [DocId.Holon]: 'חולון',
  [DocId.Israel]: 'כל הארץ',
  [DocId.Jerusalem]: 'ירושלים',
  [DocId.Kanaf]: 'כנף',
  [DocId.Katzrin]: 'קצרין',
  [DocId.KfarYona]: 'כפר יונה',
  [DocId.KfarSaba]: 'כפר סבא',
  [DocId.KfarVradim]: 'כפר ורדים',
  [DocId.KfarTabur]: 'כפר תבור',
  [DocId.KubuzimInCentralRegion]: 'קיבוצים במרכז',
  [DocId.KibbutzimInNorthRegion]: 'קיבוצים בצפון',
  [DocId.KibbutzimInSouthRegion]: 'קיבוצים בדרום',
  [DocId.KiryatAta]: 'קרית אתא',
  [DocId.KiryatBialik]: 'קרית ביאליק',
  [DocId.KiryatMotzkin]: 'קרית מוצקין',
  [DocId.KiryatShmona]: 'קרית שמונה',
  [DocId.KiryatTivon]: 'קרית טבעון',
  [DocId.KritGat]: 'קרית גת',
  [DocId.Nahariya]: 'נהריה',
  [DocId.NegevRegion]: 'נגב',
  [DocId.Netivot]: 'נתיבות',
  [DocId.MaaleGamla]: 'מעלה גמלא',
  [DocId.MaalotTarshiha]: 'מעלות תרשיחא',
  [DocId.Metula]: 'מטולה',
  [DocId.MigdalHaEmek]: 'מגדל העמק',
  [DocId.Safed]: 'צפת',
  [DocId.Sderot]: 'שדרות',
  [DocId.ShaflaRegion]: 'שפלה',
  [DocId.SharonRegion]: 'שרון',
  [DocId.Shlomi]: 'שלומי',
  [DocId.SouthRegion]: 'דרום',
  [DocId.Netanya]: 'נתניה',
  [DocId.NesZiyona]: 'נס ציונה',
  [DocId.NorthRegion]: 'צפון',
  [DocId.NofHaGalil]: 'נוף הגליל',
  [DocId.Raanana]: 'רעננה',
  [DocId.RamatHasharon]: 'רמת השרון',
  [DocId.RamatGan]: 'רמת גן',
  [DocId.RamatHaGolanRegion]: 'רמת הגולן',
  [DocId.RamatYishai]: 'רמת ישי',
  [DocId.Ramot]: 'רמות',
  [DocId.Rehovot]: 'רחובות',
  [DocId.RishonLezion]: 'ראשון לציון',
  [DocId.RoshHaayin]: 'ראש העין',
  [DocId.RoshPina]: 'ראש פינה',
  [DocId.Odem]: 'אודם',
  [DocId.Ofakim]: 'אופקים',
  [DocId.TelAviv]: 'תל אביב יפו',
  [DocId.Tveriya]: 'טבריה',
  [DocId.Ilaniya]: 'אילניה',
  [DocId.Yavne]: 'יבנה',
  [DocId.Yavneel]: 'יבנאל',
  [DocId.Yeruham]: 'ירוחם',
  [DocId.YokneamIllit]: 'יוקנעם עילית',
  [DocId.VillagesInCentralRegion]: 'מושבים במרכז',
  [DocId.VillagesInShaflaRegion]: 'מושבים בשפלה',
  [DocId.VillagesInSharonRegion]: 'מושבים בשרון',
  [DocId.VillagesInSouthRegion]: 'מושבים בדרום',
};

const createListingsLink = (t: ITranslate, options: { dealType: DealType, docId: DocId }): FooterLink => {
  const { dealType, docId } = options;
  const translateTerm = dealType === DealType.Rent ? 'footer.rentSection.linkText' : 'footer.buySection.linkText';
  return {
    id: docId,
    type: LinkType.RouterLink,
    text: t(translateTerm, { type: FooterSectionType.SearchByLocation, location: docIdToName[docId] }),
    params: {
      term: [ docId ],
      filters: {},
    },
    newTab: true,
  };
};

const createProjectsLink = (options: { docId: DocId }): FooterLink => {
  const { docId } = options;
  return {
    id: `projects-${docId}`,
    type: LinkType.RouterLink,
    text: `פרויקטים חדשים ב${docIdToName[docId]}`,
    params: {
      term: [ docId ],
      filters: {
        seller: [ Seller.Developer ],
      },
    },
    newTab: true,
  };
};

const createKibbutzimLink = (options: { docId: DocId }): FooterLink => {
  const { docId } = options;
  return {
    id: `kibbutzim-${docId}`,
    type: LinkType.RouterLink,
    text: docIdToName[docId],
    params: {
      term: [ docId ],
    },
    newTab: true,
  };
};

const createPrivateHousesLink = (options: {
  docId: DocId;
  withPrivatePropertyTypes?: boolean;
}): FooterLink => {
  const { docId, withPrivatePropertyTypes } = options;
  const filters = withPrivatePropertyTypes
    ? { propertyTypes: [ PropertyType.PrivateHouse, PropertyType.Cottage, PropertyType.TwoFamilyDwelling ] }
    : undefined;
  return {
    id: `houses-${docId}`,
    type: LinkType.RouterLink,
    text: withPrivatePropertyTypes ? `בתים פרטיים ב${docIdToName[docId]}` : `בתים ב${docIdToName[docId]}`,
    params: {
      term: [ docId ],
      filters,
    },
    newTab: true,
  };
};

const createMadadPageLink = (options: { docId: DocId }): FooterLink => {
  const { docId } = options;
  const isRegion = docId.includes('אזור');
  return {
    id: `madad-${docId}`,
    type: LinkType.RouterLink,
    text: `מתווכים ב${docIdToName[docId]}`,
    routeName: isRegion ? Route.MadadPage : Route.MadadSearchPage,
    params: isRegion ? {} : { id: docId },
    newTab: true,
  };
};

const createCommercialLink = (options: {docId: string, dealType: DealType, text: string, propertyTypes: PropertyType[]}): FooterLink => {
  const { docId = 'ישראל', dealType, text, propertyTypes } = options;

  return {
    id: docId,
    type: LinkType.RouterLink,
    text,
    routeName: Route.SearchCommercial,
    params: {
      dealType,
      term: [ docId ],
      filters: { propertyTypes },
    },
    newTab: false,
  };
};

const composeILSectionFn = (dealType: DealType): FooterSectionGetter => {
  const translateTerm = dealType === DealType.Rent ? 'footer.rentSection.linkText' : 'footer.buySection.linkText';
  return options => {
    const { t, docId, completionType, accurateAddress, isProjectsView, marketplace } = options;
    const isCountryDocId = completionType === CompletionType.Country;
    const isCityDocId = completionType === CompletionType.City;
    const isCommercial = marketplace === MarketplaceType.Commercial;

    if (isCommercial) {
      return [
        {
          type: FooterSectionType.CommercialRealEstateForSale,
          links: [
            createCommercialLink({ docId, dealType: DealType.Buy, text: `משרדים למכירה ב${accurateAddress}`, propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId, dealType: DealType.Buy, text: `חנויות למכירה ב${accurateAddress}`, propertyTypes: [ PropertyType.Shop ] }),
            createCommercialLink({ docId, dealType: DealType.Buy, text: `קליניקות למכירה ב${accurateAddress}`, propertyTypes: [ PropertyType.Clinic ] }),
            createCommercialLink({ docId, dealType: DealType.Buy, text: `אולמות למכירה ב${accurateAddress}`, propertyTypes: [ PropertyType.Hall ] }),
            createCommercialLink({ docId, dealType: DealType.Buy, text: `מחסנים למכירה ב${accurateAddress}`, propertyTypes: [ PropertyType.Storeroom ] }),
          ],
        },
        {
          type: FooterSectionType.CommercialRealEstateForRent,
          links: [
            createCommercialLink({ docId, dealType: DealType.Rent, text: `משרדים להשכרה ב${accurateAddress}`, propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId, dealType: DealType.Rent, text: `חנויות להשכרה ב${accurateAddress}`, propertyTypes: [ PropertyType.Shop ] }),
            createCommercialLink({ docId, dealType: DealType.Rent, text: `קליניקות להשכרה ב${accurateAddress}`, propertyTypes: [ PropertyType.Clinic ] }),
            createCommercialLink({ docId, dealType: DealType.Rent, text: `אולמות להשכרה ב${accurateAddress}`, propertyTypes: [ PropertyType.Hall ] }),
            createCommercialLink({ docId, dealType: DealType.Rent, text: `מחסנים להשכרה ב${accurateAddress}`, propertyTypes: [ PropertyType.Storeroom ] }),
          ],
        },
        {
          type: FooterSectionType.CityFooter,
          links: [
            createCommercialLink({ docId: 'תל-אביב-יפו-ישראל', dealType: DealType.Rent, text: 'משרדים להשכרה בתל אביב', propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId: 'רמת-גן-ישראל', dealType: DealType.Rent, text: 'משרדים להשכרה ברמת גן', propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId: 'הרצליה-ישראל', dealType: DealType.Rent, text: 'משרדים להשכרה בהרצליה', propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId: 'רעננה-ישראל', dealType: DealType.Rent, text: 'משרדים להשכרה ברעננה', propertyTypes: [ PropertyType.Office ] }),
            createCommercialLink({ docId: 'חולון-ישראל', dealType: DealType.Rent, text: 'משרדים להשכרה בחולון', propertyTypes: [ PropertyType.Office ] }),
          ],
        },
      ];
    }

    const madadLink: FooterLink = {
      id: `${docId}-agent`,
      type: LinkType.RouterLink,
      text: isCityDocId ? `מתווכים ב${accurateAddress}` : 'מתווכים בישראל',
      routeName: isCityDocId ? Route.MadadSearchPage : Route.MadadPage,
      params: isCityDocId ? { id: docId } : {},
      newTab: true,
    };

    const schoolsLink: FooterLink = {
      id: `${docId}-schools`,
      type: LinkType.ALink,
      text: `בתי ספר ב${accurateAddress}`,
      href: `/education/${accurateAddress}?source=footer_menu`,
      newTab: true,
    };

    const developersLink: FooterLink = {
      id: `${docId}-developer`,
      type: LinkType.ALink,
      text: `יזמי נדל"ן ב${accurateAddress}`,
      href: `/developers${isCountryDocId ? `/${accurateAddress}` : ''}?source=footer_menu`,
      newTab: true,
    };

    if (isCountryDocId) {
      const property = t('footer.section.property', { apartmentType: isProjectsView ? PropertyType.Project : PropertyType.Flat });

      const createFilters = (filters?: Partial<IFiltersState>): Partial<IFiltersState> => {
        const initalFilters: Partial<IFiltersState> = isProjectsView ? { seller: [ Seller.Developer ] } : {};
        return { ...initalFilters, ...filters };
      };

      return [
        {
          type: isProjectsView ? FooterSectionType.ProjectsInPopularCities : FooterSectionType.ApartmentsInPopularCities,
          links: [
            {
              id: 'apartments-in-tel-aviv',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.TelAviv],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.TelAviv ],
                filters: createFilters(),
              },
              newTab: true,
            },
            {
              id: 'apartments-in-ramat-gan',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.RamatGan],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.RamatGan ],
                filters: createFilters(),
              },
              newTab: true,
            },
            {
              id: 'apartments-in-raanana',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.Raanana],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.Raanana ],
                filters: createFilters(),
              },
              newTab: true,
            },
            {
              id: 'apartments-in-haifa',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.Haifa],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.Haifa ],
                filters: createFilters(),
              },
              newTab: true,
            },
            {
              id: 'apartments-in-jerusalem',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.Jerusalem],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.Jerusalem ],
                filters: createFilters(),
              },
              newTab: true,
            },
            {
              id: 'apartments-in-beersheba',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.Beersheba],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.Beersheba ],
                filters: createFilters(),
              },
              newTab: true,
            },
          ],
        },
        {
          type: isProjectsView ? FooterSectionType.ProjectsByArea : FooterSectionType.ApartmentsByArea,
          links: [
            {
              id: 'apartments-in-centeral-area',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.CentralRegion],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.CentralRegion ],
                filters: createFilters(),
              },
              newTab: false,
            },
            {
              id: 'apartments-in-gush-dan-area',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.GushDanRegion],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.GushDanRegion ],
                filters: createFilters(),
              },
              newTab: false,
            },
            {
              id: 'apartments-in-north-area',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.NorthRegion],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.NorthRegion ],
                filters: createFilters(),
              },
              newTab: false,
            },
            {
              id: 'apartments-in-shfela-area',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.ShaflaRegion],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.ShaflaRegion ],
                filters: createFilters(),
              },
              newTab: false,
            },
            {
              id: 'apartments-in-south-area',
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress: docIdToName[DocId.SouthRegion],
                property,
              }),
              params: {
                dealType,
                term: [ DocId.SouthRegion ],
                filters: createFilters(),
              },
              newTab: false,
            },
          ],
        },
        {
          type: FooterSectionType.ApartmentType,
          links: [
            {
              id: `${docId}-${PropertyType.GardenApartment}`,
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress,
                property: t('footer.section.property', { apartmentType: PropertyType.GardenApartment }),
              }),
              params: {
                term: [ docId ],
                filters: createFilters({
                  propertyTypes: [ PropertyType.GardenApartment ],
                }),
              },
              newTab: false,
            },
            {
              id: `${docId}-${PropertyType.PrivateHouse}`,
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress,
                property: t('footer.section.property', { apartmentType: PropertyType.PrivateHouse }),
              }),
              params: {
                term: [ docId ],
                filters: createFilters({
                  propertyTypes: [ PropertyType.PrivateHouse, PropertyType.Cottage ],
                }),
              },
              newTab: false,
            },
            {
              id: `${docId}-${PropertyType.Penthouse}`,
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress,
                property: t('footer.section.property', { apartmentType: PropertyType.Penthouse }),
              }),
              params: {
                term: [ docId ],
                filters: createFilters({
                  propertyTypes: [ PropertyType.Penthouse ],
                }),
              },
              newTab: false,
            },
            {
              id: `${docId}-${PropertyType.Duplex}`,
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress,
                property: t('footer.section.property', { apartmentType: PropertyType.Duplex }),
              }),
              params: {
                term: [ docId ],
                filters: createFilters({
                  propertyTypes: [ PropertyType.Duplex ],
                }),
              },
              newTab: false,
            },
            {
              id: `${docId}-${PropertyType.TwoFamilyDwelling}`,
              type: LinkType.RouterLink,
              text: t(translateTerm, {
                type: FooterSectionType.ApartmentType,
                accurateAddress,
                property: t('footer.section.property', { apartmentType: PropertyType.TwoFamilyDwelling }),
              }),
              params: {
                term: [ docId ],
                filters: createFilters({
                  propertyTypes: [ PropertyType.TwoFamilyDwelling ],
                }),
              },
              newTab: false,
            },
          ],
        },
        {
          type: FooterSectionType.ImportantInfo,
          links: [
            schoolsLink,
            developersLink,
            madadLink,
          ],
        },
      ];
    }

    switch (docId) {
      case DocId.GushDanRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Givataim }),
              createListingsLink(t, { dealType, docId: DocId.TelAviv }),
              createListingsLink(t, { dealType, docId: DocId.Holon }),
              createListingsLink(t, { dealType, docId: DocId.BatYam }),
              createListingsLink(t, { dealType, docId: DocId.Herzliya }),
              createListingsLink(t, { dealType, docId: DocId.RamatHasharon }),
              createListingsLink(t, { dealType, docId: DocId.GushDanRegion }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.GushDanRegion }),
              createProjectsLink({ docId: DocId.TelAviv }),
              createProjectsLink({ docId: DocId.Holon }),
              createProjectsLink({ docId: DocId.BatYam }),
              createProjectsLink({ docId: DocId.Herzliya }),
              createProjectsLink({ docId: DocId.RamatHasharon }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.SharonRegion }),
              createProjectsLink({ docId: DocId.Israel }),
              createPrivateHousesLink({ docId: DocId.VillagesInCentralRegion }),
              createPrivateHousesLink({
                docId: DocId.GushDanRegion,
                withPrivatePropertyTypes: true,
              }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.CentralRegion }),
              createMadadPageLink({ docId: DocId.GushDanRegion }),
            ],
          },
        ];
      }
      case DocId.CentralRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Raanana }),
              createListingsLink(t, { dealType, docId: DocId.Herzliya }),
              createListingsLink(t, { dealType, docId: DocId.Netanya }),
              createListingsLink(t, { dealType, docId: DocId.BatYam }),
              createListingsLink(t, { dealType, docId: DocId.RoshHaayin }),
              createListingsLink(t, { dealType, docId: DocId.GushDanRegion }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Raanana }),
              createProjectsLink({ docId: DocId.Herzliya }),
              createProjectsLink({ docId: DocId.Netanya }),
              createProjectsLink({ docId: DocId.BatYam }),
              createProjectsLink({ docId: DocId.RoshHaayin }),
              createProjectsLink({ docId: DocId.GushDanRegion }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.ShaflaRegion }),
              createProjectsLink({ docId: DocId.SharonRegion }),
              createProjectsLink({ docId: DocId.Israel }),
              createKibbutzimLink({ docId: DocId.KubuzimInCentralRegion }),
              createPrivateHousesLink({ docId: DocId.VillagesInCentralRegion }),
              createMadadPageLink({ docId: DocId.CentralRegion }),
            ],
          },
        ];
      }
      case DocId.SharonRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.RamatHasharon }),
              createListingsLink(t, { dealType, docId: DocId.HodHaSharon }),
              createListingsLink(t, { dealType, docId: DocId.Raanana }),
              createListingsLink(t, { dealType, docId: DocId.Netanya }),
              createListingsLink(t, { dealType, docId: DocId.KfarSaba }),
              createListingsLink(t, { dealType, docId: DocId.KfarYona }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.RamatHasharon }),
              createProjectsLink({ docId: DocId.HodHaSharon }),
              createProjectsLink({ docId: DocId.Raanana }),
              createProjectsLink({ docId: DocId.Netanya }),
              createProjectsLink({ docId: DocId.KfarSaba }),
              createProjectsLink({ docId: DocId.KfarYona }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.CentralRegion }),
              createProjectsLink({ docId: DocId.Israel }),
              createPrivateHousesLink({ docId: DocId.VillagesInSharonRegion }),
              createPrivateHousesLink({
                docId: DocId.SharonRegion,
                withPrivatePropertyTypes: true,
              }),
              createMadadPageLink({ docId: DocId.SharonRegion }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.GushDanRegion }),
            ],
          },
        ];
      }
      case DocId.ShaflaRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Rehovot }),
              createListingsLink(t, { dealType, docId: DocId.Yavne }),
              createListingsLink(t, { dealType, docId: DocId.NesZiyona }),
              createListingsLink(t, { dealType, docId: DocId.RishonLezion }),
              createListingsLink(t, { dealType, docId: DocId.Gedera }),
              createListingsLink(t, { dealType, docId: DocId.Ashdod }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Rehovot }),
              createProjectsLink({ docId: DocId.Yavne }),
              createProjectsLink({ docId: DocId.NesZiyona }),
              createProjectsLink({ docId: DocId.RishonLezion }),
              createProjectsLink({ docId: DocId.Gedera }),
              createProjectsLink({ docId: DocId.Ashdod }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.ShaflaRegion }),
              createProjectsLink({ docId: DocId.Israel }),
              createKibbutzimLink({ docId: DocId.KibbutzimInSouthRegion }),
              createPrivateHousesLink({ docId: DocId.VillagesInShaflaRegion }),
              createPrivateHousesLink({
                docId: DocId.ShaflaRegion,
                withPrivatePropertyTypes: true,
              }),
              createMadadPageLink({ docId: DocId.ShaflaRegion }),
            ],
          },
        ];
      }
      case DocId.NorthRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Haifa }),
              createListingsLink(t, { dealType, docId: DocId.KiryatAta }),
              createListingsLink(t, { dealType, docId: DocId.KiryatBialik }),
              createListingsLink(t, { dealType, docId: DocId.KiryatMotzkin }),
              createListingsLink(t, { dealType, docId: DocId.Nahariya }),
              createListingsLink(t, { dealType, docId: DocId.Acre }),
              createListingsLink(t, { dealType, docId: DocId.MigdalHaEmek }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Haifa }),
              createProjectsLink({ docId: DocId.KiryatAta }),
              createProjectsLink({ docId: DocId.KiryatBialik }),
              createProjectsLink({ docId: DocId.KiryatMotzkin }),
              createProjectsLink({ docId: DocId.Nahariya }),
              createProjectsLink({ docId: DocId.Acre }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.NorthRegion }),
              createProjectsLink({ docId: DocId.EmekIzraelRegion }),
              createProjectsLink({ docId: DocId.Afula }),
              createProjectsLink({ docId: DocId.MigdalHaEmek }),
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createMadadPageLink({ docId: DocId.NorthRegion }),
            ],
          },
        ];
      }
      case DocId.SouthRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Ashdod }),
              createListingsLink(t, { dealType, docId: DocId.Beersheba }),
              createListingsLink(t, { dealType, docId: DocId.Ashkelon }),
              createListingsLink(t, { dealType, docId: DocId.Eilat }),
              createListingsLink(t, { dealType, docId: DocId.Netivot }),
              createListingsLink(t, { dealType, docId: DocId.KritGat }),
              createListingsLink(t, { dealType, docId: DocId.Sderot }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Ashdod }),
              createProjectsLink({ docId: DocId.Beersheba }),
              createProjectsLink({ docId: DocId.Ashkelon }),
              createProjectsLink({ docId: DocId.Eilat }),
              createProjectsLink({ docId: DocId.Netivot }),
              createProjectsLink({ docId: DocId.KritGat }),
              createProjectsLink({ docId: DocId.Dimona }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.ShaflaRegion }),
              createProjectsLink({ docId: DocId.Israel }),
              createPrivateHousesLink({ docId: DocId.VillagesInSouthRegion }),
              createPrivateHousesLink({
                docId: DocId.SouthRegion,
                withPrivatePropertyTypes: true,
              }),
              createKibbutzimLink({ docId: DocId.KibbutzimInSouthRegion }),
              createMadadPageLink({ docId: DocId.SouthRegion }),
            ],
          },
        ];
      }
      case DocId.NegevRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Dimona }),
              createListingsLink(t, { dealType, docId: DocId.Ofakim }),
              createListingsLink(t, { dealType, docId: DocId.Beersheba }),
              createListingsLink(t, { dealType, docId: DocId.Yeruham }),
              createListingsLink(t, { dealType, docId: DocId.Arad }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Dimona }),
              createProjectsLink({ docId: DocId.Ofakim }),
              createProjectsLink({ docId: DocId.Beersheba }),
              createProjectsLink({ docId: DocId.Yeruham }),
              createProjectsLink({ docId: DocId.Arad }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.SouthRegion }),
              createKibbutzimLink({ docId: DocId.KibbutzimInSouthRegion }),
              createPrivateHousesLink({
                docId: DocId.NegevRegion,
                withPrivatePropertyTypes: true,
              }),
              createPrivateHousesLink({ docId: DocId.VillagesInShaflaRegion }),
              createMadadPageLink({ docId: DocId.NegevRegion }),
            ],
          },
        ];
      }
      case DocId.EmekIzraelRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Afula }),
              createListingsLink(t, { dealType, docId: DocId.YokneamIllit }),
              createListingsLink(t, { dealType, docId: DocId.KiryatTivon }),
              createListingsLink(t, { dealType, docId: DocId.RamatYishai }),
              createListingsLink(t, { dealType, docId: DocId.MigdalHaEmek }),
              createListingsLink(t, { dealType, docId: DocId.Haifa }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Afula }),
              createProjectsLink({ docId: DocId.YokneamIllit }),
              createProjectsLink({ docId: DocId.KiryatTivon }),
              createProjectsLink({ docId: DocId.RamatYishai }),
              createProjectsLink({ docId: DocId.MigdalHaEmek }),
              createProjectsLink({ docId: DocId.Haifa }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.NorthRegion }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.NorthRegion }),
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createPrivateHousesLink({
                docId: DocId.EmekIzraelRegion,
                withPrivatePropertyTypes: true,
              }),
              createMadadPageLink({ docId: DocId.EmekIzraelRegion }),
            ],
          },
        ];
      }
      case DocId.GalilElyonRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Safed }),
              createListingsLink(t, { dealType, docId: DocId.MaalotTarshiha }),
              createListingsLink(t, { dealType, docId: DocId.KiryatShmona }),
              createListingsLink(t, { dealType, docId: DocId.HatzorHaGlilit }),
              createListingsLink(t, { dealType, docId: DocId.KfarVradim }),
              createListingsLink(t, { dealType, docId: DocId.RoshPina }),
              createListingsLink(t, { dealType, docId: DocId.Metula }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Safed }),
              createProjectsLink({ docId: DocId.KiryatShmona }),
              createProjectsLink({ docId: DocId.HatzorHaGlilit }),
              createProjectsLink({ docId: DocId.RoshPina }),
              createProjectsLink({ docId: DocId.Haifa }),
              createProjectsLink({ docId: DocId.Nahariya }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createPrivateHousesLink({
                docId: DocId.GalilTahtonRegion,
                withPrivatePropertyTypes: true,
              }),
              createPrivateHousesLink({
                docId: DocId.GalilMaharaviRegion,
                withPrivatePropertyTypes: true,
              }),
              createPrivateHousesLink({
                docId: DocId.NorthRegion,
                withPrivatePropertyTypes: true,
              }),
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createPrivateHousesLink({
                docId: DocId.GalilElyonRegion,
                withPrivatePropertyTypes: true,
              }),
              createMadadPageLink({ docId: DocId.GalilElyonRegion }),
            ],
          },
        ];
      }
      case DocId.GalilTahtonRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Tveriya }),
              createListingsLink(t, { dealType, docId: DocId.NofHaGalil }),
              createListingsLink(t, { dealType, docId: DocId.Yavneel }),
              createListingsLink(t, { dealType, docId: DocId.KfarTabur }),
              createListingsLink(t, { dealType, docId: DocId.Ilaniya }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Tveriya }),
              createProjectsLink({ docId: DocId.NofHaGalil }),
              createProjectsLink({ docId: DocId.GalilElyonRegion }),
              createProjectsLink({ docId: DocId.GalilTahtonRegion }),
              createProjectsLink({ docId: DocId.NorthRegion }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createPrivateHousesLink({
                docId: DocId.GalilTahtonRegion,
                withPrivatePropertyTypes: true,
              }),
              createPrivateHousesLink({
                docId: DocId.GalilElyonRegion,
                withPrivatePropertyTypes: true,
              }),
              createPrivateHousesLink({
                docId: DocId.RamatHaGolanRegion,
                withPrivatePropertyTypes: true,
              }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.RamatHaGolanRegion }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.GalilMaharaviRegion }),
              createMadadPageLink({ docId: DocId.GalilTahtonRegion }),
            ],
          },
        ];
      }
      case DocId.GalilMaharaviRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Nahariya }),
              createListingsLink(t, { dealType, docId: DocId.Shlomi }),
              createListingsLink(t, { dealType, docId: DocId.KfarVradim }),
              createListingsLink(t, { dealType, docId: DocId.Acre }),
              createListingsLink(t, { dealType, docId: DocId.Haifa }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.Nahariya }),
              createProjectsLink({ docId: DocId.Shlomi }),
              createProjectsLink({ docId: DocId.NorthRegion }),
              createProjectsLink({ docId: DocId.Acre }),
              createProjectsLink({ docId: DocId.Haifa }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createProjectsLink({ docId: DocId.GalilMaharaviRegion }),
              createPrivateHousesLink({
                docId: DocId.GalilMaharaviRegion,
                withPrivatePropertyTypes: true,
              }),
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.GalilElyonRegion }),
              createMadadPageLink({ docId: DocId.GalilMaharaviRegion }),
            ],
          },
        ];
      }
      case DocId.RamatHaGolanRegion: {
        return [
          {
            type: FooterSectionType.SearchByLocation,
            links: [
              createListingsLink(t, { dealType, docId: DocId.Katzrin }),
              createListingsLink(t, { dealType, docId: DocId.MaaleGamla }),
              createListingsLink(t, { dealType, docId: DocId.Ramot }),
              createListingsLink(t, { dealType, docId: DocId.Kanaf }),
              createListingsLink(t, { dealType, docId: DocId.BneiYehuda }),
              createListingsLink(t, { dealType, docId: DocId.Odem }),
              createListingsLink(t, { dealType, docId: DocId.AloneiHaBashan }),
            ],
          },
          {
            type: FooterSectionType.NewHomes,
            links: [
              createProjectsLink({ docId: DocId.RamatHaGolanRegion }),
              createProjectsLink({ docId: DocId.GalilElyonRegion }),
              createProjectsLink({ docId: DocId.GalilTahtonRegion }),
              createProjectsLink({ docId: DocId.GalilMaharaviRegion }),
              createProjectsLink({ docId: DocId.NorthRegion }),
              createProjectsLink({ docId: DocId.EmekIzraelRegion }),
            ],
          },
          {
            type: FooterSectionType.AdditionalSearches,
            links: [
              createPrivateHousesLink({
                docId: DocId.Nahariya,
                withPrivatePropertyTypes: true,
              }),
              createKibbutzimLink({ docId: DocId.KibbutzimInNorthRegion }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.KiryatShmona }),
              createListingsLink(t, { dealType: DealType.Buy, docId: DocId.Tveriya }),
              createMadadPageLink({ docId: DocId.RamatHaGolanRegion }),
            ],
          },
        ];
      }
    }

    return [
      {
        type: FooterSectionType.ImportantInfo,
        links: [
          {
            id: `${docId}-for-sale`,
            type: LinkType.RouterLink,
            text: t('footer.apartmentForLink', { dealType, accurateAddress }),
            params: {
              dealType: dealType === DealType.Rent ? DealType.Buy : DealType.Rent,
              term: [ docId ],
              filters: {},
            },
            newTab: true,
          },
          schoolsLink,
          developersLink,
          madadLink,
        ],
      },
      {
        type: FooterSectionType.Beds,
        links: [
          {
            id: `${docId}-2-bedroom`,
            type: LinkType.RouterLink,
            text: t(translateTerm, { type: FooterSectionType.Beds, count: 2, accurateAddress }),
            params: {
              term: [ docId ],
              filters: {
                roomsRange: [ 2, null ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-3-bedroom`,
            type: LinkType.RouterLink,
            text: t(translateTerm, { type: FooterSectionType.Beds, count: 3, accurateAddress }),
            params: {
              term: [ docId ],
              filters: {
                roomsRange: [ 3, null ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-4-bedroom`,
            type: LinkType.RouterLink,
            text: t(translateTerm, { type: FooterSectionType.Beds, count: 4, accurateAddress }),
            params: {
              term: [ docId ],
              filters: {
                roomsRange: [ 4, null ],
              },
            },
            newTab: false,
          },
        ],
      },
      {
        type: FooterSectionType.ApartmentType,
        links: [
          {
            id: `${docId}-${PropertyType.GardenApartment}`,
            type: LinkType.RouterLink,
            text: t(translateTerm, {
              type: FooterSectionType.ApartmentType,
              accurateAddress,
              property: t('footer.section.property', { apartmentType: PropertyType.GardenApartment }),
            }),
            params: {
              term: [ docId ],
              filters: {
                propertyTypes: [ PropertyType.GardenApartment ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-${PropertyType.Penthouse}`,
            type: LinkType.RouterLink,
            text: t(translateTerm, {
              type: FooterSectionType.ApartmentType,
              accurateAddress,
              property: t('footer.section.property', { apartmentType: PropertyType.Penthouse }),
            }),
            params: {
              term: [ docId ],
              filters: {
                propertyTypes: [ PropertyType.Penthouse ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-${PropertyType.Rooftop}`,
            type: LinkType.RouterLink,
            text: t(translateTerm, {
              type: FooterSectionType.ApartmentType,
              accurateAddress,
              property: t('footer.section.property', { apartmentType: PropertyType.Rooftop }),
            }),
            params: {
              term: [ docId ],
              filters: {
                propertyTypes: [ PropertyType.Rooftop ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-${PropertyType.Cottage}`,
            type: LinkType.RouterLink,
            text: t(translateTerm, {
              type: FooterSectionType.ApartmentType,
              accurateAddress,
              property: t('footer.section.property', { apartmentType: PropertyType.Cottage }),
            }),
            params: {
              term: [ docId ],
              filters: {
                propertyTypes: [ PropertyType.Cottage ],
              },
            },
            newTab: false,
          },
          {
            id: `${docId}-${PropertyType.TwoFamilyDwelling}`,
            type: LinkType.RouterLink,
            text: t(translateTerm, {
              type: FooterSectionType.ApartmentType,
              accurateAddress,
              property: t('footer.section.property', { apartmentType: PropertyType.TwoFamilyDwelling }),
            }),
            params: {
              term: [ docId ],
              filters: {
                propertyTypes: [ PropertyType.TwoFamilyDwelling ],
              },
            },
            newTab: false,
          },
        ],
      },
    ];
  };
};

export const config: LinksByDealType = {
  [DealType.Rent]: composeILSectionFn(DealType.Rent),
  [DealType.Buy]: composeILSectionFn(DealType.Buy),
};
