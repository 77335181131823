import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';
import { BasicButton } from 'ds/components/button/BasicButton';
import { CleanButton } from 'ds/components/button';


export const Root = styled.div<{ desktopHeight?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme, desktopHeight }) => theme.media.showAt({ from: 3 })`
    width: 402px;
    height: ${desktopHeight || 602}px;
  `}
`;

export const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 30px;
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    animation: rotating 2s linear infinite;
  }
`;

export const CloseWrapper = styled.div`
  ${({ theme }) => `
    position: absolute;
    right: -${theme.spacing(1)};
    top: -${theme.spacing(1)};
    cursor: pointer;
  `}
`;

export const WarningWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(0.5)};
  `}
`;

export const CancelButtonWrapper = styled(SmallText)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1.5)};
    color: ${theme.colors.neutrals.grey3};
    cursor: pointer;
    width: 100%;
  `}
`;

export const CancelButton = styled(CleanButton)`
  &:hover {
    background: none;
  }
`;

const TABS_HEADER_HEIGHT = 48;

export const ProfileWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.white};
  position: relative;
`;

export const ProfileTabHeader = styled.div<{ isActive: boolean }>`
  height: ${TABS_HEADER_HEIGHT}px;
  display: inline-flex;
  align-items: center;
  transition: all .2s ease;
  cursor: pointer;
  ${({ theme, isActive }) => isActive ? `
    color: ${theme.colors.neutrals.grey1};
    border-bottom: 1px solid ${theme.colors.neutrals.grey1};
  ` : `
    color: ${theme.colors.neutrals.grey3};
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`;

export const ProfileTabsHeaderWrapper = styled.div`
  height: ${TABS_HEADER_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    & > div {
      margin-${theme.isRTL ? 'left' : 'right'}: 28px;

      :last-of-type {
        margin-${theme.isRTL ? 'left' : 'right'}: 0;
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    display: none;
  `}
`;

export const ProfileTabsContentWrapper = styled.div<{isFullWidth?: boolean}>`
  width: ${({ isFullWidth }) => isFullWidth ? '100%' : '338px'};
  max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '90%'};
  margin: ${({ theme }) => theme.spacing(8)} auto;

  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    margin: ${theme.spacing(3)} 0;
    padding: 0 ${theme.spacing(3)};
    width: auto;
    max-width: 100%;
  `}
`;

export const ProfileTabContent = styled.div`

`;

export const ProfileButtonWrapper = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    position: fixed;
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.colors.neutrals.white};
    padding: ${theme.spacing(3)} ${theme.spacing(3)};
    box-shadow: ${theme.shadow.level2}
  `}
`;

export const DeleteButton = styled(BasicButton)`
  ${({ theme }) => `
  position: relative;
    &, &:hover {
      background-color: ${theme.colors.neutrals.white};
      color: ${theme.colors.alert.red};
      border: 1px solid ${theme.colors.alert.red};
    }
  `}
`;

export const ProfileDataWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
`;

export const ProfilePhotoWrapper = styled.div`
  margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 16px;
  height: 56px;
  img {
    object-fit: cover;
    width: 56px;
    height: 56px;
    display: block;
    border-radius: ${({ theme }) => theme.borderRadius.round};
  }
`;

export const ProfileInfoWrapper = styled.div`

`;

export const ProfileName = styled.div`
  h3 {
    line-height: 30px;
  }
`;

export const ProfileEmail = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
  display: flex;
  align-items: center;
  svg {
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 8px;
  }
`;

export const ProfileFormWrapper = styled.div`
  > div {
    margin-bottom: 16px;
  }
`;

export const PasswordWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PasswordField = styled.div`

`;

export const PasswordButtonWrapper = styled.div`

`;

export const CommutePreferenceTitle = styled.div`
  margin-bottom: 32px;
`;

export const DeleteWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const DeleteLink = styled(Text)`
  color: ${({ theme }) => theme.colors.alert.red};
  text-decoration: underline;
  cursor: pointer;
`;

export const Title = styled.div(({ theme }) => ({
  fontSize: 21,
  lineHeight: '32px',
  fontWeight: 700,
  color: theme.colors.neutrals.grey1,
  marginBottom: theme.spacing(1),
}));

export const Subtitle = styled(Text)(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  maxWidth: 317,
}));

export const RadioWrapper = styled.div(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flext-start',
  width: '100%',
}));

export const TextSection = styled.div(() => ({
  width: '100%',
}));

export const TextInfo = styled(SmallText)(({ theme }) => ({
  color: theme.colors.neutrals.grey3,
  margin: `auto 0 ${theme.spacing(1)}`,
}));

export const TrashWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  svg: {
    path: {
      stroke: theme.colors.alert.red,
    },
  },
}));

