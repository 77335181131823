import { flow } from 'lodash';
import {
  searchListSelector,
  makeNullSafeDomainGetter,
  createNullSafeDomainMetaVariablesSelector,
  makeByIdsDictionarySelector,
  createIsLoadingSelector,
  projectsSearchResultListSelector,
} from './common';
import { createSelector } from 'reselect';
import { NetworkStatus } from 'apollo-client';


export const searchListDataSelector = makeNullSafeDomainGetter(searchListSelector, 'searchPoiV2', true);
export const searchListMetaVariablesSelector = createNullSafeDomainMetaVariablesSelector(searchListSelector);

export const projectsCarouselSearchListDataSelector = makeNullSafeDomainGetter(projectsSearchResultListSelector, 'searchPoiV2');

export const searchListItemsPerPageLimit = flow(searchListMetaVariablesSelector, variables => variables && variables.limit);
export const isSearchListLoadingSelector = createIsLoadingSelector(searchListSelector);
export const searchListNetworkStatusSelector = flow(searchListSelector, ({ networkStatus }) => networkStatus);
export const searchListTotalSelector = flow(searchListDataSelector, (data) => data ? data.total : null);
export const searchListTotalNearbySelector = flow(searchListDataSelector, (data) => data ? data.totalNearby : null);
export const searchLastInGeometryIdSelector = flow(searchListDataSelector, (data) => data ? data.lastInGeometryId : null);
export const searchListCursorSelector = flow(searchListDataSelector, (data) => data ? data.cursor : null);

const searchListPoisSelector = flow(searchListDataSelector, (data) => data ? data.poi : null);

export const searchListPoiListSelector = createSelector([
  searchListPoisSelector,
  searchListNetworkStatusSelector,
], (pois, networkStatus) => {
  if (!pois || networkStatus === NetworkStatus.setVariables) {
    return [];
  }
  return pois;
});

export const projectsCarouselSearchPoiListSelector = createSelector([
  projectsCarouselSearchListDataSelector,
], (data) => {
  if (data && data.poi && data.poi.length) {
    return data.poi;
  }
  return [];
});


export const searchListPoisDictionarySelector = makeByIdsDictionarySelector(searchListPoisSelector);
export const projectsCarouselSearchPoisListDictionarySelector = makeByIdsDictionarySelector(projectsCarouselSearchPoiListSelector);
