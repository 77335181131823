import React from 'react';
import { TooltipProps } from './types';
import PowerTooltip from './power-tooltip';
import Tooltip from './hold-tap-hover-tooltip';

interface TooltipSwitchProps extends TooltipProps {
  usePowerTooltip?: boolean;
}

const TooltipSwitch: React.FC<TooltipSwitchProps> = ({
  usePowerTooltip,
  ...props
}) => {

  if (usePowerTooltip) {
    return <PowerTooltip {...props} />;
  }

  return <Tooltip {...props} />;
};

TooltipSwitch.defaultProps = {
  usePowerTooltip: false,
};

export default TooltipSwitch;
