import { BiEvent } from 'analytics';
import { getContext } from 'redux-saga/effects';
import { TrackJS } from 'trackjs';

const ALLOWED_EVENTS = new Set([
  'user_contact_me_call_submit',
  'property_lead_call_submit',
  'developer_lead_call_submit',
  'mortgage_lead_call_submit',
]);

export function *callSubmitPreProcessor({ name, category, payload }: BiEvent) {
  const { rtbData, ...restPayload } = payload;
  const event = {
    name,
    category,
    payload: { ...restPayload },
  };

  const { sendRtbEvent } = yield getContext('analytics');

  if (!ALLOWED_EVENTS.has(name) || !sendRtbEvent) return event;

  try {
    const maskyooNumber = rtbData && rtbData.phone;

    if (maskyooNumber) {
      sendRtbEvent(maskyooNumber.replace('-', '').replace('+972', '0'));
    }
    else {
      TrackJS.track([
        'RtbPostProcessor failed to process event',
        `event=${name}`,
        `category=${category}`,
        `payload=${JSON.stringify(payload)}`,
        `error=Missing phone number`,
      ]);
      /* tslint:disable:no-console */
      console.error('rtb missing phone number', maskyooNumber);
    }
  }
  catch (error) {
    TrackJS.track([
      'RtbPostProcessor failed to process event',
      `event=${name}`,
      `category=${category}`,
      `payload=${JSON.stringify(payload)}`,
      `error=${JSON.stringify(error)}`,
    ]);
  }

  return event;
}
