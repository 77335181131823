import { RootAction } from 'store/state';
import { call, put, delay, takeEvery, fork, select, take, race } from 'redux-saga/effects';
import { LocalStorage } from 'utils/localStorage';
import { setMobileDiscoveryPopupStatus } from 'store/state/app/actions';
import { SET_MOBILE_DISCOVERY_DISABLED, SET_MOBILE_DISCOVERY_ENABLED, SET_MOBILE_DISCOVERY_POPUP_STATUS } from 'store/state/app/types';
import { getQAAutomationParams } from 'utils/qa';
import { getRouteParams, noPopupsSelector, routeNameSelector } from 'store/state/selectors/router';
import { TRANSITION_START, TRANSITION_SUCCESS } from 'store/state/router/types';
import { Route } from 'config/routes';
import { addEventListenerFn } from 'helpers/pan-responder';
import { TrafficSegmentationRules, trafficSegmentationShowSelector, TrafficSegmentationPlace } from 'store/state/selectors/traffic';


const KEY = 'MOBILE_DISCOVERY';
const TWENTY_SECONDS = 20 * 1000;

function getMobileDiscoveryMeta(): { closeCount: number } {
  return LocalStorage.get(KEY) || { closeCount: 0 };
}

function* updateCloseCount() {
  const discoveryMeta = getMobileDiscoveryMeta();
  LocalStorage.set(KEY, { closeCount: discoveryMeta.closeCount + 1 });
}

const mobileDiscoveryClosePopupPattern = (action: RootAction) => (
  action.type === SET_MOBILE_DISCOVERY_POPUP_STATUS && !action.payload
);

function* closeWatcher() {
  yield takeEvery(mobileDiscoveryClosePopupPattern, updateCloseCount);
}

const MODAL_SKIP_ROUTES = new Set([
  Route.UploadBulletin,
]);

function waitForBlur() {
  return new Promise<boolean>(resolve => {
    const unsubscribe = addEventListenerFn(document.documentElement, 'blur', () => {
      resolve(true);
      unsubscribe.remove();
    }, true);
  });
}

function isActiveElementInputOrTextarea(el: Element) {
  return el && (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA');
}

let wasOpened: boolean;
function* openWithDelay() {
  while (true) {
    const [ timer, routeChanged, blur, mobileDiscoveryDisabled ] = yield race([
      delay(TWENTY_SECONDS),
      take(TRANSITION_START),
      call(waitForBlur),
      take(SET_MOBILE_DISCOVERY_DISABLED),
    ]);

    if (timer) {
      if (isActiveElementInputOrTextarea(document.activeElement)) {
        continue;
      }
      else {
        yield put(setMobileDiscoveryPopupStatus(true));
        wasOpened = true;

        yield delay(TWENTY_SECONDS);
        yield put(setMobileDiscoveryPopupStatus(false));
        break;
      }
    }
    else if (blur) {
      continue;
    }
    else if (routeChanged) {
      break;
    }
    else if (mobileDiscoveryDisabled) {
      yield take(SET_MOBILE_DISCOVERY_ENABLED);
      continue;
    }
  }
}

function* maybeOpen() {
  const discoveryMeta = getMobileDiscoveryMeta();
  const { popups } = getQAAutomationParams();
  let shouldOpen: boolean;

  while (true) {
    if (wasOpened) break;

    yield take(TRANSITION_SUCCESS);

    const name = yield select(routeNameSelector);
    const { utm_campaign, mp_remarketing } = yield select(getRouteParams);
    const noPopups: boolean = yield select(noPopupsSelector);
    const { [TrafficSegmentationPlace.All]: allowShowPopup }: TrafficSegmentationRules = yield select(trafficSegmentationShowSelector);

    const skipForAutomation = popups === 'disable';

    shouldOpen = discoveryMeta.closeCount < 3 && !noPopups;
    const routeWithUTMCampaign = (utm_campaign && utm_campaign.toLowerCase().startsWith('remarketing_')) || (mp_remarketing && mp_remarketing === 'true');
    const skipedByRoute = MODAL_SKIP_ROUTES.has(name) || routeWithUTMCampaign;

    if (navigator && ('getInstalledRelatedApps' in navigator)) {
      try {
        const relatedApps: unknown[] = yield (navigator as any).getInstalledRelatedApps();
        if (relatedApps.length) {
          shouldOpen = false;
        }
      }
      catch (error) {
        shouldOpen = false;
      }
    }

    if (!shouldOpen || skipForAutomation || !allowShowPopup) break;
    if (skipedByRoute) continue;

    yield call(openWithDelay);
  }
}

export function* mobileDiscoveryPopupWatcher() {
  yield fork(closeWatcher);
  yield fork(maybeOpen);
}
