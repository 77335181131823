import { State } from 'store/state';
import { flow } from 'lodash';
import { DealType, ISearchPoiVariables, IReference } from 'utils/entities';
import { SortDirection, SortField } from 'components/listing-sort/types';
import { Seller } from 'components/filters/types';
import { createSelector } from 'reselect';


export const appStateSelector = (state: State) => state.app;
export const commutePopupStatusSelector = flow(appStateSelector, ({ commutePopup }) => commutePopup);

export const galleryPopupSelector = flow(appStateSelector, ({ galleryPopup }) => galleryPopup);
export const loginSuccessStateSelector = flow(appStateSelector, ({ showLoginSuccessNotify }) => showLoginSuccessNotify);
export const galleryPopupSlideIndexSelector = flow(galleryPopupSelector, ({ slideIndex }) => slideIndex);
export const galleryPopupIsOpenSelector = flow(galleryPopupSelector, ({ isOpen }) => isOpen);
export const authModalStatusSelector = flow(appStateSelector, ({ authModal }) => authModal.type);
export const authModalAuthSourceSelector = flow(appStateSelector, ({ authModal }) => authModal.authSource);
export const authModalAuthSourceVariablesSelector = flow(appStateSelector, ({ authModal }) => authModal.authSourceVariables);
export const mobileDiscoveryPopupStatusSelector = flow(appStateSelector, ({ mobileDiscoveryPopupOpenStatus }) => mobileDiscoveryPopupOpenStatus);
export const authModalPendingActionSelector = flow(appStateSelector, ({ authModal }) => authModal.pendingAction || null);
export const contactModalPoiIdSelector = flow(appStateSelector, ({ contactModalPoiId }) => contactModalPoiId);
export const intentModalPoiIdSelector = flow(appStateSelector, ({ intentModalPoiId }) => intentModalPoiId);
export const welcomeModalPoiIdSelector = flow(appStateSelector, ({ welcomeModalPoiId }) => welcomeModalPoiId);
export const contactAgentSuccessPoiIdSelector = flow(appStateSelector, ({ contactAgentSuccessPoiId }) => contactAgentSuccessPoiId);
export const isNeedsPopupOpenSelector = flow(appStateSelector, ({ isNeedsPopupOpen }) => isNeedsPopupOpen);
export const isUserMobileProfileOpenSelector = flow(appStateSelector, ({ isUserMobileProfileOpen }) => isUserMobileProfileOpen);
export const isUserMobileProfileReturnableSelector = flow(appStateSelector, ({ isUserMobileProfileReturnable }) => isUserMobileProfileReturnable);
export const isSidebarOpenSelector = flow(appStateSelector, ({ isSidebarOpen }) => isSidebarOpen);
export const isMobileSearchMapViewActiveSelector = flow(appStateSelector, ({ isMobileSearchMapViewActive }) => isMobileSearchMapViewActive);
export const visibleSectionSelector = flow(appStateSelector, ({ sectionInView }) => sectionInView);
export const feedbackPopupModalOpenSelector = flow(appStateSelector, ({ isFeedbackPopupModalOpen }) => isFeedbackPopupModalOpen );
export const isFeedbackPopupModalOpenSelector = flow(feedbackPopupModalOpenSelector, ({ isOpen }) => isOpen );
export const feedbackPopupModalOpenInsightSelector = flow(feedbackPopupModalOpenSelector, ({ insightId }) => insightId );
export const isReportListingPopupModalOpenSelector = flow(appStateSelector, ({ isReportListingPopupModalOpen }) => isReportListingPopupModalOpen);
export const isAddressFeedbackPopupModalOpenSelector = flow(appStateSelector, ({ isAddressFeedbackPopupModalOpen }) => isAddressFeedbackPopupModalOpen);
export const isUploadBulletinReportModalOpenSelector = flow(appStateSelector, ({ isUploadBulletinReportModalOpen }) => isUploadBulletinReportModalOpen);
export const isAfterUploadBulletinModalOpenSelector = flow(appStateSelector, ({ isAfterUploadBulletinModalOpen }) => isAfterUploadBulletinModalOpen);
export const fixedMultiPreviewSelector = flow(appStateSelector, (state) => state.fixedMultiPreview);
export const shareModalTypeSelector = flow(appStateSelector, ({ shareModalOpenType }) => shareModalOpenType);
export const madadContactModalStateSelector = flow(appStateSelector, ({ madadContactModal }) => madadContactModal);
export const isMadadContactModalStateOpenSelector = flow(madadContactModalStateSelector, state => Boolean(state));
export const tabuDictionarySelector = flow(appStateSelector, ({ tabuDictionary }) => tabuDictionary);
export const authEmailSelector = flow(appStateSelector, ({ authEmail }) => authEmail);
export const manageListingsErrorModalSelector = flow(appStateSelector, ({ manageListingsErrorModal }) => manageListingsErrorModal);
export const openStatusDeleteListingModalSelector = flow(appStateSelector, ({ deleteListingModalOpenStatus }) => deleteListingModalOpenStatus);
export const manageListingsFilterAgentUserIdSelector = flow(appStateSelector, ({ manageListingsFilterAgentUserId }) => manageListingsFilterAgentUserId);
export const developerFiltersSelector = flow(appStateSelector, ({ developerFilters }) => developerFilters);
export const isListingAdditionalBannerClosedSelector = flow(appStateSelector, ({ isListingAdditionalBannerClosed }) => isListingAdditionalBannerClosed);
export const isSearchResultAdditionalBannerClosedSelector = flow(appStateSelector, ({ isSearchResultAdditionalBannerClosed }) => isSearchResultAdditionalBannerClosed);
export const isMobileMenuHiddenSelector = flow(appStateSelector, ({ isMobileMenuHidden }) => isMobileMenuHidden);
export const isMizrahiBankModalOpenSelector = flow(appStateSelector, ({ isMizrahiBankModalOpen }) => isMizrahiBankModalOpen);
export const isAgentOfficeContactPopupOpenSelector = flow(appStateSelector, ({ isAgentOfficeContactPopupOpen }) => isAgentOfficeContactPopupOpen);
export const isMortgageOfficeContactPopupOpenSelector = flow(appStateSelector, ({ isMortgageOfficeContactPopupOpen }) => isMortgageOfficeContactPopupOpen);
export const searchViewModeSelector = flow(appStateSelector, ({ searchViewMode }) => searchViewMode);
export const isLikedToastOpenSelector = flow(appStateSelector, ({ isLikedToastOpen }) => isLikedToastOpen);
export const isHeaderTabSubmenuVisibleSelector = flow(appStateSelector, ({ isHeaderTabSubmenuVisible }) => isHeaderTabSubmenuVisible);
export const pushNotificationsModalTypeSelector = flow(appStateSelector, (s => s.pushNotificationsModalType));
export const devicePushStatusSelector = flow(appStateSelector, (s => s.devicePushStatus));
export const listingEditedSelector = flow(appStateSelector, ({ listingEdited }) => listingEdited);
export const dealAffiliationPopupStateSelector = createSelector(appStateSelector, ({ dealAffiliationPopup }) => dealAffiliationPopup);
export const isNewUnitPageMapExpandedSelector = createSelector(appStateSelector, ({ isNewUnitPageMapExpanded }) => isNewUnitPageMapExpanded);
export const isListingsTableLoadingSelector = flow(appStateSelector, (s => s.isListingsTableLoading));
export const contactFormEventsSourceSelector = flow(appStateSelector, (s => s.contactFormEventsSource));

export const agentSupportModalSelector = createSelector(appStateSelector, appState => appState.agentSupportModal);
export const isAgentSupportModalOpenSelector = flow(agentSupportModalSelector, modalState => modalState.isOpen);
export const agentSupportModalVariantSelector = flow(agentSupportModalSelector, modalState => modalState.variant);

export const makeSearchVariablesSelector = (strictDocSelector: (state: State) => { docId: string; reference: IReference }) => flow(strictDocSelector, (document): ISearchPoiVariables => {
  const { reference, docId } = document;

  return {
    tileRanges: null,
    dealType: DealType.Buy,
    poiTypes: [ 'project' ],
    sort: [
      { field: SortField.Geometry, order: SortDirection.Asc, reference, docIds: [ docId ] },
    ],
    limit: 20,
    sellerType: [ Seller.Developer ],
    searchContext: 'information',
  };
});

