import React, { useState } from 'react';
import Modal from 'ds/components/modal';
import CrossIcon from 'assets/svg/close-modal.svg';
import WarningIcon from 'assets/svg/warning-icon.svg';
import { useLocale } from 'locale';
import * as Styled from './styled';
import { DeregisterReason, IReasonState } from './types';
import RadioGroup from 'ds/components/input/RadioGroup';
import { Textarea } from 'ds/components/input/Textarea';
import { Button } from 'ds/components/button';
import TrashIcon from 'assets/svg/trash2.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';


interface DeleteUserPopupProps {
  isOpen: boolean;
  onSubmit: (reason: IReasonState) => void;
  onClose: () => void;
}


export const DeleteReasonModal: React.FC<DeleteUserPopupProps> = (props) => {
  const {
    isOpen,
    onSubmit,
    onClose,
  } = props;

  const [ category, setCategory ] = useState(null);
  const [ reason, setReason ] = useState(null);
  const { t } = useLocale();
  const screen = useScreenBreakpoint();
  const isMobile = screen < 3;

  const radioGroupOptions = [
    DeregisterReason.NoNeed,
    DeregisterReason.DontUse,
    DeregisterReason.TooManyMotifications,
    DeregisterReason.Other,
  ];

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit({ category, reason });
  };

  const handleCategoryChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setCategory(e.currentTarget.value);
  };

  const handleReasonChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value && e.currentTarget.value.length > 150) return;
    setReason(e.currentTarget.value);
  };

  return (
    <Modal
      isOpen
      hideCloseButton={true}
      onClose={onClose}
      contentWrapperStyle={{ marginTop: isMobile ? 0 : undefined }}
      footerStyle={{ borderTop: '0 none', textAlign: 'center', padding: 32 }}
      buttons={isMobile ? (
        <>
          <Styled.TextInfo>{t('profile.removeUserReason.textInfo')}</Styled.TextInfo>
          <Styled.DeleteButton onClick={handleSubmit} fullWidth size="extraLarge">
            <Styled.TrashWrapper>
              <TrashIcon width={20} height={20} />
              {t('profile.removeUserReason.deleteBtn')}
            </Styled.TrashWrapper>
          </Styled.DeleteButton>
          <Styled.CancelButtonWrapper>
            <Button mode="ghost" fullWidth size="extraLarge" onClick={onClose}>
              {t('profile.removeUserReason.cancelBtn')}
            </Button>
          </Styled.CancelButtonWrapper>
        </>
      ) : null}
    >
      <Styled.Root>
        <Styled.CloseWrapper onClick={onClose}>
          <CrossIcon width={24} height={24} />
        </Styled.CloseWrapper>
        <Styled.WarningWrapper>
          <WarningIcon width={60} />
        </Styled.WarningWrapper>
        <Styled.Title>{t('profile.removeUserReason.title')}</Styled.Title>
        <Styled.Subtitle>{t('profile.removeUserReason.subtitle')}</Styled.Subtitle>
        <Styled.RadioWrapper>
          <RadioGroup
            onChange={handleCategoryChange}
            name="reason"
            options={radioGroupOptions.map(value => ({ value, label: t('profile.removeUserReason.values', { value }) }))}
            value={category}
          />
        </Styled.RadioWrapper>
        {category === DeregisterReason.Other ? (
          <Styled.TextSection>
            <Textarea rows={2} charactersCounterMax={150} onChange={handleReasonChange} value={reason} autoFocus />
          </Styled.TextSection>
        ) : null}
        {isMobile ? null : (
          <>
            <Styled.TextInfo>{t('profile.removeUserReason.textInfo')}</Styled.TextInfo>
            <Styled.DeleteButton onClick={handleSubmit} fullWidth size="extraLarge">
              <Styled.TrashWrapper>
                <TrashIcon width={20} height={20} />
                {t('profile.removeUserReason.deleteBtn')}
              </Styled.TrashWrapper>
            </Styled.DeleteButton>
            <Styled.CancelButtonWrapper>
              <Button mode="ghost" fullWidth size="extraLarge" onClick={onClose}>
                {t('profile.removeUserReason.cancelBtn')}
              </Button>
            </Styled.CancelButtonWrapper>
          </>
        )}
      </Styled.Root>
    </Modal>
  );
};
