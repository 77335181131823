import { searchListWorker, searchVariablesSelector as listVariablesSelector } from './list';
import { multicastChannel, MulticastChannel } from 'redux-saga';
import { all, call, fork, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { searchMapWorker } from './map';
import { DecodedRouteParams, Route, State as RouteState } from 'config/routes';
import { queryData } from 'store/sagas/apiService';
import { ISaveSearchFromState, LoadType } from 'store/sagas/apiService/types';
import {
  CompletionType,
  IAutocompleteEntry,
  IBulletin,
  ICommercialBulletin,
  ICommercialProject,
  IMadadWinnersBannersByDocIdVariables,
  IMadadWinnersBannersForStreetByDocIdVariables,
  IProject,
  ISearchPoiVariables,
  MarketplaceType,
  DealType,
  AdPlacement,
} from 'utils/entities';
import { pageSelector, searchParamsSelector, userSmartSortValueSelector, projectsCarouselSearchClearFilters } from './selectors';

import { addressResolvePattern, getDocId, makeOffset, MAX_ZOOM, waitForPoiResolve } from './utils';
import { searchBboxSelector, basicSortValueSelector, smartSortValueSelector } from 'store/state/selectors/search';
import { boundsToTileRange } from 'components/map/utils';
import { createSelector } from 'reselect';

import { isEqual, head } from 'lodash';
import { waitForUserResolve, makeTransitionPattern } from '../utils';
import { SortDirection, SortField, SortValue } from 'components/listing-sort/types';
import {
  AppMode,
  appModeSelector,
  getRouteParams,
  routeNameSelector,
  routeSelector,
} from 'store/state/selectors/router';
import { RootAction } from 'store/state';
import { RESET, SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { setActivePoiIds } from 'store/state/searchContext/actions';
import {
  isUserSelector,
  projectPageCommercialProjectSelector,
  projectPageProjectSelector,
  searchDocEntriesSelector,
  unitPageBulletinSelector,
  unitPageCommercialBulletinSelector,
  userCommuteWithPrioritiesSelector,
  userHasCommutePreferenceSelector,
} from 'store/state/domainData/selectors';
import { MUTATE, SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { makeSet, sortsEqual } from 'components/listing-sort/utils';
import { strictAddSaveSearchData } from 'store/state/selectors/savedSearchPage';
import { isSearchEqual } from 'utils/saveSearch';
import { navigateTo } from 'store/state/router/actions';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { saveLocalDataRecentSearch, saveRecentSearch } from './saveLocalDataRecentSearch';
import { resetDomainData } from 'store/state/domainData/actions';
import { availableTypeToFetch } from './searchPageHandler';
import { listingEditedSelector } from 'store/state/app/selectors';
import { isSearchRoute } from 'utils/marketplaceRoutes';
import { Seller } from 'components/filters/types';


export interface IContextualSearchPoiVariables {
  searchParams: ISearchPoiVariables;
  marketplaceType: MarketplaceType;
}

const searchVariablesSelector = createSelector([
  searchParamsSelector,
  searchBboxSelector,
  pageSelector,
  userCommuteWithPrioritiesSelector,
  listingEditedSelector,
], ({ searchParams, marketplaceType }, bounds, page, userData, listingEdited): IContextualSearchPoiVariables => {

  if (!bounds || !searchParams.dealType) return null;

  const tileRanges = [ boundsToTileRange(bounds, MAX_ZOOM) ];
  return {
    searchParams: {
      ...searchParams,
      tileRanges,
      userContext: userData,
      ...makeOffset(page),
      listingEdited: listingEdited || undefined,
    },
    marketplaceType,
  };
});

export const searchRelatedRoutes = new Set([
  Route.AddressPage,
  Route.UnitPage,
  Route.ProjectPage,
  Route.Search,
  Route.LocalPage,
  Route.AriaSearchResults,
  Route.StreetPage,
  Route.SearchCommercial,
  Route.UnitPageCommercial,
  Route.ProjectPageCommercial,
  Route.EmploymentAreaPage,
]);

const reflectingSearchPattern = (action: RootAction) => (
  action.type === TRANSITION_SUCCESS
    || (action.type === RESET && action.payload.loadType === LoadType.CurrentUser)
    || (action.type === MUTATE)
    || (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.CurrentUser)
    || (action.type === SET_MUTATION_RESPONSE)
);

export const poiHoodDocIdSelector = createSelector([
  routeSelector,
  unitPageBulletinSelector,
  projectPageProjectSelector,
  unitPageCommercialBulletinSelector,
  projectPageCommercialProjectSelector,
], (route, bulletin, project, commercialBulletin, commercialProject) => {
  let poi: IBulletin | IProject | ICommercialBulletin | ICommercialProject = null;
  switch (route.name) {
    case Route.UnitPageCommercial:
      poi = commercialBulletin;
      break;
    case Route.UnitPage:
      poi = bulletin;
      break;
    case Route.ProjectPage:
      poi = project;
      break;
    case Route.ProjectPageCommercial:
      poi = commercialProject;
      break;
  }

  return poi ? poi.addressDetails.neighbourhoodDocId || poi.addressDetails.cityDocId : null;
});

const COMMUTE_SORT: SortValue = [ SortField.Commute, SortDirection.Asc ];

const sortConstraintRedirectToSelector = createSelector([
  userSmartSortValueSelector,
  userHasCommutePreferenceSelector,
  basicSortValueSelector,
  smartSortValueSelector,
  isUserSelector,
], (userSort, userHasCommute, basicSort, smartSort, isConnectedUser): SortValue[] => {

  const smartSortSet = makeSet(smartSort);

  const isUserSortDifferent = userSort && userSort.length && isConnectedUser && !sortsEqual(userSort, smartSort);
  const needToRemoveCommute = !userHasCommute && smartSortSet.has(COMMUTE_SORT);

  if (isUserSortDifferent && needToRemoveCommute) {
    const userSortSet = makeSet(userSort);
    userSortSet.delete(COMMUTE_SORT);
    return [ ...basicSort, ...userSortSet ];
  }
  if (isUserSortDifferent) {
    return [ ...basicSort, ...userSort ];
  }
  if (needToRemoveCommute) {
    smartSortSet.delete(COMMUTE_SORT);
    return [ ...basicSort, ...smartSortSet ];
  }

  return null;
});

const LOCAL_PAGES_ROUTES = new Set([ Route.LocalPage, Route.StreetPage, Route.EmploymentAreaPage ]);
const POI_ROUTES = new Set([
  Route.UnitPage,
  Route.ProjectPage,
  Route.UnitPageCommercial,
  Route.ProjectPageCommercial,
]);

export function* getSearchVariables() {
  const params: RouteState = yield select(routeSelector);
  if (!(params && searchRelatedRoutes.has(params.name))) return null;
  const appMode = yield select(appModeSelector);
  if (appMode === AppMode.Check) return null;

  const currentDocs: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
  const term = LOCAL_PAGES_ROUTES.has(params.name) ? [ params.params.id ] : params.params.term;

  if (term && (!currentDocs || currentDocs.length !== term.length || !currentDocs.every(doc => term.includes(doc.docId)))) {
    try {
      yield call(queryData, {
        loadType: LoadType.SearchDoc,
        meta: {
          variables: { docIds: term },
          errorPolicy: 'none',
        },
      });
    }
    catch (e) {
      const logger = yield getContext('logger');
      logger.error('Search docId not resolved, redirecting Home.', e);
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    }
  }

  yield call(waitForUserResolve);

  let searchVariables: IContextualSearchPoiVariables = yield select(searchVariablesSelector);

  if (!searchVariables) {
    if (POI_ROUTES.has(params.name)) {
      yield call(waitForPoiResolve);
      if (!term) {
        const docId = yield select(poiHoodDocIdSelector);
        if (docId) {
          yield call(queryData, {
            loadType: LoadType.SearchDoc,
            meta: {
              variables: { docIds: [ docId ] },
            },
          });
        }
      }
    }
    else if (params.name === Route.AddressPage || !term) {
      yield take(addressResolvePattern);
    }
    searchVariables = yield select(searchVariablesSelector);
  }

  const sortConstraintOverride: SortValue[] = yield select(sortConstraintRedirectToSelector);
  if (sortConstraintOverride && (params.name === Route.Search || params.name === Route.SearchCommercial)) {
    yield put(navigateTo(params.name, {
      ...params.params,
      sort: sortConstraintOverride,
    }, { replace: true, ssrHttpStatus: 301 }));

    return null;
  }

  return searchVariables;
}


interface ParamsRef {
  current: IContextualSearchPoiVariables;
}

function* searchParamsPublisher(chnl: MulticastChannel<IContextualSearchPoiVariables>, lastEmitedParams: ParamsRef, _: RootAction) {
  const searchVariables: IContextualSearchPoiVariables = yield call(getSearchVariables);
  if (searchVariables && !isEqual(searchVariables, lastEmitedParams.current)) {
    yield put(chnl, searchVariables);
    lastEmitedParams.current = searchVariables;
  }
}

function* searchParamsWorker(chnl: MulticastChannel<IContextualSearchPoiVariables>) {
  const lastEmitedParams: ParamsRef = { current: null };
  yield takeEvery(reflectingSearchPattern, searchParamsPublisher, chnl, lastEmitedParams);
}

function* resetActivePoiWorker(chnl: MulticastChannel<IContextualSearchPoiVariables>) {
  yield take(TRANSITION_SUCCESS);
  while (true) {
    const { term: prevTerm }: DecodedRouteParams = yield select(getRouteParams);
    yield take(chnl, '*');
    const { term: nextTerm }: DecodedRouteParams = yield select(getRouteParams);
    if (nextTerm !== prevTerm) {
      yield put(setActivePoiIds([], { source: null }));
    }
  }

}

const searchTransitionPattern = makeTransitionPattern(Route.Search);

function* saveRecentSearchWorker(chnl: MulticastChannel<IContextualSearchPoiVariables>) {
  let prevSearch: ISaveSearchFromState = null;
  yield take(searchTransitionPattern);

  while (true) {
    let nextSearch: ISaveSearchFromState = yield select(strictAddSaveSearchData);

    while (!nextSearch) {
      yield take(chnl, '*');
      nextSearch = yield select(strictAddSaveSearchData);
    }

    if (!prevSearch || (!isSearchEqual(prevSearch, nextSearch) || prevSearch.title !== nextSearch.title)) {
      prevSearch = nextSearch;
      yield fork(saveRecentSearch, nextSearch);
    }

    yield take(searchTransitionPattern);
  }
}


function* fetchMadadWinnerBanners(searchVar: IContextualSearchPoiVariables) {
  const logger = yield getContext('logger');
  try {
    const routeName: Route = yield select(routeNameSelector);

    // we need data only on marketplace
    if (routeName === Route.Search) {
      const currentDocs: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);

      const availableType = currentDocs.every(entry => availableTypeToFetch.has(entry.type));

      if (availableType && currentDocs.length) {
        const doc = currentDocs[0];

        if (doc.type === CompletionType.Street) {
          const docIds = doc.relevantNeighbourhoods.map(el => el.docId);
          const variables: IMadadWinnersBannersForStreetByDocIdVariables = { docIds };
          yield call(queryData, {
            loadType: LoadType.MadadWinnersBannersForStreetByDocId,
            meta: {
              variables: {
                ...variables,
                ...searchVar,
              },
            },
          });
        }
        else {
          const variables: IMadadWinnersBannersByDocIdVariables = { docId: getDocId(doc) };
          yield call(queryData, {
            loadType: LoadType.MadadWinnersBannersByDocId,
            meta: {
              variables: {
                ...variables,
                ...searchVar,
              },
            },
          });
        }
      }
      else {
        yield put(resetDomainData({ loadType: LoadType.MadadWinnersBannersByDocId }));
        yield put(resetDomainData({ loadType: LoadType.MadadWinnersBannersForStreetByDocId }));
        yield put(resetDomainData({ loadType: LoadType.MadadWinnersExistForStreetForDocId }));
        yield put(resetDomainData({ loadType: LoadType.MadadWinnersExistForDocId }));
      }
    }
  }
  catch (error) {
    logger.error('Error fetching madad winners banners.', error);
  }
}

function* fetchLeadingAgents(searchVar: IContextualSearchPoiVariables) {
  const routeName: Route = yield select(routeNameSelector);
  const isSearch = isSearchRoute(routeName);

  if (isSearch) {
    const currentDocs: IAutocompleteEntry[] = yield select(searchDocEntriesSelector);
    const doc: IAutocompleteEntry = head(currentDocs);

    if (doc) {
      switch (doc.type) {
        case CompletionType.Address: {
          const docIds = doc.relevantDocIds.filter(el => el.type === CompletionType.Neighbourhood).map(el => el.docId);
          if (docIds && docIds.length) {
            yield fork(queryData, {
              loadType: LoadType.SearchAwardsByDocIds,
              meta: { variables: { args: { docIds } } },
            });
          }
          break;
        }
        case CompletionType.Street: {
          const docIds = doc.relevantNeighbourhoods.map(el => el.docId);
          if (docIds && docIds.length) {
            yield fork(queryData, {
              loadType: LoadType.SearchAwardsByDocIds,
              meta: { variables: { args: { docIds } } },
            });
          }
          break;
        }
        case CompletionType.Neighbourhood: {
          yield fork(queryData, {
            loadType: LoadType.SearchAwardsByDocIds,
            meta: { variables: { args: { docIds: [ doc.docId ] } } },
          });
          break;
        }
        default:
          yield put(resetDomainData({ loadType: LoadType.SearchAwardsByDocIds }));
      }
    }
  }
}

function createCompareListVariables(docIds: string, marketplace: MarketplaceType | null, dealType: DealType | null) {
  return {
    docIds,
    marketplace,
    dealType,
  };
}

export function* projectsCarouselSearchListWorker(chnl: MulticastChannel<ISearchPoiVariables>) {
  yield take(chnl, '*');

  let prevCompareListVariables = createCompareListVariables('', null, null);
  while (true) {
    const currVariables: ISearchPoiVariables = yield select(listVariablesSelector);
    const marketplace: MarketplaceType = currVariables.isCommercialRealEstate ? MarketplaceType.Commercial : MarketplaceType.Residential;
    const currentCompareListVariables = createCompareListVariables(currVariables.sort.map(s => s.docIds).toString(), marketplace, currVariables.dealType);
    const variablesChanged = !isEqual(
      prevCompareListVariables,
      currentCompareListVariables
    );
    if (variablesChanged && (currVariables.dealType !== DealType.Rent)) {
      const emptySearchParams = projectsCarouselSearchClearFilters(currVariables.dealType, marketplace).searchParams;
      yield fork(queryData, {
        loadType: LoadType.ProjectsSearchResultList,
        meta: {
          variables: {
            ...currVariables,
            ...emptySearchParams,
            sort: currVariables.sort,
            sellerType: [ Seller.Developer ],
            offset: 0,
            limit: 10,
            adPlacement: AdPlacement.Carousel,
          },
        },
      });
    }

    prevCompareListVariables = currentCompareListVariables;
    yield take(chnl, '*');
  }
}

function* searchBannerWatcher(chnl: MulticastChannel<IContextualSearchPoiVariables>) {
  while (true) {
    const searchVar: IContextualSearchPoiVariables = yield take(chnl, '*');
    yield fork(fetchMadadWinnerBanners, searchVar);
    yield fork(fetchLeadingAgents, searchVar);
  }
}

export function* searchHandler() {
  const chnl = yield call(multicastChannel);

  yield all([
    fork(searchMapWorker, chnl),
    fork(searchListWorker, chnl),
    fork(saveRecentSearchWorker, chnl),
    fork(projectsCarouselSearchListWorker, chnl),
  ]);

  yield fork(searchParamsWorker, chnl);
  yield fork(searchBannerWatcher, chnl);
  yield fork(resetActivePoiWorker, chnl);
  yield fork(saveLocalDataRecentSearch, chnl);
}
