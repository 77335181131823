export enum AddLastUpdateToWeights {
  modeB = 'modeB',
}

export enum RemoveWizard {
  modeB = 'modeB',
}

export enum ListingPageV5 {
  modeB = 'modeB',
}

export enum ProjectPopupDesign {
  modeB = 'modeB',
}

export enum SearchResultProjectsCarousel {
  modeB = 'modeB',
}

export enum SearchPageHeadBannerAbTest {
  modeB = 'modeB',
}

export enum MenoraInsuranceBannerAbTest {
  modeB = 'modeB',
}

export enum PrioritizeFormProjectsAbTest {
  modeB = 'modeB',
  modeC = 'modeC',
}

export interface IABTestContext {
  searchResultProjectsCarousel?: SearchResultProjectsCarousel;
  projectPopupDesign?: ProjectPopupDesign;
  _be_addLastUpdateToWeights?: AddLastUpdateToWeights;
  removeWizard?: RemoveWizard;
  listingPageV5?: ListingPageV5;
  searchPageHeadBanner?: SearchPageHeadBannerAbTest;
  menoraInsuranceBanner?: MenoraInsuranceBannerAbTest;
  prioritizeFormProjects?: PrioritizeFormProjectsAbTest;
}
