import React from 'react';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from 'components/authentication/ClickAwayListener';
import { Manager, Reference, Popper } from 'react-popper';
import { Modifiers } from 'popper.js';
import { noop } from 'lodash';
import { TooltipWrapper, TooltipArrow } from '../tooltip-container/styled';
import { TooltipProps } from '../types';
import { ScrollListener } from 'components/authentication/ScrollListener';
import { isTouchDevice } from 'utils/touche';


const PowerTooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  placement,
  size,
  hideArrow,
  raw,
  wrapperStyle,
  disableClickAway,
}) => {
  const [ tooltipVisible, setTooltipVisible ] = React.useState(false);
  const handleAwayClick = () => setTooltipVisible(false);


  const modifiers: Modifiers = {
    preventOverflow: {
      boundariesElement: 'viewport',
    },
  };

  const handlers = React.useMemo(() => {
    if (isTouchDevice()) {
      return {
        onClick: () => {
          setTooltipVisible(prev => !prev);
        },
      };
    }
    else {
      return {
        onMouseEnter: () => setTooltipVisible(true),
        onMouseLeave: () => setTooltipVisible(false),
      };
    }
  }, [ isTouchDevice ]);

  const tooltipPlacement = placement || 'top';

  return (
    <Manager>
      <ClickAwayListener onClickAway={disableClickAway ? noop : handleAwayClick}>
        <Reference>
          {({ ref }) => (
            <div
              {...handlers}
              className="tooltip-wrapper"
              ref={ref}
            >
              {children}
            </div>
          )}
        </Reference>
        {tooltipVisible ? (
            <ScrollListener onScroll={handleAwayClick}>
              <Popper
                placement={tooltipPlacement}
                positionFixed
                modifiers={modifiers}
              >
                {({ ref, style, arrowProps }) => (
                  raw ? (
                    ReactDOM.createPortal(
                      <div ref={ref} style={{ ...style, zIndex: 102 }} data-placement={placement}>
                        {tooltip}
                      </div>, document.getElementById('sticky-root'))
                  ) : (
                    ReactDOM.createPortal(
                      <TooltipWrapper
                        ref={ref}
                        style={{ ...style, ...wrapperStyle }}
                        data-placement={tooltipPlacement}
                        onClick={handleAwayClick}
                        size={size}
                        hideArrow={hideArrow}
                      >
                        {tooltip}
                        {!hideArrow && (
                          <>
                            <TooltipArrow
                              placement={tooltipPlacement}
                              ref={arrowProps.ref}
                              style={arrowProps.style}
                            />
                          </>
                        )}
                      </TooltipWrapper>, document.getElementById('sticky-root'))
                  ))}
              </Popper>
            </ScrollListener>
        ) : null}
      </ClickAwayListener>
    </Manager>
  );
};

export default PowerTooltip;
