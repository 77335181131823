import React from 'react';
import { throttle } from 'lodash';
import { addEventListenerFn, EventListenerObject } from 'helpers/pan-responder';
import { isServer } from 'utils';

interface ScrollListenerProps {
  onScroll?(): void;
  children: React.ReactNode;
}

export class ScrollListener extends React.Component<ScrollListenerProps, never> {
  public componentDidMount () {
    this.scrollHandler = isServer() ? null : addEventListenerFn(window, 'scroll', this.handleScroll);
  }

  public componentWillUnmount () {
    this.scrollHandler.remove();
  }

  private handleScroll = throttle((e: MouseEvent) => {
    if (!this.props.onScroll) {
      return;
    }
    this.props.onScroll();
  }, 200);

  private elementRef = React.createRef<HTMLDivElement>();

  private scrollHandler: EventListenerObject = null;

  public render () {
    return (
      <div ref={this.elementRef}>
        {this.props.children}
      </div>
    );
  }
}
