import styled from '@emotion/styled';
import { FormControlsWrapper } from 'components/authentication/styled';
import { SmallText } from 'ds/components/typography';

export const SetPassWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ControlsWrapper = styled(FormControlsWrapper)`
  margin-top: 0;
`;

export const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.small};
    gap: ${theme.spacing(0.5)};
    border: 1px solid ${theme.colors.neutrals.grey9};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding: ${theme.spacing(1.5)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding: ${theme.spacing(1)};
  `}
`;

export const Rule = styled(SmallText)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  ${({ theme, isActive }) => theme.media.showAt({ to: 2 })`
    color: ${isActive ? theme.colors.brand.madlanGreen : theme.colors.neutrals.grey2};
  `}
  ${({ theme, isActive }) => theme.media.showAt({ from: 3 })`
    color: ${isActive ? theme.colors.brand.madlanGreen : theme.colors.neutrals.grey5};
  `}
`;

export const RuleTitle = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const SkipPassWrapper = styled.div`
  margin-top: auto;
  align-self: center;
`;
