import React from 'react';
import { Link } from 'ds/components/link';
import AnchorMiddleware from 'components/anchor';

export const getDeveloperLink = (id: string) => id ? `/developer/${id}` : null;

export const getAgencyLink = (id: string) => id ? `/agentsOffice/${id}` : null;

export const getAgentLink = (id: string) => id ? `/agent/${id}` : null;

interface CheckedLinkProps {
  link?: string;
  isPreventEvent?: boolean;
}

export const CheckedLink: React.FC<CheckedLinkProps> = ({ children, link, isPreventEvent }) => link ? (
  <AnchorMiddleware href={link}>
    {(href) => (
      <a href={href} target="_blank" onClick={(e) => isPreventEvent ? e.stopPropagation() : null}>
        {children}
      </a>
    )}
  </AnchorMiddleware>
) : <>{children}</>;

export const CheckedLinkText: React.FC<CheckedLinkProps> = ({ children, link }) => link ? (
  <AnchorMiddleware href={link}>
    {(href) => (
      <Link href={href} target="_blank">
        {children}
      </Link>
    )}
  </AnchorMiddleware>
) : <>{children}</>;
