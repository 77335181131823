import styled from '@emotion/styled';
import { H3 } from 'ds/components/typography';

export const AlertModalContent = styled.div(({ theme }) => ({
  margin: '0 auto',
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.media.createObjectMediaQuery({ from: 3 })]: {
    width: '262px',
  },
}));

export const AlertTitle = styled(H3)(() => ({
  textAlign: 'center',
  fontWeight: 700,
}));
