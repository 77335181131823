import styled from '@emotion/styled';
import { TooltipProps } from '../types';

export const TooltipArrow = styled.div<{ placement: string }>(({ theme, placement }) => ({
  position: 'absolute',
  ...(placement ? { [placement.split('-')[0]]: '100%' } : {}),
  '&:before': {
    content: '""',
    width: '12px',
    height: '12px',
    position: 'absolute',
    bottom: '-6px',
    left: 'calc(50% - 6px)',
    background: theme.colors.neutrals.grey1,
    transform: 'rotate(45deg)',
    boxShadow: theme.shadow.level1,
  },
}));

type TooltipWrapperProps = Pick<TooltipProps, 'size' | 'hideArrow' | 'clickable'>;

export const TooltipWrapper = styled.div<TooltipWrapperProps>(({ theme, size, hideArrow }) => ({
  maxWidth: '300px',
  backgroundColor: theme.colors.neutrals.grey1,
  borderRadius: theme.borderRadius.tiny,
  boxShadow: theme.shadow.level1,
  color: theme.colors.neutrals.white,
  padding: theme.spacing(...(size === 'small' ? [ 0.5, 1 ] : [ 1.5 ])),
  zIndex: 101,
  '&[data-placement^="top"]': {
    marginBottom: hideArrow ? '6px' : '12px',
  },
  '&[data-placement^="left"]': {
    marginRight: hideArrow ? '6px' : '12px',
  },
  '&[data-placement^="right"]': {
    marginLeft: hideArrow ? '6px' : '12px',
  },
  '&[data-placement^="bottom"]': {
    marginTop: hideArrow ? '6px' : '12px',
  },
}));
