import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { ToastType, FeedbackToast } from './FeedbackToast';
import { useLocale, HebrewKeys } from 'locale';
import { STICKY_ROOT } from 'consts/rootNodes';
import { Route, State as RouteState } from 'config/routes';

const ToastContainerWrapper = styled.div<{ newDesign?: boolean; }>`
  ${({ theme }) => `
    position: fixed;
    z-index: 2019;
    background-color: ${theme.colors.neutrals.white};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    left: 0;
    bottom: 0;
    right: 0;
  `}
  ${({ theme, newDesign }) => theme.media.showAt({ from: 3 })`
    ${newDesign ? `
      left: 0;
      bottom: 0;
      right: 0;
    ` : `
      bottom: ${theme.spacing(3)};
      border-radius: ${theme.borderRadius.tiny};
      left: ${theme.spacing(3)};
    `}
  `}
`;

export const TOAST_AUTO_HIDE_TIMEOUT = 3000;

export interface FeedbackToastMessage {
  term: HebrewKeys;
  params?: Record<string, string | boolean | number>;
  type?: ToastType;
  newDesign?: boolean;
  autoHideTimeout?: number;
  preLineText?: boolean;
}

interface ToastContainerProps {
  message: FeedbackToastMessage;
  onClose: () => void;
  isDesktop: boolean;
  route: RouteState;
}

export const ToastContainer: React.FC<ToastContainerProps> = props => {
  const { message, onClose, isDesktop, route } = props;
  const { t } = useLocale();

  const newDesign = route.name === Route.Listings;

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> = null;
    if (message) {
      timerId = setTimeout(onClose, message.autoHideTimeout || TOAST_AUTO_HIDE_TIMEOUT);
    }
    return () => clearTimeout(timerId);
  }, [ message, isDesktop ]);

  if (!message) return null;

  return ReactDOM.createPortal(
    <>
      <ToastContainerWrapper
        data-auto="toast-container-wrapper"
        onClick={onClose}
        newDesign={newDesign}
      >
        <FeedbackToast
          type={message.type}
          newDesign={newDesign}
          preLineText={message.preLineText}
        >
          {t(message.term, message.params)}
        </FeedbackToast>
      </ToastContainerWrapper>
    </>,
    document.getElementById(STICKY_ROOT)
  );
};
