import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { getDirection } from 'utils';
import Select, { SelectItemProps } from 'ds/components/input/Select';
import { useScreenBreakpoint } from 'consts/breakpoints';


const AdditionalMenuWrapper = styled.div`
  &&& {
    svg {
      margin: 0;
    }
  }
`;

const ToggleWrapper = styled.div`
  width: 100%;
`;

const ItemWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    svg {
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    svg {
      margin: ${theme.spacing(0.25)} 0;
      width: 16px;
      height: 16px;
    }
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    svg {
      width: 20px;
      height: 24px;
    }
  `}
`;

export enum MenuActions {
  Freeze = 'freeze',
  Delete = 'delete',
  Unfreeze = 'unfreeze',
  Edit = 'edit',
}

export type IAdditionalMenuOption = SelectItemProps & { onChange: () => void };

interface AdditionalMenuProps {
  options: IAdditionalMenuOption[];
  selectStyles: React.CSSProperties;
  disabled?: boolean;
}

export const AdditionalMenu: React.FC<AdditionalMenuProps> = React.memo((props) => {
  const isMobile = useScreenBreakpoint() < 3;

  const { children, options, selectStyles, disabled } = props;

  const shouldResetSelectedValue = () => true;
  const renderItem = (item: SelectItemProps, isSelected: boolean) =>
    <ItemWrapper>{Select.defaultProps.renderItem(item, isSelected)}</ItemWrapper>;

  const items = useMemo(() =>
    options.map(({ value, icon, label }) => ({ value, icon, label })), [ options ]);

  const onChange = (value: MenuActions) => {
    const option: IAdditionalMenuOption = options.find(v => v.value === value);
    return option.onChange ? option.onChange.call(null) : null;
  };

  return (
    <AdditionalMenuWrapper>
      <Select
        styles={selectStyles}
        isUncontrolled
        shouldResetSelectedValue={shouldResetSelectedValue}
        items={items}
        onChange={onChange}
        alignment={getDirection(true, isMobile)}
        renderItem={renderItem}
        renderCustomToggle={( toggleProps ) => disabled ? children : (
          <ToggleWrapper {...toggleProps}>{children}</ToggleWrapper>
        )}
      />
    </AdditionalMenuWrapper>
  );
});
