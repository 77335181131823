import { createPubSub, Listener } from 'utils/pubSub';
import { noop } from 'lodash';
import React from 'react';
import { Platform } from './config';


export const BACK_BTN = 'REACT_NATIVE/BACK_BTN';
export const AUTH_GOOGLE = 'REACT_NATIVE/AUTH_GOOGLE';
export const AUTH_APPLE = 'REACT_NATIVE/AUTH_APPLE';
export const AUTH_FACEBOOK = 'REACT_NATIVE/AUTH_FACEBOOK';
export const REGISTER_PUSH_DEVICE_TOKEN = 'REACT_NATIVE/REGISTER_PUSH_DEVICE_TOKEN';
export const REPORT_PUSH_STATUS = 'REACT_NATIVE/REPORT_PUSH_STATUS';

type ReactNativeClientTunnelEventType = typeof BACK_BTN
  | typeof AUTH_GOOGLE
  | typeof AUTH_APPLE
  | typeof AUTH_FACEBOOK
  | typeof REGISTER_PUSH_DEVICE_TOKEN
  | typeof REPORT_PUSH_STATUS
;

export interface ReactNativeClientTunnelEvent { type: ReactNativeClientTunnelEventType; platform: Platform; payload: unknown; }

export const ROUTE_NAME = 'WEB/ROUTE_NAME';
export const EXIT_BACKGROUND = 'WEB/EXIT_BACKGROUND';
export const AUTH_TYPE = 'WEB/AUTH_TYPE';
export const REQUEST_PUSH = 'WEB/REQUEST_PUSH';

type ClientReactNativeTunnelEventType = typeof ROUTE_NAME
 | typeof EXIT_BACKGROUND
 | typeof AUTH_TYPE
 | typeof REQUEST_PUSH
;

export interface ClientReactNativeTunnelEvent { type: ClientReactNativeTunnelEventType; payload: unknown; }

export interface IReactNativeClientTunnel {
  subscribe: (listener: Listener<ReactNativeClientTunnelEvent>) => () => void;
  push: (d: ClientReactNativeTunnelEvent) => void;
}

export const ReactNativeClientTunnelContext = React.createContext<IReactNativeClientTunnel>(null);

export class ReactNativeClientTunnel {

  private pubSub = createPubSub<ReactNativeClientTunnelEvent>();
  public subscribe = this.pubSub.subscribe;

  constructor() {
    if (document) {
      document.addEventListener('message', message => {
        try {
          const data = (message as any).data;
          const parsed = JSON.parse(data);
          if (typeof parsed.type === 'string') {
            this.pubSub.dispatch(parsed);
          }
        }
        // tslint:disable-next-line: no-empty
        catch (error) {
          // tslint:disable-next-line: no-console
          console.log(error);
        }
      });
    }
  }

  public push(data: ClientReactNativeTunnelEvent) {
    if (window && ('ReactNativeWebView' in window)) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }
}

export function useReactNativeClientTunnel(predicate: (type: ReactNativeClientTunnelEventType) => boolean = () => true, cb: (e: ReactNativeClientTunnelEvent) => void = noop): ReactNativeClientTunnelEvent | null {
  const [ action, setAction ] = React.useState<ReactNativeClientTunnelEvent | null>(null);
  const ctx = React.useContext(ReactNativeClientTunnelContext);

  if (!ctx) return action;

  ctx.subscribe((event) => {
    if (event && event.type && predicate(event.type)) {
      setAction(event);
      cb(event);
    }
  });

  return action;
}
