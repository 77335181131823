import React from 'react';
import { connect } from 'react-redux';
import { State } from 'store/state';
import ThumbIcon from 'assets/svg/thumbs-up-success.svg';
import TriangleIcon from 'assets/svg/triangle-up.svg';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { SmallText } from 'ds/components/typography';
import { useInterval } from 'hooks/useInterval';
import { loginSuccessStateSelector } from 'store/state/app/selectors';
import { toggleLoginSuccessModal } from 'store/state/app/actions';

const Root = styled(SmallText)<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  display: 'flex',
  position: 'absolute',
  left: isDesktop ? '55px' : '12px',
  top: '65px',
  borderRadius: theme.borderRadius.tiny,
  backgroundColor: theme.colors.brand.lightest,
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  color: theme.colors.brand.darker,
  opacity: 0,
  zIndex: 1001,
  animation: '4s fade-in-out infinite',
  '@keyframes fade-in-out': {
    '0%': { opacity: 0 },
    '12.5%': { opacity: 1 },
    '87.5%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  svg: {
    display: 'block',
  },
  '.triangle-svg': {
    position: 'absolute',
    top: '-5px',
    right: '180px',
  },
}));

interface SuccessLoginNotifyProps {
  showNotify: boolean;
  isDesktop: boolean;
  onToggle: (v: boolean) => void;
}

const SuccessLoginNotify: React.FC<SuccessLoginNotifyProps> = ({ isDesktop, showNotify, onToggle }) => {
  const { t } = useLocale();

  useInterval(() => {
    if (showNotify) onToggle(false);
  }, 3899, [ showNotify ]);

  if (!showNotify) return null;

  return (
    <Root isDesktop={isDesktop}>
      <TriangleIcon className="triangle-svg" width={10} height={6} />
      <ThumbIcon width={40} height={40} />
      {t('authentication.apple.success')}
    </Root>
  );
};

const mapStateToProps = (state: State) => ({
  showNotify: loginSuccessStateSelector(state),
});

const mapDispatchToProps = {
  onToggle: toggleLoginSuccessModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessLoginNotify);
