import styled from '@emotion/styled';
import { SocialVendor } from '../types';
import { TextLabel, SmallText, Text } from 'ds/components/typography';

export const SocialButtonWrapper = styled.div<{ type: SocialVendor }>`
  & > button, & > button:hover, & > button:active {
    color: ${({ theme }) => theme.colors.neutrals.grey3};
    text-transform: none;
    font-weight: 500;
    position: relative;

    svg {
      position: absolute;
      width: initial;
      height: initial;
      margin-left: ${({ theme }) => theme.spacing(1)};

      ${({ theme }) => theme.media.showAt({ to: 2 })`
        right: 15px;
      `}
      ${({ theme }) => theme.media.showAt({ from: 3 })`
        right: 20px;
      `}
    }
  }
`;

export const AuthenticationSocialSubmitWrapper = styled.div`
  button span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const OtherSocialsInfo = styled(SmallText)(({ theme }) => ({
  color: theme.colors.neutrals.grey3,
  marginTop: theme.spacing(5),
  textAlign: 'center',
}));

export const OtherSocialsButtons = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

export const AppleButton = styled(Text)(({ theme }) => ({
  cursor: 'pointer',
  maxHeight: '38px',
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  color: theme.colors.neutrals.grey2,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.borderRadius.extraXLarge,
  border: `1px solid ${theme.colors.neutrals.grey6}`,
  padding: theme.spacing(1),
}));

export const AuthenticationSocialWrapper = styled.div`
  width: 320px;

  ${({ theme }) => `
    > div + div {
      margin-top: ${theme.spacing(1)};
    }
  `}

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const AuthenticationSocialSuggestion = styled(TextLabel)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    position: absolute;
    padding: 0 ${theme.spacing(2)};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

export const AuthenticationSocialSuggestionWrapper = styled(TextLabel)`
  display: flex;
  justify-content: center;
  height: 16px;
  position: relative;
  color: ${({ theme }) => theme.colors.neutrals.grey4};
  margin: ${({ theme }) => theme.spacing(4)} 0;

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: ${theme.spacing(3)} 0;
  `};

  ::before {
    position: relative;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      width: calc(100vw - 32px);
    `};
    top: 7px;
    background-color: ${({ theme }) => theme.colors.neutrals.grey8};
  }
`;
