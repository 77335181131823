import { createSelector } from 'reselect';
import { routeSelector, prevRouteSelector } from 'store/state/selectors/router';
import { State as RouteState } from 'config/routes';
import { isProjectPageRoute } from 'utils/marketplaceRoutes';

export enum TrafficSegmentation {
  Remarketing = 'remarketing',
  ProjectLanding = 'projectLanding',
  Paid = 'paid',
}

export enum TrafficSegmentationPlace {
  All = 'all',
  WelcomePopUp = 'welcomePopUp',
  ExitPopUp = 'exitPopUp',
}

export type TrafficSegmentationRules = Record<TrafficSegmentationPlace, boolean>;

function isRemarketingSegment(route: RouteState): boolean {
  const campaign = route.params.utm_campaign;
  if (!campaign) return false;
  return campaign.toLowerCase().startsWith('remarketing_');
}

function isProjectLandingSegment(route: RouteState, prevRoute: RouteState): boolean {
  const isProjectPage = isProjectPageRoute(route.name);
  return isProjectPage && !prevRoute;
}

function isPaidSegment(route: RouteState): boolean {
  const campaign = route.params.mp_remarketing;
  return campaign && campaign === 'true';
}

const TRAFFIC_ORDER: TrafficSegmentation[] = [
  TrafficSegmentation.Remarketing,
  TrafficSegmentation.ProjectLanding,
  TrafficSegmentation.Paid,
];

const TRAFFIC_PREDICATE: Record<TrafficSegmentation, (route: RouteState, prevRoute: RouteState) => boolean> = {
  [TrafficSegmentation.Remarketing]: isRemarketingSegment,
  [TrafficSegmentation.ProjectLanding]: isProjectLandingSegment,
  [TrafficSegmentation.Paid]: isPaidSegment,
};

export const trafficSegmentationShowSelector = createSelector([
  routeSelector,
  prevRouteSelector,
], (route, prevRoute): TrafficSegmentationRules => {
  const segmentation = TRAFFIC_ORDER.find(segment => TRAFFIC_PREDICATE[segment](route, prevRoute));
  switch (segmentation) {
    case TrafficSegmentation.Remarketing:
      return {
        [TrafficSegmentationPlace.All]: false,
        [TrafficSegmentationPlace.WelcomePopUp]: true,
        [TrafficSegmentationPlace.ExitPopUp]: true,
      };
    case TrafficSegmentation.ProjectLanding:
      return {
        [TrafficSegmentationPlace.All]: false,
        [TrafficSegmentationPlace.WelcomePopUp]: false,
        [TrafficSegmentationPlace.ExitPopUp]: true,
      };
    case TrafficSegmentation.Paid:
      return {
        [TrafficSegmentationPlace.All]: false,
        [TrafficSegmentationPlace.WelcomePopUp]: false,
        [TrafficSegmentationPlace.ExitPopUp]: false,
      };
    default:
      return {
        [TrafficSegmentationPlace.All]: true,
        [TrafficSegmentationPlace.WelcomePopUp]: false,
        [TrafficSegmentationPlace.ExitPopUp]: false,
      };
  }
});
