import { createSelector } from 'reselect';
import { searchListPoiListSelector, searchDocLoadingSelector } from 'store/state/domainData/selectors';
import { PoiIdentifier } from 'screens/AreaPage/types';
import { IAd, LISTING_POI_TYPES, ListingPoiType } from 'utils/entities';


export const poiSelector = createSelector([
  searchListPoiListSelector,
  searchDocLoadingSelector,
], (pois, docLoading) => docLoading ? [] : pois.map((poi): IAd | {id: string; type: ListingPoiType} => (poi.type === 'ad' ? poi : {
  id: poi.id,
  type: poi.type,
})));

export const listingPoiSelector = createSelector([ poiSelector ], (pois) => pois.filter((p): p is PoiIdentifier => LISTING_POI_TYPES.has(p.type)));

