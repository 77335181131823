import { connect } from 'react-redux';
import { Authentication } from './Authentication';
import { RootAction, State } from 'store/state';
import { Dispatch } from 'redux';
import { AuthenticationModalType, SocialVendor } from './types';
import {
  setAuthEmail,
  setAuthModal,
  userLoginSocial,
  signInSignUpWithEmail,
  userLoginWithEmailAndPassword,
} from 'store/state/app/actions';
import {
  ILoginWithEmailAndPasswordVariables,
  ISignInSignUpWithEmailVariables,
  IResetPasswordUpdateVariables,
  MutationType,
  AuthError,
  IGenerateEmailVerificationCodeVariables,
  IVerifyEmailVerificationCodeVariables,
} from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import {
  verifyEmailVerificationCodeDataSelector,
  resetPasswordUpdateResponseSelector,
  signInSignUpLoadingSelector,
  loginWithEmailAndPasswordDataSelector,
} from 'store/state/mutationsResponse/selectors';
import { authEmailSelector, authModalAuthSourceVariablesSelector, authModalPendingActionSelector, authModalStatusSelector } from 'store/state/app/selectors';
import { isConnectedUserSelector, userAuthErrorSelector } from 'store/state/domainData/selectors';
import { flow } from 'lodash';

const authModalAlreadyExistsEmailSelector = flow(userAuthErrorSelector, (e) => e ? e.email : null);
const loginFailedSelectorDirty = flow(userAuthErrorSelector, (e) => e ? e.reason === AuthError.LoginFailed : null);
const authModalErrorSelector = flow(authModalAuthSourceVariablesSelector, (e) => e ? e.error : null);

const mapStateToProps = (state: State) => ({
  activeModal: authModalStatusSelector(state),
  alreadyExistsEmail: authModalAlreadyExistsEmailSelector(state),
  verifyEmailVerificationCodeData: verifyEmailVerificationCodeDataSelector(state),
  resetPasswordUpdateResponse: resetPasswordUpdateResponseSelector(state),
  loginEmailPasswordResponse: loginWithEmailAndPasswordDataSelector(state),
  pendingAction: authModalPendingActionSelector(state),
  isLoginFailed: loginFailedSelectorDirty(state),
  email: authEmailSelector(state),
  authModalError: authModalErrorSelector(state),
  signInSignUpLoading: signInSignUpLoadingSelector(state),
  isUser: isConnectedUserSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onSetPassword: (data: IResetPasswordUpdateVariables) => dispatch(inlineMutate(MutationType.ResetPasswordUpdate, data)),
  generateEmailVerificationCode: (data: IGenerateEmailVerificationCodeVariables) => dispatch(inlineMutate(MutationType.GenerateEmailVerificationCode, data)),
  verifyEmailVerificationCode: (data: IVerifyEmailVerificationCodeVariables) => dispatch(inlineMutate(MutationType.VerifyEmailVerificationCode, data)),
  onSocialLogin: (vendor: SocialVendor) => dispatch(userLoginSocial(vendor)),
  onSignInSignUpWithEmail: (data: ISignInSignUpWithEmailVariables) => dispatch(signInSignUpWithEmail(data)),
  onLoginWithEmailAndPassword: (data: ILoginWithEmailAndPasswordVariables) => dispatch(userLoginWithEmailAndPassword(data)),
  onSetActiveModal: (type: AuthenticationModalType) => dispatch(setAuthModal({ type }, { isUserInitiated: true })),
  setEmail: (email: string) => dispatch(setAuthEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
