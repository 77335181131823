import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  text-align: right;
  & a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.brand.main};
    text-decoration: none;
  }
`;
