import React from 'react';
import { Placement } from 'popper.js';
import { TooltipArrow, TooltipWrapper } from './styled';
import { RefHandler, PopperArrowProps } from 'react-popper';
import { TooltipSize } from '../types';

interface TooltipContainerProps {
  linkRef: RefHandler;
  style: React.CSSProperties;
  placement: Placement;
  arrowProps: PopperArrowProps;
  onClick?: () => void;
  size?: TooltipSize;
  hideArrow?: boolean;
  onMouseEnter?(event: React.MouseEvent<HTMLElement>): void;
  onMouseLeave?(event: React.MouseEvent<HTMLElement>): void;
  scheduleUpdate?(): void;
  styleVisibility?: boolean;
}

export const TooltipContainer: React.FC<TooltipContainerProps> = (props) => {
  const {
    linkRef,
    style,
    placement,
    onClick,
    size,
    hideArrow,
    children,
    arrowProps,
    onMouseEnter,
    onMouseLeave,
    scheduleUpdate,
    styleVisibility,
  } = props;

  React.useEffect(() => {
    if (scheduleUpdate) {
      scheduleUpdate();
    }
  }, [ styleVisibility ]);

  const wrapperStyle: React.CSSProperties = styleVisibility === undefined
    ? style
    : { ...style, visibility: styleVisibility ? 'visible' : 'hidden' };

  return (
    <TooltipWrapper
      ref={linkRef}
      style={wrapperStyle}
      data-placement={placement}
      onClick={onClick}
      size={size}
      hideArrow={hideArrow}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="tooltip-wrapper"
    >
      {children}
      {!hideArrow && (
        <TooltipArrow
          className="tooltip-arrow"
          placement={placement}
          ref={arrowProps.ref}
          style={arrowProps.style}
        />
      )}
    </TooltipWrapper>
  );
};
