import React from 'react';
import styled from '@emotion/styled';
import { SmallText } from 'ds/components/typography';
import SuccessIcon from 'assets/svg/interface-24-v-circle.svg';
import ErrorIcon from 'assets/svg/interface-24-error.svg';
import WarningIcon from 'assets/svg/interface-24-attention.svg';
import { Theme } from 'ds';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';

export function getToastColorsByType(theme: Theme, type: ToastType) {
  let color = theme.colors.alert.blue;
  let bg = theme.colors.alert.blueBackground;

  switch (type) {
    case 'error':
      color = theme.colors.alert.red;
      bg = theme.colors.alert.redBackground;
      break;
    case 'warning':
      color = theme.colors.alert.yellow;
      bg = theme.colors.alert.yellowBackground;
      break;
    case 'success':
      color = theme.colors.alert.green;
      bg = theme.colors.alert.greenBackground;
      break;
    default:
  }

  return { color, bg };
}

const Text = styled(SmallText)<{ preLineText?: boolean }>`
  line-height: 24px;
  display: flex;
  ${({ preLineText }) => preLineText ? `white-space: pre-line;` : ''}
`;

const TextSpacer = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin: 0 8px;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin: 0 6px;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToastWrapper = styled.div<{ type: ToastType; newDesign?: boolean }>`
  justify-content: flex-start;
  ${({ theme, type }) => theme.media.showAt({ to: 2 })`
    padding: 12px 8px;
    ${type ? '' : 'justify-content: center;'}
    display: flex;
  `}
  ${({ theme, newDesign }) => theme.media.showAt({ from: 3 })`
    ${newDesign ? `
      padding: ${theme.spacing(2)};
      width: auto;
      > div {
        font-size: ${typographyFontSizeCfg.text}px;
        line-height: ${typographyLineHeightCfg.text}px;
      }
    ` : `
      padding: 12px 6px;
      border-radius: ${theme.borderRadius.tiny};
      box-shadow: ${theme.shadow.level5};
      min-width: 242px;
      display: inline-flex;
      max-width: 540px;
    `}
  `}
  ${({ theme, type }) => {
    const { color, bg } = getToastColorsByType(theme, type);

    return `
      color: ${color};
      & svg path {
        fill: ${color};
      }
      background-color: ${bg};
    `;
  }}
  & svg {
    width: 24px;
    height: 24px;
    ${({ theme }) => theme.media.showAt({ to: 2 })`
      margin: 0 8px;
    `}
    ${({ theme, newDesign }) => theme.media.showAt({ from: 3 })`
      margin: 0 ${newDesign ? 8 : 6}px;
    `}
  }
`;

export type ToastType =  'warning' | 'error' | 'success';

interface FeedbackToastProps {
  type?: ToastType;
  children: string;
  newDesign?: boolean;
  preLineText?: boolean;
}

export const FeedbackToast: React.FC<FeedbackToastProps> = props => {
  const { type, children, newDesign, preLineText } = props;
  let icon: JSX.Element = null;

  switch (type) {
    case 'error':
      icon = <ErrorIcon />;
      break;
    case 'success':
      icon = <SuccessIcon />;
      break;
    case 'warning':
      icon = <WarningIcon />;
      break;
  }

  const content = newDesign ? children : (
    <TextSpacer>
      {children}
    </TextSpacer>
  );

  return (
    <ToastWrapper
      data-auto="toast-message-wrapper"
      type={type}
      newDesign={newDesign}
    >
      <Text data-auto="toast-message-text" preLineText={preLineText}>
        {icon ? (
          <IconWrapper>
            {icon}
            {content}
          </IconWrapper>
        ) : content}
      </Text>
    </ToastWrapper>
  );
};
