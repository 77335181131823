import { DecodedRouteParams, Route } from 'config/routes';
import { ITranslate } from 'locale';
import { CompletionType, DealType, MarketplaceType } from 'utils/entities';

export enum FooterSectionType {
  Neighbourhoods = 'neighbourhoods',
  Beds = 'beds',
  Amenities = 'amenities',
  ApartmentType = 'apartmentType',
  Price = 'price',
  CloseNeighbourhoods = 'closeNeighbourhoods',
  ImportantInfo = 'importantInfo',
  ApartmentsInPopularCities = 'apartmentsInPopularCities',
  ProjectsInPopularCities = 'projectsInPopularCities',
  ApartmentsByArea = 'apartmentsByArea',
  ProjectsByArea = 'projectsByArea',
  SearchByLocation = 'searchByLocation',
  NewHomes = 'newHomes',
  AdditionalSearches = 'additionalSearches',
  CityFooter = 'cityFooter',
  CommercialRealEstateForRent = 'commercialRealEstateForRent',
  CommercialRealEstateForSale = 'commercialRealEstateForSale',
}

export enum LinkType {
  RouterLink = 'RouterLink',
  ALink = 'ALink',
}

export interface FooterRouterLink {
  id: string;
  text: string;
  newTab: boolean;
  type: LinkType.RouterLink;
  routeName?: Route;
  params: Partial<DecodedRouteParams>;
}

interface FooterALink {
  id: string;
  text: string;
  newTab: boolean;
  type: LinkType.ALink;
  href: string;
}

export type FooterLink =  FooterRouterLink | FooterALink;

interface FooterSectionGetterOptions {
  t: ITranslate;
  docId: string;
  completionType: CompletionType;
  accurateAddress: string;
  isProjectsView?: boolean;
  marketplace?: MarketplaceType;
}
export type FooterSectionGetter = (options: FooterSectionGetterOptions) => FooterSection[];

export type LinksByDealType = Record<DealType, FooterSectionGetter>;

export interface FooterSection {
  type: FooterSectionType;
  links: FooterLink[];
}

export type SocialLinkType = 'facebook' | 'twitter' | 'linkedin' | 'instagram' | 'tiktok' | 'podcast';

export interface ISocialLink {
  url: string;
  type: SocialLinkType;
}

type GeneralLinkType = 'labs' | 'careers' | 'terms' | 'privacy' | 'agents';

export interface GeneralLink {
  href: string;
  type: GeneralLinkType;
}
