import { IUpdateUserProfileVariables } from 'store/sagas/apiService/types';
import { User } from 'utils/entities';

export interface ProfileSettingsProps {
  user: User;
  onChangePassword: () => void;
  onUserDelete: (data: IReasonState) => void;
  onSaveChanges: (data: IUpdateUserProfileVariables) => void;
  isAgent: boolean;
  deregisterUserIsLoading: boolean;
}

export interface FormValues {
  phone: string;
  firstName: string;
}

export enum DeregisterReason {
  NoNeed = 'NO_NEED_ACCOUNT',
  DontUse = 'DONT_USE_ENOUGH',
  TooManyMotifications = 'TOO_MANY_NOTIFICATIONS',
  Other = 'OTHER',
}

export type IDeleteModal = 'reason' | 'confirmation';
export interface IReasonState {
  category: DeregisterReason;
  reason: string;
}
