interface BannerConfig {
  analyticsBannerId: string;
  href: string;
  imageAlt: string;
  imageUrlDesktop: string;
  imageUrlMobile: string;
}

const DEFAULTS = {
  hrefForAgent: 'https://menora-rent.co.il/%D7%9E%D7%93%D7%9C%D7%9F/',
  hrefForPrivateUser: 'https://menora-rent.co.il/%d7%9e%d7%93%d7%9c%d7%9f-%d7%91%d7%a2%d7%9c%d7%99-%d7%93%d7%99%d7%a8%d7%94-2/',
  imageAlt: 'Menora Insurance banner',
};

const uploadBulletinPopupBannersForPrivateUser: BannerConfig[] = [
  {
    analyticsBannerId: 'menora_set3_clear_head_v2_1',
    href: DEFAULTS.hrefForPrivateUser,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set3_336x340_v2_1.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set3_336x100_v2_1.png',
  },
  {
    analyticsBannerId: 'menora_set4_dont_wait_v2_1',
    href: DEFAULTS.hrefForPrivateUser,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set4_336x340_v2_1.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set4_336x100_v2_1.png',
  },
];
const uploadBulletinPopupBannersForAgent: BannerConfig[] = [
  {
    analyticsBannerId: 'menora_set3_clear_head_v2',
    href: DEFAULTS.hrefForAgent,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set3_336x340_v2.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set3_336x100_v2.png',
  },
  {
    analyticsBannerId: 'menora_set4_dont_wait_v2',
    href: DEFAULTS.hrefForAgent,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set4_336x340_v2.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set4_336x100_v2.png',
  },
];

const manageBulletinsPageBannersForPrivateUser: BannerConfig[] = [
  {
    analyticsBannerId: 'menora_set3_clear_head_v2_1',
    href: DEFAULTS.hrefForPrivateUser,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set3_728x90_v2_1.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set3_336x100_v2_1.png',
  },
  {
    analyticsBannerId: 'menora_set4_dont_wait_v2_1',
    href: DEFAULTS.hrefForPrivateUser,
    imageAlt: DEFAULTS.imageAlt,
    imageUrlDesktop: 'https://images2.madlan.co.il/email-templates/menora_set4_728x90_v2_1.png',
    imageUrlMobile: 'https://images2.madlan.co.il/email-templates/menora_set4_336x100_v2_1.png',
  },
];
const manageBulletinsPageBannersForAgent: BannerConfig[] = [];

const menoreBannersConfigs = {
  uploadBulletinPopup: {
    privateUser: uploadBulletinPopupBannersForPrivateUser,
    agent: uploadBulletinPopupBannersForAgent,
  },
  manageBulletinsPage: {
    privateUser: manageBulletinsPageBannersForPrivateUser,
    agent: manageBulletinsPageBannersForAgent,
  },
};

export function getBanner(key: keyof typeof menoreBannersConfigs, targetRole: 'agent' | 'privateUser'): BannerConfig | null {
  const bannersByTargetRole = menoreBannersConfigs[key];
  if (!bannersByTargetRole) return null;

  const banners = bannersByTargetRole[targetRole];
  if (!banners || banners.length === 0) return null;

  const randomIndex = Math.floor(Math.random() * banners.length);
  const bannerConfig = banners[randomIndex];

  return bannerConfig;
}
