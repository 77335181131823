import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { FormApi } from 'final-form';
import { useField } from 'react-final-form-hooks';
import { useLocale } from 'locale';
import { isServer } from 'utils';
import { KeyboardKeys } from 'utils/keyboardKeys';
import { colorToAlpha } from 'helpers/insight';
import { createPhoneURI } from 'hooks/useDisplayPhone';
import { useGlobalEventListener } from 'hooks/useGlobalEventListener';
import styled from '@emotion/styled';
import { TextField } from 'ds/components/input/TextField';
import { ModalOverlay } from 'ds/components/modal/styled';
import { Button, FeedbackButton } from 'ds/components/button';
import { SmallText, Text, H3 } from 'ds/components/typography';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { IFormFields } from 'store/sagas/apiService/types';
import PhoneIcon from 'assets/svg/phone-1-5.svg';
import DoneIcon from 'assets/svg/done.svg';
import CrossIcon from 'assets/svg/cross.svg';
import withEvents from 'analytics/withEvents';
import { State } from 'store/state';
import { madadContactModalStateSelector } from 'store/state/app/selectors';
import { MadadContactModalInfo } from 'components/madad/contact-modal/ContactModal';
import { SpacingBlock } from 'components/spacing-block';
import { LeadContactTermsAndConditions } from 'components/term-and-conditions/LeadContactTermsAndConditions';

const Root = styled.div<{ withRedesignedForm: boolean }>`
  ${({ theme, withRedesignedForm }) => `
    position: relative;
    z-index: 102;
    width: 480px;
    border-top-left-radius: ${theme.borderRadius.large};
    border-top-right-radius: ${theme.borderRadius.large};
    border-bottom-left-radius: ${theme.borderRadius.large};
    border-bottom-right-radius: ${theme.borderRadius.large};
    padding: ${withRedesignedForm ? `${theme.spacing(6)} 0 ${theme.spacing(4)}` : `${theme.spacing(5)} 0`};
    box-shadow: ${theme.shadow.level7};
    background-color: ${theme.colors.neutrals.white};
  `}
`;

const FormContainer = styled.div<{ isStaticFormMessage: boolean }>`
  margin-top: ${({ theme, isStaticFormMessage }) => isStaticFormMessage ? theme.spacing(5) : theme.spacing(3)};
  padding: ${({ theme }) => `0 ${theme.spacing(5)}`};
  p[data-auto="textfield-error-msg"] {
    position: absolute;
  }
`;

const FormRow = styled.div<{ withRedesignedFormPhone?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;
  > div {
    width: ${({ withRedesignedFormPhone }) => withRedesignedFormPhone ? '100%' : 'initial'};
    margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: ${({ theme }) =>  theme.spacing(3)};
    &:last-of-type {
      margin-${({ theme }) => theme.isRTL ? 'left' : 'right'}: 0;
    }
  }
`;

const CloseButton = styled.div`
  ${({ theme }) => `
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.isRTL ? 'right' : 'left'}: ${theme.spacing(2)};
    top: ${theme.spacing(2)};
    svg {
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
`;

const PhoneDescription = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
`;

const PhoneButtonBase = styled(Button)<{ isPhoneBorderHidden?: boolean }>`
  ${({ isPhoneBorderHidden, theme }) => isPhoneBorderHidden ? `
    border: none;
    padding: 0 ${theme.spacing(6)} 0 0;
    &:hover {
      border: none;
    }
  ` : ''}

  flex-basis: 50%;
  text-decoration: none;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const PhoneButton = PhoneButtonBase.withComponent('a');

const PhoneButtonWrapper = styled.div<{ withCallDescription: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ withCallDescription }) => withCallDescription ? 'space-between' : 'flex-end'};
  padding: ${props => props.theme.spacing(4)} ${props => props.theme.spacing(5)} 0;
  border-top: solid 1px ${({ theme }) => theme.colors.neutrals.grey8};
  > a {
    font-weight: 500;
  }
`;

const SubmitButton = styled(FeedbackButton)<{ withRedesignedSubmit: boolean, mode: string }>`
  ${({ theme, mode, withRedesignedSubmit }) => `
    width: 100%;
    background: ${mode === 'primary' ? theme.colors.brand.madlanGreen : 'initial'};
    border-color: ${mode === 'primary' ? theme.colors.brand.madlanGreen : theme.colors.neutrals.grey6};
    margin: ${withRedesignedSubmit ? '0' : `${theme.spacing(4)} 0 0`};
  `}
`;

const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 14;
`;

const VerySpecialModalOverlay = styled(ModalOverlay)`
  position: absolute;
  z-index: auto;
`;

const SentContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    min-width: 400px;
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(5)};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.brand.main};
    background-color: ${colorToAlpha(theme.colors.brand.main, 0.05)};
    svg {
      margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(2)};
    }
  `}
`;

const Title = styled(H3)<{ withRedesignedForm: boolean }>`
  ${({ theme, withRedesignedForm }) => `
    color: ${theme.colors.neutrals.grey1};
    text-align: center;
    margin-bottom: ${withRedesignedForm ? theme.spacing(4) : theme.spacing(3)};
  `}
`;

const ButtonDesription = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
    padding: ${theme.spacing(1.5)} ${theme.spacing(1)};
  `}
`;

export enum RequestType {
  RequestInfo = 'request_info',
  RequestMeeting = 'request_meeting',
}

export interface HebrewContactModalProps {
  formApi: FormApi;
  phone: string;
  contactName: string;
  isContacted: boolean;
  withStaticFormMessage?: boolean;
  withRedesignedFormPhone?: boolean;
  withFormName?: boolean;
  withCallDescription?: boolean;
  withRedesignedSubmit?: boolean;
  withRedesignedForm?: boolean;
  withAdditionalSubmitButton?: boolean;
  isPhoneBorderHidden?: boolean;
  isNotPromotedProject?: boolean;
  sellerInfo?: React.ReactNode;
  info?: MadadContactModalInfo;
  onPhoneFieldClick?: (props: MadadContactModalInfo) => void;

  onClose: (props: MadadContactModalInfo) => void;
  onSubmit: (requestType?: RequestType | React.SyntheticEvent<HTMLFormElement, Event>) => void;
  onPhoneShown?: () => void;
  onCallClick?: (values: Partial<IFormFields>) => void;
}

const HebrewContactModalBase: React.FC<HebrewContactModalProps> = props => {
  const {
    contactName,
    onClose,
    onSubmit,
    formApi,
    onPhoneShown,
    onCallClick,
    phone,
    isContacted,
    withStaticFormMessage,
    withRedesignedFormPhone,
    withFormName,
    withCallDescription,
    withRedesignedSubmit,
    withRedesignedForm,
    withAdditionalSubmitButton,
    isPhoneBorderHidden,
    isNotPromotedProject,
    sellerInfo,
    info,
    onPhoneFieldClick = noop,
  } = props;
  const { t } = useLocale();

  const closeBtnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    closeBtnRef.current.focus();
  }, []);

  const { submitFailed, submitting } = formApi.getState();
  const nameField = useField('name', formApi);
  const phoneField = useField('phone', formApi);

  const [ showPhoneNumber, setShowPhoneNumber ] = useState(false);

  const handlePhoneSubmit = (e: React.MouseEvent<HTMLElement>) => {
    if (showPhoneNumber) {
      onCallClick({ phone });
    }
    else {
      e.preventDefault();
      setShowPhoneNumber(true);
      onPhoneShown();
    }
  };

  const submit = (event: React.SyntheticEvent<EventTarget>, requestType?: RequestType) => {
    event.preventDefault();
    onSubmit(requestType);
  };

  const handleClose = (e: KeyboardEvent) => {
    if (e.code === KeyboardKeys.ESC) {
      handleOnclose();
    }
  };

  const handlePhoneInputClick = () => {
    onPhoneFieldClick(info);
  };

  const handleOnclose = () => {
    onClose(info);
  };

  useGlobalEventListener(isServer() ? null : window, 'keydown',  handleClose);

  return (
    <OverlayWrapper>
      <Modal>
        <VerySpecialModalOverlay onClick={handleOnclose} />
        <Root data-auto="contact-seller-popup" withRedesignedForm={withRedesignedForm}>
          <CloseButton ref={closeBtnRef} data-auto="contact-seller-popup-close-button" onClick={handleOnclose}>
            <CrossIcon width={24} height={24} />
          </CloseButton>
          <Title withRedesignedForm={withRedesignedForm} weight="bold">
            {t('unitPage.contactHebrewName', { withRedesignedForm, isNotPromotedProject, name: contactName })}
          </Title>
          {sellerInfo ? sellerInfo : null}
          <FormContainer data-auto="agent-contact-form-wrapper" isStaticFormMessage={withStaticFormMessage}>
            {isContacted ? (
              <SentContainer>
                <DoneIcon data-auto="done-icon" width={40} height={40} />
                <Text weight={'normal'} >{t('contactForm.contact.form.succeedMessage')}</Text>
              </SentContainer>
            ) : (
              <form data-auto="agent-contact-form" onSubmit={() => onSubmit()}>
                {withStaticFormMessage ? (
                  <SmallText weight="bold">
                    {isNotPromotedProject
                      ? t('contactForm.contact.form.static.message.notPromotedProject.popup')
                      : t('contactForm.contact.form.static.message')}
                  </SmallText>
                ) : null}
                <FormRow withRedesignedFormPhone={withRedesignedFormPhone}>
                  <TextField
                    data-auto="agent-contact-phone"
                    {...phoneField.input}
                    hasError={(submitFailed || phoneField.meta.dirty) && phoneField.meta.invalid}
                    type="tel"
                    placeholder={withRedesignedFormPhone ? t('contactForm.contact.form.placeholder.phone') : null}
                    label={withRedesignedFormPhone ? null : t('contactAgentModal.labels.phone')}
                    errorMessage={phoneField.meta.error}
                    disabled={submitting}
                    onClick={handlePhoneInputClick}
                  />
                  {withFormName ? (
                    <TextField
                      data-auto="agent-contact-name"
                      {...nameField.input}
                      hasError={(submitFailed || nameField.meta.dirty) && nameField.meta.invalid}
                      errorMessage={nameField.meta.error}
                      disabled={submitting}
                      type="text"
                      label={t('contactAgentModal.labels.name')}
                    />
                  ) : null}
                </FormRow>
                <FormRow>
                  {withAdditionalSubmitButton ? (
                    <>
                      <SubmitButton
                        data-auto="agent-form-contact-button"
                        mode="ghost"
                        type="submit"
                        withRedesignedSubmit={withRedesignedSubmit}
                        isLoading={submitting}
                        onClick={(e) => submit(e, RequestType.RequestMeeting)}
                        disabled={submitting}
                        size="large"
                      >
                        {t('contactForm.contact.form.formButton', { disabled: submitting, isAdditionalSubmitButton: true, isRedesignSubmit: null })}
                      </SubmitButton>
                      <ButtonDesription>
                        {t('contactForm.contact.form.formButton.description')}
                      </ButtonDesription>
                    </>
                  ) : null}
                  <SubmitButton
                    data-auto="agent-form-contact-button"
                    mode="primary"
                    type="submit"
                    withRedesignedSubmit={withRedesignedSubmit}
                    isLoading={submitting}
                    onClick={(e) => submit(e, RequestType.RequestInfo)}
                    disabled={submitting}
                    size="large"
                  >
                    {t('contactAgentModal.send', { withRedesignedSubmit })}
                  </SubmitButton>
                </FormRow>
                <SpacingBlock mTop={1} mBottom={5}>
                  <LeadContactTermsAndConditions />
                </SpacingBlock>
              </form>
            )}
          </FormContainer>
          <PhoneButtonWrapper withCallDescription={withCallDescription}>
            {withCallDescription ? (
              <PhoneDescription weight={withRedesignedFormPhone ? 'bold' : 'normal'}>
                {t('contactAgentModal.phoneButton.title')}
              </PhoneDescription>
            ) : null}
            <PhoneButton
              data-auto="phone-button"
              isPhoneBorderHidden={isPhoneBorderHidden}
              mode="ghost"
              href={createPhoneURI(phone)}
              onClick={handlePhoneSubmit}
              size="large"
            >
              <PhoneIcon/>
              {showPhoneNumber ? phone : t('contactAgentModal.phoneButton')}
            </PhoneButton>
          </PhoneButtonWrapper>
        </Root>
      </Modal>
    </OverlayWrapper>
  );
};

const HebrewContactModalWithEvents = withEvents<HebrewContactModalProps>((sendEvent) => ({
  onPhoneFieldClick: (props: MadadContactModalInfo) => {
    sendEvent('user_contact_me_form_phone_text_box_click', 'user', {
      event: {
        agent_details: {
          id: props.id,
          name: props.agentName,
          office_id: props.officeId,
          office_name: props.officeName,
          phone_number: props.phone,
        },
        source: props.eventSource,
      },
      rtbData: {
        phone: props.phone,
      },
    });
  },
  onClose: (props: MadadContactModalInfo) => {
    sendEvent('user_contact_me_form_close', 'user', {
      event: {
        agent_details: {
          id: props.id,
          name: props.agentName,
          office_id: props.officeId,
          office_name: props.officeName,
          phone_number: props.phone,
        },
        source: props.eventSource,
      },
    });
  },
}))(HebrewContactModalBase);


const mapStateToProps = (state: State) => ({
  info: madadContactModalStateSelector(state),
});

export const HebrewContactModal = connect(mapStateToProps)(HebrewContactModalWithEvents);
