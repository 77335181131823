import { DealType, MarketplaceType } from 'utils/entities';
import { BreakpointValue } from 'consts/breakpoints';
import { HebrewKeys } from 'locale';


export type AmenitiesSections = 'listingAmenities'
  | 'buildingAmenities'
  | 'common'
  | 'another'
  | 'parking';

export type FiltersListByRange = Partial<Record<BreakpointValue, Filter[]>> & {
  1: Filter[];
  3: Filter[];
};

interface FilterItem {
  filterType: FilterType;
  isInline: boolean;
}

interface SingleFilter {
  type: 'single';
  item: FilterItem;
}
interface DividerFilter {
  type: 'divider';
  item: JSX.Element;
}

export enum GroupType {
  Price = 'price',
  Additional = 'additional',
  Size = 'size',
}

interface GroupFilter {
  type: 'group';
  groupType: GroupType;
  items: FilterItem[];
}

interface CommonAmenity {
  amenity: Amenity;
  icon: React.ReactNode;
}

interface AmenityCheckbox extends CommonAmenity {
  type: 'checkbox';
}

interface AmenityBlock extends CommonAmenity {
  type: 'block';
}

type IAmenity = AmenityCheckbox | AmenityBlock;

interface AmenitySectionCommon {
  name: AmenitiesSections;
  items: IAmenity[];
}

export interface AmenityCheckboxGroupSection extends AmenitySectionCommon {
  type: 'checkboxGroup';
}

export interface AmenityGridSection extends AmenitySectionCommon {
  type: 'grid';
}

export type AmenitySection = AmenityCheckboxGroupSection | AmenityGridSection;

export interface AmenitiesBody {
  title: HebrewKeys;
  sections: AmenitySection[];
}

type Amenities = Partial<Record<BreakpointValue, AmenitiesBody>> & {
  1: AmenitiesBody;
};

export type Filter = SingleFilter | GroupFilter | DividerFilter;

export interface BaseConfig {
  amenities: Amenities;
  propertyTypes: PropertyType[];
  roomsOptions: RoomOption[];
  bathsOptions: BathOption[];
  floorsOptions: number[];
  uploadFloorsOptions: number[];
  areaOptions: number[];
  employeesOptions: number[];
  budgetByDealType: Record<DealType, BudgetProps>;
  budgetByPerSquareMeter: Record<DealType, BudgetProps>;
  conditions: Condition[];
  seller: Seller[];
  filtersByDealType: Record<DealType, FiltersListByRange>;
  filtersIcons: Record<FilterType | 'additional' | 'price', JSX.Element>;
  monthlyTaxRange?: BudgetProps;
  priceChanges?: IPriceChanges;
  dealTypeOptions: DealType[];
  qualityClassOptions: QualityClassOption[];
}

export type ConfigByMarketplaceType = Record<MarketplaceType, BaseConfig>;

export type GetFilterProps = <K extends FilterType>(type: K, i: IFiltersState, c: IFiltersState) => GenericFilterProps<K>;

export interface GroupFilterProps {
  getProps: GetFilterProps;
  intermediateValues: IFiltersState;
  commitedValues: IFiltersState;
  filters: FilterItem[];
  config: BaseConfig;
  marketplace: MarketplaceType;
  isHeaderTabSubmenuVisible: boolean;
  noPopups?: boolean;
  allowShowTooltip?: boolean;
  makeMultipleResetValue: (arr: FilterType[]) => void;
  commitValues: () => void;
  isFilterActive: (filtersAliases: string[], c: IFiltersState) => boolean;
  countActiveFilters: (filtersAliases: string[], c: IFiltersState) => number;
}
export type SingleFilterMap = Record<MarketplaceType, { [K in FilterType]: React.ComponentType<GenericFilterProps<K>> }>;
export type GroupFilterMap = Record<MarketplaceType, Record<GroupType, React.ComponentType<GroupFilterProps>>>;

export type RangeTuple<N extends number> = [ N, N ];

export enum RoomOption {
  Studio = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Fifteen = 15,
  Twenty = 20,
  All = -1,
}

export enum QualityClassOption {
  ClassA = 'classA',
  ClassB = 'classB',
  ClassC = 'classC',
}

export enum Amenity {
  // US specific
  UnitLaundry = 'unitLaundry',
  UnitPetsAllowed = 'unitPetsAllowed',
  Dishwasher = 'dishwasher',
  Furnished = 'furnished',
  Fireplace = 'fireplace',
  Heating = 'heating',
  Loft = 'loft',
  OutdoorSpace = 'outdoorSpace',
  Garage = 'garage',
  Gym = 'gym',
  BuildingLaundry = 'buildingLaundry',
  Pool = 'pool',
  SmokeFree = 'smokeFree',
  Roofdeck = 'roofdeck',
  // ^^^^^^^^^^^
  // IL specific
  Parking = 'parking',
  Balcony = 'balcony',
  SecureRoom = 'secureRoom',
  Accessible = 'accessible',
  Grating = 'grating',
  Reception = 'reception',
  // ^^^^^^^^^^^
  // common
  Elevator = 'elevator',
  AirConditioner = 'airConditioner',
  Storage = 'storage',
  Basement = 'basement',
  Attic = 'attic',
  // ^^^^^^^^^^^
  Doorman = 'doorman',
  OuterSpace = 'outerSpace',
  ParkingEmployee = 'parkingEmployee',
  ParkingBikes = 'parkingBikes',
  ParkingVisitors = 'parkingVisitors',
  Kitchenette = 'kitchenette',
  FullTimeAccess = 'fullTimeAccess',
  Alarm = 'alarm',
  ConferenceRoom = 'conferenceRoom',
  SubDivisible = 'subDivisible',
  ColdRoom = 'coldRoom',
  LoadingRamp = 'loadingRamp',
  HighCeiling = 'highCeiling',
  ActiveBusiness = 'activeBusiness',
}

export enum Condition {
  New = 'new',
  AsNew = 'asNew',
  Renovated = 'renovated',
  Preserved = 'preserved',
  ToRenovated = 'toRenovated',
  FullFinish = 'fullFinish',
  FurnishedFinish = 'furnishedFinish',
  RequiresRenovation = 'requiresRenovation',
  Finish = 'finish',
  Skin = 'skin',
}

export enum Seller {
  Private = 'private',
  Agent = 'agent',
  Developer = 'developer',
}

export enum PropertyType {
  Hall = 'hall',
  Clinic = 'clinic',
  Hotel = 'hotel',
  Flat = 'flat',
  PrivateHouse = 'villa',
  GardenApartment = 'gardenApartment',
  Cottage = 'cottage',
  TwoFamilyDwelling = 'dualCottage',
  PenthouseApp = 'penthouseApp',
  Penthouse = 'penthouse',
  Rooftop = 'attic',
  Shop = 'shop',
  Duplex = 'duplex',
  AgricultureLand = 'agricultureLand',
  Land = 'land',
  Building = 'building',
  Office = 'office',
  Project = 'project',
  RoofFlat = 'roofFlat',
  MiniPenthouse = 'miniPenthouse',
  ResidentialUnit = 'residentialUnit',
  SerialCottage = 'serialCottage',
  Studio = 'studio',
  Triplex = 'triplex',
  Basement = 'basement',
  Storeroom = 'storeroom',
  SplitFlat = 'splitFlat',
  VacationProperty = 'vacationProperty',
  BuyerTeam = 'buyerTeam',
  ProfessionalFarm = 'professionalFarm',
  Parking = 'parking',
  Other = 'other',
  AgricultureFarm = 'agricultureFarm',
  Restaurant = 'restaurant',
  SharedWorkSpace = 'sharedWorkSpace',
  ParkingLot = 'parkingLot',
  IndustrialBuilding = 'industrialBuilding',
  IncomeProducingProperty = 'incomeProducingProperty',
  FarmLand = 'farmLand',
}

export type BathOption = 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4;


export type FilterType = keyof IFiltersState;

export interface IPriceChanges {
  underPriceEstimation: boolean;
  priceDrop: boolean;
}

export type ValueSetter<K extends FilterType> = (value: IFiltersState[K], callback?: () => void) => void;

export interface GenericFilterProps<K extends FilterType> {
  commitValues: () => void;
  resetValue: () => void;
  resetAll: (withDealType?: DealType) => void;
  setIntermediateValue: ValueSetter<K>;
  setIntermediateProjectDiscountValue?: ValueSetter<'projectDiscount'>;
  initialValue: IFiltersState[K];
  intermediateValue: IFiltersState[K];
  commitedValue: IFiltersState[K];
  marketplace: MarketplaceType;
  dealType: DealType;
  projectDiscount?: boolean;
  alignment?: 'last';
  type: K;
  config: BaseConfig;
  isInline?: boolean;
  isMobile?: boolean;
}
export interface IFiltersState {
  dealType: DealType;
  priceRange: RangeTuple<number>;
  numberOfEmployeesRange: RangeTuple<number>;
  monthlyTaxRange: RangeTuple<number>;
  roomsRange: RangeTuple<RoomOption>;
  propertyTypes: PropertyType[];
  amenities: Amenity[];
  bathsRange: RangeTuple<BathOption>;
  conditions: Condition[];
  seller: Seller[];
  fee: boolean;
  projectDiscount: boolean;
  floorRange: RangeTuple<number>;
  areaRange: RangeTuple<number>;
  ppmRange: RangeTuple<number>;
  qualityClass: QualityClassOption[];
  // @TODO: these are not designed yet
  // area: number;
  // preWar: boolean;
  // yearBuilt: number;
  priceChanges: IPriceChanges;
}

export interface BudgetSliderProps {
  min: number;
  max: number;
  step: number;
  minDelta: number;
}

export interface BudgetProps {
  initialBudgetValue: RangeTuple<number>;
  budgetSliderProps: BudgetSliderProps;
}
