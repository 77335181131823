import React from 'react';
import { WebAuthSocialsButtons, WebAuthSocialsButtonsProps } from './web-social-buttons';

// tslint:disable-next-line: no-empty-interface
interface AuthSocialsButtonsProps extends WebAuthSocialsButtonsProps {}


const AuthSocialsButtons: React.FC<AuthSocialsButtonsProps> = (props) => {
  const {
    withSuggestionBefore,
    onSocialLogin,
  } = props;


  return (
    <WebAuthSocialsButtons
      withSuggestionBefore={withSuggestionBefore}
      onSocialLogin={onSocialLogin}
    />
  );
};

export default AuthSocialsButtons;
