import { LogoWrapper } from 'components/authentication/styled';
import React from 'react';
import ClockIcon from 'assets/svg/auth/clock.svg';
import { useLocale } from 'locale';
import { Link } from 'ds/components/link';
import { TimeoutWrapper, SkipLink, TimeoutInfo, TimeoutInfoHeader } from './styled';
import { SpacingBlock } from 'components/spacing-block';
import { AuthenticationModalType } from '../types';


export const TimeoutStep: React.FC<{ setActiveModal: (type: AuthenticationModalType) => void; }> = ({ setActiveModal }) => {
  const { t } = useLocale();

  const handleLinkClick = () => {
    setActiveModal(AuthenticationModalType.AuthTrap);
  };

  return (
    <TimeoutWrapper>
      <LogoWrapper>
        <ClockIcon width={44} height={44} />
      </LogoWrapper>
      <SpacingBlock mTop={3} />
      <TimeoutInfoHeader weight="bold">
        {t('authentication.timeout.title')}
      </TimeoutInfoHeader>
      <SpacingBlock mTop={1} />
      <TimeoutInfo>{t('authentication.timeout.info')}</TimeoutInfo>
      <SpacingBlock mTop={5} />
      <SkipLink>
        <Link simple onClick={handleLinkClick}>{t('authentication.timeout.link')}</Link>
      </SkipLink>
    </TimeoutWrapper>
  );
};
