import React, { MutableRefObject, useCallback, useState } from 'react';
import { Text } from 'ds/components/typography';
import { Root, ModalRoot, PhoneButton, Header, Main, FormTitle, Column, Row, FieldsContainer, SentContainer, ModalFooter } from './styled';
import DescriptionApartment from '../description-apartment';
import { IBulletin, IAddressDetails, IProject, PromotionValues, ICommercialProject, ICommercialBulletin } from 'utils/entities';
import { IContactAgentValues } from 'helpers/contactFormStore';
import { ContactAgentSubmitterSource, SubmitContactAgentPayload } from 'store/state/app/payloads';
import { POCLayout } from '../poc-layout';
import { createPhoneURI, useDisplayPhone } from 'hooks/useDisplayPhone';
import PhoneIcon from 'assets/svg/phone-1-5.svg';
import DoneIcon from 'assets/svg/done.svg';
import { useLocale } from 'locale';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { SpacingBlock } from 'components/spacing-block';
import { LeadContactTermsAndConditions } from 'components/term-and-conditions/LeadContactTermsAndConditions';
import { createMortgageValidationFn } from 'components/madad/contact-modal/utils';
import { IContactPageSubmitVariables } from 'store/sagas/apiService/types';
import { getSpacing } from 'ds/theme/spacing';
import { getAddress, getShortlistContactFormParams, renderAddress, shortlistContactFormTranslateKeys, UnitCardPrimaryParams } from 'utils/addressFormatter';
import { ContactAgentModalSource } from 'store/state/app';
import { isBulletinPoiType, isProjectPoiType } from 'utils/marketplaceRoutes';
import replaceEmoji from 'utils/emojis';
import { FullPoiType } from './modeB';
import { useABTests } from 'hooks/useABTests';
import { PrioritizeFormProjectsAbTest } from 'config/abTests';


export interface MortgageFormProps {
  contactFormRef?: MutableRefObject<HTMLDivElement>;
  description?: string;
  isContacted: boolean;
  isLeadingAgent: boolean;
  poi: IProject | ICommercialProject | IBulletin | ICommercialBulletin;
  isModal: boolean;
  source?: ContactAgentModalSource;
  addressDetails?: IAddressDetails;
  contactFormInitialValues?: IContactAgentValues;
  onContactPageSubmit: (payload: SubmitContactAgentPayload) => void;
  handleSubmitEvent?: (hasErrors: boolean, formValues: IContactPageSubmitVariables) => void;
  onCallClickEvent?: (isVisible: boolean, formValues: IContactPageSubmitVariables) => void;
  hidePhoneNumber?: boolean;
  onContactAgentInputClick?: (eventName: string, poi: FullPoiType) => void;
}

export const Mortgage: React.FC<MortgageFormProps> = props => {
  const {
    poi,
    isModal,
    isContacted,
    description,
    addressDetails,
    contactFormRef,
    onCallClickEvent,
    handleSubmitEvent,
    onContactPageSubmit,
    contactFormInitialValues,
    hidePhoneNumber,
    onContactAgentInputClick,
    source,
    isLeadingAgent,
  } = props;

  const {
    phone: initialPhone,
    name: initialName,
  } = contactFormInitialValues;

  const { t } = useLocale();
  const address = getAddress<UnitCardPrimaryParams>(t, renderAddress)(
    addressDetails,
    shortlistContactFormTranslateKeys,
    addressDetails.resolutionPreferences,
    getShortlistContactFormParams
  );
  const isProject = isProjectPoiType(poi);
  const { prioritizeFormProjects } = useABTests();
  const messagePlaceholder = isProjectPoiType(poi) && poi.promotionStatus.status === PromotionValues.Promoted
    ? t('contactAgentModal.labels.messagePlaceholderProject', { projectName: poi.projectName })
    : t('contactAgentModal.labels.messagePlaceholder', { address });

  const [ phoneNumberIsVisible, setPhoneNumberIsVisible ] = useState(false);
  const [ userMessage, setUserMessage ] = useState(messagePlaceholder);
  const [ userPhone, setUserPhone ] = useState(initialPhone || '');
  const [ userName, setUserName ] = useState(initialName || '');
  const [ formErrors, setFormErrors ] = useState<{ phone?: string; message?: string; }>({});
  const breakpoint = useScreenBreakpoint();
  const phone = useDisplayPhone({ id: poi.id, type: poi.type });
  const isMobile = breakpoint === 1;
  // Changes should be implemented only for range 4
  const isDesktop = breakpoint === 4;
  const poc = isBulletinPoiType(poi) && poi.poc;
  const showOnlyOffice = poc && 'showOnlyOffice' in poc && poc.showOnlyOffice;

  const validate = useCallback(createMortgageValidationFn({
    phone: {
      invalid: t('contactAgentModal.form.phone.invalid'),
      required: t('contactAgentModal.form.phone.required'),
    },
    message: {
      required: t('contactAgentModal.form.message.required'),
    },
  }), [ t ]);

  const onSubmit = () => {
    const errors = validate({
      phone: userPhone,
      message: userMessage,
    });
    const formValues = {
      phone: userPhone,
      name: userName,
      message: userMessage,
      isMortgageConsult: false,
      type: poi.type,
      id: poi.id,
      ...(source ? { eventSource: source } : null),
    };
    setFormErrors(errors);

    let hasErrors = false;

    if (isMobile || isModal) {
      if (errors.phone || errors.message) {
        hasErrors = true;
      }
    }
    else {
      if (errors.phone) {
        hasErrors = true;
      }
    }

    handleSubmitEvent(hasErrors, formValues);

    if (hasErrors) return;

    onContactPageSubmit({
      key: formValues.id,
      variables: formValues,
      source: ContactAgentSubmitterSource.Form,
    });
  };

  const onPhoneChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserPhone(e.currentTarget.value), [ setUserPhone ]);
  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.currentTarget.value), [ setUserName ]);
  const onMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserMessage(replaceEmoji(e.currentTarget.value)), [ setUserMessage ]);

  const handleCallClickEvent = () => {
    const isCallEvent = isMobile || phoneNumberIsVisible;
    onCallClickEvent(isCallEvent, { phone: userPhone, name: userName, message: userMessage, isMortgageConsult: false, id: poi.id, type: poi.type });
  };

  const handlePhoneSubmit = (e: React.MouseEvent<HTMLElement>) => {
    handleCallClickEvent();

    if (!phoneNumberIsVisible) {
      e.preventDefault();
      setPhoneNumberIsVisible(true);
    }
  };

  if (isModal) {
    return (
      <ModalRoot data-auto="popup-leads-form">
        <FormTitle
          style={{ marginBottom: getSpacing(1), textAlign: 'center' }}
          weight="bold"
        >
          {t('contactForm.contact.form.withMortgage.mobile.title', { isContacted: false })}
        </FormTitle>
        <Header isMobile>
          <POCLayout
            poi={poi}
            isLeadingAgent={isLeadingAgent}
            oneRowLayout
            showOnlyOffice={showOnlyOffice}
            isModal
          />
        </Header>
        <Main ref={contactFormRef}>
          {isContacted ? (
            <SentContainer>
              <DoneIcon width={40} height={40} />
              <Text>{t('contactForm.contact.form.withMortgage.succeedMessage', { isMobile: false })}</Text>
            </SentContainer>
          ) : (
            <>
              <Row>
                <FieldsContainer largeInput isMobile noTopMargin>
                  <Column>
                    <Row>
                      <Column>
                        <TextField
                          fullWidth
                          value={userPhone}
                          hasError={Boolean(formErrors.phone)}
                          data-auto="phone-input"
                          type="tel"
                          errorMessage={formErrors.phone}
                          onChange={onPhoneChange}
                          placeholder={t('contactAgentModal.labels.phone')}
                          onClick={() => onContactAgentInputClick('property_lead_form_phone_text_box_click', poi)}
                        />
                      </Column>
                      {isProject ? (
                        <Column>
                          <TextField
                            fullWidth
                            data-auto="name-input"
                            value={userName}
                            onChange={onNameChange}
                            placeholder={t('contactAgentModal.labels.name')}
                          />
                        </Column>
                      ) : null}
                    </Row>
                  </Column>
                  {isProject ? null : (
                    <Column>
                      <TextField
                        rows={3}
                        multiline
                        fullWidth
                        data-auto="message-input"
                        hasError={Boolean(formErrors.message)}
                        errorMessage={formErrors.message}
                        value={userMessage}
                        onChange={onMessageChange}
                        onClick={() => onContactAgentInputClick('property_lead_form_messege_box_click', poi)}
                      />
                    </Column>
                  )}
                </FieldsContainer>
              </Row>
              <Button
                style={{ marginTop: getSpacing(1) }}
                onClick={onSubmit}
                fullWidth
                size="extraLarge"
              >
                {t('contactForm.contact.form.withMortgage.submitPhone.modal.button')}
              </Button>
            </>
          )}
        </Main>
        {hidePhoneNumber ? null : (
          <ModalFooter isProject={isProject} isDesktop={isDesktop}>
            <FormTitle>
              {t('contactForm.contact.form.phoneButton.title')}
            </FormTitle>
            <PhoneButton
              data-auto="phone-number-button"
              mode="ghost"
              size="extraLarge"
              onClick={handlePhoneSubmit}
              href={createPhoneURI(phone)}
            >
              <PhoneIcon />
              {phoneNumberIsVisible ? phone : t('contactForm.contact.form.withMortgage.phoneButton', { isMobile: false })}
            </PhoneButton>
          </ModalFooter>
        )}
      </ModalRoot>
    );
  }

  const phoneContent = isMobile ? (
    <PhoneButton
      data-auto="phone-number-button"
      size="large"
      style={{ flexBasis: '40px' }}
      href={createPhoneURI(phone)}
      onClick={handleCallClickEvent}
    >
      {t('contactForm.contact.form.withMortgage.phoneButton', { isMobile })}
    </PhoneButton>
  ) : (
    <PhoneButton
      data-auto="phone-number-button"
      mode="ghost"
      size="extraLarge"
      onClick={handlePhoneSubmit}
      href={createPhoneURI(phone)}
    >
      <PhoneIcon />
      {phoneNumberIsVisible ? phone : t('contactForm.contact.form.withMortgage.phoneButton', { isMobile })}
    </PhoneButton>
  );

  const isPrioritizeFormAbTestActive = isProject && [ PrioritizeFormProjectsAbTest.modeB, PrioritizeFormProjectsAbTest.modeC ].includes(prioritizeFormProjects);

  return (
    <>
      {description ? <DescriptionApartment description={description} withRedesignedForm /> : null}
      <Root data-auto="leads-form">
        <Header isMobile={isMobile} isProject={isProject}>
          {isMobile ? (
            <FormTitle style={{ marginBottom: getSpacing(2) }} weight="bold">
              {t('contactForm.contact.form.withMortgage.mobile.title', { isContacted })}
            </FormTitle>
          ) : null}
          <POCLayout isLeadingAgent={isLeadingAgent} poi={poi} oneRowLayout={!isMobile} showOnlyOffice={showOnlyOffice} />
          {hidePhoneNumber || isPrioritizeFormAbTestActive ? null : phoneContent}
        </Header>
        <Main ref={contactFormRef} isMobile={isMobile} isProject={isProject}>
          {isContacted ? (
            <SentContainer>
              <DoneIcon width={40} height={40} />
              <Text>{t('contactForm.contact.form.withMortgage.succeedMessage', { isMobile })}</Text>
            </SentContainer>
          ) : (
            <>
              {isMobile ? (
                <FormTitle weight="bold">{t('contactForm.contact.form.withMortgage.title', { isPrioritizeFormAbTest: Boolean(isPrioritizeFormAbTestActive) })}</FormTitle>
              ) : null}
              <Row>
                <FieldsContainer largeInput={!isMobile} isMobile={isMobile} isProject={isProject} isDesktop={isDesktop}>
                  <Column>
                    <TextField
                      fullWidth
                      data-auto="phone-input"
                      value={userPhone}
                      type="tel"
                      hasError={Boolean(formErrors.phone)}
                      errorMessage={formErrors.phone}
                      onChange={onPhoneChange}
                      placeholder={t('contactAgentModal.labels.phone')}
                    />
                  </Column>
                  {isMobile ? (
                    <>
                      {isProject ? (
                        <Column>
                          <TextField
                            fullWidth
                            data-auto="name-input"
                            value={userName}
                            onChange={onNameChange}
                            placeholder={t('contactAgentModal.labels.name')}
                          />
                        </Column>
                      ) : null}
                      {isProject ? null : (
                        <Column>
                          <TextField
                            rows={3}
                            multiline
                            fullWidth
                            data-auto="message-input"
                            hasError={Boolean(formErrors.message)}
                            errorMessage={formErrors.message}
                            value={userMessage}
                            onChange={onMessageChange}
                          />
                        </Column>
                      )}
                    </>
                  ) : (
                    <>
                      {isProject ? (
                        <Column>
                          <TextField
                            fullWidth
                            data-auto="name-input"
                            value={userName}
                            onChange={onNameChange}
                            placeholder={t('contactAgentModal.labels.name')}
                          />
                        </Column>
                      ) : null}
                      <Column>
                        <Button
                          data-auto="agent-form-contact-button"
                          style={{ whiteSpace: 'nowrap', minWidth: isProject && isDesktop ? '266px' : undefined }}
                          onClick={onSubmit}
                          fullWidth
                          size="extraLarge"
                        >
                          {t('contactForm.contact.form.withMortgage.submitPhone.button')}
                        </Button>
                      </Column>
                    </>
                  )}
                </FieldsContainer>
              </Row>
              {isMobile ? (
                <Button fullWidth mode={isPrioritizeFormAbTestActive ? 'primary' : 'ghost'} size="large" onClick={onSubmit}>
                  {t('contactForm.contact.form.withMortgage.submitPhone.modal.button', { isPrioritizeFormAbTest: Boolean(isPrioritizeFormAbTestActive) })}
                </Button>
              ) : null}
              {isMobile ? (
                <SpacingBlock mTop={1}>
                  <LeadContactTermsAndConditions />
                </SpacingBlock>
              ) : null}
            </>
          )}
        </Main>
      </Root>
    </>
  );
};
