import React from 'react';
import { useLocale } from 'locale';
import { Platform } from 'react-native/config';
import { SocialButton } from 'components/authentication/social-buttons/social-button';
import { SocialVendor } from 'components/authentication/types';
import { ReactNativeClientTunnelContext, AUTH_TYPE } from 'react-native/reactNativeClientTunnel';
import * as Styled from './styled';
import { getPlatformName, getConfigKeyValue } from 'react-native/lib/platform';


const buttonsMap: Record<Platform, SocialVendor[]> = {
  'android': [ 'google' ],
  'ios': [
    'google',
    'apple',
  ],
};

function showButtonCondition(type: SocialVendor, platform: Platform): boolean {
  switch (type) {
    case 'apple':
      if (platform === 'ios') return Boolean(getConfigKeyValue('showAppleSignIn'));
      else return false;
    case 'google':
      if (platform === 'ios') return Boolean(getConfigKeyValue('showGoogleSignIn'));
      else return true;
    default:
      return true;
  }
}

interface SocialsButtonsProps {
  suggestion?: JSX.Element;
}

export const SocialsButtons: React.FC<SocialsButtonsProps> = ({ suggestion }) => {
  const { t } = useLocale();
  const ctx = React.useContext(ReactNativeClientTunnelContext);
  const platformName = getPlatformName();
  const buttons = (buttonsMap[getPlatformName()] || []).filter(type => showButtonCondition(type, platformName));

  if (!buttons.length) return null;

  return (
    <>
      {suggestion}
      <Styled.Root>
        {buttons.map((type) => (
          <SocialButton key={type} onClick={() => ctx.push({ type: AUTH_TYPE, payload: { type } })} type={type}>
            {t('authentication.socialButtonLabel', { type })}
          </SocialButton>
        ))}
      </Styled.Root>
    </>
  );
};
