import React, { useState } from 'react';
import { AuthenticationInfoHeader, AuthenticationRoot, LogoWrapper } from 'components/authentication/styled';
import KeyIcon from 'assets/svg/auth/key-icon.svg';
import CheckIcon from 'assets/svg/auth/circle-check.svg';
import GreenCheckIcon from 'assets/svg/auth/circle-green-check.svg';
import { useLocale } from 'locale';
import { TextField } from 'ds/components/input/TextField';
import { Button } from 'ds/components/button';
import { noop } from 'lodash';
import { ControlsWrapper, Rule, RulesWrapper, RuleTitle, SetPassWrapper } from './styled';
import { Сonsents } from 'components/authentication/consents';
import { SpacingBlock } from 'components/spacing-block';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { ApolloQueryResult } from 'apollo-client';
import { ConsentTypeNG, IConsent, IResetPasswordUpdateVariables, MutationResponseByType, MutationType } from 'store/sagas/apiService/types';
import Spinner from 'components/widget/prices/prices-table/tables/history/affiliation/Spinner';


const PASSWORD_LENGTH = 8;

interface IPassworRules {
  minLengthRule: boolean;
  camelCaseRule: boolean;
  numberRule: boolean;
  specCharacterRule: boolean;
}

const validatePassword = (password: string): IPassworRules => {
  const hasUpperCase = !!password && /[A-Z]/.test(password);
  const hasLowerCase = !!password && /[a-z]/.test(password);
  const hasNumbers = !!password && /[0-9]/.test(password);
  const hasSpecialChars = !!password && /[@#$%^&*!=(),.?":{}|<>]/.test(password);

  return {
    minLengthRule: password.length >= PASSWORD_LENGTH,
    camelCaseRule: hasUpperCase && hasLowerCase,
    numberRule: hasNumbers,
    specCharacterRule: hasSpecialChars,
  };
};

interface AuthenticationEmailFormProps {
  password: string;
  smallWidthSubmitButton?: boolean;
  setPassword(e: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit: () => void;
  passwordRules: IPassworRules;
  consents: IConsent[];
  handleConsentsChange?: (data: IConsent[]) => void;
  isLoading: boolean;
}

export const RuleEl: React.FC<{ isActive: boolean, title: string }> = ({ isActive, title }) => {
  return (
    <Rule isActive={isActive}>
      {isActive ? <GreenCheckIcon width={16} height={16} /> : <CheckIcon width={16} height={16} />}
      {title}
    </Rule>
  );
};

export const PasswordSetForm: React.FC<AuthenticationEmailFormProps> = (props) => {
  const {
    password,
    smallWidthSubmitButton,
    setPassword,
    onSubmit,
    passwordRules,
    handleConsentsChange,
    consents,
    isLoading,
  } = props;

  const { t } = useLocale();
  const screen = useScreenBreakpoint();
  const isMobile = screen < 3;
  const { camelCaseRule, minLengthRule, numberRule, specCharacterRule } = passwordRules;

  const updateDisabled = !camelCaseRule
    || !minLengthRule
    || !numberRule
    || !specCharacterRule
    || (handleConsentsChange && !consents[0].approval)
    || isLoading;

  return (
    <AuthenticationRoot>
        <TextField
          data-auto="email-pass-set-password-field"
          type="password"
          fullWidth
          onChange={setPassword}
          value={password}
          placeholder={t('authentication.setPass.placeholder')}
        />
        <SpacingBlock mTop={2} />
        <RulesWrapper>
          {isMobile ? <RuleTitle weight="bold">{t('authentication.setPass.rule.title')}</RuleTitle> : null}
          <RuleEl isActive={minLengthRule} title={t('authentication.setPass.rule.length')} />
          <RuleEl isActive={camelCaseRule} title={t('authentication.setPass.rule.camelCase')} />
          <RuleEl isActive={numberRule} title={t('authentication.setPass.rule.number')} />
          <RuleEl isActive={specCharacterRule} title={t('authentication.setPass.rule.specialCharacter')} />
        </RulesWrapper>
        <SpacingBlock mTop={3} />
        {handleConsentsChange ? <Сonsents consents={consents} setConsents={handleConsentsChange} /> : null}
        <SpacingBlock mTop={2} />
        <ControlsWrapper smallWidth={smallWidthSubmitButton}>
          <Button
            data-auto="authentication-submit-set-password-button"
            fullWidth
            size="extraLarge"
            disabled={updateDisabled}
            onClick={updateDisabled ? noop : onSubmit}
          >
            {isLoading ? <Spinner /> : t('authentication.setPass.buttonText')}
          </Button>
        </ControlsWrapper>
    </AuthenticationRoot>
  );
};


export const PasswordSet: React.FC<{
  verifyEmailVerificationCodeData: ApolloQueryResult<MutationResponseByType[MutationType.VerifyEmailVerificationCode]>;
  onSubmit: (data: IResetPasswordUpdateVariables) => void;
  isLoading: boolean;
  email: string;
}> = ({ verifyEmailVerificationCodeData, onSubmit, email, isLoading }) => {
  const { t } = useLocale();
  const [ password, setPassword ] = useState('');
  const [ consents, setConsents ] = useState<IConsent[]>([
    {
      type: ConsentTypeNG.TERMS_AND_CONDITIONS,
      approval: false,
    },
    {
      type: ConsentTypeNG.MARKETING,
      approval: false,
    },
  ]);

  const hasData = verifyEmailVerificationCodeData && verifyEmailVerificationCodeData.data;
  const showConsents = hasData && !verifyEmailVerificationCodeData.data.verifyEmailVerificationCode.isTermsAndConditionsAccepted;

  const handleConsentsChange = (data: IConsent[]) => {
    setConsents(data);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value.trim());
  };
  const handleSubmit = () => {
    onSubmit(
      {
        newPassword: password,
        token: verifyEmailVerificationCodeData.data.verifyEmailVerificationCode.token,
        consents: showConsents ? consents : undefined,
        email,
      }
    );
  };

  return (
    <SetPassWrapper>
      <LogoWrapper>
        <KeyIcon width={44} height={44} />
      </LogoWrapper>
      <AuthenticationInfoHeader weight="bold">
        {t('authentication.setPass.title')}
      </AuthenticationInfoHeader>
      <SpacingBlock mTop={3} />
      <PasswordSetForm
        passwordRules={validatePassword(password)}
        password={password}
        setPassword={handlePasswordChange}
        consents={consents}
        handleConsentsChange={showConsents ? handleConsentsChange : undefined}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </SetPassWrapper>
  );
};
