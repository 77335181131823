import React from 'react';
import { insideReactNative } from 'react-native/lib/platform';

const tempBase = 'https://basement';

function ensureDevDisableSSR(url: string) {
  const urlObj = new URL(url, tempBase);

  if (window && (urlObj.host !== window.location.host) && tempBase !== urlObj.origin) return url;

  if (urlObj.searchParams.get('devDisableSSR') === '1') return url;

  urlObj.searchParams.set('devDisableSSR', '1');
  return urlObj.toString().split(urlObj.origin)[1];
}


function useAnchorLink(href: string) {
  const isInsideRN = insideReactNative();

  return React.useMemo(() => {
    if (!href) return href;
    return isInsideRN ? ensureDevDisableSSR(href) : href;
  }, [ href, isInsideRN ]);
}

export default useAnchorLink;
