import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import ModalIcon from 'assets/svg/notification-with-cirlce.svg';
import WhatsappIcon from 'assets/svg/whatsapp-icon.svg';
import EmailIcon from 'assets/svg/email-icon.svg';
import { useLocale } from 'locale';
import { RootAction, State } from 'store/state';
import { AgentSupportModalVariant } from 'store/state/app';
import { closeAgentSupportModal } from 'store/state/app/actions';
import {
  agentSupportModalVariantSelector,
  isAgentSupportModalOpenSelector,
} from 'store/state/app/selectors';
import Modal from 'ds/components/modal';
import { DefaultText, H3, Text } from 'ds/components/typography';
import { useContactSupportHandlers } from './useContactSupportHandlers';
import { SUPPORT_EMAIL, SUPPORT_WHATSAPP_DISPLAY_NUMBER } from './const';
import { ItemHeader, ItemRightSide, ItemSubheader, ItemWrapper } from './styled';

const ModalContent = styled.div(({ theme }) => ({
  margin: '0 auto',
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.media.createObjectMediaQuery({ from: 3 })]: {
    width: '300px',
  },
}));

const ModalIconWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Title = styled(H3.withComponent('h1'))(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
  textAlign: 'center',
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

const Subtitle = styled(Text)(({ theme }) => ({
  color: theme.colors.neutrals.grey1,
  textAlign: 'center',
}));

const SupportOptions = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}));

const WorkingHoursText = styled(DefaultText)(({ theme }) => ({
  color: theme.colors.neutrals.grey3,
  fontSize: '11px',
  marginTop: theme.spacing(4),
}));


interface AgentSupportModalProps {
  isOpen: boolean;
  onClose: () => void;
  variant: AgentSupportModalVariant;
}

const AgentSupportModal: React.FC<AgentSupportModalProps> = props => {
  const { isOpen, onClose, variant } = props;
  const { t } = useLocale();
  const { handleWhatsAppClick, handleEmailClick } = useContactSupportHandlers();

  const title = t('agentSupportModal.title', { variant });

  const options = [
    {
      header: t('agentSupportModal.contactViaWhatsapp'),
      subheader: SUPPORT_WHATSAPP_DISPLAY_NUMBER,
      icon: <WhatsappIcon />,
      onClick: handleWhatsAppClick,
    },
    {
      header: t('agentSupportModal.contactViaEmail'),
      subheader: SUPPORT_EMAIL,
      icon: <EmailIcon />,
      onClick: handleEmailClick,
    },
  ];

  const handleSubheaderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalIconWrapper>
          <ModalIcon width={88} height={88} />
        </ModalIconWrapper>
        {title ? <Title>{title}</Title> : null}
        <Subtitle>
          {t('agentSupportModal.forDetails', { variant })}
        </Subtitle>
        <SupportOptions>
          {options.map((item) => (
            <ItemWrapper
              key={item.header}
              onClick={item.onClick}
            >
              <ItemRightSide>
                <ItemHeader weight="medium">{item.header}</ItemHeader>
                <ItemSubheader onClick={handleSubheaderClick}>{item.subheader}</ItemSubheader>
              </ItemRightSide>
              {item.icon}
            </ItemWrapper>
          ))}
        </SupportOptions>
        <WorkingHoursText>
          {t('agentSupportModal.workingHours')}
        </WorkingHoursText>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state: State) => ({
  isOpen: isAgentSupportModalOpenSelector(state),
  variant: agentSupportModalVariantSelector(state),
});


const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(closeAgentSupportModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentSupportModal);
