import { call, select, all, put, getContext, fork } from 'redux-saga/effects';
import { State as RouteState, Route } from 'config/routes';
import { LoadType, LoadOptions } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { flow } from 'lodash';
import { ISearchPoiVariables, IDocId2InformationVariables, IAutocompleteAddress } from 'utils/entities';
import { FetchInsightsParams } from '../loadInsights';
import { loadInsights } from '../loadInsights';
import { navigateTo } from 'store/state/router/actions';
import { addressDocEntrySelector as addressSelector, addressDocIdSelector, localDataDocSelector, addressDocEntrySelector } from 'store/state/domainData/selectors';
import { queryUGCData, ugcAddressPageWatcher } from './ugcAddressPageWatcher';
import { madadWinnersHandler } from './madadWinnersHandler';
import { getTileRange } from 'utils/geo';
import { makeSearchVariablesSelector } from 'store/state/app/selectors';
import { resetDomainData } from 'store/state/domainData/actions';
import { tabuWatcher } from 'store/sagas/tabuWatcher';
import { fetchArticles, getCityDocIdFromDocument } from '../utils';


const insightsVariablesSelector = flow(
  addressSelector,
  (doc): FetchInsightsParams => {
    if (!doc) return null;

    return {
      docId: doc.docId,
      user: null,
      location: doc.location,
    };
  }
);
const searchVariablesSelector = flow(
  addressSelector,
  (doc): ISearchPoiVariables => {
    const [ lng, lat ] = doc.location;
    const tileRange = getTileRange(lng, lat);

    return {
      dealType: null,
      tileRanges: [ tileRange ],
      poiTypes: [ 'bulletin', 'project' ],
    };
  }
);

const localDataVariablesSelector = flow(addressSelector, (doc: IAutocompleteAddress) => {
  const { neighbourhoodDocId } = doc;
  return neighbourhoodDocId ? { docId: neighbourhoodDocId } : null;
});

export function* addressHandler(params: RouteState) {
  yield fork(SSRAddressHandler, params);
  yield fork(tabuWatcher);
}

export function* SSRAddressHandler(params: RouteState) {
  try {
    try {
      const loadOptions: LoadOptions<LoadType.AddressDoc> = {
        loadType: LoadType.AddressDoc,
        meta: { variables: { docId: params.params.address } },
      };
      yield call(queryData, loadOptions);
    }
    catch (e) {
      const logger = yield getContext('logger');
      logger.error('Address page docId query failed, redirecting Home.', e);
      yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
      return;
    }
    const identityDocId = yield select(addressDocIdSelector);
    yield call(queryUGCData, identityDocId);

    const searchVariables: ISearchPoiVariables = yield select(searchVariablesSelector);
    const insightsVariables: FetchInsightsParams = yield select(insightsVariablesSelector);
    const localDataVariables: IDocId2InformationVariables = yield select(localDataVariablesSelector);
    const document = yield select(addressDocEntrySelector);

    const neighbourhoodDocId = document && document.neighbourhoodDocId;

    yield all([
      localDataVariables && call(queryData, {
        loadType: LoadType.LocalData,
        meta: { variables: localDataVariables },
      }),
      searchVariables && call(queryData, {
        loadType: LoadType.AddressSearch,
        meta: { variables: searchVariables },
      }),
      call(madadWinnersHandler),
      insightsVariables && fork(loadInsights, insightsVariables),
      neighbourhoodDocId && call(queryData, {
        loadType: LoadType.SearchAwardsByDocIds,
        meta: { variables: { args: { docIds: [ neighbourhoodDocId ] } } },
      }),
      fork(fetchArticles, getCityDocIdFromDocument([ document ]), 'address'),
    ].filter(Boolean));


    // currently the BE may return addresses that don't have neighborhood
    // in this case we need to reset LoadType.LocalData because we might display data from the last query
    if (localDataVariables) {
      const variables: ISearchPoiVariables = yield select(makeSearchVariablesSelector(localDataDocSelector));
      const projectLoadOptions: LoadOptions<LoadType.SearchProjectList> = {
        loadType: LoadType.SearchProjectList,
        meta: { variables },
      };
      yield fork(queryData, projectLoadOptions);
    }
    else {
      yield put(resetDomainData({ loadType: LoadType.LocalData }));
    }
  }
  catch (e) {
    const logger = yield getContext('logger');
    logger.error('One of queries failed in address page handler, redirecting Home.', e);
    yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
    return;
  }
}

export function* addressPageClientHandler (params: RouteState) {
  yield fork(addressHandler, params);
  yield fork(ugcAddressPageWatcher);
}
