import React from 'react';
import { MenuLinkWrapper, ChevronIconContainer } from './styled';
import { DecodedRouteParams, Route } from 'config/routes';
import { noop } from 'lodash';
import { Link } from 'components/link';
import ChevronIcon from 'assets/svg/navigation/chevron-left.svg';
import WarningIcon from 'assets/svg/navigation/exclamation-error.svg';
import useAnchorLink from 'hooks/useAnchorLink';

interface MenuLinkProps {
  target?: string;
  onClick?: () => void;
  routeName?: Route;
  routeParams?: Partial<DecodedRouteParams>;
  href?: string;
  withArrow?: boolean;
  fullWidthAnchor?: boolean;
  children: React.ReactNode;
  accented?: boolean;
  isInitialIconColor?: boolean;
  isDrawerLink?: boolean;
  hasWarning?: boolean;
  grey2Color?: boolean;
}

export const MenuLink: React.FC<MenuLinkProps> = ({
  onClick = noop,
  routeName,
  href,
  withArrow,
  fullWidthAnchor,
  children,
  accented,
  isInitialIconColor,
  isDrawerLink,
  grey2Color,
  hasWarning,
  ...rest
}) => {
  let content = null;

  const link = useAnchorLink(href);
  const anchorStyle = fullWidthAnchor ? { width: '100%' } : null;

  if (routeName) {
    content = <Link preserveParams routeName={routeName} style={anchorStyle} {...rest}>{children}</Link>;
  }
  else if (link) {
    content = <a href={link} style={anchorStyle} {...rest}>{children}</a>;
  }
  else {
    content = <div>{children}</div>;
  }

  return (
    <MenuLinkWrapper
      data-auto={`action-${typeof children === 'string' ? children : children[1]}`.split(' ').join('-').toLowerCase()}
      onClick={onClick}
      accented={accented}
      isInitialIconColor={isInitialIconColor}
      isDrawerLink={isDrawerLink}
      grey2Color={grey2Color}
    >
      {content}
      {hasWarning
        ? <WarningIcon />
        : withArrow
          ? <ChevronIconContainer><ChevronIcon /></ChevronIconContainer>
          : null
      }
    </MenuLinkWrapper>
  );
};
