import {
  Amenity,
  BathOption,
  Condition,
  Seller,
  IFiltersState,
  PropertyType,
  RoomOption,
  QualityClassOption,
} from 'components/filters/types';
import { SortValue } from 'components/listing-sort/types';
import { parse as parseSortValue, stringify as stringifySortValue } from 'components/listing-sort/utils';
import { invert, isString, isNil, keys, pick, flow, values } from 'lodash';
import { useRoute as baseUseRoute } from 'react-router5';
import { RouteContext as BaseRouteContext } from 'react-router5/types/types';
import createRouter, { Router, State as BaseState, StateMeta } from 'router5';
import { BoundingBox, PoiId, DealType, MarketplaceType } from 'utils/entities';
import { fakeHistoryPluginFactory } from './routerPlugins';
import { ProfileTab } from 'components/profile/Profile';
import { useMemo } from 'react';
import { routeSelector, DialogWithURLType, DialogUrl, DialogMeta, prevRouteSelector } from 'store/state/selectors/router';
import { ExpandCollection } from 'store/state/insightsContext';
import { StepOrder } from 'components/user-generated-content/utils';
import { NavigationOptions } from 'store/state/router/actionPayloads';
import { Section } from 'components/navigation/subheader/SectionsNavigation';
import { crossAtob, crossBtoa } from 'utils/base64';
import { useSelector } from 'react-redux';
import { UploadBulletinStep } from 'components/bulletin-form/types';

export enum Route {
  Home = 'home',
  Search = 'search',
  AddressPage = 'address',
  CheckAddress = 'checkAddress',
  UnitPage = 'bulletin',
  UnitPageCommercial = 'bulletinCommercial',
  Profile = 'profile',
  ProjectPage = 'projects',
  ProjectPageCommercial = 'projectsCommercial',
  ProjectPromotionPage = 'projectPromotion',
  MyHomes = 'myHomes',
  SavedSearchesPage = 'savedSearches',
  Shortlist = 'shortlist',
  LocalPage = 'local',
  UnsubscribePage = 'unsubscribe',
  SavedSearch = 'savedSearch',
  AriaSearchForm = 'ariaSearchForm',
  AriaSearchResults = 'ariaSearchResults',
  AriaUnit = 'ariaUnit',
  AriaProject = 'ariaProject',
  UploadBulletin = 'uploadBulletin',
  ManageBulletins = 'manageBulletins',
  Sold = 'sold',
  TermsPage = 'terms',
  EditBulletin = 'editBulletin',
  EditCommercialBulletin = 'editCommercialBulletin',
  StreetPage = 'street',
  MadadCity = 'madadCity',
  MadadPage = 'madad',
  MadadAgentPage = 'madadAgent',
  MadadArchivePage = 'madadArchive',
  MadadCityArchive = 'madadCityArchive',
  MadadArchive2020Page = 'madadArchive2020',
  Accessibility = 'accessibility',
  MadadSearchPage = 'madadSearch',
  Sitemap = 'site-map',
  CommercialMarketLanding = 'commercialMarketLanding',
  SearchCommercial = 'searchCommercial',
  DeveloperPage = 'developer',
  DevelopersSearchPage = 'developersSearch',
  DevelopersCitySearchPage = 'developersCitySearch',
  MortgageOfficesSearchPage = 'mortgageOfficesSearch',
  MortgageOfficesCitySearchPage = 'mortgageOfficesCitySearch',
  EmploymentAreaPage = 'employmentArea',
  OfficePage = 'office',
  AgentPage = 'agent',
  MortgageOfficePage = 'mortgageOffice',
  MadadLottery = 'madadLottery',
  MadadLotteryTerms = 'madadLotteryTerms',
  Israel4ever = 'israel4ever',
  AutomationTestPage = 'automationTestPage',
  PushSettings = 'pushSettings',
  DevelopersPromotionPage = 'developersPromotionPage',
  AgentsPromotionPage = 'AgentsPromotionPage',
  Deals = 'deals',
  Listings = 'listings',
  UploadBulletinForm = 'uploadBulletinForm',
  EditBulletinForm = 'editBulletinForm',
  EditResidentialBulletinForm = 'editResidentialBulletinForm',
}

export const AGENT_CONSOLE_ROUTES = new Set([
  Route.Deals,
  Route.Listings,
  Route.UploadBulletinForm,
  Route.EditBulletinForm,
  Route.EditResidentialBulletinForm,
]);

export const COMMERCIAL_ROUTES = new Set([
  Route.SearchCommercial,
  Route.CommercialMarketLanding,
  Route.UnitPageCommercial,
  Route.ProjectPageCommercial,
  Route.EmploymentAreaPage,
]);

export const COMMERCIAL_INFO_PAGES_ROUTES = new Set([
  Route.EmploymentAreaPage,
]);

export type NavigateToFn = (route: Route, params: DecodedRouteParams, options?: NavigationOptions) => void;

interface EncodedRouteParams {
  id?: string;
  dealType?: string;
  marketplace?: string;
  page: string;
  term?: string;
  bbox: string;
  filters: string;
  sort: string;
  address?: string;
  initialTab?: string;
  token?: string;
  loginToken?: string;
  insightId?: string;
  step?: string;
  insightCollection?: ExpandCollection;
  isMapActive?: number;
  agentId?: string;
  initialQuestion?: string;
  buyer_success_popup?: string;
  agentReferences?: string;
  noLoginRedirect?: string;
  dialog?: string;
  hiddenListingModal?: number;
  year?: string;
  scrollTo?: string;
  noPopups?: string;
  showAgentConsole?: string;
  activeListingImage?: string;
  openDeals?: boolean;
  searchDeals?: boolean;
}

export interface DecodedRouteParams {
  id?: PoiId;
  dealType?: DealType;
  marketplace?: MarketplaceType;
  term?: string[];
  page?: number;
  bbox?: BoundingBox;
  filters?: Partial<IFiltersState>;
  sort?: SortValue[];
  address?: string;
  initialTab?: ProfileTab;
  token?: string;
  loginToken?: string;
  insightId?: string;
  step?: UploadBulletinStep;
  insightCollection?: ExpandCollection;
  tracking_event_source?: string;
  tracking_push_uuid?: string;
  tracking_list_index?: number;
  tracking_event_section_name?: string;
  tracking_event_story_index?: number;
  tracking_search_source?: string;
  isMapActive?: number;
  utm_campaign?: string;
  mp_remarketing?: string;
  isActiveReviewResultPage?: number;
  initialQuestion?: StepOrder;
  agentId?: string;
  dialog?: DialogUrl;
  buyer_success_popup?: boolean;
  ugc_invite?: string;
  agentLoginToken?: string;
  shareBulletin?: boolean;
  agentReferences?: boolean;
  sourceVisibleSection?: Section;
  noLoginRedirect?: boolean;
  dynamic_search?: string;
  source?: string;
  hiddenListingModal?: number;
  year?: number;
  projectIL?: string;
  scrollTo?: string;
  isMapExpanded?: boolean;
  noPopups?: boolean;
  showAgentConsole?: boolean;
  activeListingImage?: number;
  openDeals?: boolean;
  searchDeals?: boolean;
  type?: string;
}

const nonNullTuple = (tuple: [number, number]) => {
  if (!tuple) return false;
  const [ a, b ] = tuple;
  return a !== null || b !== null;
};

export const parseInteger = (str: string) => {
  const parsed = parseInt(str, 10);
  return isNaN(parsed) ? null : parsed;
};

const parseFloatval = (str: string) => {
  const parsed = parseFloat(str);
  return isNaN(parsed) ? null : parsed;
};

const FILTER_CHECK = '✓';

const parseDialog = (str: string): DialogUrl => {
  const empty: DialogUrl = {
    type: null,
    meta: null,
  };

  if (!str) return empty;

  const [
    type,
    meta,
  ] = str.split('_') as [ DialogWithURLType, string ];

  if (type && DialogWithURLSet.has(type)) {
    let parsedMeta = null;
    try {
      parsedMeta = flow(crossAtob, decodeURI, JSON.parse)(meta);
    }
    catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`can not parse - ${meta}`);
    }

    return parsedMeta
      ? { type, meta: parsedMeta as DialogMeta }
      : empty;
  }

  return empty;
};

export const stringifyDialog = ({
  type,
  meta,
}: Partial<DialogUrl> = {}): string => {
  const filteredMeta = pick(meta, [ 'block', 'downloadToken', 'parcel' ]);
  const emptyMetaFotTabu = isNil(meta) || keys(filteredMeta).length === 0;
  if (!type || emptyMetaFotTabu) return undefined;

  return [
    type,
    flow(JSON.stringify, encodeURI, crossBtoa)(filteredMeta),
  ].join('_');
};

const parseFilters = (str: string): Partial<IFiltersState> => {
  if (!str) return undefined;
  const [
    feeStr,
    priceRange,
    roomsRange,
    conditionsStr,
    sellerStr,
    propTypesStr,
    amenitiesStr,
    bathsRange,
    floorRangeStr,
    areaRangeStr,
    monthlyTaxRange,
    ppmRange,
    underPriceEstimation,
    priceDrop,
    qualityClassStr,
    numberOfEmployeesRangeStr,
    projectDiscountStr,
  ] = str.split('_');

  return {
    fee: feeStr ? feeStr === FILTER_CHECK : undefined,
    projectDiscount: projectDiscountStr ? projectDiscountStr === FILTER_CHECK : undefined,
    qualityClass: qualityClassStr ? (qualityClassStr.split(',') as QualityClassOption[]) : undefined,
    numberOfEmployeesRange: numberOfEmployeesRangeStr ? (numberOfEmployeesRangeStr.split('-').map(parseInteger) as [number, number]) : undefined,
    priceRange: priceRange ? (priceRange.split('-').map(parseInteger) as [number, number]) : undefined,
    ppmRange: ppmRange ? (ppmRange.split('-').map(parseInteger) as [number, number]) : undefined,
    monthlyTaxRange: monthlyTaxRange ? (monthlyTaxRange.split('-').map(parseInteger) as [number, number]) : undefined,
    conditions: conditionsStr ? (conditionsStr.split(',') as Condition[]) : undefined,
    seller: sellerStr ? (sellerStr.split(',') as Seller[]) : undefined,
    roomsRange: roomsRange ? (roomsRange.split('-').map(parseInteger) as [RoomOption, RoomOption]) : undefined,
    bathsRange: bathsRange ? (bathsRange.split('-').map(parseFloatval) as [BathOption, BathOption]) : undefined,
    propertyTypes: propTypesStr ? (propTypesStr.split(',') as PropertyType[]) : undefined,
    amenities: amenitiesStr ? (amenitiesStr.split(',') as Amenity[]) : undefined,
    floorRange: floorRangeStr ? (floorRangeStr.split('-').map(parseInteger) as [number, number]) : undefined,
    areaRange: areaRangeStr ? (areaRangeStr.split('-').map(parseInteger) as [number, number]) : undefined,
    priceChanges: {
      underPriceEstimation: underPriceEstimation === FILTER_CHECK,
      priceDrop: priceDrop === FILTER_CHECK,
    },
  };
};

export const stringifyFilters = ({
  priceRange,
  roomsRange,
  propertyTypes,
  amenities,
  bathsRange,
  fee,
  conditions,
  seller,
  floorRange,
  areaRange,
  monthlyTaxRange,
  ppmRange,
  priceChanges,
  qualityClass,
  projectDiscount,
  numberOfEmployeesRange,
}: Partial<IFiltersState> = {}): string => {
  const priceRangeStr = nonNullTuple(priceRange) ? priceRange.join('-') : undefined;
  const ppmRangeStr = nonNullTuple(ppmRange) ? ppmRange.join('-') : undefined;
  const roomsRangeStr = nonNullTuple(roomsRange) ? roomsRange.join('-') : undefined;
  const bathsRangeStr = nonNullTuple(bathsRange) ? bathsRange.join('-') : undefined;
  const propertyTypesStr = propertyTypes && propertyTypes.join(',');
  const amenitiesStr = amenities && amenities.join(',');
  const feeStr = fee ? FILTER_CHECK : undefined;
  const projectDiscountStr = projectDiscount ? FILTER_CHECK : undefined;
  const conditionsStr = conditions && conditions.join(',');
  const sellerStr = seller && seller.join(',');
  const floorRangeStr = nonNullTuple(floorRange) ? floorRange.join('-') : undefined;
  const areaRangeStr = nonNullTuple(areaRange) ? areaRange.join('-') : undefined;
  const monthlyTaxRangeStr = nonNullTuple(monthlyTaxRange) ? monthlyTaxRange.join('-') : undefined;
  const underPriceEstimation = priceChanges && priceChanges.underPriceEstimation ? FILTER_CHECK : undefined;
  const priceDrop = priceChanges && priceChanges.priceDrop ? FILTER_CHECK : undefined;
  const qualityClassStr = qualityClass && qualityClass.join(',');
  const numberOfEmployeesRangeStr = nonNullTuple(numberOfEmployeesRange) ? numberOfEmployeesRange.join('-') : undefined;

  const arr = [
    feeStr,
    priceRangeStr,
    roomsRangeStr,
    conditionsStr,
    sellerStr,
    propertyTypesStr,
    amenitiesStr,
    bathsRangeStr,
    floorRangeStr,
    areaRangeStr,
    monthlyTaxRangeStr,
    ppmRangeStr,
    underPriceEstimation,
    priceDrop,
    qualityClassStr,
    numberOfEmployeesRangeStr,
    projectDiscountStr,
  ];
  if (!arr.filter(Boolean).length) return undefined;

  return arr.join('_');
};

const parseBbox = (encoded: string): BoundingBox => {
  if (!encoded) return undefined;
  const [ west, south, east, north ] = encoded.split(',').map(parseFloat);
  return [ [ west, south ], [ east, north ] ];
};

const stringifyBbox = (decoded: BoundingBox) => {
  if (!decoded) return undefined;
  const [ [ west, south ], [ east, north ] ] = decoded;
  // toFixed for prettier url
  return [ west, south, east, north ].map(v => v.toFixed(5)).join(',');
};

const stringifySort = (decoded: SortValue[]): string => {
  if (!decoded || !decoded.length) return undefined;
  return decoded.map(stringifySortValue).join(',');
};
const parseSort = (encoded: string): SortValue[] => {
  if (!encoded || !encoded.length) return [];
  const splitResult = encoded.split(',');

  if (!splitResult.length) return [];

  return splitResult.map(parseSortValue);
};

export const dealTypeToSlug: Record<DealType, string> = {
  [DealType.Buy]: 'sale',
  [DealType.Rent]: 'rent',
};
const slugToDealType = invert(dealTypeToSlug) as Record<string, DealType>;

const FORWARD_SLASH_IN_UTF_SYSTEM = '%2F';
const parseAddress = (address: string) => {
  if (!address) return undefined;
  return address.replace(FORWARD_SLASH_IN_UTF_SYSTEM, '/');
};

const stringifyAddress = (address: string) => {
  if (!address) return undefined;
  return address.replace('/', FORWARD_SLASH_IN_UTF_SYSTEM);
};

function parseMarketplace(v: string) {
  return values(MarketplaceType).find(type => type === v) || undefined;
}

export const SEARCH_TERM_DELIMITER = ',';

const parseTerm = (term: string | string[]): string[] => term && isString(term) ? term.split(SEARCH_TERM_DELIMITER) : term as string[];


const stringifyTerm = (term: string[]): string => term && isString(term) ? term : term && term.join(SEARCH_TERM_DELIMITER);

const DialogWithURLSet = new Set(Object.values(DialogWithURLType));

export const commonCoders = {
  decodeParams: ({
    term,
    agentReferences,
    buyer_success_popup,
    bbox,
    dealType,
    filters,
    id,
    page,
    sort,
    address,
    initialTab,
    token,
    insightCollection,
    loginToken,
    insightId,
    step,
    initialQuestion,
    noLoginRedirect,
    dialog,
    marketplace,
    year,
    noPopups,
    showAgentConsole,
    activeListingImage,
    openDeals,
    searchDeals,
    ...params
  }: EncodedRouteParams): DecodedRouteParams => ({
    ...params,
    term: parseTerm(term),
    address: parseAddress(address),
    id: id as PoiId,
    page: parseInteger(page) || undefined,
    bbox: parseBbox(bbox),
    dealType: slugToDealType[dealType],
    marketplace: parseMarketplace(marketplace),
    filters: parseFilters(filters),
    sort: parseSort(sort),
    initialTab: initialTab as ProfileTab,
    dialog: parseDialog(dialog),
    token: token ? decodeURIComponent(token) : undefined,
    loginToken: loginToken ? decodeURIComponent(loginToken) : undefined,
    insightCollection: insightCollection || undefined,
    insightId: insightId || undefined,
    step: step as UploadBulletinStep,
    initialQuestion: initialQuestion as StepOrder || undefined,
    buyer_success_popup: (buyer_success_popup === 'true') || undefined,
    agentReferences: (agentReferences === 'true') || undefined,
    noLoginRedirect: (noLoginRedirect === 'true') || undefined,
    year: parseInteger(year) || undefined,
    noPopups: (noPopups === 'true') || undefined,
    showAgentConsole: showAgentConsole !== undefined,
    activeListingImage: activeListingImage !== undefined ? parseInteger(activeListingImage) : undefined,
    openDeals: openDeals !== undefined,
    searchDeals: searchDeals !== undefined,
  }),
  encodeParams: ({
    term,
    buyer_success_popup,
    agentReferences,
    bbox,
    dealType,
    filters = {},
    page,
    sort,
    address,
    initialTab,
    token,
    loginToken,
    insightCollection,
    insightId,
    step,
    dialog,
    initialQuestion,
    noLoginRedirect,
    marketplace,
    year,
    noPopups,
    showAgentConsole,
    activeListingImage,
    openDeals,
    searchDeals,
    ...params
  }: DecodedRouteParams): EncodedRouteParams => ({
    ...params,
    term: stringifyTerm(term),
    address: stringifyAddress(address),
    page: page && page.toString(),
    filters: stringifyFilters(filters),
    bbox: stringifyBbox(bbox),
    dealType: dealTypeToSlug[dealType],
    marketplace: marketplace || undefined,
    sort: stringifySort(sort),
    initialTab: initialTab as string,
    token: token ? encodeURIComponent(token) : undefined,
    loginToken: loginToken ? encodeURIComponent(loginToken) : undefined,
    insightCollection: insightCollection || undefined,
    insightId: insightId || undefined,
    dialog: stringifyDialog(dialog),
    step: step ? String(step) : undefined,
    initialQuestion: initialQuestion || undefined,
    buyer_success_popup: buyer_success_popup ? 'true' : undefined,
    agentReferences: agentReferences ? 'true' : undefined,
    noLoginRedirect: noLoginRedirect ? 'true' : undefined,
    year: year ? String(year) : undefined,
    noPopups: noPopups ? 'true' : undefined,
    showAgentConsole: showAgentConsole ? null : undefined,
    activeListingImage: activeListingImage !== undefined ? activeListingImage.toString() : undefined,
    openDeals: openDeals ? null : undefined,
    searchDeals: searchDeals ? null : undefined,
  }),
};

const PARAM_MATCHER_RE = `([^\\/\?]*)`; // all except ? and /


export interface AppRouterRoute {
  path: string;
  name: Route;
  encodeParams?: (params: DecodedRouteParams) => EncodedRouteParams;
  decodeParams?: (params: EncodedRouteParams) => DecodedRouteParams;
  canonicalPath?: string;
  privateRoute?: (routeName: Route, routeParams: DecodedRouteParams) => boolean;
}


export const routes: AppRouterRoute[] = [
  {
    name: Route.Home,
    path: '/',
    canonicalPath: '/',
    ...commonCoders,
  },
  {
    name: Route.Search,
    path: `/for-:dealType<${Object.keys(slugToDealType).join('|')}>/:term<${PARAM_MATCHER_RE}>?:page&:bbox&:filters&:sort`,
    canonicalPath: `/for-:dealType<${Object.keys(slugToDealType).join('|')}>/:term?:page`,
    privateRoute: (_, params) => params.term.length > 1,
    ...commonCoders,
  },
  {
    name: Route.SearchCommercial,
    path: `/commercial/for-:dealType<${Object.keys(slugToDealType).join('|')}>/:term<${PARAM_MATCHER_RE}>?:page&:bbox&:filters&:sort`,
    canonicalPath: `/commercial/for-:dealType<${Object.keys(slugToDealType).join('|')}>/:term?:page`,
    privateRoute: (_, params) => params.term.length > 1,
    ...commonCoders,
  },
  {
    name: Route.UnitPageCommercial,
    path: `/commercial/listings/:id<${PARAM_MATCHER_RE}>?:dealType&:term&:page&:bbox&:filters&:sort&:address&:insightId&:insightCollection&:isMapActive`,
    canonicalPath: `/commercial/listings/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.UnitPage,
    path: `/listings/:id<${PARAM_MATCHER_RE}>?:dealType&:term&:page&:bbox&:filters&:sort&:address&:insightId&:insightCollection&:isMapActive`,
    canonicalPath: `/listings/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.AddressPage,
    path: `/address/:address<${PARAM_MATCHER_RE}>?:dealType&:id&:term&:page&:bbox&:filters&:sort&:insightId&:insightCollection&:isMapActive`,
    ...commonCoders,
  },
  {
    name: Route.ProjectPage,
    path: `/projects/:id<${PARAM_MATCHER_RE}>?:term&:page&:bbox&:filters&:sort&:insightId&:insightCollection&:isMapActive`,
    canonicalPath: `/projects/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.ProjectPageCommercial,
    path: `/commercial/projects/:id<${PARAM_MATCHER_RE}>?:term&:page&:bbox&:filters&:sort&:insightId&:insightCollection&:isMapActive`,
    canonicalPath: `/commercial/projects/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.ProjectPromotionPage,
    path: `/projects/promotion/:projectId<${PARAM_MATCHER_RE}>`,
    canonicalPath: `/projects/promotion/:projectId<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.MadadPage,
    path: '/madad',
    canonicalPath: `/madad`,
    ...commonCoders,
  },
  {
    name: Route.MadadCity,
    path: '/madad/2024/:id',
    canonicalPath: '/madad/2024/:id',
    ...commonCoders,
  },
  {
    name: Route.MadadArchive2020Page,
    path: `/madad/2020`,
    canonicalPath: `/madad/2020`,
    ...commonCoders,
  },
  {
    name: Route.MadadCityArchive,
    path: '/madad/:year/:id',
    canonicalPath: '/madad/:year/:id',
    ...commonCoders,
  },
  {
    name: Route.MadadArchivePage,
    path: `/madad/:year`,
    canonicalPath: `/madad/:year`,
    ...commonCoders,
  },
  {
    name: Route.MadadAgentPage,
    path: `/search-agent`,
    canonicalPath: `/search-agent`,
    ...commonCoders,
  },
  {
    name: Route.MadadSearchPage,
    path: `/madad-search/:id<${PARAM_MATCHER_RE}>`,
    canonicalPath: `/madad-search/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.Accessibility,
    path: '/accessibility',
    canonicalPath: '/accessibility',
    ...commonCoders,
  },
  {
    name: Route.TermsPage,
    path: '/etc/terms',
    canonicalPath: '/etc/terms',
    ...commonCoders,
  },
  {
    name: Route.Sitemap,
    path: '/site-map',
    canonicalPath: '/site-map',
    ...commonCoders,
  },
  {
    name: Route.Profile,
    path: '/profile',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.CheckAddress,
    path: '/explore',
    canonicalPath: `/explore`,
    ...commonCoders,
  },
  {
    name: Route.MyHomes,
    path: '/my-homes',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.SavedSearchesPage,
    path: '/saved-searches',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.PushSettings,
    path: '/push-settings',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.SavedSearch,
    path: `/saved-search/:id<${PARAM_MATCHER_RE}>`,
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.Shortlist,
    path: '/shortlist',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.LocalPage,
    path: `/area-info/:id<${PARAM_MATCHER_RE}>&:term&:bbox&:filters&:sort&:insightId&:insightCollection`,
    ...commonCoders,
  },
  {
    name: Route.UnsubscribePage,
    path: `/unsubscribe/:token?:type&:source`,
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.AriaSearchForm,
    path: '/aria-search-form',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.AriaSearchResults,
    path: '/aria-search-results',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.AriaUnit,
    path: '/aria-unit',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.AriaProject,
    path: '/aria-project',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.UploadBulletin,
    path: '/upload-bulletin',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.ManageBulletins,
    path: '/manage-bulletins',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.Sold,
    path: `/sold/:id`,
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.EditBulletin,
    path: '/edit-bulletin/:id',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.EditCommercialBulletin,
    path: '/commercial/edit-bulletin/:id',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.StreetPage,
    path: `/street-info/:id<${PARAM_MATCHER_RE}>&:term`,
    ...commonCoders,
  },
  {
    name: Route.CommercialMarketLanding,
    path: `/commercial-market`,
    canonicalPath: '/commercial-market',
    ...commonCoders,
  },
  {
    name: Route.DeveloperPage,
    path: `/developer/:id<${PARAM_MATCHER_RE}>`,
    canonicalPath: '/developer/:id',
    ...commonCoders,
  },
  {
    name: Route.MortgageOfficePage,
    path: `/mortgage-office/:id<${PARAM_MATCHER_RE}>`,
    canonicalPath: '/mortgage-office/:id',
    ...commonCoders,
  },
  {
    name: Route.DevelopersSearchPage,
    path: '/developers',
    canonicalPath: '/developers',
    ...commonCoders,
  },
  {
    name: Route.DevelopersCitySearchPage,
    path: '/developers/:id',
    canonicalPath: '/developers/:id',
    ...commonCoders,
  },
  {
    name: Route.MortgageOfficesSearchPage,
    path: '/mortgage-offices?:page',
    canonicalPath: '/mortgage-offices?:page',
    ...commonCoders,
  },
  {
    name: Route.MortgageOfficesCitySearchPage,
    path: `/mortgage-offices/:term<${PARAM_MATCHER_RE}>?:page`,
    canonicalPath: '/mortgage-offices/:term?:page',
    ...commonCoders,
  },
  {
    name: Route.EmploymentAreaPage,
    path: `/commercial/area-info/:id<${PARAM_MATCHER_RE}>&:term&:bbox&:filters&:sort&:insightId&:insightCollection`,
    canonicalPath: `/commercial/area-info/:id<${PARAM_MATCHER_RE}>`,
    ...commonCoders,
  },
  {
    name: Route.OfficePage,
    path: `/agentsOffice/:id<${PARAM_MATCHER_RE}>`,
    canonicalPath: `/agentsOffice/:id`,
    ...commonCoders,
  },
  {
    name: Route.AgentPage,
    path: `/agent/:id<${PARAM_MATCHER_RE}>`,
    canonicalPath: `/agent/:id`,
    ...commonCoders,
  },
  {
    name: Route.MadadLottery,
    path: '/madad-lottery',
    canonicalPath: '/madad-lottery',
    ...commonCoders,
  },
  {
    name: Route.MadadLotteryTerms,
    path: '/madad-lottery/terms',
    canonicalPath: '/madad-lottery/terms',
    ...commonCoders,
  },
  {
    name: Route.Israel4ever,
    path: '/israel4ever',
    canonicalPath: '/israel4ever',
    ...commonCoders,
  },
  {
    name: Route.AutomationTestPage,
    path: '/automation-test-page',
    canonicalPath: '/automation-test-page',
    ...commonCoders,
  },
  {
    name: Route.DevelopersPromotionPage,
    path: '/lp/promotion',
    canonicalPath: '/lp/promotion',
    ...commonCoders,
  },
  {
    name: Route.AgentsPromotionPage,
    path: '/lp/agents',
    canonicalPath: '/lp/agents',
    ...commonCoders,
  },
  {
    name: Route.Deals,
    path: '/console/agent/deals',
    canonicalPath: '/console/agent/deals',
    ...commonCoders,
  },
  {
    name: Route.Listings,
    path: '/console/agent/listings',
    canonicalPath: '/console/agent/listings',
    ...commonCoders,
  },
  {
    name: Route.UploadBulletinForm,
    path: '/console/agent/upload-bulletin',
    canonicalPath: '/console/agent/upload-bulletin',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.EditBulletinForm,
    path: '/console/agent/edit-bulletin/:id',
    canonicalPath: '/console/agent/edit-bulletin/:id',
    privateRoute: () => true,
    ...commonCoders,
  },
  {
    name: Route.EditResidentialBulletinForm,
    path: '/console/agent/edit-residential-bulletin/:id',
    canonicalPath: '/console/agent/edit-residential-bulletin/:id',
    privateRoute: () => true,
    ...commonCoders,
  },
];

const routerFactory = () => {
  const router = createRouter(routes, {
    defaultRoute: Route.Home,
    queryParamsMode: 'loose',
  });

  router.usePlugin(fakeHistoryPluginFactory());
  return router;
};

export interface State extends BaseState {
  name: Route;
  params: DecodedRouteParams;
  meta?: StateMeta & NavigationOptions;
}

export interface RouteState extends BaseRouteContext {
  router: Router;
  route: State;
  previousRoute: State | null;
}

export default routerFactory;

export const useRoute = () => {
  const realContextValue = baseUseRoute() as RouteState;
  const route = useSelector(routeSelector);
  const previousRoute = useSelector(prevRouteSelector);
  // @TODO get rid of this hook
  const contextValue: RouteState = useMemo(
    () => ({
      ...realContextValue,
      route,
      previousRoute,
    }),
    [ realContextValue, route, previousRoute ]
  );

  return contextValue;
};
