import { BiEvent } from 'analytics';
import { fork, getContext, select, take, call, cancel, takeEvery } from 'redux-saga/effects';
import { contactModalPoiIdSelector, intentModalPoiIdSelector, welcomeModalPoiIdSelector } from 'store/state/app/selectors';
import { contactAgentInputClick, setContactAgentOpen } from 'store/state/app/actions';
import { CONTACT_AGENT_INPUT_CLICK, SET_CONTACT_AGENT_OPEN, SET_INTENT_MODAL_OPEN, SET_WELCOME_MODAL_OPEN } from 'store/state/app/types';
import { ContactAgentModalSource } from 'store/state/app';
import config from 'config';
import { DealType } from 'utils/entities';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { MutationType } from 'store/sagas/apiService/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';

const allowedEventNames = [
  'property_lead_form_submit',
  'property_lead_form_submit_attempt',
  'property_lead_call_submit',
  'property_lead_phone_expose',
  'property_lead_form_close',
  'property_lead_form_submit_success',
];

// smells, but required, not for export
let globalContactAgentSource: ContactAgentModalSource;

export function* contactAgentModalPreProcessor({ name, category, payload }: BiEvent) {
  if (!allowedEventNames.includes(name)) {
    return { name, category, payload };
  }

  return {
    name,
    category,
    payload: {
      ...payload,
      event: {
        source: globalContactAgentSource,
        ...(payload.event ? payload.event : {}),
      },
    },
  };
}

function* contactAgentModalChangeWorker() {
  const { sendEvent } = yield getContext('analytics');
  while (true) {
    const oldPoiId = yield select(contactModalPoiIdSelector);
    const { payload: poiId, meta: { source, userInitiated } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_CONTACT_AGENT_OPEN);
    if (source) globalContactAgentSource = source; // dont reassign globalContactAgentSource when source falsy to keep source for property_lead_form_close event
    if (poiId) {
      yield call(sendEvent, 'property_lead_form_open', 'property', { bulletinId: poiId, event: { source } });
    }
    else if (userInitiated) {
      yield call(sendEvent, 'property_lead_form_close', 'property', { bulletinId: oldPoiId });
    }
  }
}

function* contactInputClickResolver(source: string) {
  const { sendEvent } = yield getContext('analytics');
  while (true) {
    const { payload: { eventName, poi } }: ReturnType<typeof contactAgentInputClick> = yield take(CONTACT_AGENT_INPUT_CLICK);
    const location = {
      borough: poi.addressDetails && poi.addressDetails.borough,
      street_name: poi.addressDetails && poi.addressDetails.streetName,
      street_number: poi.addressDetails && poi.addressDetails.streetNumber,
      unit_number: poi.addressDetails && poi.addressDetails.unitNumber,
      city: poi.addressDetails && poi.addressDetails.city,
      country: config.country,
      location_type: 'address',
      neighborhood: poi.addressDetails && poi.addressDetails.neighbourhood,
    };
    yield call(sendEvent, eventName, 'property', { event: { source }, location, forceProperty: true, property: { deal_type: poi.dealType === DealType.Buy ? 'for_sale' : 'for_rent', property_id: poi.id } });
  }
}

let task: any;
function* contactInputClickWorker() {
  while (true) {
    const { payload: poiId, meta: { source } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_CONTACT_AGENT_OPEN);
    if (task && !poiId) {
      yield cancel(task);
    }
    if (poiId) {
      task = yield fork(contactInputClickResolver, source);
    }
  }
}

let intentTask: any;
function* intentInputClickWorker() {
  while (true) {
    const { payload: poiId, meta: { source } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_INTENT_MODAL_OPEN);
    if (intentTask && !poiId) {
      yield cancel(intentTask);
    }
    if (poiId) {
      intentTask = yield fork(contactInputClickResolver, source);
    }
  }
}

let welcomeTask: any;
function* welcomeInputClickWorker() {
  while (true) {
    const { payload: poiId, meta: { source } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_WELCOME_MODAL_OPEN);
    if (welcomeTask && !poiId) {
      yield cancel(welcomeTask);
    }
    if (poiId) {
      welcomeTask = yield fork(contactInputClickResolver, source);
    }
  }
}

function* intentModalChangeWorker() {
  const { sendEvent } = yield getContext('analytics');
  while (true) {
    const oldPoiId = yield select(intentModalPoiIdSelector);
    const { payload: poiId, meta: { source, userInitiated } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_INTENT_MODAL_OPEN);
    if (source) globalContactAgentSource = source; // dont reassign globalContactAgentSource when source falsy to keep source for property_lead_form_close event
    if (poiId) {
      yield call(sendEvent, 'property_lead_form_open', 'property', { bulletinId: poiId, event: { source } });
    }
    else if (userInitiated) {
      yield call(sendEvent, 'property_lead_form_close', 'property', { bulletinId: oldPoiId });
    }
  }
}

function* welcomeModalChangeWorker() {
  const { sendEvent } = yield getContext('analytics');
  while (true) {
    const oldPoiId = yield select(welcomeModalPoiIdSelector);
    const { payload: poiId, meta: { source, userInitiated } } : ReturnType<typeof setContactAgentOpen> = yield take(SET_WELCOME_MODAL_OPEN);
    if (source) globalContactAgentSource = source; // dont reassign globalContactAgentSource when source falsy to keep source for property_lead_form_close event
    if (poiId) {
      yield call(sendEvent, 'property_lead_form_open', 'property', { bulletinId: poiId, event: { source } });
    }
    else if (userInitiated) {
      yield call(sendEvent, 'property_lead_form_close', 'property', { bulletinId: oldPoiId });
    }
  }
}


const contactSubmitResponseMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.ContactPageSubmit
);

function* contactSubmitResponseWatcher(action: SetMutationResponseAction<MutationType.ContactPageSubmit>) {
  const { variables, response: { data } } = action.meta;

  if (data && data.contactV4) {
    const leadId = data.contactV4 && data.contactV4.lead && data.contactV4.lead.leadId;

    if (leadId) {
      const { sendEvent } = yield getContext('analytics');
      const { message, email, phone, isMortgageConsult, eventSource, name, source } = variables;
      const eSource = eventSource || source;
      const payload = {
        event: {
          message,
          is_mortgage_consult: isMortgageConsult,
          contact_details: {
            email,
            phone,
            full_name: name,
          },
          lead_id: leadId,
          ...(eSource ? { source: eSource } : null),
        },
        bulletinId: variables.id,
      };

      yield call(sendEvent, 'property_lead_form_submit_success', 'property', payload);
    }
  }
}

export function* contactAgentModalEventsWorker() {
  yield fork(contactAgentModalChangeWorker);
  yield fork(intentModalChangeWorker);
  yield fork(welcomeModalChangeWorker);
  yield fork(contactInputClickWorker);
  yield fork(intentInputClickWorker);
  yield fork(welcomeInputClickWorker);
  yield takeEvery(contactSubmitResponseMutationPattern, contactSubmitResponseWatcher);
}
