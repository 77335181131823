import { useCallback } from 'react';
import { useLocale } from 'locale';
import { createEmailLink, createWhatsAppLink } from 'utils/shareLinksUtils';
import { SUPPORT_EMAIL, SUPPORT_WHATSAPP_NUMBER } from './const';

export const useContactSupportHandlers = () => {
  const { t } = useLocale();
  const body = t('navigation.subheader.supportPopup.message.body');
  const subject = t('navigation.subheader.supportPopup.message.subject');

  const handleWhatsAppClick = useCallback(() => {
    const urlObj = new URL(window.location.href);
    urlObj.search = '';

    const href = createWhatsAppLink({ phone: SUPPORT_WHATSAPP_NUMBER, text: body });

    window.open(href);
  }, [ body ]);

  const handleEmailClick = useCallback(() => {
    const href = createEmailLink({ subject, body, email: SUPPORT_EMAIL });
    window.location.href = href;
  }, [ subject, body ]);

  return {
    handleWhatsAppClick,
    handleEmailClick,
  };
};
